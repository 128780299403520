import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faTruckBolt as powerIssuesIcon,
    faTemperatureHigh as temperatureIssuesIcon,
    faSlidersUp as modeIssuesIcon,
    faSignalSlash as silentIssuesIcon,
    faGasPump as fuelLevelIssuesIcon,
    faPowerOff as inactiveIssuesIcon,
    faTruckClock as detentionTruckIcon,
    faArrowUpRightFromSquare as linkIcon
} from '@fortawesome/pro-solid-svg-icons';
import { reactPlugin } from 'AppInsights';
import AuthContext from 'AuthContext';
import { groupedByIssues, issuesStatusUrl, detainedTrailersNumberUrl, totalTrailersNumberUrl } from 'global/constants';
import { getDayBefore } from 'global/services/DateTimeService';
import { issueType } from 'global/services/IssueTypeService';
import QueryParamService from 'global/services/QueryParamService';
import history from 'global/history';
import IssueCard from 'components/card/IssueCard';
import TooltipComp from 'components/tooltip/TooltipComp';

import './Dashboard.scss';

const powerTooltip = <>The table displays discrepancies in <br /> case the reefer unit setting is <br /> set to be powered on or off in the <br /> system and it's not matching what the <br /> reefer unit is transmitting.</>;
const modeTooltip = <>The table displays a list of issues when <br /> the Mode Configuration from the reefer <br /> unit is not matching the Mode Configuration <br /> required in the system.</>;
const tempTooltip = <>The table displays potential temperature <br /> issues when reefer temperatures from the <br /> reefer unit are detected to fall outside of the <br /> minimum and maximum temperature thresholds <br /> set forth in this system.</>;
const silentUnitTooltip = <>The table displays a list of trailers that <br /> have not transmitted updates to the system <br /> within the last 2 hours. Due to the lack of <br /> updates, the system cannot detect nor monitor <br /> potential issues. A manual check must be <br /> done to reboot the reefer unit.</>;
const detentionTooltip = <>The table displays a list of trailers <br /> that are currently located at the facility.</>;
const inactiveTrailersTooltip = <>The table displays a list of trailers that <br /> are inactive. Inactive trailers are trailers <br /> that have NOT moved outside of the a <br /> radius of 0.5 miles for 3 or more days.</>;
const fuelLevelIssuesTooltip = <>The table displays a list of trailers with <br /> low fuel levels.</>;
const fleetOverviewTooltip = <>The Dashboard view displays a real-time overview of reefer issue statistics for the past 24 hours for the trailers in the system.</>;

const CACHE = {};
const cachingIndex = 'dashboard';

class Dashboard extends Component {
    static contextType = AuthContext;
    interval = 0;

    constructor(props) {
        super(props);

        let activeIssues = [];
        let resolvedIssues = [];
        let snoozedIssues = [];

        for (let type in issueType) {
            activeIssues[type] = 0;
            resolvedIssues[type] = 0;
            snoozedIssues[type] = 0;
        }

        QueryParamService.addFleets(JSON.parse(localStorage.getItem('fleets') || '[]'));

        this.state = {
            groupedIssues: {
                "POWER_STATE": [],
                "TEMPERATURE": [],
                "UNIT_MODE": [],
                "SILENT": [],
                "INACTIVITY": [],
                "LOW_FUEL_ALARM": []
            },
            countActiveIssues: activeIssues,
            countResolvedIssues: resolvedIssues,
            countSnoozedIssues: snoozedIssues,
            detainedTrailers: 0,
            activeTrailers: 0,
            loaded: false,
            height: window.innerHeight,
            width: window.innerWidth,
            account: props.account,
            issueCards: [],
            pinnedIssues: []
        }

        this.updateDimensions = this.updateDimensions.bind(this);
        this.addToPinned = this.addToPinned.bind(this);
        this.removeFromPinned = this.removeFromPinned.bind(this);
    }

    async componentDidMount() {
        await this.fetchShortcutModuleData();
        window.addEventListener('resize', this.updateDimensions);
        this.interval = setInterval(async () => await this.fetchShortcutModuleData(), 60000);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.fleets !== this.props.fleets) {
            this.fetchShortcutModuleData();
        }

        if (prevState.countActiveIssues !== this.state.countActiveIssues
            || prevState.countResolvedIssues !== this.state.countResolvedIssues
            || prevState.countSnoozedIssues !== this.state.countSnoozedIssues
            || prevState.detainedTrailers !== this.state.detainedTrailers) {
            this.createIssueCards();
        }
    }

    createIssueCards() {
        const pinnedIssuesFromStorrage = JSON.parse(localStorage.getItem("pinnedIssues")) || [];
        const allIssues = [
            {
                id: issueType.POWER_STATE,
                activeIssuesCount: this.state.countActiveIssues[issueType.POWER_STATE],
                snoozedIssuesCount: this.state.countSnoozedIssues[issueType.POWER_STATE],
                resolvedIssuesCount: this.state.countResolvedIssues[issueType.POWER_STATE],
                pathname: "issues/power",
                title:"Power Issues",
                tooltip: powerTooltip,
                icon: <FontAwesomeIcon icon={ powerIssuesIcon } />,
                pinned: pinnedIssuesFromStorrage.includes(issueType.POWER_STATE)
            },
            {
                id: issueType.TEMPERATURE,
                activeIssuesCount: this.state.countActiveIssues[issueType.TEMPERATURE],
                snoozedIssuesCount: this.state.countSnoozedIssues[issueType.TEMPERATURE],
                resolvedIssuesCount: this.state.countResolvedIssues[issueType.TEMPERATURE],
                pathname: "issues/temperature",
                title:"Temperature Issues",
                tooltip: tempTooltip,
                icon: <FontAwesomeIcon icon={ temperatureIssuesIcon } />,
                pinned: pinnedIssuesFromStorrage.includes(issueType.TEMPERATURE)
            },
            {
                id: issueType.UNIT_MODE,
                activeIssuesCount: this.state.countActiveIssues[issueType.UNIT_MODE],
                snoozedIssuesCount: this.state.countSnoozedIssues[issueType.UNIT_MODE],
                resolvedIssuesCount: this.state.countResolvedIssues[issueType.UNIT_MODE],
                pathname: "issues/mode",
                title:"Mode Issues",
                tooltip: modeTooltip,
                icon: <FontAwesomeIcon icon={ modeIssuesIcon } />,
                pinned: pinnedIssuesFromStorrage.includes(issueType.UNIT_MODE)
            },
            {
                id: issueType.SILENT,
                activeIssuesCount: this.state.countActiveIssues[issueType.SILENT],
                snoozedIssuesCount: this.state.countSnoozedIssues[issueType.SILENT],
                resolvedIssuesCount: this.state.countResolvedIssues[issueType.SILENT],
                pathname: "issues/silent",
                title:"Silent Units",
                tooltip: silentUnitTooltip,
                icon: <FontAwesomeIcon icon={ silentIssuesIcon } />,
                pinned: pinnedIssuesFromStorrage.includes(issueType.SILENT)
            },
            {
                id: issueType.LOW_FUEL_ALARM,
                activeIssuesCount: this.state.countActiveIssues[issueType.LOW_FUEL_ALARM],
                snoozedIssuesCount: this.state.countSnoozedIssues[issueType.LOW_FUEL_ALARM],
                resolvedIssuesCount: this.state.countResolvedIssues[issueType.LOW_FUEL_ALARM],
                pathname: "issues/low-fuel",
                title:"Fuel level issues",
                tooltip: fuelLevelIssuesTooltip,
                icon: <FontAwesomeIcon icon={ fuelLevelIssuesIcon } />,
                pinned: pinnedIssuesFromStorrage.includes(issueType.LOW_FUEL_ALARM)
            },
            {
                id: issueType.INACTIVITY,
                activeIssuesCount: this.state.countActiveIssues[issueType.INACTIVITY],
                snoozedIssuesCount: this.state.countSnoozedIssues[issueType.INACTIVITY],
                resolvedIssuesCount: this.state.countResolvedIssues[issueType.INACTIVITY],
                pathname: "issues/inactivity",
                title:"Inactive trailers",
                tooltip: inactiveTrailersTooltip,
                icon: <FontAwesomeIcon icon={ inactiveIssuesIcon } />,
                pinned: pinnedIssuesFromStorrage.includes(issueType.INACTIVITY)
            },
            {
                id: 'DETENTION',
                activeIssuesCount: this.state.detainedTrailers,
                pathname: "detention",
                title:"Detained trailers",
                tooltip: detentionTooltip,
                icon: <FontAwesomeIcon icon={ detentionTruckIcon } />,
                pinned: pinnedIssuesFromStorrage.includes('DETENTION')
            }
        ];

        this.setState({
            issueCards: allIssues,
            pinnedIssues: pinnedIssuesFromStorrage.map(issueId => allIssues.find(issue => issue.id === issueId))
        });
    }

    updateDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        clearInterval(this.interval);
    }

    async fetchShortcutModuleData() {
        this.setState({
            loaded: false
        });

        await this.fetchIssueNumber();
        await this.fetchIssuesStatus();
        await this.fetchDetainedTrailersNumber();
        await this.fetchTotalTrailersNumber();

        this.setState({
            loaded: true
        });
    }

    async fetchIssuesStatus() {
        if (CACHE[cachingIndex] !== undefined) {
            this.setState({
                countActiveIssues: CACHE[cachingIndex].activeIssues ? CACHE[cachingIndex].activeIssues : [],
                countResolvedIssues: CACHE[cachingIndex].resolvedIssues ? CACHE[cachingIndex].resolvedIssues : [],
                countSnoozedIssues: CACHE[cachingIndex].snoozedIssues ? CACHE[cachingIndex].snoozedIssues : []
            });
        }

        const response = await this.context.get(issuesStatusUrl, { endTimeFrom: getDayBefore(new Date()) });

        if (response.status === "error") {
            console.error(response.message);
            return {};
        }

        let activeIssues = Array.from([]);
        let resolvedIssues = Array.from([]);
        let snoozedIssues = Array.from([]);

        for (let type in issueType) {
            if (type === "DEFAULT") {
                continue;
            }

            activeIssues[type] = response.filter(issueCount => issueCount.issueType === type)[0].countActiveIssues;
            resolvedIssues[type] = response.filter(issueCount => issueCount.issueType === type)[0].countResolvedIssues;
            snoozedIssues[type] = response.filter(issueCount => issueCount.issueType === type)[0].countSnoozedIssues;
        }

        CACHE[cachingIndex] = {
            ...CACHE[cachingIndex],
            activeIssues: activeIssues,
            resolvedIssues: resolvedIssues,
            snoozedIssues: snoozedIssues
        };

        this.setState({
            countActiveIssues: activeIssues,
            countResolvedIssues: resolvedIssues,
            countSnoozedIssues: snoozedIssues
        });
    }

    async fetchIssueNumber() {
        if (CACHE[cachingIndex] !== undefined) {
            this.setState({
                groupedIssues: CACHE[cachingIndex].groupedIssues ? CACHE[cachingIndex].groupedIssues : []
            });
        }

        const response = await this.context.get(groupedByIssues, {});

        if (response.status === "error") {
            console.error(response.message);
            return {};
        }

        CACHE[cachingIndex] = {
            ...CACHE[cachingIndex],
            groupedIssues: response
        };

        this.setState({
            groupedIssues: response
        });
    }

    async fetchDetainedTrailersNumber() {
        if (CACHE[cachingIndex] !== undefined) {
            this.setState({
                detainedTrailers: CACHE[cachingIndex].detainedTrailers
            });
        }

        const response = await this.context.get(detainedTrailersNumberUrl);

        if (response.status === "error") {
            console.error(response.message);
            return;
        }

        this.setState({
            detainedTrailers: response
        });
    }

    async fetchTotalTrailersNumber() {
        if (CACHE[cachingIndex] !== undefined) {
            this.setState({
                activeTrailers: CACHE[cachingIndex].activeTrailers
            });
        }

        const response = await this.context.get(totalTrailersNumberUrl);

        if (response.status === "error") {
            console.error(response.message);
            return;
        }

        this.setState({
            activeTrailers: response
        });
    }

    addToPinned(id) {
        const index = this.state.issueCards.findIndex(issueCard => issueCard.id === id);
        const pinnedIssues = [...this.state.pinnedIssues, this.state.issueCards[index]];

        this.setState(prevState => ({
            issueCards: [
                ...prevState.issueCards.slice(0, index),
                { ...prevState.issueCards[index], pinned: true },
                ...prevState.issueCards.slice(index + 1)
            ],
            pinnedIssues
        }));

        localStorage.setItem("pinnedIssues", JSON.stringify(pinnedIssues.map(issue => issue.id)));
    }

    removeFromPinned(id) {
        const index = this.state.issueCards.findIndex(issueCard => issueCard.id === id);
        const pinnedIssues = this.state.pinnedIssues.filter(issue => issue.id !== id);

        this.setState(prevState => ({
            issueCards: [
                ...prevState.issueCards.slice(0, index),
                { ...prevState.issueCards[index], pinned: false },
                ...prevState.issueCards.slice(index + 1)
            ],
            pinnedIssues
        }));

        localStorage.setItem("pinnedIssues", JSON.stringify(pinnedIssues.map(issue => issue.id)));
    }

    routeChange(pathname) {
        history.push({
          pathname
        });
    }

    renderIssueCard(issue, pinned) {
        return (
            <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                <IssueCard
                    key={ issue.id }
                    id={ issue.id }
                    activeIssuesCount={ issue.activeIssuesCount || 0 }
                    snoozedIssuesCount={ issue.snoozedIssuesCount || 0 }
                    resolvedIssuesCount={ issue.resolvedIssuesCount || 0 }
                    pathname={ issue.pathname }
                    title={ issue.title }
                    tooltip={ issue.tooltip }
                    icon={ issue.icon }
                    pinCard={ pinned ? this.removeFromPinned : this.addToPinned }
                />
            </div>
        );
    }    

    render() {
        const hasIssuesBoxes = this.state.issueCards.some(issue => !issue.pinned)

        return (
            <div className="container-fluid page dashboard">
                <div className="row">
                    <div className="col page-header">
                        <h1 className="page-title">
                            Dashboard <TooltipComp customClassName="fleet-tooltip" description={fleetOverviewTooltip} position="center"></TooltipComp> &nbsp; &nbsp; <Loader type="TailSpin" color="#289AC2" height={40} width={40} visible={!this.state.loaded}/>
                        </h1>
                        <h3 className="page-subtitle">Total trailers: <span>{ this.state.activeTrailers !== undefined ? this.state.activeTrailers : 0 }</span> <FontAwesomeIcon className="link-icon" onClick={() => this.routeChange("fleet")} icon={ linkIcon } /></h3>
                    </div>
                </div>
                <div className="row">
                    { this.state.pinnedIssues.length > 0 &&
                        <div className="col">
                            <h3 className="page-subtitle">Pinned categories</h3>
                        </div>
                    }
                    <div className="issues col-12">
                        {this.state.pinnedIssues.map(issue => this.renderIssueCard(issue, true))}
                    </div>

                    { hasIssuesBoxes && 
                        <div className="col">
                            <h3 className="page-subtitle">Categories</h3>
                        </div>
                    }
                    
                    <div className="issues col-12">
                        { this.state.issueCards.filter(issue => !issue.pinned).map(issue => this.renderIssueCard(issue, false)) }
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withAITracking(reactPlugin, Dashboard, "Dashboard"));
