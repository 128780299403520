import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../AppInsights';
import { withRouter } from "react-router";
import Loader from 'react-loader-spinner';

import DataTableComp from 'components/datatable/DataTableComp';

import { defaultGridRecordsNumber, notificationHistoryUrl } from 'global/constants'
import { issueTypeMap } from 'global/services/IssueTypeService'
import { formatLongDate } from "global/services/DateTimeService"
import AuthContext from "AuthContext"
import QueryParamService from 'global/services/QueryParamService';

import './NotificationHistory.scss';

const CACHE = {};

class NotificationHistory extends Component {
    static contextType = AuthContext;
    constructor(props) {
      super(props);

      this.columnNames = {
        0 : "ASSIGNMENT",
        1 : "TRAILER",
        2 : "ISSUE_TYPE",
        6 : "TIME"
      }

      this.columns = [
        { title: "Manifest #", data: "assignmentId" },
        { title: "Trailer #", data: "trailerId"},
        { title: "Alert type", data: "alertType" },
        { title: "Issue type", data: "issueType"},
        { title: "Notified", data: "notified" },
        { title: "Date and time", data: "datetime" },
        { title: "Status", data: "status" }
      ];
      this.sortingRule = [
      ];
      this.data = Array.from([]);
      this.handleFirstPage = this.handleFirstPage.bind(this);
      this.handleLastPage = this.handleLastPage.bind(this);
      this.handlePreviousPage = this.handlePreviousPage.bind(this);
      this.handleNextPage = this.handleNextPage.bind(this);
      this.handleRecordsNumber = this.handleRecordsNumber.bind(this);
      this.handlePageChange = this.handlePageChange.bind(this);

      this.columnDefs = [
        { width: "5%", "targets": [0, 1] },
        { width: "10%", "targets": [2, 5, 6]},
        { width: "15%", "targets": [3, 4] },
        { className: "dt-align-center", targets: [] },
        { className: "dt-align-left", targets: [2, 3, 4] },
        { className: "dt-align-right", targets: [0, 1, 5] },
        {
          searchable: false,
          targets: [0, 1, 2, 3, 4, 5, 6], //change this when implement search option
        },
        {
          orderable: false,
          targets: [0, 1, 2, 3, 4, 5, 6]
        },
        {
          targets: 0,
          createdCell: (td, cellData, rowData, row, col) => {
            const contentDecorator = rowData.assignmentId !== 'No manifest' ? 'blueColorText assignment link' : '';

            ReactDOM.render(
              <div className={contentDecorator}>
                {rowData.assignmentId}
              </div>,
              td
            );
          }
        },
        {
          targets: 1,
          createdCell: (td, cellData, rowData, row, col) => {
            ReactDOM.render(
              <div className="blueColorText trailer link">
                {rowData.trailerId}
              </div>,
              td
            );
          }
        },
        {
          targets: 6,
          createdCell: (td, cellData, rowData, row, col) => {
            let className= "bold ";
            let status = "";
            if(rowData.status.startsWith("RESOLVED")) {
              status = "Resolved"
              className += "greenColorText";
            } else {
              className += "redColorText"
              status = "Unresolved"
            }
            ReactDOM.render(
              <div className="">
              <div className={className}>
                  { status.indexOf("Resolved") !== -1 && <FontAwesomeIcon icon={faCheckCircle}/> }
                  { status.indexOf("Unresolved") !== -1 && <FontAwesomeIcon icon={faExclamationCircle}/> }
                  &nbsp;&nbsp;
                  {status}
                </div>
              </div>,
              td
            );
          }
        }
      ];

      let currentPage = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page') ? parseInt(QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page')) : 1;

      QueryParamService.addFleets(JSON.parse(localStorage.getItem('fleets') || '[]'));

      this.state = {
        notifications: this.data,
        sort: [],
        columns: this.columns,
        columnDefs: this.columnDefs,
        sortingRule: this.sortingRule,
        notificationsLoaded: false,
        loading: false,
        page: currentPage,
        pagingInfo: {
          recordsNumber: defaultGridRecordsNumber //default value
        }
      }
    };

    async componentDidMount() {
      await this.fetchNotifications();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.fleets !== this.props.fleets) {
        this.state.page !== 1 ? this.handleFirstPage() : this.fetchNotifications(true);
      }
    }

    alertTypeMap(alertType) {
      const lowerCase = alertType.toLowerCase().replace(/_/g, " ")
      return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
    }

    handleFirstPage = async () => {
      this.setState({
        page: 1
      }, async () => {
        await this.fetchNotifications();
        this.setState({
          pagingInfo: {
            ...this.state.pagingInfo,
            currentPage: this.state.page
          }
        })
      });
    }

    handleLastPage = async () => {
      this.setState({
        page: parseInt(this.state.pagingInfo.totalPageNumber)
      }, async () => {
        await this.fetchNotifications();
        this.setState({
          pagingInfo: {
            ...this.state.pagingInfo,
            currentPage: this.state.page
          }
        })
      })
    }

    handleNextPage = async () => {
      this.setState({
        page: parseInt(this.state.page) + 1
      }, async () => {
        await this.fetchNotifications();
        this.setState({
          pagingInfo: {
            ...this.state.pagingInfo,
            currentPage: this.state.page
          }
        })
      })
    }

    handlePreviousPage = async () => {
      this.setState({
        page: parseInt(this.state.page) - 1
      }, async () => {
        await this.fetchNotifications();
        this.setState({
          pagingInfo: {
            ...this.state.pagingInfo,
            currentPage: this.state.page
          }
        })
      })
    }

    handleRecordsNumber = async (event) => {
      let recordsNumber = parseInt(event.target.value);
      this.setState({
        pagingInfo: {
          ...this.state.pagingInfo,
          currentPage: 1,
          recordsNumber: recordsNumber
        },
        page: 1
      }, async () => {
        await this.fetchNotifications();
      })
    }

    handlePageChange = async (event) => {
      if (isNaN(event.target.value) || event.target.value === "") {
        this.state.page = "";
      } else {
        this.state.page = parseInt(event.target.value);
        const that = this;
        setTimeout(async function () {
          await that.fetchNotifications();
        }, 1000);
      }
      this.setState({
        pagingInfo: {
          ...this.state.pagingInfo,
          currentPage: this.state.page
        }
      })
    }

    async fetchNotifications(force) {
      if(!force && (this.state.page === "" || this.state.page < 1 || this.state.page > this.state.pagingInfo.totalPageNumber)) {
        this.setState({
          loading: false
        });
        return;
      }
      let cachingIndex = 'notification_history';

      if (CACHE[cachingIndex] !== undefined && this.state.page === 1) {
        this.setState({
          notifications: CACHE[cachingIndex].notifications,
          loading: true,
          notificationsLoaded: true,
          pagingInfo: CACHE[cachingIndex].pagingInfo,
        });
      } else {
        this.setState({
          loading: true
        });
      }

      const response = await this.context.get(notificationHistoryUrl, { forMaintenance: false, pageNumber: this.state.page, pageSize: this.state.pagingInfo.recordsNumber});
      if (response.status === "error") {
        console.error(response.message);
        this.setState({
          loading: false
        });
        return {};
      }
      const data = response.data;
      if(!data) {
        this.setState({ notificationsLoaded: true, loading: false });
        return;
      }

      let currentPage = Math.min(response.pageNumber, Math.ceil(response.available / response.pageSize));
      let queryString = window.location.search.slice(1);
      QueryParamService.addPageToQueryString(queryString, currentPage || 1);

      const notifications = data.map(notification => (
        {
          id: notification.metadata,
          assignmentId: notification.issue.assignment ? notification.issue.assignment.businessId : "No manifest",
          trailerId: notification.issue.trailer.businessId,
          issueId: notification.issue.id,
          alertType: this.alertTypeMap(notification.reason),
          notified: notification.contactInfo.email || "Unknown",
          issueType: issueTypeMap(notification.issue.type),
          datetime: formatLongDate(new Date(notification.time)),
          emailRecipient: notification.contactInfo.email,
          status: notification.issue.state,
          action: "",
          date: formatLongDate(new Date(notification.time)),
          onClickState:  notification.issue.assignment ? {trailerId: notification.issue.trailer.id, assignmentId: notification.issue.assignment.id } : {trailerId: notification.issue.trailer.id}
        }
      ));

      const pagingInfo = {
        itemNumber: Math.min(response.pageSize, response.data.length),
        totalItemNumber: response.available,
        totalPageNumber: Math.ceil(response.available / response.pageSize),
        currentPage: Math.min(response.pageNumber, Math.ceil(response.available / response.pageSize)),
        recordsNumber: response.pageSize
      }

      if (this.state.page === 1) {
        CACHE[cachingIndex] = {
          notifications: notifications,
          pagingInfo: pagingInfo
        };
      }

      this.setState({
        notifications: notifications,
        pagingInfo: pagingInfo,
        loading: false,
        notificationsLoaded: true
      });
    }


    render() {
      return (
        <>
          <div className="notifications">
            <nav>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item active"><a href="#!">All notifications</a></li>
                </ol>
            </nav>
            <p className="heading">
              Notification History <Loader type="TailSpin" color="#289AC2" height={47} width={47} visible={!this.state.notificationsLoaded || this.state.loading}/>
            </p>
            <div className="notification-history-div">
                { this.state.notificationsLoaded && <DataTableComp
                    tableId="notificationsHistory"
                    columns={this.state.columns}
                    orderRule={this.state.sortingRule}
                    data={this.state.notifications}
                    columnDefs={this.state.columnDefs}
                    customTableClass="cell-border"
                    tableHeight="1200px"
                    handlePreviousPage={this.handlePreviousPage}
                    handleNextPage={this.handleNextPage}
                    handleFirstPage={this.handleFirstPage}
                    handleLastPage={this.handleLastPage}
                    handleRecordsNumber={(event) => this.handleRecordsNumber(event)}
                    handlePageChange={(event) => this.handlePageChange(event)}
                    pagingInfo = {this.state.pagingInfo}
                /> }
            </div>
          </div>
        </>
      )
    };
  }

export default withRouter(withAITracking(reactPlugin, NotificationHistory, "NotificationHistory"));
