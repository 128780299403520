import React from 'react';
import { Route } from 'react-router-dom';
import NavBar from 'components/navigation/NavBar';
import Header from 'components/navigation/Header';

const CustomRoute = ({
    component: Component,
    ...rest
}) => (

  <div className="main">
    <Header />
    <NavBar />
    <main className="application-content">
      <Route {...rest} component={(props) => {return <Component {...props} />}}/>
    </main>
  </div>

)

export default CustomRoute;
