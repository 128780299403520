import React, { Children, Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort as sortIcon } from '@fortawesome/pro-duotone-svg-icons';
import QueryParamService from 'global/services/QueryParamService';

import './Table.scss';

export class Table extends Component {

    static propTypes = {
        className: PropTypes.string,
        layout: PropTypes.oneOf(['full', 'compact'])
    }

    static defaultProps = {
        className: '',
        layout: 'full'
    }

    render() {
        const { className, layout, ...rest } = this.props;

        return (
            <div className="overflow-auto">
                <table className={ `custom-table ${className} ${layout}` } { ...rest } />
            </div>
        );
    }
}

export class TableRow extends Component {

    static propTypes = {
        className: PropTypes.string
    }

    static defaultProps = {
        className: ''
    }

    render() {
        const { className, ...rest } = this.props;

        return (
            <tr className={ `table-row ${className}` } { ...rest } />
        );
    }
}

export class TableHeaderRow extends Component {

    static propTypes = {
        className: PropTypes.string
    }

    static defaultProps = {
        className: ''
    }

    constructor(props) {
        super(props);

        this.totalWidth = Children.toArray(props.children).reduce((prev, child) => prev + (child.props.width || 0), 0);
    }

    render() {
        const { className, children, ...rest } = this.props;

        return (
            <TableRow className={ `table-header-row ${className}` } { ...rest }>
                { Children.map(this.props.children, (child, i) => {
                    if (child.props.fixedWidth) {
                        return React.cloneElement(child, { key: i, style: { minWidth: child.props.fixedWidth } });
                    }

                    if (child.props.width) {
                        const width = (child.props.width / this.totalWidth * 100.0) + '%';
                        return React.cloneElement(child, { key: i, style: { width } });
                    }

                    return React.cloneElement(child, { key: i });
                }) }
            </TableRow>
        );
    }
}

export class TableHeaderCell extends Component {

    static propTypes = {
        align: PropTypes.oneOf(['left', 'center', 'right']),
        className: PropTypes.string,
        fixedWidth: PropTypes.number,
        width: PropTypes.number
    }

    static defaultProps = {
        align: 'left',
        className: '',
        fixedWidth: null,
        width: null
    }

    render() {
        const { align, className, fixedWidth, width, ...rest } = this.props;

        return (
            <th className={ `cell header-cell ${className} ${align}` } { ...rest } />
        );
    }
}

export class TableCell extends Component {

    static propTypes = {
        align: PropTypes.oneOf(['left', 'center', 'right']),
        className: PropTypes.string
    }

    static defaultProps = {
        align: 'left',
        className: ''
    }

    render() {
        const { align, className, ...rest } = this.props;

        return (
            <td className={ `cell data-cell ${className} ${align}` } { ...rest } />
        );
    }
}

export class SortableTableHeaderCell extends Component {

    static propTypes = {
        className: PropTypes.string,
        columnName: PropTypes.string.isRequired,
        onSort: PropTypes.func
    }

    static defaultProps = {
        className: '',
        onSort: () => { /* */ }
    }

    constructor(props) {
        super(props);

        const direction = QueryParamService.parseSimpleValueFromQueryString(
            window.location.search.slice(1),
            props.columnName.toLowerCase(),
            null
        );

        this.state = {
            direction
        };

        this.onSortClick = this.onSortClick.bind(this);
    }

    getDirection(previousDirection) {
        switch (previousDirection) {
            case null: // no sort selected, sort in ascending order
                return 'asc';
            case 'asc': // ascending order selected, reverse the order
                return 'desc';
            default: // descending order selected, clear sort
                return null;
        }
    }

    onSortClick() {
        this.setState(
            prevState => ({ direction: this.getDirection(prevState.direction) }),
            () => this.props.onSort(this.props.columnName, this.state.direction)
        );
    }

    render() {
        const { children, className, columnName, onSort, ...rest } = this.props;

        return (
            <TableHeaderCell className={ `clickable ${className}` } onClick={ this.onSortClick } { ...rest }>
                { children }
                <FontAwesomeIcon className={ `sort-icon ${this.state.direction}` } icon={ sortIcon } />
            </TableHeaderCell>
        );
    }
}

export class NoDataRow extends Component {
    render() {
        return (
            <TableRow { ...this.props }>
                <TableCell align="center" colSpan={ 1000 }>
                    No data available in table
                </TableCell>
            </TableRow>
        );
    }
}
