import React, { Component } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../AppInsights';

import './Fleet.scss';
import { defaultGridRecordsNumber, searchByUrl } from 'global/constants';
import AuthContext from 'AuthContext'
import FleetTable from './FleetTable'
import NoResults from './NoResults';
import { withRouter } from "react-router";
import Loader from 'react-loader-spinner';

class FleetOrders extends Component {
  static contextType = AuthContext;
  constructor(props) {
    super(props);
    this.state = {
      activeLegs: Array.from([]),
      inactiveLegs: Array.from([]),
      contains: props.location.state ? props.location.state.contains : "",
      searchBy: props.location.state ? props.location.state.searchBy : "leg",
      page: 1,
      pagingInfo: {
        recordsNumber: defaultGridRecordsNumber //default value
      },
      loadingFinished: false,
      dataUpdated: false,
      sort: Array.from([]),
    }
  }

  async componentDidMount() {
    await this.fetchFleet();
  }

  async componentDidUpdate(prevProps) {
    if(this.props.location.state) {
      const oldContainsString = this.state.contains;
      const contains = this.props.location.state ? this.props.location.state.contains : "";
      const oldSearchBy = this.state.searchBy;
      const searchBy = this.props.location.state ? this.props.location.state.searchBy : "";
      if (oldContainsString !== contains || oldSearchBy !== searchBy) {
        this.setState({
          contains,
          searchBy,
          page: 1,
          pagingInfo: {
            recordsNumber: defaultGridRecordsNumber
          },
          dataUpdated: false
        }, async () => {
          await this.fetchFleet(this.state.sort);
        });
      }
    }
  }

  async fetchFleet(sort) {
    this.setState({
      sort
    });
    if(this.state.page === "" || this.state.page < 1 || this.state.page > this.state.pagingInfo.totalPageNumber) return;
    const response = await this.context.get(searchByUrl(this.state.searchBy), {
      contains : this.state.contains.trim(),
      sort : sort || [],
      pageNumber: this.state.page,
      pageSize: this.state.pagingInfo.recordsNumber
    }, true);
    if(response.status === "error") {
      console.error(response.message);
      this.setState({
        loadingFinished: true,
        dataUpdated: true
      });
      return;
    }
    const data = response.data;
    if(!data || data.length <= 0) {
      this.setState(
        {
          loadingFinished: true,
          dataUpdated: true,
          data: Array.from([])
        }
      )
    } else {
      let dataToShow = [];
      data.forEach((item) => {
        if(this.state.searchBy === "legs") {
          item = {
            ...item,
            leg: item.businessId,
            division: "",
            onClickState: {trailerId: item.trailer.id, assignmentId: item.assignment.id}
          }
          dataToShow.push(item)

        } else if (this.state.searchBy === "trailers") {
          item = {
            ...item,
            assignment: item.leg ? item.leg.assignment : "",
            active: item.leg ? item.leg.active : "",
            leg: item.leg ? item.leg.businessId : "",
            onClickState: {trailerId: item.trailer.id, assignmentId: item.leg ? item.leg.assignment.id : undefined}
          }
          dataToShow.push(item)
        }
      });
      this.setState(
        {
          data : dataToShow,
          loadingFinished: true,
          dataUpdated: true,
          pagingInfo: {
            itemNumber: Math.min(response.pageSize, response.data.length),
            totalItemNumber: response.available,
            totalPageNumber: Math.ceil(response.available / response.pageSize),
            currentPage: Math.min(response.pageNumber, Math.ceil(response.available / response.pageSize)),
            recordsNumber: response.pageSize
          }
        }
      )
    }
  }

  handleFirstPage = async () => {
    this.setState({
      page: 1,
      dataUpdated: false
    }, async () => {
      await this.fetchFleet(this.state.sort);
      this.setState({
        pagingInfo: {
          ...this.state.pagingInfo,
          currentPage: this.state.page
        }
      })
    });
  }

  handleLastPage = async () => {
    this.setState({
      page: parseInt(this.state.pagingInfo.totalPageNumber),
      dataUpdated: false
    }, async () => {
      await this.fetchFleet(this.state.sort);
      this.setState({
        pagingInfo: {
          ...this.state.pagingInfo,
          currentPage: this.state.page
        }
      })
    })
  }

  handleNextPage = async () => {
    this.setState({
      page: parseInt(this.state.page) + 1,
      dataUpdated: false
    }, async () => {
      await this.fetchFleet(this.state.sort);
      this.setState({
        pagingInfo: {
          ...this.state.pagingInfo,
          currentPage: this.state.page
        }
      })
    })
  }

  handlePreviousPage = async () => {
    this.setState({
      page: parseInt(this.state.page) - 1,
      dataUpdated: false
    }, async () => {
      await this.fetchFleet(this.state.sort);
      this.setState({
        pagingInfo: {
          ...this.state.pagingInfo,
          currentPage: this.state.page
        }
      })
    })
  }

  handleRecordsNumber = async (event) => {
    let recordsNumber = parseInt(event.target.value);
    this.setState({
      pagingInfo: {
        ...this.state.pagingInfo,
        currentPage: 1,
        recordsNumber: recordsNumber
      },
      page: 1,
      dataUpdated: false
    }, async () => {
      await this.fetchFleet(this.state.sort);
    })
  }

  handlePageChange = async (event) => {
    if (isNaN(event.target.value) || event.target.value === "") {
      this.state.page = "";
    } else {
      this.state.page = parseInt(event.target.value);
      this.state.dataUpdated = false;
      const that = this;
      setTimeout(async function () {
        await that.fetchFleet(that.state.sort);
      }, 1000);
    }
    this.setState({
      pagingInfo: {
        ...this.state.pagingInfo,
        currentPage: this.state.page
      }
    })
  }

  render() {
    return (
      <>
        <div className="page fleet">
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/issues/all">All issues </a></li>
                        <li className="breadcrumb-item active">
                          <a href="#!">Search results </a>
                          <span className="svg-icon">
                            <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.5332 4.51978L0.123047 1.10962C0.0410156 1.02759 0 0.927979 0 0.810791C0 0.693604 0.0410156 0.593994 0.123047 0.511963L0.509766 0.125244C0.591797 0.0432129 0.691406 0.00219727 0.808594 0.00219727C0.925781 -0.00952148 1.02539 0.0256348 1.10742 0.107666L3.83203 2.83228L6.55664 0.107666C6.63867 0.0256348 6.73828 -0.00952148 6.85547 0.00219727C6.97266 0.00219727 7.07227 0.0432129 7.1543 0.125244L7.54102 0.511963C7.62305 0.593994 7.66406 0.693604 7.66406 0.810791C7.66406 0.927979 7.62305 1.02759 7.54102 1.10962L4.13086 4.51978C4.04883 4.61353 3.94922 4.6604 3.83203 4.6604C3.71484 4.6604 3.61523 4.61353 3.5332 4.51978Z" fill="#172437"/>
                            </svg>
                          </span>
                        </li>
                    </ol>
                </nav>
                <div className="searchHeading">
                  <p className="heading">
                    {this.state.data && this.state.data.length > 0 && this.state.loadingFinished && this.state.contains}
                  </p>
                  &nbsp; &nbsp; <Loader type="TailSpin" color="#289AC2" height={47} width={47} visible={!this.state.loadingFinished || !this.state.dataUpdated}/>
                </div>
                <div className="fleet-table">
                { this.state.data && this.state.data.length > 0 ?
                    <FleetTable
                      data={this.state.data}
                      account={this.state.account}
                      handlePreviousPage={this.handlePreviousPage}
                      handleNextPage={this.handleNextPage}
                      handleFirstPage={this.handleFirstPage}
                      handleLastPage={this.handleLastPage}
                      handleRecordsNumber={(event) => this.handleRecordsNumber(event)}
                      handlePageChange={(event) => this.handlePageChange(event)}
                      fetchFleet={this.fetchFleet.bind(this)}
                      pagingInfo = {this.state.pagingInfo}
                    />
               : this.state.loadingFinished ? <NoResults query={this.state.contains} /> : <></>
                }
                </div>
        </div>
      </>
    );
  }
}

export default withRouter(withAITracking(reactPlugin, FleetOrders, "FleetOrders"));
