import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faArrowUpRightFromSquare as redirectIcon,
    faUser as userIcon
} from '@fortawesome/pro-solid-svg-icons';

import { 
    managerUsersUrl, 
    USER_STATE, 
    userDropdownRecordsNumber, 
    sendStatsEmailSummaryUrl 
} from 'global/constants';
import { formatDateTimeToTimestamp } from 'global/services/DateTimeService';

import Chip from 'components/chip/Chip';
import MultiSelect from 'components/input/MultiSelect';
import ConfirmMessageContainer from 'components/container/ConfirmMessageContainer';
import ErrorMessageContainer from 'components/container/ErrorMessageContainer';
import SimpleContainer from 'components/container/SimpleContainer';

import AuthContext from 'AuthContext';

import './ShareStatsPopup.scss'

class ShareStatsPopup extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.initialState = {
            requestSucceeded: null,
            errorTitle: null,
            errorDescription: null,
            loading: false,
            recipientsLoading: true,
            recipients: [],
            selectedRecipientIds: [],
            selectedRecipients: [],
            userPage: 1,
            searchValue: ''
        };
        
        this.state = { ...this.initialState };

        this.onClose = this.onClose.bind(this);
        this.isDisabled = this.isDisabled.bind(this);
        this.handleSend = this.handleSend.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.fetchRecipients = this.fetchRecipients.bind(this);
        this.recipientOptions = this.recipientOptions.bind(this);
        this.mapRecipientOptions = this.mapRecipientOptions.bind(this);
        this.handleRemoveRecipients = this.handleRemoveRecipients.bind(this);
        this.handleResetSearchOptions = this.handleResetSearchOptions.bind(this);
    }
    
    componentDidMount() {
        this.fetchRecipients();
    }

    async fetchRecipients() {
        this.setState({ recipientsLoading: true });
        const pageNumber = this.state.userPage;
        const pageSize = userDropdownRecordsNumber;
        const params = { state: USER_STATE.ACCEPTED, pageNumber, pageSize };

        if (this.state.searchValue) {
            params.contains = this.state.searchValue;
        }

        const response = await this.context.get(managerUsersUrl, params);

        if (response.status === 'error') {
            console.error(response.message);
            this.setState({ recipientsLoading: false });
            return;
        }

        this.setState({
            searchValue: null,
            recipients: response.data,
            recipientsLoading: false
        });
    }

    mapRecipientOptions(recipients) {
        return recipients && recipients.length > 0 && 
            recipients.map(recipient => ({
                searchObject: recipient,
                value: recipient.id, 
                icon: userIcon, 
                label: <>{recipient.name} <span class="multi-select-custom-label" >({recipient.email})</span></>,
                mainLabel: recipient.name,
                isRemovable: true
            }))
    }

    recipientOptions(recipients) {
        return recipients && this.mapRecipientOptions(recipients);
    }

    generatePreviewStatsLink() {
        const startDate = formatDateTimeToTimestamp(this.props.data.startTimeFrom);
        const endDate = formatDateTimeToTimestamp(this.props.data.endTimeTo);
        return `/statistics/summary?startDate=${startDate}&endDate=${endDate}`;
    }

    handleRemoveRecipients(value) {
        this.setState(prevState => ({
            selectedRecipientIds: prevState.selectedRecipientIds.filter((id) => id !== value ),
            selectedRecipients: prevState.selectedRecipients.filter((recipient) => recipient.id !== value )
        }));
    }

    handleSearch(searchValue) {
        this.setState({ searchValue, userPage: 1 }, this.fetchRecipients);
    }

    handleResetSearchOptions() {
        this.fetchRecipients();
    }

    handleSelect(option) {
        const alreadySelected = this.state.selectedRecipients.filter(recipient => recipient.id === option.id);
        if(alreadySelected && alreadySelected.length > 0) {
            this.setState(prevState => ({
                selectedRecipients: prevState.selectedRecipients.filter(recipient => recipient.id !== option.id)
            }));
        } else {
            this.setState(prevState => ({
                selectedRecipients: [...prevState.selectedRecipients, option]
            }));
        }
    }

    async handleSend() {
        const body = {
            startTime: this.props.data.startTimeFrom, 
            endTime: this.props.data.endTimeTo, 
            recipients: this.state.selectedRecipients.map((recipient) => {
                return {
                    id: recipient.id,
                    name: recipient.name,
                    email: recipient.email
                }
            })
        };
    
        this.setState({ loading: true }); 

        const response = await this.context.post(sendStatsEmailSummaryUrl, body);

        const requestSucceeded = response.status !== 'error';
        this.setState({
            requestSucceeded,
            errorTitle: requestSucceeded ? '' : response.message.response.data.error,
            errorDescription: requestSucceeded ? '' : response.message.response.data.message,
            successfullyReceivedEmail: requestSucceeded ? response : "",
            loading: false
        }); 
    }

    onClose() {
        this.setState({ 
            requestSucceeded: null,
            errorTitle: null,
            errorDescription: null,
            loading: false,
            recipients: [],
            recipientsLoading: false,
            selectedRecipientIds: [],
            selectedRecipients: [],
            userPage: 1
         }, () => {
            this.fetchRecipients();
            this.props.onClose();
        });
    }

    isDisabled() {
        return !this.state.selectedRecipientIds || (this.state.selectedRecipientIds && this.state.selectedRecipientIds.length === 0) || this.state.loading;
    }

    render() {
        let modalContent;

        if (this.state.requestSucceeded) {
            const users = this.state.successfullyReceivedEmail.length > 1 ? (this.state.successfullyReceivedEmail.length + " users") : this.state.successfullyReceivedEmail[0].name;
            modalContent = (
                <ConfirmMessageContainer
                    title="Email sent"
                    description={
                        (
                            <>
                                Stats Overview Email has been successfully sent to <span className="bold">{ users }</span>.
                            </>
                        )
                    }
                    onClose={ this.onClose }
                />
            );
        } else if (this.state.requestSucceeded === false) {
            modalContent = (
                <ErrorMessageContainer
                    title={ this.state.errorTitle }
                    description={ this.state.errorDescription }
                    onClose={ this.onClose }
                />
            );
        } else {
            modalContent = (
                <SimpleContainer className="modal-container" modal title="SHARE STATS VIA EMAIL">
                    <div className="subtitle">Share Current Stats</div>
                    <p>
                        Enter the email addresses of the users you want to share the <br />
                        stats overview with. Notice that the email will contain the <br />
                        stats defined by the filters on the stats page.
                    </p>
                    <p>
                        <Link 
                            target="_blank"
                            className="redirect-link" 
                            to={ this.generatePreviewStatsLink() }
                        >
                            <FontAwesomeIcon className="redirect-icon" icon={ redirectIcon } />
                            Preview Email Template
                        </Link>
                    </p>
                    <div className="label">Recipients</div>

                    <MultiSelect
                        searchable
                        placeholder="Enter user name..."
                        disabled={ this.state.recipientsLoading }
                        selected={ this.state.selectedRecipientIds }
                        options={ this.recipientOptions(this.state.recipients) }
                        onChange={ (selectedRecipientIds) => this.setState({ selectedRecipientIds }) } 
                        onSearch={ this.handleSearch }
                        onSelectedItem={ this.handleSelect }
                        resetSearchOptions={ this.handleResetSearchOptions }
                        selectedSearchOptions={ this.recipientOptions(this.state.selectedRecipients) }
                        renderSelected={ ({ value, icon, label, mainLabel, isRemovable }) => (
                            <Chip
                                key={ value }
                                icon={ icon }
                                text={ mainLabel }
                                isRemovable={ isRemovable }
                                onClick={ e => e.stopPropagation() }
                                onRemove={ () => this.handleRemoveRecipients(value) }
                            />
                        ) }
                    />

                    <div className="buttons">
                        <Button
                            disabled={ this.state.loading }
                            variant="light"
                            className="cancel-button"
                            onClick={ this.onClose }
                        >
                            CANCEL
                        </Button>
                        <Button
                            disabled={ this.isDisabled() }
                            variant="continue"
                            className="send-button"
                            onClick={ this.handleSend }
                        >
                            SEND
                        </Button>
                    </div>
                </SimpleContainer>
            );
        }

        return (
            <Modal
                show={ this.props.show }
                onHide={ this.onClose }
                backdrop="static"
                keyboard={ false }
                centered
                dialogClassName="share-stats-via-email-modal"
            >
                { modalContent }
            </Modal>
        );
    }
}

export default ShareStatsPopup;
