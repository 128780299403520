var qs = require('qs');

export default class QueryParamService {
    static CUSTOM_QUERY_PARAMS = ['relevant', 'page', 'fleet', 'search', 'startDate', 'endDate', 'issueTypes', 'user'];
    static defaultOptions = { indices: false, addQueryPrefix: true };

    static addSortToQueryString(queryString, sortingArray) {
        let path = window.location.pathname;
        let currentParams = qs.parse(queryString);

        Object.keys(currentParams).forEach((key) => {
            if (QueryParamService.CUSTOM_QUERY_PARAMS.indexOf(key) === -1) {
                delete currentParams[key];
            }
        })

        sortingArray.forEach((value) => {
            currentParams[value.columnName.toLowerCase()] = value.direction.toLowerCase();
        });

        let newQueryString = qs.stringify(currentParams, QueryParamService.defaultOptions);
        window.history.replaceState(
            { path: `${path}${newQueryString}${window.location.hash}` },
            '',
            `${path}${newQueryString}${window.location.hash}`
        );
    }

    static addValueToQueryString(queryString, key, value) {
        const path = window.location.pathname;

        const currentParams = qs.parse(queryString);
        currentParams[key] = value;

        const newQueryString = qs.stringify(currentParams, QueryParamService.defaultOptions);
        window.history.replaceState(
            { path: `${path}${newQueryString}${window.location.hash}` },
            '',
            `${path}${newQueryString}${window.location.hash}`
        );
    }

    static addFleets(fleets) {
        if (fleets.some(fleet => fleet === null)) {
            return;
        }

        const queryParamsString = window.location.search.slice(1);

        const newQueryString = qs.stringify(
            { ...qs.parse(queryParamsString), fleet: fleets },
            QueryParamService.defaultOptions
        );

        const path = window.location.pathname;

        window.history.replaceState(
            { path: `${path}${newQueryString}${window.location.hash}` },
            '',
            `${path}${newQueryString}${window.location.hash}`
        );
    }

    static addActiveLegToQueryString(queryString, relevantIssues) {
        let path = window.location.pathname;

        let currentParams = qs.parse(queryString);
        delete currentParams['relevant'];
        currentParams['relevant'] = relevantIssues;

        let newQueryString = qs.stringify(currentParams, QueryParamService.defaultOptions);
        window.history.replaceState(
            { path: `${path}${newQueryString}${window.location.hash}` },
            '',
            `${path}${newQueryString}${window.location.hash}`
        );
    }

    static addPageToQueryString(queryString, pageNumber) {
        let path = window.location.pathname;

        let currentParams = qs.parse(queryString);
        delete currentParams['page'];
        currentParams['page'] = pageNumber;

        let newQueryString = qs.stringify(currentParams, QueryParamService.defaultOptions);
        window.history.replaceState(
            { path: `${path}${newQueryString}${window.location.hash}` },
            '',
            `${path}${newQueryString}${window.location.hash}`
        );
    }

    static parseSortingQueryString(queryString) {
        const filterParams = [];
        const queryMap = qs.parse(queryString);

        Object.keys(queryMap).forEach((key) => {
            if (QueryParamService.CUSTOM_QUERY_PARAMS.indexOf(key) === -1) {
                filterParams.push(key.toUpperCase() + "," + queryMap[key].toUpperCase());
            }
        });
        return filterParams;
    }

    static formSortArray(queryString) {
        return QueryParamService.parseSortingQueryString(queryString)
            .flatMap(value => {
                const element = value.split(",");
                return { columnName: element[0], direction: element[1] };
            });
    }

    static parseSimpleValueFromQueryString(queryString, key, defaultValue) {
        const value = qs.parse(queryString)[key];
        return value !== undefined ? value : defaultValue;
    }

    static deleteParam(param) {
        const queryParamsString = window.location.search.slice(1);
        const queryParams = qs.parse(queryParamsString);
        delete queryParams[param];

        const newQueryString = qs.stringify(queryParams, QueryParamService.defaultOptions);
        const path = window.location.pathname;

        window.history.replaceState(
            { path: `${path}${newQueryString}${window.location.hash}` },
            '',
            `${path}${newQueryString}${window.location.hash}`
        );
    }

    static parseParams(paramArray) {
        const queryParamsString = window.location.search.slice(1);
        const queryMap = qs.parse(queryParamsString);
        const parsedParams = {};

        for (const key of Object.keys(queryMap)) {
            if (paramArray.indexOf(key) !== -1) {
                parsedParams[key] = queryMap[key];
            }
        }

        return qs.stringify(parsedParams);
    }
}
