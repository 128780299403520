import React, { Component } from 'react';

import { GRAPH_REQUESTS, isAuthEnabled, msal, requiresInteraction } from 'global/utils/auth';
import QueryParamService from 'global/services/QueryParamService';
import config from 'config';

const { URL_FRONT } = config;

const ACCESS_ROLE_MAPPING = {
    ACCESS_TRAILER_COMMANDS: 'accessTrailerCommands',
    USER: 'user',
    MANAGER: 'manager',
    ADMIN: 'admin'
}

// If you support IE, sign-in using Redirect APIs
// const useRedirectFlow = `${URL_FRONT}/`; //isIE();

export default C => class AuthProvider extends Component {
    constructor(props) {
        super(props);

        const role = this.getRole();

        let fleets = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'fleet');
        if (fleets) {
            fleets = Array.isArray(fleets) ? fleets : [fleets];
            localStorage.setItem('fleets', JSON.stringify(fleets));
        } else {
            fleets = JSON.parse(localStorage.getItem('fleets') || '[null]');
        }

        this.state = {
            account: null,
            token: null,
            error: null,
            mounted: false,
            role: role,
            fleets
        };

        this.setFleets = this.setFleets.bind(this);
    }

    getRole() {
        const roles = (((msal.getAccount() || {}).idTokenClaims || {}).roles || []);
        const accessRoles = roles.map(role => ACCESS_ROLE_MAPPING[role]).filter(e => e);
        return accessRoles;
    }

    async acquireToken(request, redirect) {
        if (isAuthEnabled) {
            return msal.acquireTokenSilent(request).then(function(accessTokenResponse) {
                const idToken = accessTokenResponse.idToken.rawIdToken;
                return idToken;
            }).catch(error => {
                // Call acquireTokenPopup (popup window) in case of acquireTokenSilent failure
                // due to consent or interaction required ONLY
                if (requiresInteraction(error.errorCode)) {
                    return redirect
                        ? msal.acquireTokenRedirect({
                            ...request,
                            redirectUri: URL_FRONT
                        })
                        : msal.acquireTokenPopup(request);
                } else {
                    console.error('Non-interactive error:', error)
                }
            });
        } else {
            return null;
        }
    }

    async onSignIn(redirect) {
        if (redirect) {
            return msal.loginRedirect({
                ...GRAPH_REQUESTS.LOGIN,
                redirectUri: window.location.origin
            });
        }

        const loginResponse = await msal
            .loginPopup(GRAPH_REQUESTS.LOGIN)
            .catch(error => {
                this.setState({
                    error: error.message
                });
                return;
            });

        if (loginResponse) {
            this.setState({
                account: loginResponse.account,
                error: null
            });

            const token = await this.acquireToken(
                GRAPH_REQUESTS.LOGIN
            ).catch(error => {
                this.setState({
                    error: error.message
                });
            });

            this.setState({
                token
            });
        }
    }

    onSignOut() {
        this.setState({mounted: false});
        msal.logout();
    }

    async setToken() {
        msal.handleRedirectCallback((error, result) => {
            if (error) {
                const errorMessage = error.errorMessage ? error.errorMessage : "Unable to acquire access token.";
                // setState works as long as navigateToLoginRequestUrl: false
                this.setState({
                    mounted: true,
                    error: errorMessage
                });
            }
        });

        const token = await this.acquireToken({
            ...GRAPH_REQUESTS.LOGIN,
            },
            true
        );
        this.setState({
            token
        });
    }

    async componentDidMount() {
        this.setState({mounted: false});
        msal.handleRedirectCallback((error, result) => {
            if (error) {
                const errorMessage = error.errorMessage ? error.errorMessage : "Unable to acquire access token.";
                // setState works as long as navigateToLoginRequestUrl: false
                this.setState({
                    mounted: true,
                    error: errorMessage
                });
            }
        });

        const account = msal.getAccount();
        await this.setToken();
        this.setState({
            mounted: true,
            account
        });
    }

    setFleets(fleets) {
        const allFleetsSelected = fleets.some(fleet => fleet === null);

        if (allFleetsSelected) {
            QueryParamService.deleteParam('fleet');
        } else {
            QueryParamService.addFleets(fleets);
        }

        localStorage.setItem('fleets', JSON.stringify(fleets));
        this.setState({ fleets });
    }

    render() {
        return (
            this.state.mounted && <C
                {...this.props}
                account={this.state.account}
                token={this.state.token}
                role={this.state.role}
                setToken={async () => await this.setToken()}
                error={this.state.error}
                history={this.state.history}
                onSignIn={(redirectFlow) => this.onSignIn(redirectFlow)}
                onSignOut={() => this.onSignOut()}
                fleets={ this.state.fleets }
                setFleets={ this.setFleets }
            />
            );
    }
};
