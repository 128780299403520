import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { groupsUrl, usersUrl } from 'global/constants';
import Chip from 'components/chip/Chip';
import ConfirmMessageContainer from 'components/container/ConfirmMessageContainer';
import ErrorMessageContainer from 'components/container/ErrorMessageContainer';
import SimpleContainer from 'components/container/SimpleContainer';
import MultiSelect from 'components/input/MultiSelect';
import AuthContext from 'AuthContext';

import './AddOrEditUserPopup.scss';

export default class AddOrEditUserPopup extends Component {
    static contextType = AuthContext;

    static defaultProps = {
        user: {
            name: '',
            email: '',
            groups: []
        }
    }

    constructor(props) {
        super(props);

        this.initialState = {
            name: this.props.user.name,
            email: this.props.user.email,
            groups: [],
            selectedGroupIds: this.props.user.groups ? this.props.user.groups.map(group => group.id) : [],
            requestSucceeded: null,
            groupsLoading: false,
            loading: false
        };

        this.state = { ...this.initialState };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChipRemove = this.onChipRemove.bind(this);
    }

    componentDidMount() {
        this.setState({ groupsLoading: true });
        this.context.get(groupsUrl)
            .then(response => this.setState({ 
                groups: response.data || [], 
                selectedGroupIds: this.props.user.groups ? this.props.user.groups.map(group => group.id) : []
            }))
            .catch(error => console.error(error))
            .finally(() => this.setState({ groupsLoading: false }));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            this.setState(this.initialState);
        }
    }

    handleSubmit() {
        this.setState({ loading: true });

        const body = {
            name: this.state.name,
            email: this.state.email,
            groupIds: this.state.selectedGroupIds
        };

        let promise;
        if (this.props.edit) {
            promise = this.context.put(`${usersUrl}/${this.props.user.id}`, body);
        } else {
            promise = this.context.post(usersUrl, body);
        }

        promise
            .then(response => {
                const requestSucceeded = response.status !== 'error';
                this.setState({
                    requestSucceeded,
                    errorTitle: requestSucceeded ? '' : response.message.response.data.error,
                    errorDescription: requestSucceeded ? '' : response.message.response.data.message
                });
            })
            .catch(error => {
                console.error(error);
                this.setState({ requestSucceeded: false });
            })
            .finally(() => this.setState({ loading: false }));
    }

    groupOptions() {
        return this.state.groups.map(group => ({
            value: group.id,
            label: group.name,
            icon: group.icon
        }));
    }

    isButtonDisabled() {
        return this.state.loading || !this.state.name || !/\S/.test(this.state.name) || !/\S+@\S+\.\S+/.test(this.state.email);
    }

    onChipRemove(value) {
        this.setState(prevState => ({
            selectedGroupIds: prevState.selectedGroupIds.filter(id => id !== value)
        }));
    }

    render() {
        let modalContent;

        if (this.state.requestSucceeded) {
            let title;
            let description;

            if (this.props.edit) {
                title = 'User updated';
                description = (
                    <>
                        The information for the user <span className="bold">{this.state.name}</span> has been successfully updated.
                    </>
                );
            } else {
                title = 'User invited';
                description = (
                    <>
                        An invitation has been sent to the new user <span className="bold">{ this.state.name }</span>.
                    </>
                );
            }

            modalContent = ( 
                <ConfirmMessageContainer title={ title } description={ description } onClose={ () => this.props.onClose(true) } /> 
            );
        } else if (this.state.requestSucceeded === false) {
            let props = {};
            if (this.state.errorTitle) {
                props = { title: this.state.errorTitle, description: this.state.errorDescription };
            }

            modalContent = (
                <ErrorMessageContainer { ...props } onClose={ () => this.props.onClose(false) } />
            );
        } else {
            let title;
            let modalDescription;
            let actionButtonTitle;
            
            if (this.props.edit) {
                title = 'EDIT USER';
                modalDescription = (
                    <p>
                        Enter the new information about the user:
                    </p>
                );
                actionButtonTitle = 'UPDATE';
            } else {
                title = 'ADD NEW USER';
                modalDescription = (
                    <p>
                        Enter the information about the new user:
                    </p>
                );
                actionButtonTitle = 'CONFIRM';
            }

            modalContent = (
                <SimpleContainer className="modal-container" modal title={ title }>
                    { modalDescription }
                    <p className="label">Full Name</p>
                    <input
                        type="text"
                        className="custom-input"
                        maxLength="255"
                        placeholder="Enter Full Name"
                        value={ this.state.name }
                        onChange={ e => this.setState({ name: e.target.value }) }
                    />

                    <p className="label">Email</p>
                    <input
                        disabled={ this.props.edit }
                        type="email"
                        className="custom-input"
                        maxLength="255"
                        placeholder="Enter Email"
                        value={ this.state.email }
                        onChange={ e => this.setState({ email: e.target.value }) }
                    />

                    <p className="label">Groups</p>
                    <MultiSelect
                        placeholder="Select Groups..."
                        loading={ this.state.groupsLoading }
                        options={ (this.groupOptions()) }
                        selected={ this.state.selectedGroupIds }
                        onChange={ selectedGroupIds => this.setState({ selectedGroupIds }) }
                        renderSelected={ ({ value, icon, label }) => (
                            <Chip
                                key={ value }
                                icon={ icon }
                                text={ label }
                                isRemovable
                                onClick={ e => e.stopPropagation() }
                                onRemove={ () => this.onChipRemove(value) }
                            />
                        ) }
                    />
                    <div className="buttons">
                        <Button
                            disabled={ this.state.loading }
                            variant="light"
                            className="cancel-button"
                            onClick={ () => this.props.onClose(false) }
                        >
                            CANCEL
                        </Button>
                        <Button
                            disabled={ this.isButtonDisabled() }
                            variant="continue"
                            className="submit-button"
                            onClick={ this.handleSubmit }
                        >
                            { actionButtonTitle }
                        </Button>
                    </div>
                </SimpleContainer>
            );
        }

        return (
            <Modal
                show={ this.props.show }
                onHide={ () => this.props.onClose(false) }
                backdrop="static"
                keyboard={ false }
                centered
                dialogClassName="add-user-modal"
            >
                { modalContent }
            </Modal>
        );
    }
}
