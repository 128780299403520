import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes as close } from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle as check, faTimesCircle as xcheck } from '@fortawesome/pro-solid-svg-icons';

import './Tag.scss';

export default class Tag extends Component {

    render() {
        const { closeable, title, wrongFileType } = this.props;

        let titleDiv = <></>;
        if (title) {
            titleDiv = (
                <small className="title">
                    { this.props.title }:
                </small>
            );
        }

        let closeIconDiv = <></>;
        if (closeable) {
            closeIconDiv = (
                <small>
                    <FontAwesomeIcon onClick={ () => this.props.onClose() } className="close-icon" icon={ close } />
                </small>
            );
        }

        let checkIconDiv = <></>;
        if (!wrongFileType) {
            checkIconDiv = (
                <small>
                    <FontAwesomeIcon className="check-icon" icon={ check } />
                </small>
            );
        } else {
            checkIconDiv = (
                <small>
                    <FontAwesomeIcon className="xcheck-icon" icon={ xcheck } />
                </small>
            );
        }

        return (
            <div className="tag-wrapper">
                <div className="tag">
                    { checkIconDiv }
                    { titleDiv }
                    <small className="text">
                        { this.props.text }
                    </small>
                    { closeIconDiv }
                </div>
            </div>
        );
    }
}
