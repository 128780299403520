import constants from 'global/constants.scss';

export const relevantIssuesUrl = "/api/v1/issues/relevant";
export const issuesDetailsUrl = "/api/v1/issues/withDetails";
export const issuesStatusUrl = "/api/v1/issues/status";
export const issuesExportUrl = "/api/v1/issues/export";
export const measurementsUrl = "/api/v1/measurements";
export const measurementsExportUrl = "/api/v1/measurements/export";
export const existsMeasurementUrl = "/api/v1/measurements/exists";
export const latestMeasurementInstant = "/api/v1/state/time/latest";
export const assignmentUrl = "/api/v1/assignments";
export const assignmentWithLegsUrl = "/api/v1/assignments/withLegs"
export const assignmentExistsUrl = "/api/v1/assignments/exists";
export const assignmentByTrailerUrl = "/api/v1/assignments/byTrailer";
export const assignmentWithCurrentTrailerUrl = "/api/v1/assignments/withTrailer";
export const trailerUrl = "/api/v1/trailers";
export const trailerWithDetailsUrl = "/api/v1/trailers/details";
export const allTrailersUrl = "/api/v1/trailers/simple-search";
export const detainedTrailersUrl = "/api/v1/trailers/detained";
export const detainedTrailersNumberUrl = "/api/v1/trailers/detained/total";
export const totalTrailersNumberUrl = "/api/v1/trailers/total-trailers";
export const deletedTrailerUrl = "/api/v1/trailers/deleted";
export const groupedByIssues = "/api/v1/issues/byHour";
export const notificationHistoryUrl = "/api/v1/notifications/history";
export const activityUrl = "/api/v1/activity";
export const snoozeIssue = "/api/v1/issues/snoozed";
export const closeIssue = "/api/v1/issues/close";
export const commentUrl = "/api/v1/comment";
export const notificationDetailsUrl = "/api/v1/notifications/withDetails"
export const closeReasonsUrl = "/api/v1/reasons/close";
export const snoozeReasonsUrl = "/api/v1/reasons/snooze";
export const fleetUrl = "/api/v1/fleet";

export const usersUrl = "/api/v1/admin/auth/users";
export const groupsUrl = "/api/v1/admin/auth/groups";

export const managerUsersUrl = "/api/v1/manager/auth/users";

export const managerCloseReasonsUrl = "/api/v1/manager/issue/close/reason";
export const managerCloseReasonNotesUrl = "/api/v1/manager/issue/close/note";
export const managerSnoozeReasonsUrl = "/api/v1/manager/issue/snooze/reason";
export const managerSnoozeReasonNotesUrl = "/api/v1/manager/issue/snooze/note";
export const managerTrailerCommandsUrl = "/api/v1/manager/trailers/commands";

export const issuesByTypeUrl = "/api/v1/manager/statistics/issuesByStateAndType";
export const typeIssueInfoUrl = "/api/v1/manager/statistics/issueTypeIssueInfo";
export const snoozedIssuesUrl = "/api/v1/manager/statistics/snoozedIssuesByType";
export const issueInfoUrl = "/api/v1/manager/statistics/issueInfo";
export const countSeenByUserUrl = "/api/v1/manager/statistics/countSeenByUser";
export const countSnoozeByUserUrl = "/api/v1/manager/statistics/countSnoozeByUser";
export const countCloseByUserUrl = "/api/v1/manager/statistics/countCloseByUser";
export const avgSeenDurationUrl = "/api/v1/manager/statistics/avgSeenDuration";
export const userActivityUrl = "/api/v1/manager/statistics/userActivity";
export const snoozedIssuesStatisticsUrl = "/api/v1/manager/statistics/snoozedIssues";
export const closedIssuesStatisticsUrl = "/api/v1/manager/statistics/closedIssues";
export const sendStatsEmailSummaryUrl = "/api/v1/manager/statistics/summary";

export const contactsUrl = "/api/v1/manager/contacts";
export const contactGroupsUrl = "/api/v1/manager/contacts/groups";
export const contactToGroupUrl = "/api/v1/manager/contacts/contact-to-group";

export const notificationRulesUrl = "/api/v1/admin/notification/rules";
export const notificationIssueTypeRulesUrl = "/api/v1/admin/notification/issue/rules";
export const groupToRuleUrl = "/api/v1/admin/notification/group-to-rule";
export const scheduleNotificationUrl = "/api/v1/notification/schedule";

export const manageManifestUrl = "/api/v1/settings/assignment";

export const isTrackedTrailerUrl = "/api/v1/trailers/filters/is-tracked";
export const isTrailerSerialNumberTrackedUrl = "/api/v1/trailers/filters/is-serial-number-tracked";
export const addTrailerUrl = "/api/v1/trailers/filters/add";
export const addTrailerCSVUrl = "/api/v1/trailers/filters/add/csv";
export const editTrailerUrl = "/api/v1/trailers/filters/edit";
export const removeTrailerUrl = "/api/v1/trailers/filters/remove";
export const downloadCsvTemplateUrl = "/api/v1/trailers/filters/csv-template";

export const userRecordsNumber = 9;
export const userDropdownRecordsNumber = 20;
export const detailsGridRecordsNumber = 5;
export const smallGridRecordsNumber = 10;
export const defaultGridRecordsNumber = 20;
export const moreGridRecordsNumber = 50;

export const modeOptions = [
    { value: 'CYCLE_SENTRY', label: 'Cycle-Sentry' },
    { value: 'CONTINUOUS', label: 'Continuous' }
];

export const powerOptions = [
    { value: true, label: 'On' },
    { value: false, label: 'Off' }
];

export const MODE_MAPPING = {
    "CONTINUOUS": "Continuous",
    "CYCLE_SENTRY": "Cycle-Sentry"
}

export const NOTIFICATION_SCHEDULE_FREQUENCY_OPTIONS = [
    { value: 'EVERY_DAY', label: "Every day", default: true },
    { value: 'EVERY_WEEK', label: "Every week", default: false },
    { value: 'EVERY_MONTH', label: "Every month", default: false }
];

export const NOTIFICATION_SCHEDULE_FREQUENCY_MAPPING = {
    "EVERY_DAY" : "Daily",
    "EVERY_WEEK" : "Weekly",
    "EVERY_MONTH" : "Monthly"
};

export function searchByUrl(byValue) {
    return `/api/v1/${byValue}/search`;
}

export function mapSort(sort) {
    if (sort) {
        const mappedSort = [];
        sort.forEach(s => s.direction !== "" && mappedSort.push(s.columnName + "," + s.direction));
        return mappedSort;
    } else {
        return [];
    }
}

export function formSort(sort, columnName, direction) {
  if (direction === null) {
    sort = sort.filter(rule => rule.columnName !== columnName);
  } else {
    const index = sort.findIndex(rule => rule.columnName === columnName);

    if (index !== -1) {
      sort[index] = { columnName, direction };
    } else {
      sort = [...sort, { columnName, direction }];
    }
  }

  return sort;
}

export function capitalFirstMap(str) {
    if (!str) {
        return null;
    }
    const lowerCase = str.toLowerCase().replace(/_/g, " ")
    return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
}

export function notificationReasonMap(reason) {
    if (reason.indexOf("WARNING") !== -1 || reason.indexOf("MAINTENANCE") !== -1) {
        return "Warning";
    } else if (reason.indexOf("ESCALATION") !== -1) {
        return "Escalation"
    } else if (reason.indexOf("RESOLVED") !== -1) {
        return "Resolved"
    } else {
        return "Unknown"
    }
}

export const UNRESOLVED_STATES = ["FRESH", "WARNED", "ESCALATED", "SNOOZED"];

export const RESOLVED_STATES = ["CLOSED", "RESOLVED", "RESOLVED_AND_NOTIFIED"];

export const USER_STATE = { PENDING: 'PENDING', ACCEPTED: 'ACCEPTED' };

export const NO_FLEET_SELECTION_PAGES = [
    'orders',
    'details',
    'manifest-details',
    'release-notes',
    'settings',
    'statistics'
];

export function isFleetSelectable() {
  return NO_FLEET_SELECTION_PAGES.indexOf(window.location.pathname.split('/')[1]) === -1;
}

export const inputTimeoutDelay = 700;

export const COLORS = {
    WHITE: constants.white,
    BLUE: constants.blue,
    DARK_GREY: constants.darkGrey,
    LIGHT_BORDER: constants.lightBorder,
    DISABLED: constants.disabled,
    CHART: [constants.chartColor1, constants.chartColor2, constants.chartColor3, constants.chartColor4],
    BLUE_CHART: [constants.blueChartColor1, constants.blueChartColor2]
};
