import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import QueryParamService from 'global/services/QueryParamService';
import {issueTypeMap} from 'global/services/IssueTypeService';
import AuthContext from 'AuthContext';
import {
    COLORS,
    defaultGridRecordsNumber,
    formSort,
    mapSort,
    snoozedIssuesStatisticsUrl,
    managerUsersUrl,
    USER_STATE,
    userDropdownRecordsNumber
} from 'global/constants';
import {
    formatShortDate,
    formatTimestampToDate,
    getCurrentDate, getDifferenceBetweenDates,
    getMonthsBefore
} from 'global/services/DateTimeService';
import StatsFilter from 'pages/statistics/StatsFilter';
import {
    NoDataRow,
    SortableTableHeaderCell,
    Table,
    TableCell,
    TableHeaderRow,
    TableRow
} from 'components/table/Table';
import Pagination from 'components/paging/Pagination';
import history from 'global/history';

import './IssuesPage.scss';

export default class SnoozedIssues extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        const queryParamsString = window.location.search.slice(1);
        const sort = QueryParamService.formSortArray(queryParamsString);
        const pageNumber = QueryParamService.parseSimpleValueFromQueryString(queryParamsString, 'page', 1);
        const issueTypes = QueryParamService.parseSimpleValueFromQueryString(queryParamsString, 'issueTypes', '');
        const startTime = QueryParamService.parseSimpleValueFromQueryString(queryParamsString, 'startDate');
        const endTime = QueryParamService.parseSimpleValueFromQueryString(queryParamsString, 'endDate');
        const userId = QueryParamService.parseSimpleValueFromQueryString(queryParamsString, 'user');

        let processedIssueTypes;
        if (Array.isArray(issueTypes)) {
            processedIssueTypes = issueTypes;
        } else if (issueTypes.length > 0 && issueTypes !== 'ALL') {
            processedIssueTypes = [issueTypes];
        } else {
            processedIssueTypes = [];
        }

        this.state = {
            loading: true,
            usersLoading: true,
            snoozedIssues: null,
            users: [],
            userId,
            userPage: 1,
            pageNumber,
            pageSize: defaultGridRecordsNumber,
            sort,
            filter: {
                startTime: startTime ? formatTimestampToDate(startTime) : getMonthsBefore(getCurrentDate(), 1),
                endTime: endTime ? formatTimestampToDate(endTime) : getCurrentDate(),
                issueTypes: processedIssueTypes
            }
        };

        this.fetchSnoozedIssues = this.fetchSnoozedIssues.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onSort = this.onSort.bind(this);
        this.fetchMoreUsers = this.fetchMoreUsers.bind(this);
        this.searchUser = this.searchUser.bind(this);
        this.onUserSelect = this.onUserSelect.bind(this);
        this.onOrderIdClick = this.onOrderIdClick.bind(this);
        this.onTrailerIdClick = this.onTrailerIdClick.bind(this);
    }

    componentDidMount() {
        this.fetchSnoozedIssues();
        this.fetchUsers(true);

        if (this.state.userId) {
            this.fetchUser();
        }
    }

    async fetchSnoozedIssues() {
        this.setState({ loading: true });
        let payload = {
            ...this.getActiveFilter(),
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize,
            sort: mapSort(this.state.sort)
        }

        if (this.state.userId) {
            payload.userId = this.state.userId;
        }

        const response = await this.context.get(snoozedIssuesStatisticsUrl, payload);

        if (!response || response.status === "error") {
            console.error(response.message);
            this.setState({
                snoozedIssues: [],
                loading: false
            });

            return;
        }

        QueryParamService.addSortToQueryString(window.location.search.slice(1), this.state.sort);
        QueryParamService.addPageToQueryString(window.location.search.slice(1), this.state.pageNumber);

        if (this.state.userId) {
            QueryParamService.addValueToQueryString(window.location.search.slice(1), "user", this.state.userId);
        }  else {
            QueryParamService.deleteParam("user");
        }

        const { data, ...pagingInfo } = response;

        this.setState({
            snoozedIssues: data,
            ...pagingInfo,
            loading: false
        });
    }

    async fetchUsers(addToExisting) {
        this.setState({ usersLoading: true });
        const pageNumber = this.state.userPage;
        const pageSize = userDropdownRecordsNumber;

        const params = { state: USER_STATE.ACCEPTED, pageNumber, pageSize };

        if (this.state.searchUser) {
            params.contains = this.state.searchUser;
        }

        const response = await this.context.get(managerUsersUrl, params);

        if (response.status === 'error') {
            console.error(response.message);
            this.setState({ usersLoading: false });
            return;
        }

        this.setState(prevState => ({
            searchUser: null,
            users: addToExisting ? [...prevState.users, ...response.data] : response.data,
            hasMoreUsers: pageNumber * pageSize < response.available,
            usersLoading: false
        }));
    }

    async fetchUser() {
        const response = await this.context.get(managerUsersUrl + `/${this.state.userId}`);

        if (response.status === 'error') {
            console.error(response.message);
            this.setState({ usersLoading: false });
            return;
        }

        this.setState(prevState => ({
            userName: response.name
        }));
    }

    fetchMoreUsers() {
        this.setState(prevState => ({
            userPage: prevState.userPage + 1, usersLoading: true
        }), () => this.fetchUsers(true));
    }

    searchUser(searchUser) {
        this.setState({ searchUser, userPage: 1 }, this.fetchUsers);
    }

    onPageChange(pageNumber) {
        this.setState({ pageNumber }, this.fetchSnoozedIssues);
    }

    onSort(columnName, direction) {
        this.setState(prevState => ({
            sort: formSort(prevState.sort, columnName, direction)
        }), this.fetchSnoozedIssues);
    }

    onUserSelect(user) {
        this.setState({ userId: user.id, userName: user ? 'All Users' : this.state.userName }, this.fetchSnoozedIssues);
    }

    getActiveFilter() {
        return {
            startTimeFrom: this.state.filter.startTime.toDate(),
            endTimeTo: this.state.filter.endTime.toDate(),
            types: this.state.filter.issueTypes
        };
    }

    onFilterChange(selectedTypes, selectedStartDate, selectedEndDate) {
        const types = selectedTypes.indexOf('ALL') === -1 ? selectedTypes : [];

        this.setState(prevState => ({
            filter: {
                startTime: selectedStartDate && prevState.filter.startTime !== selectedStartDate ? selectedStartDate : prevState.filter.startTime,
                endTime: selectedEndDate && prevState.filter.endTime !== selectedEndDate ? selectedEndDate : prevState.filter.endTime,
                issueTypes: types
            }
        }), () => this.fetchSnoozedIssues());
    }

    renderSnoozedIssues() {
        return this.state.snoozedIssues.map(snoozedIssue => {
            let assignmentId;
            if (snoozedIssue.issue.assignment) {
                assignmentId = (
                    <p className="blueColorText link" id="manifest-id" onClick={ () => this.onOrderIdClick(snoozedIssue.issue.assignment.id) }>
                        { snoozedIssue.issue.assignment.businessId }
                    </p>
                );
            } else {
                assignmentId = "No manifest";
            }

            return (
                <TableRow key={ snoozedIssue.id }>
                    <TableCell>
                        <p className="blueColorText link"
                           id="trailer-id"
                           onClick={ () => this.onTrailerIdClick(snoozedIssue.issue.trailer.id, (snoozedIssue.issue.assignment || {}).id) }>
                            { snoozedIssue.issue.trailer.businessId }
                        </p>
                    </TableCell>
                    <TableCell>
                        { assignmentId }
                    </TableCell>
                    <TableCell>
                        { issueTypeMap(snoozedIssue.issue.type) }
                    </TableCell>
                    <TableCell>
                        { snoozedIssue.snoozedBy }
                    </TableCell>
                    <TableCell>
                        { getDifferenceBetweenDates(snoozedIssue.snoozeStart, snoozedIssue.snoozeEnd) }
                    </TableCell>
                    <TableCell>
                        { snoozedIssue.snoozeReason.reason }
                        { snoozedIssue.note && <span className="grayColorText">&nbsp;&nbsp;({ snoozedIssue.note })</span>}
                    </TableCell>
                    <TableCell>
                        { formatShortDate(new Date(snoozedIssue.snoozeStart), true) }
                    </TableCell>
                </TableRow>
            )
        });
    }

    routeChange(pathname, state) {
        history.push({ pathname, state });
    }

    onTrailerIdClick(trailerId, assignmentId) {
        if (assignmentId) {
            this.routeChange(`/details/${trailerId}/${assignmentId}`, { assignmentId });
        } else {
            this.routeChange(`/details/${trailerId}`);
        }
    }

    onOrderIdClick(assignmentId) {
        this.routeChange("/manifest-details/" + assignmentId, { assignmentId: assignmentId} );
    }

    render() {
        return (
            <div className="container-fluid page issues">
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to={"/statistics?" + QueryParamService.parseParams(["startDate", "endDate", "issueTypes"])}>
                                Statistics
                            </Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <Link to={ window.location.pathname }>
                                Snoozed issues
                            </Link>
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <div className="heading">
                            Snoozed issues
                            <Loader
                                type="TailSpin"
                                className="loader"
                                color={ COLORS.BLUE }
                                height={ 36 }
                                width={ 36 }
                                visible={ this.state.loading }
                            />
                        </div>
                    </div>
                    <div className="col-sm-8 col-xs-12 d-flex">
                        <StatsFilter
                            filter={ this.state.filter }
                            onFilterChange={ this.onFilterChange }
                            filterUser={ this.state.userName ? this.state.userName : "All Users" }
                            users={ this.state.users }
                            onUserSelect={ this.onUserSelect }
                            searchUser={ this.searchUser }
                            fetchMoreUsers={ this.fetchMoreUsers }
                            hasMoreUsers={ this.state.hasMoreUsers }
                            allowClear={ true }
                        />
                    </div>
                </div>
                { this.state.snoozedIssues && (
                    <>
                        <Table>
                            <TableHeaderRow>
                                <SortableTableHeaderCell width={ 97 } columnName='DISPLAY_NAME' onSort={ this.onSort }>
                                    Trailer
                                </SortableTableHeaderCell>
                                <SortableTableHeaderCell width={ 100 } columnName='ASSIGNMENT' onSort={ this.onSort }>
                                    Manifest
                                </SortableTableHeaderCell>
                                <SortableTableHeaderCell width={ 140 } columnName='ISSUE_TYPE' onSort={ this.onSort }>
                                    Issue Type
                                </SortableTableHeaderCell>
                                <SortableTableHeaderCell fixedWidth={ 159 } columnName='SNOOZED_BY' onSort={ this.onSort }>
                                    Snoozed By
                                </SortableTableHeaderCell>
                                <SortableTableHeaderCell fixedWidth={ 114 } columnName='SNOOZE_DURATION' onSort={ this.onSort }>
                                    Snoozed For
                                </SortableTableHeaderCell>
                                <SortableTableHeaderCell fixedWidth={ 541 } columnName='SNOOZE_REASON' onSort={ this.onSort }>
                                    Snooze Reason
                                </SortableTableHeaderCell>
                                <SortableTableHeaderCell fixedWidth={ 143 } columnName='SNOOZED_AT' onSort={ this.onSort }>
                                    Snoozed At
                                </SortableTableHeaderCell>
                            </TableHeaderRow>
                            { this.state.snoozedIssues.length > 0 ? this.renderSnoozedIssues() : <NoDataRow /> }
                        </Table>
                        <Pagination
                            page={ this.state.pageNumber }
                            size={ this.state.pageSize }
                            total={ this.state.available }
                            onChange={ this.onPageChange }
                        />
                    </>
                ) }
            </div>
        );
    }
}
