import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import {
    faSlidersH,
    faTachometerAlt,
    faBrakeWarning,
    faTruck,
    faWrench,
    faMegaphone,
    faListAlt,
    faBell,
    faChartPie
} from '@fortawesome/pro-solid-svg-icons';

import AuthContext from "AuthContext";
import UnreadContext from 'UnreadContext';
import NavItem from 'components/navigation/NavItem';
import RequiresRole from "components/requires-role/RequiresRole"
import { ReactComponent as Logo } from 'files/logo/icon.svg';

import './NavBar.scss';

export default class NavBar extends Component {
    static contextType = AuthContext;

    render() {
        return (
            <nav className="nav-bar">
                <div className="container">
                    <div className="top">
                        <NavLink to="/dashboard">
                            <Logo className="logo" />
                        </NavLink>
                        <RequiresRole
                            roles={this.context.role}
                            perform="dashboard-page:visit"
                            yes={() => (
                                <NavLink to="/dashboard" activeClassName="active">
                                    <NavItem icon={faTachometerAlt} tooltip="Dashboard" />
                                </NavLink>
                            )}
                            no={() => <></>}
                        />
                        <RequiresRole
                            roles={this.context.role}
                            perform="all-issues:list"
                            yes={() => (
                                <NavLink to="/issues/all" activeClassName="active">
                                    <NavItem icon={faBrakeWarning} tooltip="All Issues" />
                                </NavLink>
                            )}
                            no={() => <></>}
                        />
                        <RequiresRole
                            roles={this.context.role}
                            perform="all-trailers:list"
                            yes={() => (
                                <NavLink to="/fleet" activeClassName="active">
                                    <NavItem icon={faTruck} tooltip="Fleet Overview" />
                                </NavLink>
                            )}
                            no={ () => <></> }
                        />
                        <RequiresRole
                            roles={this.context.role}
                            perform="all-manifests:list"
                            yes={() => (
                                <NavLink to="/manifests" activeClassName="active">
                                    <NavItem icon={faListAlt} tooltip="Manifest Overview" />
                                </NavLink>
                            )}
                            no={() =>  <></>} />
                        <RequiresRole
                            roles={this.context.role}
                            perform="alarm-issues:list"
                            yes={() => (
                                <NavLink to="/issues/alarm" activeClassName="active">
                                    <NavItem icon={faWrench} tooltip="Alarm Issues" />
                                </NavLink>
                            )}
                            no={() => <></>}
                        />
                        <RequiresRole
                            roles={ this.context.role }
                            perform="statistics:visit"
                            yes={ () => (
                                <NavLink to="/statistics" activeClassName="active">
                                    <NavItem icon={ faChartPie } tooltip="Stats" />
                                </NavLink>
                            ) }
                            no={ () => <></> }
                        />
                        <RequiresRole
                            roles={ this.context.role }
                            perform="notifications:list"
                            yes={ () => (
                                <NavLink to="/notifications">
                                    <NavItem icon={ faBell } tooltip="Notification History" />
                                </NavLink>
                            ) }
                            no={ () => <></> }
                        />
                    </div>
                    <div className="bottom">
                        <UnreadContext.Consumer>
                            { ({ data }) => (
                                <>
                                    <NavLink to="/release-notes" activeClassName="active" className={ (data.releaseNotes || {}).read ? '' : 'unread' }>
                                        <NavItem icon={faMegaphone} tooltip="Release Notes" />
                                    </NavLink>
                                </>
                            ) }
                        </UnreadContext.Consumer>
                        <RequiresRole
                            roles={ this.context.role }
                            perform={ [
                                "settings:user-management:visit",
                                "settings:email-recipients:visit",
                                "settings:notifications:visit",
                                "settings:close-reasons:visit",
                                "settings:snooze-reasons:visit"
                            ] }
                            yes={ () => (
                                <NavLink to="/settings" activeClassName="active">
                                    <NavItem icon={ faSlidersH } tooltip="Settings" />
                                </NavLink>
                            ) }
                        />
                    </div>
                </div>
            </nav>
        );
    }
}
