import React, { Component } from 'react';
import ReactDOM from "react-dom";
import DataTableComp from 'components/datatable/DataTableComp';
import './Fleet.scss';
import { defaultGridRecordsNumber, mapSort } from 'global/constants';
import AuthContext from 'AuthContext';
import "./FleetTable.scss"

export default class FleetTable extends Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.columnNames = {
            0: "ASSIGNMENT",
            1: "TRAILER",
            2: "ACTIVE"
        }

        const columns = [
          { title: "Manifest #", data: "assignment" }, // 0
          { title: "Trailer #", data: "trailer" }, // 1
          { title: "Manifest Status", data: "active" }, // 2
        ];

        const sortingRule = [
        ];

        const columnDefs = [
            {
              targets: 0,
              createdCell: (td, cellData, rowData, row, col) => {
                  ReactDOM.render(
                    <div className="blueColorText assignment link">
                        {rowData.assignment ? rowData.assignment.businessId : "/"}
                    </div>,
                      td
                  );
              }
            },
            {
              targets: 1,
              createdCell: (td, cellData, rowData, row, col) => {
                  ReactDOM.render(
                    <div className="blueColorText trailer link">
                        {rowData.trailer.businessId}
                    </div>,
                      td
                  );
              }
            },
            {
                targets: 2,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className={`${rowData.active === true ? "bold greenColorText" : rowData.active === false && "bold greyColorText"}`}>
                            {rowData.active === true ? "Active" : rowData.active === false ? "Inactive" : "/"}
                        </div>,
                        td
                    );
                }
            }
        ];

        this.state = {
            columns: columns,
            columnDefs: columnDefs,
            sort: [],
            sortingRule: sortingRule,
            account: this.props.account,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber //default value
              }
        };
    }

    componentDidMount() {
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.sort;
        const that = this;
        const existing = sortingArray.findIndex(x => x && x.columnName === that.columnNames[index]);

        existing === -1 ? sortingArray.push({
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
          }) : sorting === "" ? sortingArray.splice(existing, 1) : sortingArray[existing] = {
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }

        this.setState({
          sort: sortingArray
          }, async () => {
            await this.props.fetchFleet(mapSort(this.state.sort));
          }
        );
      }
    

    render() {
        if(this.props.data) {
            const data = this.props.data;
            return (
                    <div className="fleet-table-div">
                        <DataTableComp
                            tableId="searchData"
                            columns={this.state.columns}
                            columnDefs={this.state.columnDefs}
                            customTableClass="cell-border"
                            orderRule={this.state.sortingRule}
                            rowGroupColumn="active"
                            data={data}
                            isDetailView={true}
                            account={this.state.account}
                            tableHeight="1200px"
                            handlePreviousPage={this.props.handlePreviousPage}
                            handleNextPage={this.props.handleNextPage}
                            handleFirstPage={this.props.handleFirstPage}
                            handleLastPage={this.props.handleLastPage}
                            handleRecordsNumber={(event) => this.props.handleRecordsNumber(event)}
                            handlePageChange={(event) => this.props.handlePageChange(event)}
                            pagingInfo = {this.props.pagingInfo}
                            sortRule={this.state.sort.concat([])}
                            addSortingForColumn={this.addSortingForColumn.bind(this)}
                        />
                    </div> 
            );
        } else return <></>
    }
}
