import React, { Component } from "react";
import axios from 'axios';

import { isFleetSelectable } from 'global/constants';
import config from 'config';
import AuthContext from 'AuthContext'

var qs = require('qs');
const { URL_BACK } = config;

export default C => class ApiServiceProvider extends Component {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.get = this.get.bind(this);
    this.download = this.download.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.delete = this.delete.bind(this);
  }

  _headers() {
    const headers = {};

    if (this.props.token) {
      headers['Authorization'] = `Bearer ${this.props.token}`;
    }

    return headers;
  }

  errorObject(message) {
    return {
      status: "error",
      message: message
    }
  }

  fullUrl(url) {
    return URL_BACK + url;
  }

  async getResponse(url, queryParams) {
    await this.props.setToken();

    queryParams.teamColor = ['*'];

    if (!this.props.fleets.some(fleet => fleet === null) && isFleetSelectable()) {
      queryParams.fleets = this.props.fleets;
    }

    return axios({
      method: 'get',
      url: this.fullUrl(url),
      params: queryParams,
      paramsSerializer: params => {
        return qs.stringify(params, {arrayFormat: 'repeat'});
      },
      headers: this._headers(),
      withCredentials: true
    });
  }

  async get(url, queryParams = {}) {
    try {
      const { data } = await this.getResponse(url, queryParams);
      return data;
    } catch (e) {
      console.error(e);
      return this.errorObject(e);
    }
  }

  async download(url, queryParams) {
    try {
      const response = await this.getResponse(url, queryParams);
      const filename = response.headers["content-disposition"].split("filename=")[1];

      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // IE variant
        window.navigator.msSaveOrOpenBlob(new Blob([response.data]), filename);
      } else {
        const blob = new Blob([response.data]);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      }

      return response.data;
    } catch (e) {
      console.error(e);
      return this.errorObject(e);
    }
  }

  async post(url, body, queryParams = {}) {
    try{
      await this.props.setToken();
      const { data } = await axios({
        method: 'post',
        url: this.fullUrl(url),
        data: body,
        params: queryParams,
        headers: this._headers(),
        withCredentials: true
      });
      return data;
    } catch (e) {
      console.error(e);
      return this.errorObject(e);
    }
  }

  async put(url, body) {
    try{
      await this.props.setToken();
      const { data } = await axios({
        method: 'put',
        url: this.fullUrl(url),
        data: body,
        headers: this._headers(),
        withCredentials: true
      });
      return data;
    } catch (e) {
      console.error(e);
      return this.errorObject(e);
    }
  }

  async delete(url, params) {
    try {
      await this.props.setToken();
      const { data } = await axios({
        method: 'delete',
        url: this.fullUrl(url),
        params: params,
        paramsSerializer: params => {
          return qs.stringify(params, {arrayFormat: 'repeat'});
        },
        headers: this._headers(),
        withCredentials: true
      });
      return data;
    } catch (e) {
      console.error(e);
      return this.errorObject(e);
    }
  }

  render() {
    return (
        <C
            {...this.props}
            get={this.get}
            download={this.download}
            post={this.post}
            put={this.put}
            delete={this.delete}
        />
    );
  }
};
