import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AuthContext from "AuthContext";
import SimpleContainer from 'components/container/SimpleContainer';
import { contactsUrl } from 'global/constants';

import './AddMemberPopup.scss';

export default class AddMemberPopup extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            submittable: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                name: '',
                email: '',
                submittable: false,
            });
        }
    }

    async handleSubmit() {
        try {
            const response = await this.context.post(contactsUrl, {
                name: this.state.name.trim(),
                email: this.state.email,
                contactGroups: [this.props.contactGroup],
            });
            if (!response || response.status === "error") {
                this.props.closeModal();
                return;
            }
            this.props.closeModal(response);
        } catch (error) {
            console.error(error);
            this.props.closeModal();
        }
    }

    validForm = () => this.state.name && /\S/.test(this.state.name) && this.state.email && /\S+@\S+\.\S+/.test(this.state.email)

    onChange = (event, name) => {
        const value = event.target.value;
        this.setState({
            [name]: value
        }, () => {
            this.setState({
                submittable: this.validForm()
            })
        })
    }

    onClose = () => this.props.closeModal()

    render() {
        return (
            <Modal
                show={this.props.showModal}
                onHide={this.onClose}
                backdrop="static"
                keyboard={false}
                centered
                dialogClassName="add-member-modal"
            >
                <SimpleContainer className="modal-container" modal title="NEW MEMBER">
                    <p className="action-label bold d-block">Email Recipient Group</p>
                    <p className="action-label d-block mt-0">{this.props.contactGroup.name}</p>
                    <div className="line" />

                    <p className="action-label">Member Name</p>
                    <input
                        className="member-input"
                        type="email"
                        maxLength="255"
                        required
                        onChange={e => this.onChange(e, "name")}
                        value={this.state.name}
                        placeholder="Floyd Bell"
                    />

                    <p className="action-label">Member Email</p>
                    <input
                        className="member-input"
                        type="text"
                        maxLength="255"
                        required
                        onChange={e => this.onChange(e, "email")}
                        value={this.state.email}
                        placeholder="floyd.bell@mail.com"
                    />

                    <div className="buttons">
                        <Button variant="light" onClick={this.onClose} className="cancel-button">Cancel</Button>
                        <Button
                            variant="continue"
                            className="add-button"
                            disabled={!this.state.submittable}
                            onClick={this.handleSubmit.bind(this)}
                        >
                            Add member
                        </Button>
                    </div>
                </SimpleContainer>
            </Modal>
        );
    }
}

AddMemberPopup.contextType = AuthContext;
