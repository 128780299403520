import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy as copyIcon } from '@fortawesome/pro-light-svg-icons';

import './Copy.scss';

export default class Copy extends Component {

    static defaultProps = {
        data: ''
    }

    constructor(props) {
        super(props);

        this.state = {
            className: ''
        };

        this.copy = this.copy.bind(this);
    }

    copy() {
        navigator.clipboard.writeText(this.props.data)
            .then(() => {
                this.setState(
                    { className: 'active' },
                    () => setTimeout(() => this.setState({ className: '' }), 1000)
                );
            });
    }

    render() {
        return (
            <FontAwesomeIcon className={ `copy ${this.state.className}` } icon={ copyIcon } onClick={ this.copy } />
        );
    }
}
