const rules = {
    visitor: {
        static: []
    },
    accessTrailerCommands: {
        static: [
            "trailer-commands:read",
            "trailer-commands:write"
        ]
    },
    user: {
        static: [
            "dashboard-page:visit",
            "manifest-details:visit",
            "details-page:dm",
            "details-page:maintenance",
            "all-issues:list",
            "mode-issues:list",
            "power-issues:list",
            "silent-issues:list",
            "temperature-issues:list",
            "inactivity-issues:list",
            "low-fuel-issues:list",
            "alarm-issues:list",
            "detained-trailers:list",
            "search-page:visit",
            "notifications:list",
            "all-manifests:list",
            "all-trailers:list"
        ]
    },
    manager: {
        static: [
            "dashboard-page:visit",
            "manifest-details:visit",
            "details-page:dm",
            "details-page:maintenance",
            "alarm-issues:list",
            "all-issues:list",
            "mode-issues:list",
            "power-issues:list",
            "silent-issues:list",
            "temperature-issues:list",
            "inactivity-issues:list",
            "low-fuel-issues:list",
            "detained-trailers:list",
            "search-page:visit",
            "settings:close-reasons:visit",
            "settings:email-recipients:visit",
            "settings:snooze-reasons:visit",
            "notifications:list",
            "trailer-commands:read",
            "trailer-commands:write",
            "all-manifests:list",
            "all-trailers:list",
            "statistics:visit"
        ]
    },
    admin: {
        static: [
            "dashboard-page:visit",
            "details-page:dm",
            "details-page:maintenance",
            "manifest-details:visit",
            "alarm-issues:list",
            "all-issues:list",
            "mode-issues:list",
            "power-issues:list",
            "silent-issues:list",
            "temperature-issues:list",
            "inactivity-issues:list",
            "low-fuel-issues:list",
            "detained-trailers:list",
            "search-page:visit",
            "notifications:list",
            "settings:user-management:visit",
            "settings:email-recipients:visit",
            "settings:notifications:visit",
            "settings:close-reasons:visit",
            "settings:snooze-reasons:visit",
            "release-notes:publish",
            "trailer-commands:read",
            "trailer-commands:write",
            "all-manifests:list",
            "all-trailers:list",
            "statistics:visit"
        ]
    }
};

export default rules;
