import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faListAlt, faSlidersH } from '@fortawesome/pro-solid-svg-icons';
import Loader from 'react-loader-spinner';
import { Button } from 'react-bootstrap';
import $ from 'jquery';

import AuthContext from 'AuthContext'
import DataTableComp from 'components/datatable/DataTableComp';
import ActiveSelection from "components/input/ActiveSelection";
import ManageManifestPopup from 'components/popup/ManageManifestPopup';
import SimpleTooltip from 'components/tooltip/SimpleTooltip';
import { assignmentWithCurrentTrailerUrl, defaultGridRecordsNumber, mapSort, MODE_MAPPING } from 'global/constants';
import QueryParamService from 'global/services/QueryParamService';
import formSortObject from 'global/utils/formSortObject';

import "./ManifestOverview.scss";

export default class ManifestOverview extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.columnNames = {
            0 : "ASSIGNMENT"
        }

        const columns = [
          { title: "Manifest #", data: "businessId" }, // 0
          { title: "Trailer #", data: "trailerId" }, // 1
          { title: "Min temp.", data: "minTemp" }, // 2
          { title: "Max temp.", data: "maxTemp" }, // 3
          { title: "Expected mode", data: "expectedMode" }, // 4
          { title: "Expected power", data: "expectedPower"}, // 5
          { title: "Actions", data: "actions"} // 6
        ];

        const sortingRule = [];

        const columnDefs = [
            { width: "10%", "targets": [2, 3, 5] },
            { width: "15%", "targets": [0, 1, 4, 6] },
            { className: "dt-align-left", targets: [4, 5, 6] },
            { className: "dt-align-right", targets: [0, 1, 2, 3] },
            {
                orderable: false,
                targets: [1, 2, 3, 4, 5, 6]
            },
            {
                targets: 0,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="blueColorText assignment link">
                            { rowData.businessId }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 1,
                createdCell: (td, cellData, rowData, row, col) => {
                    const hasMeasurements = rowData.hasMeasurements;
                    let textColor;
                    if (hasMeasurements && rowData.trailer.active) {
                        textColor = "blueColorText";
                    } else {
                        textColor = "orangeColorText";
                    }
                    
                    ReactDOM.render(
                        <div className="manifest-trailer-cell">
                            { !rowData.trailer.active && (
                                <FontAwesomeIcon icon={ faInfoCircle } className="info-icon" />
                            ) }
                            { !hasMeasurements && rowData.trailer.active &&
                                    <div className="tooltip-container">
                                        <FontAwesomeIcon icon={ faInfoCircle } className="tooltip-info-icon" />
                                        <SimpleTooltip direction="right">
                                            This trailer has not received any TK measurements yet. Check if the Trailer ID is valid.
                                        </SimpleTooltip>
                                    </div>
                            }
                            <div className={ `trailer link ${textColor}` }>
                                { rowData.trailerId }
                            </div>
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div>
                            <Button
                                variant="continue"
                                className="submit-button manifest-button manage"
                                onClick={ () => this.showManifestPopup(rowData) }
                            >
                                <FontAwesomeIcon icon={ faSlidersH } className="icon-manifest"></FontAwesomeIcon>
                                MANAGE
                            </Button>
                        </div>,
                        td
                    );
                }
            }
        ];

        let activeManifests = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') ? QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant') === "true" : true;
        let buttonNode;

        if (!!activeManifests) {
            activeManifests = true;
            buttonNode = $(`button[id=relevant]`);
        } else {
            activeManifests = false;
            buttonNode = $(`button[id=irrelevant]`);
        }

        buttonNode.trigger('click');

        QueryParamService.addValueToQueryString(window.location.search.slice(1), 'relevant', activeManifests);

        let currentPage = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page') ? parseInt(QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page')) : 1;

        QueryParamService.addFleets(JSON.parse(localStorage.getItem('fleets') || '[]'));

        this.state = {
            dataLoaded: false,
            dataUpdated: false,
            columns: columns,
            columnDefs: columnDefs,
            sortingRule: sortingRule,
            account: this.props.account,
            activeManifests: !!activeManifests,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber
            },
            showManifestPopup: false,
            page: currentPage,
            sort: []
        }
    }

    componentDidMount() {
        this.fetchManifests();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fleets !== this.props.fleets) {
            this.state.page !== 1 ? this.handleFirstPage() : this.fetchManifests();
        }
    }

    showManifestPopup(assignmentWithTrailer) {
        const { trailer, manifest } = assignmentWithTrailer || {};
        this.setState({
            manifest,
            trailer,
            showManifestPopup: true
        })
    }

    closeManifestPopup() {
        this.setState({
            manifest: null,
            trailer: null,
            showManifestPopup: false
        }, () => {
            this.fetchManifests();
        })
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.sort;
        const that = this;
        const existing = sortingArray.findIndex(x => x && x.columnName === that.columnNames[index]);

        existing === -1 ? sortingArray.push({
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }) : sorting === "" ? sortingArray.splice(existing, 1) : sortingArray[existing] = {
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }

        let queryString = window.location.search.slice(1);
        QueryParamService.addSortToQueryString(queryString, sortingArray);

        this.setState({
            sort: sortingArray
            }, async () => {
                await this.fetchManifests();
            }
        );
    }

    changeActiveness = (relevant) => {
        const queryString = window.location.search.slice(1);
        QueryParamService.addActiveLegToQueryString(queryString, relevant);

        this.setState({
            activeManifests: relevant,
            page: 1,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber,
            }
            }, async () => {
                await this.fetchManifests();
            }
        )
    }

    async fetchManifests() {
        try {
            this.setState({
               dataUpdated: false
            });

            let sort = this.state.sort.length > 0 ? mapSort(this.state.sort) : QueryParamService.parseSortingQueryString(window.location.search.slice(1));
            let tableSortObject = formSortObject(sort, this.columnNames);

            const response = await this.context.get(assignmentWithCurrentTrailerUrl, {
                sort: sort,
                active: this.state.activeManifests,
                pageNumber: this.state.page || 1,
                pageSize: this.state.pagingInfo.recordsNumber
            });

            if (response.status === "error") {
                return {};
            }

            const data = response.data;

            if (!data) {
                this.setState({
                    dataLoaded: true,
                    dataUpdated: true
                });
                return;
            }

            let currentPage = Math.min(response.pageNumber, Math.ceil(response.available / response.pageSize));
            let queryString = window.location.search.slice(1);
            QueryParamService.addPageToQueryString(queryString, currentPage || 1);

            const mappedData = data.map(
                assignmentWithTrailer => ({
                    trailer: assignmentWithTrailer.trailer,
                    manifest: assignmentWithTrailer.assignment,
                    trailerId: assignmentWithTrailer.trailer.businessId,
                    businessId: assignmentWithTrailer.assignment.businessId,
                    minTemp: assignmentWithTrailer.assignment.temperatureMin === "-Infinity" ? "NO DATA" : assignmentWithTrailer.assignment.temperatureMin,
                    maxTemp: assignmentWithTrailer.assignment.temperatureMax === "Infinity" ? "NO DATA" : assignmentWithTrailer.assignment.temperatureMax,
                    expectedMode: MODE_MAPPING[assignmentWithTrailer.assignment.unitMode] || "NO DATA",
                    expectedPower: assignmentWithTrailer.assignment.power ? "On" : "Off",
                    active: assignmentWithTrailer.assignment.active,
                    actions: {},
                    onClickState: {
                        trailerId: assignmentWithTrailer.trailer.id,
                        assignmentId: assignmentWithTrailer.assignment.id
                    },
                    hasMeasurements: assignmentWithTrailer.hasMeasurements
                })
            )

            this.setState({
                data: mappedData,
                dataLoaded: true,
                dataUpdated: true,
                pagingInfo: {
                    itemNumber: Math.min(response.pageSize, response.data.length),
                    totalItemNumber: response.available,
                    totalPageNumber: Math.ceil(response.available / response.pageSize),
                    currentPage: Math.min(response.pageNumber, Math.ceil(response.available / response.pageSize)),
                    recordsNumber: response.pageSize
                },
                sort: tableSortObject
            });

        } catch(error) {
            console.warn(error);
            this.setState({
                data: Array.from([]),
                dataLoaded: true,
                dataUpdated: true
            });
        }

    }

    handleFirstPage = async () => {
        this.setState({
            page: 1
        }, async () => {
            await this.fetchManifests();
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            })
        });
    }

    handleLastPage = async () => {
        this.setState({
            page: parseInt(this.state.pagingInfo.totalPageNumber)
        }, async () => {
            await this.fetchManifests();
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            })
        })
    }

    handleNextPage = async () => {
        this.setState({
            page: parseInt(this.state.page) + 1
            }, async () => {
                await this.fetchManifests();
                this.setState({
                    pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                    }
                })
            }
        )
    }

    handlePreviousPage = async () => {
        this.setState({
            page: parseInt(this.state.page) - 1
        }, async () => {
            await this.fetchManifests();
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            })
        })
    }

    handleRecordsNumber = async (event) => {
        let recordsNumber = parseInt(event.target.value);
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: 1,
                recordsNumber: recordsNumber
                },
                page: 1
        }, async () => {
            await this.fetchManifests();
        })
    }

    handlePageChange = async (event) => {
        if (isNaN(event.target.value) || event.target.value === "") {
            this.setState({
                page: ""
            });
        } else {
            this.setState({
                page: parseInt(event.target.value)
            });
            const that = this;
            setTimeout(async function () {
                await that.fetchManifests();
            }, 1000);
        }
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: this.state.page
            }
        })
    }

    render() {
        return (
            <div className="page manifest-overview">
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active"><a href="#!">Manifest Overview </a></li>
                    </ol>
                </nav>
                <div className="heading">
                    Manifest Overview <Loader type="TailSpin" color="#289AC2" height={47} width={47} visible={!this.state.dataLoaded || !this.state.dataUpdated}/>
                </div>
                <div className="manifest-actions">
                    <div className="active-toggle">
                        <ActiveSelection activeCallback={this.changeActiveness.bind(this)} relevantLabel="Active Manifest" irrelevantLabel="Inactive Manifest" initiallyActive={ this.state.activeManifests }></ActiveSelection>
                    </div>
                    <div  className="manifest-buttons">
                        <Button
                            variant="continue"
                            className="manifest-button create" onClick={ () => this.showManifestPopup() }>
                                <FontAwesomeIcon icon={faListAlt} className="icon-manifest" />
                                <span>
                                    CREATE MANIFEST
                                </span>
                        </Button>
                    </div>
                </div>
                <div className="manifestTableDiv">
                { this.state.dataLoaded && <DataTableComp
                    tableId="manifestOverview"
                    columns={this.state.columns}
                    orderRule={this.state.sortingRule}
                    data={this.state.data}
                    columnDefs={this.state.columnDefs}
                    isDetailView={false}
                    customTableClass="cell-border"
                    tableHeight="1200px"
                    handlePreviousPage={this.handlePreviousPage}
                    handleNextPage={this.handleNextPage}
                    handleFirstPage={this.handleFirstPage}
                    handleLastPage={this.handleLastPage}
                    handleRecordsNumber={(event) => this.handleRecordsNumber(event)}
                    handlePageChange={(event) => this.handlePageChange(event)}
                    pagingInfo = {this.state.pagingInfo}
                    account={this.state.account}
                    sortRule={this.state.sort.concat([])}
                    addSortingForColumn={this.addSortingForColumn.bind(this)}
                />}
                <ManageManifestPopup
                    close={ this.closeManifestPopup.bind(this) }
                    show={ this.state.showManifestPopup }
                    title={ this.state.manifest ? "Manage Manifest" : "Create Manifest" }
                    isManifestEditable={ this.state.manifest ? false : true }
                    manifest={ this.state.manifest }
                    trailer={ this.state.trailer }
                />
                </div>
            </div>
        )
    }
}
