import { Logger, LogLevel, UserAgentApplication } from "msal";
import config from 'config';

const {
    AUTH_CLIENT_ID,
    AUTH_TENANT_ID,
    AUTHENTICATION
} = config;

const {
    REACT_APP_NAME,
    REACT_APP_VERSION
} = process.env;

export const isAuthEnabled = AUTHENTICATION && AUTHENTICATION.toLowerCase() === "enabled";

export const requiresInteraction = errorMessage => {
    if (!errorMessage || !errorMessage.length) {
        return false;
    }

    return (
        errorMessage.indexOf("consent_required") > -1 ||
        errorMessage.indexOf("interaction_required") > -1 ||
        errorMessage.indexOf("login_required") > -1
    );
};

export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;

    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    // const isEdge = ua.indexOf("Edge/") > -1;

    return msie || msie11;
};

export const GRAPH_SCOPES = {
    OPENID: "openid",
    PROFILE: "profile",
    USER_READ: "User.Read"
};

export const GRAPH_ENDPOINTS = {
    ME: "https://graph.microsoft.com/v1.0/me",
    ME_PROFILE_PHOTO: "https://graph.microsoft.com/v1.0/me/photo/$value"
};

export const GRAPH_REQUESTS = {
    LOGIN: {
        scopes: [
            GRAPH_SCOPES.OPENID,
            GRAPH_SCOPES.PROFILE,
            GRAPH_SCOPES.USER_READ
        ]
    }
};

export const msal = new UserAgentApplication({
    auth: {
        clientId: AUTH_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${AUTH_TENANT_ID}`,
        validateAuthority: true,
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: isIE()
    },
    system: {
        navigateFrameWait: 1000,
        tokenRenewalOffsetSeconds: 1000,
        loadFrameTimeout: 30000,
        logger: new Logger(
            (logLevel, message) => {
                // console.log(message);
            },
            {
                level: LogLevel.Verbose,
                piiLoggingEnabled: true
            }
        ),
        telemetry: {
            applicationName: REACT_APP_NAME,
            applicationVersion: REACT_APP_VERSION,
            telemetryEmitter: (events) => {
                // console.log('Telemetry Events:', events);
            }
        }
    }
});
