import React, { Component } from 'react';

export default class UserStatsHeader extends Component {

    static defaultProps = {
        seenCount: '-',
        closeCount: '-',
        snoozeCount: '-'
    }

    render() {
        return (
            <div className="stats-header user-stats-header">
                <div className="issue-count-heading row grid-divider">
                    <div className="col-2">
                        Seen Issues
                    </div>
                    <div className="col-2">
                        Closed Issues
                    </div>
                    <div className="col-2">
                        Snoozed Issues
                    </div>
                </div>
                <div className="issue-count row grid-divider">
                    <div className="col-2">
                        <span>
                            { this.props.seenCount }
                        </span>
                    </div>
                    <div className="col-2">
                        <span>
                            { this.props.closeCount }
                        </span>
                    </div>
                    <div className="col-2">
                        <span>
                            { this.props.snoozeCount }
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}
