import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { customAlphabet } from 'nanoid';
import { alphanumeric } from 'nanoid-dictionary';

import AuthContext from 'AuthContext';
import config from 'config';

import SimpleContainer from 'components/container/SimpleContainer';
import Pill from 'components/input/Pill';
import Message from 'components/Message';
import SimpleTooltip from 'components/tooltip/SimpleTooltip';
import { modeOptions, powerOptions, allTrailersUrl, manageManifestUrl, assignmentExistsUrl, assignmentByTrailerUrl } from 'global/constants';

import './ManageManifestPopup.scss';

const {
    MIN_TEMP, MAX_TEMP, MAX_DIFF
} = config;

const removedTrailerMessage = "This trailer has been removed from your fleet. If you set the manifest tracking to Active, the trailer will automatically be restored to your fleet.";

// TODO: Improve trailer dropdown component.
export default class ManageManifestPopup extends Component {
    static contextType = AuthContext;

    nanoid = customAlphabet(alphanumeric, 8);

    /**
     * A trailer can be provided through props without a manifest (if no manifest for it has ever existed), but not the other way around.
     */
    static defaultProps = {
        manifest: null,
        isManifestEditable: true,
        trailer: null,
        show: false,
        title: null,
        close: () => {/* */}
    };

    constructor(props) {
        super(props);

        const { trailer, manifest } = this.props;

        this.state = {
            manifestId: manifest ? manifest.businessId : null,
            validManifest: manifest ? true : false,
            activeManifestChanged: false,
            activeManifestId: manifest && manifest.active ? manifest.businessId : null,
            trailerId: trailer ? trailer.businessId : null,
            serialNumber: trailer ? trailer.serialNumber : null,
            trailerActive: trailer ? trailer.active : null,
            fleet: trailer ? trailer.fleet : null,
            mode: manifest ? manifest.unitMode : null,
            minTemp: manifest ? manifest.temperatureMin : null,
            maxTemp: manifest ? manifest.temperatureMax : null,
            power: manifest ? manifest.power : true,
            tracking: manifest ? manifest.active : true,
            submittable: false,
            showLoader: false,
            showWarning: trailer && !trailer.active,
            warningMessage: trailer && !trailer.active ? removedTrailerMessage : null,
            timeout: 0,
            errors: {
                manifestId: null,
                minTemp: null,
                maxTemp: null
            }
        };
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            const { trailer, manifest } = this.props;

            this.setState({
                manifestId: manifest ? manifest.businessId : null,
                validManifest: manifest ? true : false,
                activeManifestChanged: false,
                activeManifestId: manifest && manifest.active ? manifest.businessId : null,
                trailerId: trailer ? trailer.businessId : null,
                serialNumber: trailer ? trailer.serialNumber : null,
                trailerActive: trailer ? trailer.active : null,
                fleet: trailer ? trailer.fleet : null,
                mode: manifest ? manifest.unitMode : null,
                minTemp: manifest ? manifest.temperatureMin : null,
                maxTemp: manifest ? manifest.temperatureMax : null,
                power: manifest ? manifest.power : true,
                tracking: manifest ? manifest.active : true,
                submittable: false,
                showLoader: false,
                showWarning: trailer && !trailer.active,
                warningMessage: trailer && !trailer.active ? removedTrailerMessage : null,
                timeout: 0,
                errors: {
                    manifestId: null,
                    minTemp: null,
                    maxTemp: null
                }
            });
        }
    }

    async fetchTrailers(input) {
        try {
            const response = await this.context.get(allTrailersUrl, {
                contains: input
            });

            return response.data.map(trailer => (
                {
                    value: {
                        businessId: trailer.businessId,
                        serialNumber: trailer.serialNumber,
                        active: trailer.active,
                        fleet: trailer.fleet
                    },
                    label: trailer.businessId
                }
            ));
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    onManifestIdChange(event) {
        const self = this;

        if (this.state.timeout) {
            clearTimeout(this.state.timeout);
        }

        this.checkActiveManifestChanged(event.target.value, null, null);
        this.setState({
            manifestId: event.target.value,
            timeout: setTimeout(function () {
                self.validateManifestInput(self.state.manifestId);
            }, 500)
        });
    }

    async validateManifestInput(value) {
        this.setState({
            showLoader: true
        });

        const trimmedValue = value.trim();

        let validManifest = true;
        let manifestId = null;

        if (!trimmedValue) {
            validManifest = false;
            manifestId = "The entered manifest ID can't be blank.";
        } else {
            try {
                const response = await this.context.get(assignmentExistsUrl, { id: trimmedValue });
                if (typeof response === "object" && response.status === "error") {
                    validManifest = false;
                    manifestId = "Unable to validate Manifest ID. Please check your internet connection and reload the page.";
                } else if (!(this.props.manifest && this.props.manifest.businessId === trimmedValue) && response) {
                    validManifest = false;
                    manifestId = "The entered manifest ID is not unique.";
                }
            } catch (error) {
                validManifest = false;
                manifestId = "Unable to validate Manifest ID.";
                console.error(error);
            }
        }

        this.setState(prevState => ({
            manifestId: trimmedValue,
            validManifest,
            showLoader: false,
            errors: {
                ...prevState.errors,
                manifestId
            }
        }));

        this.validForm();
    }

    async setRandomManifestId() {
        this.setState({
            showLoader: true
        });

        let randomId;
        while (true) {
            randomId = this.nanoid();
            const exists = await this.context.get(assignmentExistsUrl, { id: randomId });

            if (!exists) {
                break;
            }
        }

        document.getElementById('manifest-id').value = randomId;
        this.checkActiveManifestChanged(randomId, null, null);

        this.setState(prevState => ({
            manifestId: randomId,
            validManifest: true,
            showLoader: false,
            errors: {
                ...prevState.errors,
                manifestId: null
            }
        }), () => {
            this.validForm();
        });
    }

    /**
     * Checks whether or not submitting the form will have deactivating an already existing manifest as a consequence, aka whether the active manifest will change.
     * The scenarios covered are as follows:
     * <ul>
     *  <li>The original manifest is active, tracking is turned on and there has been a change in the manifest id.</li>
     *  <li>Tracking is turned on and there is an active manifest for the selected trailer different than the manifest entered by the user.</li>
     * </ul>
     *
     * @param {String} manifestBusinessId
     * @param {String} trailerBusinessId
     * @param {boolean} tracking
     */
    async checkActiveManifestChanged(manifestBusinessId, trailerBusinessId, tracking) {

        // Tracking can always be turned off without consequence
        if (tracking === false) {
            this.setState({
                activeManifestChanged: false
            });

            return;
        }

        if (manifestBusinessId) {
            const originalManifest = this.props.manifest;

            // There's been a manifest id change and an existing active manifest was provided through props
            if (originalManifest && originalManifest.active) {
                const activeManifestChanged = originalManifest.businessId !== manifestBusinessId && this.state.tracking;

                this.setState({
                    activeManifestChanged
                });

                return;
            }
        }

        const currentTrailer = trailerBusinessId || this.state.trailerId;
        const currentManifest = manifestBusinessId || this.state.manifestId;
        const toTrack = tracking || this.state.tracking;

        if (currentTrailer && currentManifest && toTrack) {
            try {
                const response = await this.context.get(assignmentByTrailerUrl, {
                    trailerBusinessId: currentTrailer,
                    active: true
                });

                if (response.status === 'error') {
                    console.error(response.message);
                    return;
                }

                const activeManifestChanged = response && response.businessId !== currentManifest;

                this.setState({
                    activeManifestChanged,
                    activeManifestId: response ? response.businessId : null
                });

                return;
            } catch (error) {
                console.error(error);
                return;
            }
        }

        this.setState({
            activeManifestChanged: false
        });
    }

    setSelectValue(field, selected) {
        let newState = {
            [field]: selected.value
        };

        if (field === 'trailerId') {
            this.checkActiveManifestChanged(null, selected.value.businessId, null);

            newState = {
                trailerId: selected.value.businessId,
                serialNumber: selected.value.serialNumber,
                trailerActive: selected.value.active,
                fleet: selected.value.fleet
            };
        }

        this.setState(newState, () => this.validForm());
    }

    setInputValue(event) {
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.validTemperature(this.state.minTemp, this.state.maxTemp);
            this.validForm();
        });
    }

    handleTrackingToggle(checked) {
        this.checkActiveManifestChanged(null, null, checked);

        this.setState({
            tracking: checked
        }, () => {
            this.validForm();
        });
    }

    validTemperature(min, max) {
        const minimum = Number(min);
        let minimumValid = true;

        const maximum = Number(max);
        let maximumValid = true;

        if (!this.state.power) {
            this.setState({
                errors: {
                    minTemp: null,
                    maxTemp: null
                }
            });

            return true;
        }

        if (min !== null && max !== null) {
            if (minimum > maximum) {
                minimumValid = false;
                maximumValid = false;

                this.setState(prevState => ({
                    errors: {
                        ...prevState.errors,
                        minTemp: 'The min. temperature must not exceed the max. temperature.',
                        maxTemp: ' '
                    }
                }));
            } else if (Math.abs(maximum - minimum) > MAX_DIFF) {
                minimumValid = false;
                maximumValid = false;

                this.setState(prevState => ({
                    errors: {
                        ...prevState.errors,
                        minTemp: 'The difference between min. and max. temperatures must not exceed ' + MAX_DIFF + '.',
                        maxTemp: ' '
                    }
                }));
            }
        }

        if (min != null && minimum < MIN_TEMP) {
            minimumValid = false;

            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    minTemp: 'The min. temperature must be greater or equal to ' + MIN_TEMP + '.',
                }
            }));
        }

        if (max != null && maximum > MAX_TEMP) {
            maximumValid = false;

            this.setState(prevState => ({
                errors: {
                    ...prevState.errors,
                    maxTemp: 'The max. temperature must be less or equal to ' + MAX_TEMP + '.'
                }
            }));
        }

        const valid = min !== null && max !== null && minimum <= maximum && minimum >= MIN_TEMP && maximum <= MAX_TEMP && Math.abs(max - min) <= MAX_DIFF;

        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                minTemp: minimumValid ? null : prevState.errors.minTemp,
                maxTemp: maximumValid ? null : prevState.errors.maxTemp
            }
        }));

        return valid;
    }

    /**
     * Checks whether the state of the form has changed since opening it.
     *
     * @private
     */
    _stateChanged() {
        const { trailer, manifest } = this.props;

        const stateFields = {
            manifestId: manifest ? manifest.businessId : null,
            trailerId: trailer ? trailer.businessId : null,
            serialNumber: trailer ? trailer.serialNumber : null,
            trailerActive: trailer ? trailer.active : null,
            fleet: trailer ? trailer.fleet : null,
            mode: manifest ? manifest.unitMode : null,
            minTemp: manifest ? manifest.temperatureMin : null,
            maxTemp: manifest ? manifest.temperatureMax : null,
            power: manifest ? manifest.power : true,
            tracking: manifest ? manifest.active : true
        }

        return this.state.manifestId !== stateFields.manifestId ||
                this.state.trailerId !== stateFields.trailerId ||
                this.state.serialNumber !== stateFields.serialNumber ||
                this.state.mode !== stateFields.mode ||
                Number(this.state.minTemp) !== stateFields.minTemp ||
                Number(this.state.maxTemp) !== stateFields.maxTemp ||
                this.state.power !== stateFields.power ||
                this.state.tracking !== stateFields.tracking;
    }

    validForm() {
        const validFields = this.state.validManifest && this.state.trailerId && this.state.mode;
        const validTemperature = this.validTemperature(this.state.minTemp, this.state.maxTemp);
        const stateChanged = this._stateChanged();

        this.setState({
            submittable: validFields && validTemperature && stateChanged
        });
    }

    _formErrorMessages() {
        let manifestErrorClass, minTempErrorClass, maxTempErrorClass;
        let manifestErrorMessage, minTempErrorMessage, maxTempErrorMessage;

        manifestErrorClass = minTempErrorClass = maxTempErrorClass = '';
        manifestErrorMessage = minTempErrorMessage = maxTempErrorMessage = <></>;

        if (this.state.errors.manifestId) {
            manifestErrorClass = 'error';
            manifestErrorMessage = (
                <span className="error-message">
                    { this.state.errors.manifestId }
                </span>
            );
        }

        if (this.state.errors.minTemp) {
            minTempErrorClass = 'error';
            minTempErrorMessage = (
                <span className="error-message">
                    { this.state.errors.minTemp }
                </span>
            );
        }

        if (this.state.errors.maxTemp) {
            maxTempErrorClass = 'error';
            maxTempErrorMessage = (
                <span className="error-message">
                    { this.state.errors.maxTemp }
                </span>
            );
        }

        return [ manifestErrorClass, manifestErrorMessage, minTempErrorClass, minTempErrorMessage, maxTempErrorClass, maxTempErrorMessage ];
    }

    async handleSubmit() {
        const status = this.state.tracking ? "STD" : "CMP";

        const legBusinessId = this.state.manifestId || (this.props.manifest && this.props.manifest.legs && this.props.manifest.legs[0].businessId);
        const body = {
            businessId: this.state.manifestId,
            temperatureMin: this.state.power ? this.state.minTemp : "-Infinity",
            temperatureMax: this.state.power ? this.state.maxTemp : "Infinity",
            unitMode: this.state.mode,
            power: this.state.power,
            active: this.state.tracking,
            legData: [
                {
                    businessId: legBusinessId,
                    trailerData: {
                        businessId: this.state.trailerId,
                        serialNumber: this.state.serialNumber,
                        active: this.state.trailerActive,
                        fleetData: this.state.fleet
                    },
                    priority: 1,
                    startDate: null,
                    endDate: null,
                    legStatus: status,
                    assignmentStatus: status,
                    active: this.state.tracking,
                    stopData: []
                }
            ]
        };

        let response;

        if (this.props.manifest) {
            response = await this.context.put(manageManifestUrl, body);
        } else {
            response = await this.context.post(manageManifestUrl, body);
        }

        if (response.status !== "error") {
            this.props.close(true);
        } else {
            this.setState({
                showWarning: true,
                warningMessage: response.message.response.data.message
            });
        }
    }

    onClose = () => this.props.close(false);

    render() {
        const { manifestId, trailerId, mode, minTemp, maxTemp, power } = this.state;

        const manifestDisabledClass = this.props.isManifestEditable ? '' : 'disabled';

        const [ manifestErrorClass, manifestErrorMessage, minTempErrorClass, minTempErrorMessage, maxTempErrorClass, maxTempErrorMessage ] = this._formErrorMessages();

        const requestFailedMessage = this.state.showWarning ? <Message type="warning" size="small">{ this.state.warningMessage }</Message> : <></>;

        let manifestInputInfo = <></>;
        if (this.state.activeManifestChanged) {
            manifestInputInfo = (
                <div className="message">
                    <Message type="info" size="small">
                        Submitting this form will cause manifest { this.state.activeManifestId } to be deactivated.
                    </Message>
                </div>
            );
        }

        let checkingManifestValidity = <></>;
        if (this.state.showLoader) {
            checkingManifestValidity = (
                <div className="loader">
                    <Loader type="TailSpin" color="#289AC2" height={ 12 } width={ 12 } visible={ true } />
                    <span>Checking ID...</span>
                </div>
            );
        }

        return (
            <Modal
                show={ this.props.show }
                onHide={ this.onClose }
                backdrop="static"
                keyboard={ false }
                centered
                dialogClassName="manage-manifest-modal"
            >
                <SimpleContainer className="modal-container" modal title={ this.props.title }>
                    { requestFailedMessage }
                    <div className="manifest-section">
                        <h6 className="heading">
                            Manifest Details
                        </h6>
                        <p className="description">
                            In order to track the trailer you need to associate it with either a real manifest number or
                            generate a random one. You can choose to always track this trailer within this manifest,
                            or to better preserve history, create a new manifest for each new order.
                        </p>
                        <div className="prompt">
                            <span className="asterisk">*</span> Manifest ID
                            <div className="tooltip-container" style={{ position: 'relative' }}>
                                <FontAwesomeIcon icon={ faQuestionCircle } className="icon" />
                                <SimpleTooltip direction="top">
                                    The manifest ID can be your load's <br />
                                    manifest, or a randomly generated <br />
                                    combination. It needs to be unique.
                                </SimpleTooltip>
                            </div>
                        </div>
                        <input
                            id="manifest-id"
                            type="text"
                            className={ `manifest-id-input ${ manifestDisabledClass } ${ manifestErrorClass }` }
                            maxLength="255"
                            required
                            placeholder="Enter Manifest ID"
                            value={ manifestId || "" }
                            onChange={ this.onManifestIdChange.bind(this) }
                            disabled={ !this.props.isManifestEditable }
                        />
                        <span className="helper">or</span>
                        <Button
                            className="generate-id-button"
                            onClick={ this.setRandomManifestId.bind(this) }
                            disabled={ !this.props.isManifestEditable }
                        >
                            Generate ID
                        </Button>
                        { checkingManifestValidity }
                        { manifestErrorMessage }
                        { manifestInputInfo }

                        <div className="prompt">
                            <span className="asterisk">*</span> Trailer ID
                            <div className="tooltip-container" style={{ position: 'relative' }}>
                                <FontAwesomeIcon icon={ faQuestionCircle } className="icon" />
                                <SimpleTooltip direction="top">
                                    ID of the trailer you wish to track <br />
                                    under this manifest.
                                </SimpleTooltip>
                            </div>
                        </div>
                        <AsyncSelect
                            className="basic-single-trailer"
                            classNamePrefix="select-trailer"
                            cacheOptions
                            defaultOptions
                            loadOptions={ this.fetchTrailers.bind(this) }
                            defaultValue={ trailerId ? { label: trailerId, value: trailerId } : null }
                            value={ trailerId ? { label: trailerId, value: trailerId } : null }
                            onChange={ this.setSelectValue.bind(this, 'trailerId') }
                            isDisabled={ this.props.trailer ? true : false }
                        />
                    </div>

                    <div className="trailer-section">
                        <h6 className="heading">
                            Trailer Details
                        </h6>
                        <p className="description">
                            Please enter the reefer configuration data that will be matched to the reefer readings in
                            order to prevent issues with your order.
                        </p>
                        <div className="trailer-settings">
                            <div className="minimum">
                                <div className="prompt">
                                    <span className="asterisk">*</span> Min. temperature <span className="unit">(&deg;F)</span>
                                    <div className="tooltip-container" style={{ position: 'relative' }}>
                                        <FontAwesomeIcon icon={ faQuestionCircle } className="icon" />
                                        <SimpleTooltip direction="top">
                                            The temperature under which the <br />
                                            system should send alerts.
                                        </SimpleTooltip>
                                    </div>
                                </div>
                                <input
                                    name="minTemp"
                                    type="number"
                                    className={ `min-temp-input ${ minTempErrorClass }` }
                                    required
                                    placeholder="Min. temperature"
                                    value={ minTemp || minTemp === 0 ? minTemp : '' }
                                    onChange={ this.setInputValue.bind(this) }
                                    disabled={ !this.state.power }
                                />
                            </div>

                            <div className="maximum">
                                <div className="prompt">
                                    <span className="asterisk">*</span> Max. temperature <span className="unit">(&deg;F)</span>
                                    <div className="tooltip-container" style={{ position: 'relative' }}>
                                        <FontAwesomeIcon icon={ faQuestionCircle } className="icon" />
                                        <SimpleTooltip direction="top">
                                            The temperature above which the <br />
                                            system should send alerts.
                                        </SimpleTooltip>
                                    </div>
                                </div>
                                <input
                                    name="maxTemp"
                                    type="number"
                                    className={ `max-temp-input ${ maxTempErrorClass }` }
                                    required
                                    placeholder="Max. temperature"
                                    value={ maxTemp || maxTemp === 0 ? maxTemp : '' }
                                    onChange={ this.setInputValue.bind(this) }
                                    disabled={ !this.state.power }
                                />
                            </div>
                        </div>
                        { minTempErrorMessage } { maxTempErrorMessage }

                        <div className="trailer-settings">
                            <div className="mode">
                                <div className="prompt">
                                    <span className="asterisk">*</span> Expected mode
                                    <div className="tooltip-container" style={{ position: 'relative' }}>
                                        <FontAwesomeIcon icon={ faQuestionCircle } className="icon" />
                                        <SimpleTooltip direction="top">
                                            Expected reefer mode configuration
                                        </SimpleTooltip>
                                    </div>
                                </div>
                                <Select
                                    className="basic-single-select"
                                    classNamePrefix="select-value"
                                    options={ modeOptions }
                                    defaultValue={ modeOptions.filter(option => option.value === mode) }
                                    value={ modeOptions.filter(option => option.value === mode) }
                                    isSearchable={ false }
                                    onChange={ this.setSelectValue.bind(this, 'mode') }
                                />
                            </div>

                            <div className="power">
                                <div className="prompt">
                                    <span className="asterisk">*</span> Expected power
                                    <div className="tooltip-container" style={{ position: 'relative' }}>
                                        <FontAwesomeIcon icon={ faQuestionCircle } className="icon" />
                                        <SimpleTooltip direction="top">
                                            Expected reefer power configuration
                                        </SimpleTooltip>
                                    </div>
                                </div>
                                <Select
                                    className="basic-single-select"
                                    classNamePrefix="select-value"
                                    options={ powerOptions }
                                    defaultValue={ powerOptions.filter(option => option.value === power) }
                                    value={ powerOptions.filter(option => option.value === power) }
                                    isSearchable={ false }
                                    onChange={ this.setSelectValue.bind(this, 'power') }
                                />
                            </div>
                        </div>
                    </div>

                    <div className="tracking-section">
                        <h6 className="heading">
                            Tracking
                        </h6>
                        <Pill
                            uncheckedGreyColor
                            checked={ this.state.tracking }
                            onChange={ this.handleTrackingToggle.bind(this) }
                        />
                    </div>
                    <p className="description">
                        When reefer tracking is enabled, in case any of the reefer readings do not match the configured
                        settings, the system will detect and alert users about the issues. In case the issue is not
                        resolved in the configured time threshold, new emails will be sent.
                    </p>

                    <div className="buttons">
                        <Button
                            variant="light"
                            onClick={ this.onClose }
                            className="cancel-button"
                        >
                            Cancel
                        </Button>

                        <Button
                            variant="continue"
                            className="submit-button"
                            disabled={ !this.state.submittable }
                            onClick={ this.handleSubmit.bind(this) }
                        >
                            Submit
                        </Button>
                    </div>
                </SimpleContainer>
            </Modal>
        );
    }
}

ManageManifestPopup.contextType = AuthContext;
