import React, { Component } from 'react';
import DataTableComp from 'components/datatable/DataTableComp';
import AuthContext from "AuthContext"
import ReactDOM from 'react-dom';
import './DetailView.scss';
import './CommandsTable.scss';
import SimpleTooltip from '../../components/tooltip/SimpleTooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const STATUS_DETAILS = {
    SCHEDULED: {
        label: 'Scheduled',
        description: 'This indicates that the request has been sent to the Unit, but the unit has not sent an acknowledgement the request.'
    },
    ACKNOWLEDGED: {
        label: 'Acknowledged',
        description: 'The Unit has received the request and will process the request.'
    },
    IN_PROGRESS: {
        label: 'In Progress',
        description: 'The Unit has received the request and is carrying out the request. This status update is only given for requests that take considerable time.'
    },
    SUCCESS: {
        label: 'Success',
        description: 'The request was successfully carried out.'
    },
    FAILED: {
        label: 'Failed',
        description: 'This indicates that the request was not carried out.'
    },
    NO_RESPONSE_FROM_UNIT: {
        label: 'No Response From Unit',
        description: 'This indicates that the request has expired, but the unit has not reported back whether it was successful or not in carrying out the operation.'
    },
    TIMEOUT: {
        label: 'Timeout',
        description: 'The Unit has received the request at a time that exceeded the request timeout. This can happen if a vehicle is out of SMS coverage.'
    },
    UNKNOWN: {
        label: 'Unknown',
        description: 'The status of this request is unknown at this time.'
    }
};

export default class CommandsTable extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        let data = Array.from([]);

        const columns = [
            {title: "Command Name", data: "command"}, // 0
            {title: "Submitted at", data: "date"}, // 1
            {title: "Status", data: "status"}, // 2
        ];

        const sortingRule = [];

        const columnDefs = [
            {
                className: "dt-align-left",
                orderable: false,
                targets: [0, 1, 2]
            },
            {
                targets: [0],
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<>{ this._mapCommandName(rowData) }</>, td);
                }
            },
            {
                targets: [2],
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<>{ this._mapCommandStats(rowData) }</>, td);
                }
            }
        ];

        this.state = {
            sort: [],
            columns: columns,
            columnDefs: columnDefs,
            sortingRule: sortingRule,
            data: data
        };
    }

    _mapCommandName(rowData) {
        const command = rowData.command;
        const metaData = rowData.metaData;

        const commandLabelClass = 'command-label';

        let commandText = <></>;
        switch (command) {
            case 'POWER_ON':
                commandText = <><span className={ commandLabelClass }>Power:</span>On</>;
                break;
            case 'POWER_OFF':
                commandText = <><span className={ commandLabelClass }>Power:</span>Off</>;
                break;
            case 'CONFIGURE_UNIT_MODE_CONTINUOUS':
                commandText = <><span className={ commandLabelClass }>Unit Mode:</span>Continuous</>;
                break;
            case 'CONFIGURE_UNIT_MODE_CYCLE_SENTRY':
                commandText = <><span className={ commandLabelClass }>Unit Mode:</span>Cycle Sentry</>;
                break;
            case 'CONFIGURE_SET_POINT':
                commandText = <><span className={ commandLabelClass }>Set Point:</span>{ metaData.setPoint } &deg;F</>;
                break;
            default:
                console.error("Unknown command:", command);
                break;
        }

        return commandText;
    }

    _mapCommandStats(rowData) {
        const status = rowData.status;

        return (
            <>
                { STATUS_DETAILS[status].label }
                <span className="command-status-label" style={{ position: 'relative' }}>
                    <FontAwesomeIcon icon={ faInfoCircle } />
                    <SimpleTooltip direction="left">{ STATUS_DETAILS[status].description }</SimpleTooltip>
                </span>
            </>
        );
    }

    render() {
        if (this.props.data) {
            return (
                <div className="detail-tables commands-table">
                    <DataTableComp
                        tableId="detailCommands"
                        columns={this.state.columns}
                        columnDefs={this.state.columnDefs}
                        isDetailView={false}
                        orderRule={this.state.sortingRule}
                        tableHeight="300px"
                        data={this.props.data}
                        sortRule={this.state.sort.concat([])}
                        addSortingForColumn={ () => {} }
                    />
                </div>
            );
        } else {
            return(<div/>)
        }
    }
}
