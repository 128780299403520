import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../AppInsights';
import history from "global/history";

import { formatDate } from "global/services/DateTimeService"
import { withRouter } from "react-router";
import { assignmentWithLegsUrl, issuesDetailsUrl, detailsGridRecordsNumber } from "global/constants"
import SimpleContainer from '../../components/container/SimpleContainer';
import HeaderCard from 'components/card/HeaderCard';
import { mapIssuesFromResponse } from "global/services/IssueApiService";

import AuthContext from "AuthContext";
import LegCard from "./LegCard";
import IssueTable from "./IssueTable";
import './DetailView.scss';

const CACHE = {};

class AssignmentDetailView extends Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            issuesList: [],
            account: props.account,
            relevantLegPagingInfo: {},
            irrelevantLegPagingInfo: {}
        }

        this.fetchAssignment = this.fetchAssignment.bind(this);
    }

    async componentDidMount() {
        if (this.props.match && this.props.match.params.assignmentId) {
            await this.fetchAssignment();
        }
    }

    mapAssignmentData(assignment, legInfoList, relevantLeg, relevantIssues, inactiveLegs, irrelevantLegIssues) {
        return {
            assignment: assignment,
            trailer: assignment.legs[0] ? assignment.legs[0].trailer : null,
            assignmentId: assignment.id,
            assignmentBusinessId: assignment.businessId,
            assignmentStatus: assignment.active ? "Active" : "Inactive",
            trailerBusinessId: relevantLeg ? relevantLeg.leg.trailer.businessId : "Unknown",
            insideSalesRepName: assignment.insideSalesRep ? assignment.insideSalesRep.contactInfo.name : "Unknown",
            mode: assignment.unitMode === "CONTINUOUS" ? "Continuous" : "Cycle-Sentry",
            minTemp: assignment.temperatureMin,
            maxTemp: assignment.temperatureMax,
            expectedPower: assignment.power ? "On" : "Off",
            legs: legInfoList,
            irrelevantLegIssues,
            relevantIssues,
            relevantLeg: relevantLeg,
            inactiveLegs,
            loaded: true
        }
    }

    async fetchAssignment() {
        const cachedPage = 'assignment_' + this.props.match.params.assignmentId.toString();
        if (CACHE[cachedPage] !== undefined) {
            this.setState(CACHE[cachedPage].mappedAssignmentData);
        }

        this.setState({
            loaded: false
        });

        const response = await this.context.get(assignmentWithLegsUrl, { id: this.props.match.params.assignmentId }, true);
        if (response.status === "error" || !response || response.length <= 0 || !response.assignment) {
            history.push('/404');
            return;
        }
        const assignment = response.assignment;
        if (assignment.temperatureMax === null || assignment.temperatureMin === undefined) {
            assignment.temperatureMin = null;
        } else if (!isFinite(assignment.temperatureMin)) {
            assignment.temperatureMin = "NO DATA";
        }
        if (assignment.temperatureMax === null || assignment.temperatureMax === undefined) {
            assignment.temperatureMax = null;
        } else if (!isFinite(assignment.temperatureMax)) {
            assignment.temperatureMax = "NO DATA";
        }

        const relevantLeg = response.legInfoList && response.relevantLeg ? response.legInfoList.find(legInfo => legInfo.leg.id === response.relevantLeg.id) : null;
        const inactiveLegs = response.legInfoList ? Array.from(response.legInfoList.filter(legInfo => !relevantLeg || legInfo.leg.id !== relevantLeg.leg.id)) : [];
        const relevantIssues = relevantLeg && relevantLeg.issues ? mapIssuesFromResponse(relevantLeg.issues) : [];

        const mappedAssignmentData = this.mapAssignmentData(assignment, response.legInfoList, relevantLeg, relevantIssues, inactiveLegs, null);

        CACHE['assignment_' + this.props.match.params.assignmentId] = {
            ...CACHE['assignment_' + this.props.match.params.assignmentId],
            mappedAssignmentData
        };

        this.setState(mappedAssignmentData, async () => {
            await this.fetchIssues(null, true);
            await this.fetchIssues(null, false);
        });
    }

    async fetchIssues(sort, active, pageNumber = 1, pageSize = detailsGridRecordsNumber) {
        if ((active && pageNumber > Math.ceil(this.state.relevantLegPagingInfo.totalItemNumber / this.state.relevantLegPagingInfo.recordsNumber))
            || (!active && pageNumber > Math.ceil(this.state.irrelevantLegPagingInfo.totalItemNumber / this.state.irrelevantLegPagingInfo.recordsNumber))) {
            return;
        }

        if (CACHE['assignment_' + this.state.assignmentId] !== undefined) {
            if (active && CACHE['assignment_' + this.state.assignmentId].activeLegIssues !== undefined) {
                this.setState({
                    activeLegIssues: CACHE['assignment_' + this.state.assignmentId].activeLegIssues
                });
            } else if (!active && CACHE['assignment_' + this.state.assignmentId].inactiveLegIssues !== undefined) {
                this.setState({
                    inactiveLegIssues: CACHE['assignment_' + this.state.assignmentId].inactiveLegIssues
                });
            }
        }

        let response = await this.context.get(issuesDetailsUrl,
            {
                forMaintenance: false,
                sort: sort || [],
                trailerId: this.props.match.params.trailerId,
                assignmentId: this.state.assignmentId,
                active: active,
                pageNumber: pageNumber,
                pageSize: pageSize
            },
            true);
        if (response.status === "error" || !response || !response.data) {
            return {}
        }

        const issues = mapIssuesFromResponse(response.data);
        const pagingInfo = {
            itemNumber: Math.min(response.pageSize, response.data.length),
            totalItemNumber: response.available,
            totalPageNumber: Math.ceil(response.available / response.pageSize),
            currentPage: Math.min(response.pageNumber, Math.ceil(response.available / response.pageSize)),
            recordsNumber: response.pageSize
        };
        if (active) {
            CACHE['assignment_' + this.state.assignmentId] = {
                ...CACHE['assignment_' + this.state.assignmentId],
                activeLegIssues: issues
            };

            this.setState({
                relevantLegIssues: issues,
                relevantLegPagingInfo: pagingInfo
            })
        } else {
            CACHE['assignment_' + this.state.assignmentId] = {
                ...CACHE['assignment_' + this.state.assignmentId],
                inactiveLegIssues: issues
            };

            this.setState({
                irrelevantLegIssues: issues,
                irrelevantLegPagingInfo: pagingInfo
            })
        }
    }

    sortFunction(a, b) {
        const checkNulls = (!a.firstMeasurement) - (!b.firstMeasurement);
        const activeCheck = (a.leg.active === b.leg.active) ? 0 : a.leg.active ? -100 : 100;
        let datesCheck = null;
        if (a.legInfos && b.legInfos) {
            datesCheck = -(a.firstMeasurement > b.firstMeasurement) || +(a.firstMeasurement < b.firstMeasurement)
        }
        return datesCheck || checkNulls || activeCheck;
    }

    render() {
        const currentTrailerInfo = {
            activeTrailerId: this.state.relevantLeg && this.state.relevantLeg.leg && this.state.relevantLeg.leg.trailer && this.state.relevantLeg.leg.trailer.businessId,
            mode: this.state.mode,
            actualMode: this.state.relevantLeg && this.state.relevantLeg.lastMeasurement.modeState.unitMode ? this.state.relevantLeg.lastMeasurement.modeState.unitMode === "CONTINUOUS" ? "Continuous" : "Cycle-Sentry" : null,
            minTemp: this.state.minTemp,
            maxTemp: this.state.maxTemp,
            setPoint: this.state.relevantLeg && this.state.relevantLeg.lastMeasurement.temperatureState.setTemperature,
            returnAir: this.state.relevantLeg && this.state.relevantLeg.lastMeasurement.temperatureState.actualTemperature,
            detentionState: this.state.relevantLeg && this.state.relevantLeg.lastMeasurement.detentionState,
            inactivityState: this.state.relevantLeg && this.state.relevantLeg.lastMeasurement,
            expectedPower: this.state.expectedPower,
            power: this.state.relevantLeg && (this.state.relevantLeg.lastMeasurement.powerState.power === true ? "On" : this.state.relevantLeg.lastMeasurement.powerState.power === false ? "Off" : null),
            issuesList: this.state.relevantIssues,
            insideSalesRepName: this.state.insideSalesRepName,
            relevantLeg: this.state.relevantLeg
        }

        return (
            <>
                <div className="container-fluid page detailview assignmentDetails">
                    {
                        <div>
                            <nav className="col-12">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/issues/all">All issues</a></li>
                                    <li className="breadcrumb-item active"><a href="#!">Manifest #{this.state.assignmentBusinessId}</a></li>
                                    <NavLink to="/dashboard" className="back-link">
                                        <p>
                                            &larr; Back to dashboard
                                        </p>
                                    </NavLink>
                                </ol>
                            </nav>

                            <div className="col-12">
                                { this.state.legs && this.state.legs.sort((a, b) => this.sortFunction(a, b)).map((legInfos, legX) => {
                                    const lastMeasurement = legInfos.lastMeasurement;

                                    const leg = legInfos.leg;
                                    let totalNumber;

                                    if (this.state.relevantLegPagingInfo.totalItemNumber) {
                                        totalNumber = this.state.relevantLegPagingInfo.totalItemNumber;
                                    } else if (this.state.irrelevantLegPagingInfo.totalItemNumber) {
                                        totalNumber = this.state.irrelevantLegPagingInfo.totalItemNumber;
                                    }

                                    const legInfo = {
                                        assignmentId: leg.assignment.id,
                                        trailerId: leg.trailer.id,
                                        legBusinessId: leg.businessId,
                                        trailerBusinessId: leg.trailer.businessId,
                                        issues: totalNumber,
                                        active: leg.active,
                                        startDate: leg.startTime ? formatDate(new Date(leg.startTime)) : "Unknown",
                                        endDate: leg.endTime ? formatDate(new Date(leg.endTime)) : "Unknown",
                                        location: lastMeasurement ? lastMeasurement.positionState.position || "Unknown" : "Unknown"
                                    }

                                    const trailerInfo = {
                                        ...this.state.trailer,
                                        location: lastMeasurement ? lastMeasurement.positionState.position || "Unknown" : "Unknown",
                                        moving: lastMeasurement.positionState.moving ? "Moving" : (lastMeasurement.positionState.moving !== undefined ? "Stopped" : "Silent"),
                                        statusClassName: "gathered-info " + (lastMeasurement.positionState.moving ? "greenColorText" : lastMeasurement.positionState.moving !== undefined ? "redColorText" : "orangeColorText")
                                    }

                                    const issuesInfo = {
                                        unresolvedIssuesNumber: totalNumber
                                    }

                                    return (
                                        <>
                                            <HeaderCard
                                                loaded={ this.state.loaded }
                                                assignment={ this.state.assignment }
                                                trailer={ trailerInfo }
                                                issues={ issuesInfo }
                                                onCloseManifestPopup={ this.fetchAssignment.bind(this) }
                                            />
                                            <LegCard key={ legX } legInfo={ legInfo } currentInfo={ leg.active === true || (this.state.relevantLeg && leg.id === this.state.relevantLeg.leg.id) ? currentTrailerInfo : null } isRelevant={ this.state.relevantLeg ? leg.id === this.state.relevantLeg.leg.id : false }></LegCard>
                                        </>
                                    )
                                })}
                            </div>
                            <div className="col-12">
                                {
                                    !this.state.legs && <LegCard key={0} className="active" legInfo={{}} currentInfo={ null }></LegCard>
                                }
                            </div>
                            { ((this.state.relevantLegIssues || []).length > 0 || (this.state.irrelevantLegIssues || []).length > 0) &&
                                <div className="col-12 col-lg-12">
                                    <SimpleContainer className="issues-container" title="TRAILER ISSUES">
                                        <IssueTable
                                            relevantLegIssues={ this.state.relevantLegIssues || [] }
                                            relevantLegPagingInfo={ this.state.relevantLegPagingInfo }
                                            irrelevantLegIssues={ this.state.irrelevantLegIssues || [] }
                                            irrelevantLegPagingInfo={ this.state.irrelevantLegPagingInfo }
                                            account={ this.state.account }
                                            fetchIssues={ this.fetchIssues.bind(this) }>
                                        </IssueTable>
                                    </SimpleContainer>
                                </div>
                            }
                        </div>
                    }
                </div>
            </>
        )
    }
}

export default withRouter(withAITracking(reactPlugin, AssignmentDetailView, "AssignmentDetailView"));
