import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faInfoCircle as infoIcon,
    faClose as closeIcon,
    faTruckBolt as powerIssueIcon,
    faGasPump as fuelIssueIcon,
    faTemperatureHigh as temperatureIssueIcon,
    faSlidersUp as modeIssueIcon,
    faSignalBarsSlash as silentIssueIcon 
} from '@fortawesome/pro-solid-svg-icons';

import { issueInfoUrl, typeIssueInfoUrl } from 'global/constants';
import { 
    dayPart,
    formatDateTimeToTimestamp,
    formatLongDateWithoutTime, 
    formatTimestampToDate, 
    getCurrentDate, 
    getMonthsBefore, 
    hourPart,
    minutePart
} from 'global/services/DateTimeService';
import QueryParamService from 'global/services/QueryParamService';
import { MONITORED_ISSUE_TYPES } from 'global/services/IssueTypeService';

import AuthContext from 'AuthContext';
import { ReactComponent as InactivityTruck } from 'files/issue-icon/inactivity-issue-truck.svg';
import { ReactComponent as PrsIconBlue } from 'files/logo/prs-icon-blue.svg';
import { ReactComponent as PrsIconWhite } from 'files/logo/prs-icon-white.svg';

import './StatisticsSummaryPreview.scss'

class StatisticsSummaryPreview extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        const queryParamsString = window.location.search.slice(1);
        const startTime = QueryParamService.parseSimpleValueFromQueryString(queryParamsString, 'startDate');
        const endTime = QueryParamService.parseSimpleValueFromQueryString(queryParamsString, 'endDate');

        const issueTypeLabels = Object.keys(MONITORED_ISSUE_TYPES).map(key => ({ label: key }));

        this.state = {
            showInfo: true,
            startTime: startTime ? formatTimestampToDate(startTime) : getMonthsBefore(getCurrentDate(), 1),
            endTime: endTime ? formatTimestampToDate(endTime) : getCurrentDate(),
            avgResolutionData: issueTypeLabels
        };

        this.generateLink = this.generateLink.bind(this);
        this.getActiveFilter = this.getActiveFilter.bind(this);
        this.fetchStatistics = this.fetchStatistics.bind(this);
        this.fetchTotalStats = this.fetchTotalStats.bind(this);
        this.renderIssueCounts = this.renderIssueCounts.bind(this);
        this.fetchAvgResolutionData = this.fetchAvgResolutionData.bind(this);
        this.renderAvgResolutionTime = this.renderAvgResolutionTime.bind(this);
    }

    componentDidMount() {
        this.fetchStatistics();
    }

    async fetchStatistics() {
        await this.fetchTotalStats();
        await this.fetchAvgResolutionData();
    }

    async fetchTotalStats() {
        const response = await this.context.get(issueInfoUrl, this.getActiveFilter());

        if (response.status === 'error') {
            console.error(response.message);
            return;
        }

        this.setState({
            orderCount: response.orderCount,
            issueCount: response.issueCount,
            escalationsCount: response.escalationsCount,
            resolvedIssueCount: response.resolvedIssueCount,
            manuallyResolvedIssueCount: response.manuallyResolvedIssueCount,
            avgIssueResolutionTime: response.avgIssueResolutionTime ? response.avgIssueResolutionTime.toFixed(1) : 0,
            snoozedIssueCount: response.snoozedIssueCount
        });
    }

    async fetchAvgResolutionData() {
        const response = await this.context.get(typeIssueInfoUrl, this.getActiveFilter());

        if (response.status === 'error') {
            console.error(response.message);
            return;
        }

        const avgResolutionData = {};
        response.forEach(entity => {
            avgResolutionData[entity.issueType] =  entity.issueCount
        });

        this.setState({
            avgResolutionData: avgResolutionData
        });
    }

    getActiveFilter() {
        return {
            startTimeFrom: this.state.startTime.toDate(),
            endTimeTo: this.state.endTime.toDate()
        };
    }

    generateLink() {
        const startDate = formatDateTimeToTimestamp(this.state.startTime.toDate());
        const endDate = formatDateTimeToTimestamp(this.state.endTime.toDate());

        return `/statistics?startDate=${startDate}&endDate=${endDate}`;
    }

    renderAvgResolutionTime(time) {
        if (isNaN(time)) {
            return time;
        }

        if (time === 0 ) {
            return (
                <>
                    less than a minute 
                </>
            );
        }

        const day = dayPart(time || 0);
        time = time - day * (24 * 60);

        const hour = hourPart(time || 0);
        time = time - hour * 60;
        
        const minute = minutePart(time || 0);

        let dayContent;
        let hourContent;
        let minuteContent;

        if (day > 0) {
            dayContent = (
                <>
                    { day }
                    <span className="time-unit">d</span>
                    &nbsp;
                </>
            );
        }
        if (hour > 0) {
            hourContent = (
                <>
                    { hour }
                    <span className="time-unit">h</span>
                    &nbsp;
                </>
            );
        }
        if (minute > 0) {
            minuteContent = (
                <>
                    { minute }
                    <span className="time-unit">min</span>
                    &nbsp;
                </>
            );
        }

        return (
            <span>
                { dayContent }
                { hourContent }
                { minuteContent }
            </span>
        );
    }

    renderIssueCounts() {
        const issueTypeMap = [
            {
                label: 'Power Issues',
                icon: powerIssueIcon,
                issueType: 'POWER_STATE'
            },
            {
                label: 'Fuel Issues',
                icon: fuelIssueIcon,
                issueType: 'LOW_FUEL_ALARM'
            },
            {
                label: 'Temp. Issues',
                icon: temperatureIssueIcon,
                issueType: 'TEMPERATURE'
            },
            {
                label: 'Mode Issues',
                icon: modeIssueIcon,
                issueType: 'UNIT_MODE'
            },
            {
                label: 'Inactive Trailers',
                icon: <InactivityTruck className="issue-icon inactive-issue" />,
                issueType: 'INACTIVITY'
            },
            {
                label: 'Silent Units',
                icon: silentIssueIcon,
                issueType: 'SILENT'
            },
        ];

        return issueTypeMap.map(issue => (
            <div key={ "issue_type-" + issue.issueType } className={ "issue-count-card" + (issue.issueType === "INACTIVITY" ? " inactive-issue" : "") }>
                { issue.issueType === "INACTIVITY" ? issue.icon : <FontAwesomeIcon className="issue-icon" icon={ issue.icon } /> }
                <div className={ "issue-text" + (issue.issueType === "INACTIVITY" ? " inactive-issue" : "") }>{ issue.label }</div>
                <div className={ "issue-count" + (issue.issueType === "INACTIVITY" ? " inactive-issue" : "") }>{ this.state.avgResolutionData[issue.issueType] }</div>
            </div>
        ));

    }

    render() {
        return(
            <div className="statistics-summary-preview">
                { this.state.showInfo &&
                    <div className="info-container">
                        <FontAwesomeIcon icon={ infoIcon } className="info-icon" />
                        <div className="info-text">This is an email template preview and it does not contain accurate Statistics data.</div>
                        <FontAwesomeIcon icon={ closeIcon } className="close-icon" onClick={ () => this.setState({ showInfo: false })} />
                    </div>
                }

                <div className="summary-container">
                    <div className="summary-header">
                        <div>Your Fleet at <br />Your Fingertips. </div>
                        <PrsIconBlue width={ 130 } className="summary-header-prs-logo" />
                    </div>

                    <div className="summary-details">
                        <div className="summary-details-header">
                            <img
                                className="summary-header-prs-icon"
                                height={ 32 }
                                width={ 32 }
                                src={ process.env.PUBLIC_URL + '/prs-apple-touch-icon.png' }
                                alt="prs icon"
                            />
                            <div>
                                <div className="summary-header-text">Statistics Overview</div>
                                <div className="summary-details-date">{ formatLongDateWithoutTime(this.state.startTime.toDate()) } - { formatLongDateWithoutTime(this.state.endTime.toDate()) }</div>
                            </div>
                        </div>
                        <Link 
                            variant="continue"
                            className="summary-details-open-in-app-btn"
                            to={ this.generateLink() }
                        >
                            <PrsIconWhite width={ 14 } height={ 14 }/>
                            Open in App
                        </Link>
                    </div>

                    <div className="summary-issues-info-count-box">
                        <div className="count-box">
                            <div className="count-text">{ this.state.issueCount }</div>
                            <div className="issue-desc-text">Detected Issues</div>
                        </div>
                        <div className="count-box">
                            <div className="count-text">{ this.state.resolvedIssueCount }</div>
                            <div className="issue-desc-text">Resolved Issues</div>
                        </div>
                        <div className="count-box">
                            <div className="count-text">{ this.state.manuallyResolvedIssueCount }</div>
                            <div className="issue-desc-text">Closed Issues</div>
                        </div>
                    </div>

                    <div className="summary-issues-info">
                        <div className="summary-header-text">Issue Types</div>

                        <div className="issue-count-box">
                            { this.renderIssueCounts() }
                        </div>

                        <div className="summary-header-text">Other Stats</div>
                        <div className="summary-other-stats-box">
                            <div className="stats-box">
                                <div className="count-text">{ this.state.orderCount }</div>
                                <div className="desc-text">Created Manifests</div>
                            </div>
                            <div className="stats-box">
                                <div className="count-text">{ this.state.snoozedIssueCount }</div>
                                <div className="desc-text">Snoozed Issues</div>
                            </div>
                            <div className="stats-box">
                                <div className="count-text">{ this.state.escalationsCount }</div>
                                <div className="desc-text">Escalations</div>
                            </div>
                            <div className="stats-box">
                                <div className="count-text">{ this.renderAvgResolutionTime(this.state.avgIssueResolutionTime) }</div>
                                <div className="desc-text">Avg. Resolution Time</div>
                            </div>
                        </div>

                        <div className="summary-header-text">Notice</div>
                        <div className="summary-notice">
                            This Statistics Overview is automatically generated and reflects the data from the selected period. To
                            see more accurate details and customise the period or scope of the statistics, make sure to visit the
                            <Link className="main-page-link" to={ this.generateLink() }> Stats page</Link>.
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default StatisticsSummaryPreview;
