import React, { Component } from 'react';

import RequiresRole from 'components/requires-role/RequiresRole'
import SearchBar from 'components/input/SearchBar';
import AccountWidget from 'components/navigation/AccountWidget';
import MultiSelect from 'components/input/MultiSelect';
import { fleetUrl, isFleetSelectable } from 'global/constants';
import AuthContext from 'AuthContext';

import './Header.scss';

export default class Header extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            fleets: []
        };
    }

    componentDidMount() {
        this.context.get(fleetUrl, { active: true })
            .then(response => this.setState({ fleets: response.data || [] }))
            .catch(error => console.error(error));
    }

    fleetOptions(selectedFleets) {
        const allTrailersSelected = selectedFleets.some(fleet => fleet === null);

        const defaultOption = { 
            value: null,
            label: 'All Trailers'
        };

        const fleetOptions = this.state.fleets.map(fleet => ({
            value: fleet.name,
            label: fleet.name,
            disabled: allTrailersSelected
        }));

        const noFleetOption = { 
            value: 'none', 
            label: 'No Fleets', 
            disabled: allTrailersSelected
        }

        return [defaultOption, ...fleetOptions, noFleetOption];
    }

    render() {
        return (
            <header className="header container-fluid">
                <div className="row justify-content-between">
                    <div className="search col-auto">
                        <RequiresRole
                            roles={this.context.role}
                            perform="search-page:visit"
                            yes={() => (<SearchBar /> )}
                            no={() =>
                                <RequiresRole
                                roles={this.context.role}
                                perform="alarm-issues:list"
                                yes={() => (<SearchBar maintenance={true}/> )}
                                no={() =>  <></>} />
                            } />
                    </div>
                    <div className="user-settings col-auto">
                        <div className={ isFleetSelectable() ? "d-flex align-items-center" : "d-none" }>
                            <span className="multi-select-text">
                                Displaying:
                            </span>
                            <MultiSelect
                                initiallySelected={ this.context.fleets }
                                defaultSelected={ null }
                                options={ this.fleetOptions(this.context.fleets) }
                                onChange={ this.context.setFleets }
                                multiLabel="Multiple fleets"
                                borderless
                            />
                        </div>
                        <AccountWidget />
                    </div>
                </div>
            </header>
        );
    }
}
