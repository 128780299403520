import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AuthContext from 'AuthContext';
import SimpleContainer from 'components/container/SimpleContainer';
import ConfirmMessageContainer from 'components/container/ConfirmMessageContainer';
import ErrorMessageContainer from 'components/container/ErrorMessageContainer';

import './DeletePopup.scss';

export default class DeletePopup extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            requestSucceeded: null,
            loading: false
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            this.setState({ requestSucceeded: null });
        }
    }

    async handleSubmit() {
        try {
            this.setState({ loading: true });
            const response = await this.context.delete(this.props.url, this.props.params);
            if (response.status === 'error') {
                console.error(response.message);
                this.onError();
            } else {
                this.onSuccess(response);
            }
        } catch (error) {
            console.error(error);
            this.onError();
        }

        this.setState({ loading: false });
    }

    onSuccess(response) {
        if (this.props.withConfirmMessage) {
            this.setState({ requestSucceeded: true });
        } else {
            this.props.onClose(response, this.props.params);
        }
    }

    onError() {
        if (this.props.withConfirmMessage) {
            this.setState({ requestSucceeded: false });
        } else {
            this.props.onClose(false);
        }
    }

    render() {
        let modalContent;

        if (this.state.requestSucceeded) {
            modalContent = (
                <ConfirmMessageContainer
                    title={ this.props.confirmMessageTitle }
                    description={ this.props.confirmMessageText }
                    onClose={ () => this.props.onClose(true) }
                />
            );
        } else if (this.state.requestSucceeded === false) {
            modalContent = (
                <ErrorMessageContainer onClose={ () => this.props.onClose(false) } />
            );
        } else {
            modalContent = (
                <SimpleContainer className="modal-container" modal title={ this.props.title }>
                    { this.props.subtitle && (
                        <div className="warning-header">
                            { this.props.subtitle }
                        </div>
                    ) }
                    <div className="warning-text">
                        { this.props.children }
                    </div>
                    <div className="buttons">
                        <Button
                            disabled={ this.state.loading }
                            variant="light"
                            className="cancel-button"
                            onClick={ () => this.props.onClose(false) }
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={ this.state.loading }
                            variant="danger"
                            className="delete-button"
                            onClick={ this.handleSubmit.bind(this) }
                        >
                            Delete
                        </Button>
                    </div>
                </SimpleContainer>
            );
        }

        return (
            <Modal
                show={ this.props.show }
                onHide={ () => this.props.onClose(false) }
                backdrop="static"
                keyboard={ false }
                centered
                dialogClassName="delete-modal"
            >
                { modalContent }
            </Modal>
        );
    }
}

DeletePopup.contextType = AuthContext;
