import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { snoozeDurations, snoozeReasons } from 'global/services/SnoozeOptionsProvider';
import { snoozeIssue } from "global/constants";
import history from 'global/history';
import AuthContext from "AuthContext";

import './SnoozeIssuePopup.scss';

export default class SnoozeIssuePopup extends Component {
    constructor(props) {
        super();

        this.state = {
            note: '',
            showSnoozeModal: props.showSnoozeModal,
            trailerId: props.trailerId,
            issueType: props.issueType,
            reason: null,
            duration: null,
            account: props.account,
            issue: props.issue,
            submittable: false,
            characterCount: 0,
        };
    }

    componentDidUpdate(props) {
        if (props !== this.props) {
            this.setState({
                note: this.state.note || '',
                showModal: this.props.showSnoozeModal,
                trailerId: this.props.trailerId,
                issueType: this.props.issueType,
                reason: this.state.reason || null,
                duration: this.state.duration || null,
                submittable: this.state.submittable || false,
                characterCount: this.state.characterCount || 0,
            });
        }
    }

    handleClose = () => {
        history.replace();
        this.setState({
            showSnoozeModal: false
        }, () => {
            this.props.modalClosed();
        });
    }

    updateReason = (props) => {
        this.setState({
            reason: props.value
        }, function() {
            this.setState({
                note: '',
                submittable: this.state.duration && !this.state.reason.extended,
                characterCount: 0
            });
        });
    }

    updateDuration = (props) => {
        this.setState({
            duration: props.value
        }, function() {
            this.setState({
                submittable: this.state.reason && (this.state.reason.extended ? this.state.note : true)
            });
        });
    }

    handleSubmit = async () => {
        await this.context.post(snoozeIssue, {
            issueId: this.state.issue.id,
            duration: this.state.duration,
            snoozeReason: this.state.reason,
            note: this.state.note.trim() || null,
        });

        this.setState({
            showSnoozeModal: false
        }, () => {
            this.props.modalClosed();
        });
        window.location = window.location.pathname;
    }

    handleNote = (event) => {
        const value = event.target.value;
        this.setState({
            note: value,
            characterCount: value.length,
            submittable: value && /\S/.test(value) && this.state.duration
        });
    }

    render() {
        return(
            <Modal
                show={this.state.showSnoozeModal}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                centered
                dialogClassName="snoozeModal"
            >
                <Modal.Body>
                    <div className="header">
                        <p className="heading-label">Trailer ID: </p>
                        <p className="info">#{this.state.trailerId}</p>
                    </div>
                    <div className="header">
                        <p className="heading-label">Issue type: </p>
                        <p className="info">{this.state.issueType}</p>
                    </div>
                    <hr style={{marginTop: "2px"}}></hr>
                    <p className="action-label">Snooze notifications for: </p>
                    <Select
                        className="basic-single-duration"
                        classNamePrefix="select-duration"
                        options={snoozeDurations}
                        defaultValue={null}
                        isSearchable={false}
                        onChange={this.updateDuration}
                    >
                    </Select>
                    <br/>
                    <p className="action-label mt-3">Reason for snoozing: </p>
                    <AsyncSelect
                        className="basic-single-reason"
                        classNamePrefix="select-reason"
                        cacheOptions
                        loadOptions={snoozeReasons.bind(this)}
                        defaultOptions
                        defaultValue={null}
                        isSearchable={false}
                        onChange={this.updateReason}
                    />
                    {
                        this.state.reason && this.state.reason.extended &&
                        <>
                            <p className="action-label">Note: <span className="required-text">(required)</span> </p>
                            <textarea
                                value={this.state.note}
                                onChange={this.handleNote}
                                className="reason-box"
                                maxLength="240"
                                required
                                placeholder="Please be as specific and detailed as possible and write down the reason for snoozing issue for this trailer..."
                            />
                            <div className="character-count">
                                {this.state.characterCount}/240
                            </div>
                        </>
                    }
                    <div className="buttons">
                        <Button variant="light" onClick={this.handleClose} className="cancel-button">Cancel</Button>
                        <Button as="input" variant="continue" className="snooze-button" type="submit" value="Snooze" disabled={!this.state.submittable} onClick={this.handleSubmit} readOnly></Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
SnoozeIssuePopup.contextType = AuthContext;