import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SimpleContainer from 'components/container/SimpleContainer';

import './ConfirmMessageContainer.scss';

export default class ConfirmMessageContainer extends Component { 

    render() {
        return (
            <SimpleContainer className="modal-container" modal>
                <div className="successful-message-section">
                    <div className="centered">
                        <FontAwesomeIcon 
                            icon={ faCheckCircle } 
                            className="greenColorText icon"
                        />
                    </div>
                    <div className="centered">
                        <h1 className="titleContent">
                            { this.props.title }
                        </h1>                   
                    </div>
                    <div className="messageContent">
                        { this.props.description }
                    </div>
                    <Button
                        variant="continue"
                        className="submit-button messageButton"
                        onClick={ this.props.onClose }
                    >
                        OK
                    </Button>
                </div>  
            </SimpleContainer>
        );
    }
}
