import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle as tooltipIcon } from '@fortawesome/free-solid-svg-icons';
import { faThumbtack as pinIcon } from '@fortawesome/pro-solid-svg-icons';
import history from 'global/history';
import SimpleTooltip from 'components/tooltip/SimpleTooltip';

import './IssueCard.scss'

export default class IssueCard extends Component {

    routeChange(pathname) {
        history.push({
          pathname: pathname,
          state: this.state,
        });
    }
    
    pin(e, key){
        e.stopPropagation();
        this.props.pinCard(key)
    }

    render() {
        const {
            activeIssuesCount,
            snoozedIssuesCount,
            resolvedIssuesCount,
            tooltip,
            pathname,
            title,
            icon,
            id
        } = this.props;

        const isIssue = pathname.includes("issue");

        return (
                <div className="overview-box simple-container titled-card" onClick={() => this.routeChange(pathname)}>
                    <div className="content">
                        <div className="container-title">
                            <h5 className="title">
                                <span>{ title }</span>
                            </h5>
                            <div className="tooltip-container">
                                <FontAwesomeIcon icon={ tooltipIcon } className="tooltip-info-icon" />
                                <SimpleTooltip direction="bottom">
                                    { tooltip }
                                </SimpleTooltip>
                            </div>
                        </div>
                    </div>
                    <div className="status-data">
                        <p className={`current-active ${activeIssuesCount !== 0 ? "redColorText" : "greenColorText"}`}>
                            <span className={isIssue ? "icon-box" : "detention-icon"}>
                                { icon }
                            </span>                                  
                            { activeIssuesCount }
                        </p>
                        { isIssue &&
                            <>
                                <hr />
                                <div className="card-issues">
                                    <p className="card-data">
                                        {resolvedIssuesCount} <span>Resolved Today</span>
                                    </p>
                                    <div className="separator" />
                                    <p className="card-data">
                                        {snoozedIssuesCount} <span>Snoozed Issues</span>
                                    </p>
                                </div>
                            </>
                        }           
                        <div className="pin-icon" onClick={e => this.pin(e, id)}>
                            <FontAwesomeIcon icon={ pinIcon } className="pin" />
                        </div>
                    </div>
                </div>
                
        )
    }
}
