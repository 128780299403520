import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { managerCloseReasonsUrl, managerSnoozeReasonsUrl } from 'global/constants';
import AuthContext from "AuthContext";
import SimpleContainer from 'components/container/SimpleContainer';

import './DeleteReasonPopup.scss';

export default class DeleteReasonPopup extends Component {
    static contextType = AuthContext;

    handleSubmit = async () => {
        const url = this.props.snooze ? managerSnoozeReasonsUrl : managerCloseReasonsUrl;
        try {
            const response = await this.context.delete(url, this.props.reason);
            if (response.status === "error") {
                console.error(response.message);
                this.props.closeModal();
                return;
            }
            this.props.closeModal(response);
        } catch (error) {
            console.error(error);
            this.props.closeModal();
        }
    }

    onClose = () => this.props.closeModal()

    render() {
        return (
            <Modal
                show={this.props.showModal}
                onHide={this.onClose}
                backdrop="static"
                keyboard={false}
                centered
                dialogClassName="delete-reason-modal"
            >
                <SimpleContainer className="modal-container" modal title={(this.props.snooze ? "SNOOZE" : "CLOSE") + " ISSUE REASON"}>
                    <div className="warning-header">
                        Delete Reason?
                    </div>
                    <div className="warning-text">
                        Are you sure you want to delete <span className="bold">“{this.props.reason && this.props.reason.reason}”</span> as a {this.props.snooze ? "snooze" : "close"} reason?
                    </div>
                    <div className="buttons">
                        <Button variant="light" onClick={this.onClose} className="cancel-button">Cancel</Button>
                        <Button variant="danger" className="delete-button" onClick={this.handleSubmit}>Delete</Button>
                    </div>
                </SimpleContainer>
            </Modal>
        );
    }
}

DeleteReasonPopup.contextType = AuthContext;
