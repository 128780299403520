import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import './TermsConditionsPopup.scss';

export default class TermsConditionsPopup extends Component {
    constructor(props) {
        super();
        this.state = {
            showModal: true,
            allChecked: false,
            numChecked: 0,
            checkbox_1: {
                label: "The User accepts responsibility for these added permissions and understands the need to use extreme caution in making changes.",
                isChecked: false
            },
            checkbox_2: {
                label: "The User agrees to indemnity and holding PRS, and its affiliates, harmless from any and all losses, costs, or damages, including reasonable attorney's fees, arising out of and/or related to the User's use of PRS.",
                isChecked: false
            },
            checkbox_3: {
                label: "The User understands that PRS reserves the right to disable access upon User's request for any reason.",
                isChecked: false
            }
        }
    }

    handleClose = () => {
        this.setState({
            showModal: false
        });
        localStorage.setItem('didAgreeToConditions', true);
        if (this.props.updateParentState) {
            this.props.updateParentState();
        }
    }

    handleEnableButton = (numChecked) => {
        if (numChecked === 3) {
            this.setState({
                allChecked: true
            });
        } else {
            this.setState({
                allChecked: false
            });
        }
    }

    onCheckboxChange = (event) => {
        let numChecked = this.state.numChecked;
        let currentCheckbox, key;

        if (this.state.checkbox_1.label === event.target.name) {
            currentCheckbox = this.state.checkbox_1;
            key = 'checkbox_1';
        } else if (this.state.checkbox_2.label === event.target.name) {
            currentCheckbox = this.state.checkbox_2;
            key = 'checkbox_2';
        } else if (this.state.checkbox_3.label === event.target.name) {
            currentCheckbox = this.state.checkbox_3;
            key = 'checkbox_3';
        }

        currentCheckbox.isChecked = !currentCheckbox.isChecked;
        if (currentCheckbox.isChecked) {
            numChecked = numChecked + 1;
        } else if (numChecked > 0) {
            numChecked = numChecked - 1;
        }

        this.setState({
            [key]: currentCheckbox,
            numChecked: numChecked
        }, function () {
            this.handleEnableButton(numChecked);
        });
    }

    render() {
        return (
            <Modal
                show={this.state.showModal}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <Modal.Title className="title">
                        Terms of Service <FontAwesomeIcon className="redColorText" icon={faExclamationCircle}></FontAwesomeIcon>
                    </Modal.Title>
                    <p className="modalText">
                        Precision Reefer Solutions (PRS) is intended to help monitor and alert users of potential issues with the reefer unit per trailer.
                    </p>
                    <div className="modalText">
                        Within the system there are settings that users can control per role:
                        <ul>
                            <li><strong>Power Settings</strong> (On/Off)</li>
                            <li><strong>Mode Settings</strong> (Continuous/Cycle-Sentry)</li>
                            <li><strong>Temperature Settings</strong> (Reefer Temp Set Point)</li>
                        </ul>
                    </div>
                    <p className="modalText italic">
                        These settings are extremely sensitive and must be adjusted with the utmost attention to detail
                        and caution. Settings should only be adjusted if the User has verified that the settings are not
                        running per the correct specifications. The User <span className="redColorText">acknowledges </span>
                        that errors with these settings can be costly. The User is responsible for the adjustments they
                        make and they agree to indemnity and holding PRS harmless from any and all losses, costs, or damages
                        (including reasonable attorney's fees) arising out of their use of Precision Reefer Solutions.
                    </p>
                    <div className="form-check">
                        <label className={`checkBoxText ${this.state.checkbox_1.isChecked ? "bold" : ""}`}>
                            <input
                                type="checkbox"
                                name={this.state.checkbox_1.label}
                                checked={this.state.checkbox_1.isChecked}
                                onChange={this.onCheckboxChange}
                                className="form-check-input"
                            />
                            {this.state.checkbox_1.label}
                        </label>
                        <label className={`checkBoxText ${this.state.checkbox_2.isChecked ? "bold" : ""}`}>
                            <input
                                type="checkbox"
                                name={this.state.checkbox_2.label}
                                checked={this.state.checkbox_2.isChecked}
                                onChange={this.onCheckboxChange}
                                className="form-check-input"
                            />
                            {this.state.checkbox_2.label}
                        </label>
                        <label className={`checkBoxText ${this.state.checkbox_3.isChecked ? "bold" : ""}`}>
                            <input
                                type="checkbox"
                                name={this.state.checkbox_3.label}
                                checked={this.state.checkbox_3.isChecked}
                                onChange={this.onCheckboxChange}
                                className="form-check-input"
                            />
                            {this.state.checkbox_3.label}
                        </label>
                    </div>
                    <br/>
                    <p className="modalText center italic">
                        By clicking CONTINUE the User acknowledges that they have read, understand and agree to these Terms of Service.
                    </p>
                    <Button variant="continue" onClick={this.handleClose} disabled={!this.state.allChecked}>
                        Continue
                    </Button>
                </Modal.Body>
            </Modal>
        );
    }
}
