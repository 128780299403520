import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt } from '@fortawesome/pro-solid-svg-icons';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../AppInsights';
import { Button } from 'react-bootstrap';
import { DatePicker } from 'antd';
import Loader from 'react-loader-spinner';
import RequiresRole from "components/requires-role/RequiresRole"
import {
    formatDate,
    formatLongDate,
    formatLongDateWithoutTime,
    getCurrentDate
} from 'global/services/DateTimeService'
import { withRouter } from 'react-router';
import { mapIssuesFromResponse } from 'global/services/IssueApiService'
import { check } from 'global/services/RoleCheckService';
import AuthContext from 'AuthContext';
import HeaderCard from 'components/card/HeaderCard';
import SimpleContainer from 'components/container/SimpleContainer';
import TrailerSettings from 'components/trailer-settings/TrailerSettings';
import Message from 'components/Message';
import {
    assignmentUrl,
    detailsGridRecordsNumber,
    issuesDetailsUrl,
    measurementsExportUrl,
    measurementsUrl,
    existsMeasurementUrl,
    relevantIssuesUrl,
    RESOLVED_STATES,
    trailerUrl,
    UNRESOLVED_STATES,
    managerTrailerCommandsUrl
} from "global/constants"
import IssueTable from './IssueTable';
import MeasurementsTable from './MeasurementsTable';
import CommandsTable from './CommandsTable';
import AlarmIssueTable from "./AlarmIssueTable"
import history from "global/history";

import './DetailView.scss';

const noDataString = "NO DATA";
const CACHE = {};

class DetailView extends Component {
    static contextType = AuthContext;

    getInitialState = () => {
        const { assignmentId, trailerId } = this.props.match.params;

        return {
            loaded: false,
            measurementsLoaded: false,
            measurements: [],
            measurementsPagingInfo: {},
            statusClassName: "gathered-info orangeColorText",
            cacheIndex: 'trailer_' + trailerId + assignmentId ? '_assignment_' + assignmentId : '',
            moving: undefined,
            date: "NO DATA",
            longDate: undefined,
            location: "Unknown",
            actualMode: undefined,
            setPoint: undefined,
            returnAir: undefined,
            power: undefined,
            issues: [],
            resolvedIssues: [],
            unresolvedIssues: [],
            activeAlarmIssues: [],
            previousAlarmIssues: [],
            resolvedPagingInfo: {},
            unresolvedPagingInfo: {},
            activeAlarmPagingInfo: {},
            previousAlarmPagingInfo: {},
            resolvedIssuesNumber: 0,
            unresolvedIssuesNumber: 0,
            showManifestPopup: false,
            isManager: false,
            assignment: undefined,
            assignmentId: undefined,
            trailer: {},
            isActiveTrailer: undefined,
            relevantLeg: undefined,
            insideSalesRepName: undefined,
            mode: undefined,
            minTemp: undefined,
            maxTemp: undefined,
            expectedPower: undefined,
            hasMeasurements: false,
            type: ""
        };
    }

    constructor(props) {
        super(props);
        this.state = this.getInitialState();

        this.onCloseManifestPopup = this.onCloseManifestPopup.bind(this)
        this.onCloseRemoveTrailerPopup = this.onCloseRemoveTrailerPopup.bind(this)
        this.onCloseAddOrEditTrailerPopup = this.onCloseAddOrEditTrailerPopup.bind(this)
        this.fetchCommands = this.fetchCommands.bind(this)
    }

    async fetchData() {
        await this.fetchTrailer();
        await this.fetchAssignment();
        await this.fetchLatestMeasurement();
        await this.fetchMeasurements();
        await this.fetchTrailerHasMeasuremnt();

        await this.fetchIssues(null, true);
        await this.fetchIssues(null, false);
        await this.fetchAlarmIssues(null, true);
        await this.fetchAlarmIssues(null, false);

        await this.fetchCommands();
        await this.fetchAllRelevantIssues(); // Issues that are relevant for the Trailer Settings part
    }

    async componentDidMount() {
        if (this.props.match && (this.props.match.params.trailerId || this.props.match.params.assignmentId)) {

            try {
                await this.fetchData();
            } catch (e) {
                console.error(e);
            } finally {
                this.setState({
                    isManager: true,
                    loaded: true
                });
            }
        }
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            if (this.props.match && (this.props.match.params.trailerId || this.props.match.params.assignmentId)) {

                this.setState(this.getInitialState());

                try {
                    await this.fetchData();
                } catch (e) {
                    console.error(e);
                } finally {
                    this.setState({
                        isManager: true,
                        loaded: true
                    });
                }
            }
        }
    }

    async fetchTrailer() {
        if (CACHE[this.state.cacheIndex] !== undefined) {
            this.setState({
                trailer: CACHE[this.state.cacheIndex].trailer
            });
        }

        const response = await this.context.get(trailerUrl, { id: this.props.match.params.trailerId }, true);
        if (response.status === "error" || !response || response.length <= 0) {
            this.routeChange("/404");
            return;
        }

        CACHE[this.state.cacheIndex] = {
            ...CACHE[this.state.cacheIndex],
            trailer: response
        };

        this.setState({
            trailer: response
        });
    }

    async fetchLatestAssignment() {
        const response = await this.context.get(assignmentUrl, {
            latest: true,
            trailerBusinessId: this.state.trailer.businessId
        }, true);

        if (response.status === "error") {
            console.error(response.message);
            return {};
        }

        if (!this.props.match.params.assignmentId) {
            this.setState({
                assignment: response
            });
        }

        return response;
    }

    async fetchAssignment() {
        if (CACHE[this.state.cacheIndex] !== undefined) {
            this.setState({
                assignment: CACHE[this.state.cacheIndex].assignment,
                assignmentId: CACHE[this.state.cacheIndex].assignmentId,
                assignmentBusinessId: CACHE[this.state.cacheIndex].assignmentBusinessId,
                isActiveTrailer: CACHE[this.state.cacheIndex].isActiveTrailer,
                assignmentStatus: CACHE[this.state.cacheIndex].active ? "Active" : "Inactive",
                insideSalesRepName: CACHE[this.state.cacheIndex].insideSalesRep ? CACHE[this.state.cacheIndex].insideSalesRep.contactInfo.name : "Unknown",
                mode: CACHE[this.state.cacheIndex].unitMode === "CONTINUOUS" ? "Continuous" : "Cycle-Sentry",
                minTemp: CACHE[this.state.cacheIndex].temperatureMin,
                maxTemp: CACHE[this.state.cacheIndex].temperatureMax,
                expectedPower: CACHE[this.state.cacheIndex].power ? "On" : "Off"
            });
        }

        const assignmentId = this.props.match.params.assignmentId;

        let params = {
            trailerBusinessId: this.state.trailer.businessId
        };

        if (assignmentId) {
            params = {
                ...params,
                id: assignmentId
            };
        } else {
            params = {
                ...params,
                latest: true
            }
        }

        const response = await this.context.get(assignmentUrl, params, true);

        if (response.status === "error") {
            console.error(response.message);
            return {};
        }

        if (!response || response.length <= 0) {
            if (assignmentId) {
                this.routeChange("/404");
            }
            return {};
        }

        if (response.temperatureMin === null || response.temperatureMin === undefined) {
            response.temperatureMin = noDataString;
        } else if (!isFinite(response.temperatureMin)) {
            response.temperatureMin = "NO DATA";
        }

        if (response.temperatureMax === null || response.temperatureMax === undefined) {
            response.temperatureMax = noDataString;
        } else if (!isFinite(response.temperatureMax)) {
            response.temperatureMax = "NO DATA";
        }

        const activeLeg = response.legs && response.relevantLeg ? response.relevantLeg : null;
        const isActiveTrailer = activeLeg ? activeLeg.trailer.businessId === this.state.trailer.businessId : false;

        CACHE[this.state.cacheIndex] = {
            ...CACHE[this.state.cacheIndex],
            assignment: response,
        };

        this.setState({
            assignment: response,
        });

        if (assignmentId) {
            this.setState({
                assignmentId: response.id,
                assignmentBusinessId: response.businessId,
                isActiveTrailer: isActiveTrailer,
                relevantLeg: activeLeg,
                assignmentStatus: response.active ? "Active" : "Inactive",
                insideSalesRepName: response.insideSalesRep ? response.insideSalesRep.contactInfo.name : "Unknown",
                mode: response.unitMode === "CONTINUOUS" ? "Continuous" : "Cycle-Sentry",
                minTemp: response.temperatureMin,
                maxTemp: response.temperatureMax,
                expectedPower: response.power ? "On" : "Off"
            });

            CACHE[this.state.cacheIndex] = {
                ...CACHE[this.state.cacheIndex],
                assignmentId: response.id,
                assignmentBusinessId: response.businessId,
                isActiveTrailer: isActiveTrailer,
                active: response.active,
                activeLeg: activeLeg,
                insideSalesRep: response.insideSalesRep,
                unitMode: response.unitMode,
                temperatureMin: response.temperatureMin,
                maxTemp: response.temperatureMax,
                temperatureControlled: response.temperatureControlled
            };
        }
    }

    mapLatestMeasurement(measurement) {
        return {
            moving: measurement.positionState.moving ? "Moving" : "Stopped",
            statusClassName: "gathered-info " + (measurement.positionState.moving ? "greenColorText" : measurement.positionState.moving !== undefined ? "redColorText" : "orangeColorText"),
            detentionState: (measurement || {}).detentionState || {},
            inactivityState: measurement,
            longDate: measurement.time ? formatLongDate(new Date(measurement.time)) : "Unknown",
            date: measurement.time ? formatLongDateWithoutTime(new Date(measurement.time)) : "Unknown",
            location: measurement.positionState.position ? measurement.positionState.position : "Unknown",
            fuelLevel: measurement.engineState && measurement.engineState.fuelPercentage !== undefined ? measurement.engineState.fuelPercentage : "Unknown",
            actualMode: !measurement.modeState.unitMode ? null : measurement.modeState.unitMode === "CONTINUOUS" ? "Continuous" : "Cycle-Sentry",
            setPoint: measurement.temperatureState.setTemperature !== null ? measurement.temperatureState.setTemperature : 'NO DATA',
            returnAir: measurement.temperatureState.actualTemperature !== null ? measurement.temperatureState.actualTemperature : 'NO DATA',
            power: measurement.powerState.power !== null && measurement.powerState.power !== undefined ? (measurement.powerState.power ? "On" : "Off") : noDataString,
            expectedPower: !this.state.isActiveTrailer ? (measurement.powerState.expectedPower === true ? "On" : measurement.powerState.expectedPower === false ? "Off" : null) : this.state.expectedPower,
            minTemp: !this.state.isActiveTrailer ? (measurement.temperatureState.temperatureMin !== null ? measurement.temperatureState.temperatureMin : 'NO DATA') : this.state.minTemp,
            maxTemp: !this.state.isActiveTrailer ? (measurement.temperatureState.temperatureMax !== null ? measurement.temperatureState.temperatureMax : 'NO DATA') : this.state.maxTemp,
            mode: !this.state.isActiveTrailer ? (measurement.modeState.expectedUnitMode === "CONTINUOUS" ? "Continuous" : "Cycle-Sentry") : this.state.mode,
        };
    }

    async fetchLatestMeasurement() {
        let params = {
            sort: 'TIME,DESC',
            trailerId: this.props.match.params.trailerId,
            pageSize: 1,
            pageNumber: 1
        };

        if (this.props.match.params.assignmentId) {
            params = {
                ...params,
                assignmentId: this.props.match.params.assignmentId
            };
        }

        let response = await this.context.get(measurementsUrl, params, true);

        if (response.status === "error") {
            console.error(response.message);
            return;
        }

        if (!response || response.data.length <= 0) {
            return;
        }

        const mappedMeasurements = this.mapLatestMeasurement(response.data[0]);

        this.setState({
            ...mappedMeasurements
        });
    }

    async fetchMeasurements(sort, pageNumber = 1, pageSize = detailsGridRecordsNumber) {
        if (!pageNumber || pageNumber < 1 || pageNumber > this.state.measurementsPagingInfo.totalPageNumber) {
            return;
        }

        this.setState({
            measurementsLoaded: false
        });

        if (CACHE[this.state.cacheIndex] !== undefined) {
            this.setState({
                ...CACHE[this.state.cacheIndex].mappedMeasurements,
                measurements: CACHE[this.state.cacheIndex].measurements
            });
        }

        let params = {
            sort: sort || [],
            trailerId: this.props.match.params.trailerId,
            pageNumber: pageNumber,
            pageSize: pageSize
        };

        if (this.props.match.params.assignmentId) {
            params = {
                ...params,
                ...{ assignmentId: this.props.match.params.assignmentId }
            };
        }

        if (this.state.selectedStartDate) {
            params = {
                ...params,
                startDate: new Date(this.state.selectedStartDate)
            };
        }

        if (this.state.selectedEndDate) {
            params = {
                ...params,
                endDate: new Date(this.state.selectedEndDate)
            };
        }

        let response = await this.context.get(measurementsUrl, params, true);

        if (response.status === "error") {
            console.error(response.message);
            return {};
        }

        CACHE[this.state.cacheIndex] = {
            ...CACHE[this.state.cacheIndex],
            measurements: response.data
        };

        this.setState({
            measurements: response.data,
            measurementsSort: sort,
            measurementsPagingInfo: {
                itemNumber: Math.min(response.pageSize, response.data.length),
                totalItemNumber: response.available,
                totalPageNumber: Math.ceil(response.available / response.pageSize),
                currentPage: Math.min(response.pageNumber, Math.ceil(response.available / response.pageSize)),
                recordsNumber: response.pageSize
            },
            measurementsLoaded: true
        });
    }

    async fetchTrailerHasMeasuremnt() {
        const params = {
            trailerId: this.props.match.params.trailerId,
            pageSize: 1,
        };

        const response = await this.context.get(existsMeasurementUrl, params);

        if (response.status === "error") {
            console.error(response.message);
            return;
        }

        this.setState({
            hasMeasurements: response
        });
    }

    async fetchAllRelevantIssues() {
        let response = await this.context.get(relevantIssuesUrl, { trailerId: this.props.match.params.trailerId }, true);

        if (response.status === "error" || !response) {
            return {};
        }

        this.setState({
            issues: mapIssuesFromResponse(response)
        });
    }

    async fetchIssues(sort, resolved, pageNumber = 1, pageSize = detailsGridRecordsNumber) {
        let totalNumber, recordsNumber, state;

        if (resolved) {
            totalNumber = this.state.resolvedPagingInfo.totalItemNumber;
            recordsNumber = this.state.resolvedPagingInfo.recordsNumber;
            state = RESOLVED_STATES;
        } else {
            totalNumber = this.state.unresolvedPagingInfo.totalItemNumber;
            recordsNumber = this.state.unresolvedPagingInfo.recordsNumber;
            state = UNRESOLVED_STATES;
        }

        if (pageNumber > Math.ceil(totalNumber / recordsNumber)) {
            return;
        }

        if (CACHE[this.state.cacheIndex] !== undefined) {
            this.setState(CACHE[this.state.cacheIndex].mappedIssues);
        }

        let issuesDetailsParams = {
            states: state,
            forMaintenance: false,
            sort: sort || [],
            trailerId: this.props.match.params.trailerId,
            pageNumber: pageNumber,
            pageSize: pageSize
        };

        if (this.props.match.params.assignmentId) {
            issuesDetailsParams = {
                ...issuesDetailsParams,
                assignmentId: this.state.assignmentId
            };
        }

        let response = await this.context.get(issuesDetailsUrl, issuesDetailsParams, true);

        if (response.status === "error" || !response || !response.data) {
            return {};
        }

        const issues = mapIssuesFromResponse(response.data);
        const detained = issues.filter(function(element) { return element && element.type && element.type.startsWith("Detained") && RESOLVED_STATES.indexOf(element.state) === -1 }).length;
        const inactive = issues.filter(function(element) { return element && element.type && element.type.startsWith("Inactive") && RESOLVED_STATES.indexOf(element.state) === -1 }).length;

        const pagingInfo = {
            itemNumber: Math.min(response.pageSize, response.data.length),
            totalItemNumber: response.available,
            totalPageNumber: Math.ceil(response.available / response.pageSize),
            currentPage: Math.min(response.pageNumber, Math.ceil(response.available / response.pageSize)),
            recordsNumber: response.pageSize
        };

        if (resolved) {
            CACHE[this.state.cacheIndex] = {
                ...CACHE[this.state.cacheIndex],
                mappedIssues: {
                    resolvedIssues: issues
                }
            };

            this.setState({
                resolvedIssuesNumber: response.available,
                resolvedIssues: issues,
                resolvedPagingInfo: pagingInfo,
                detained: detained,
                inactive: inactive
            });
        } else if (resolved === false) {
            CACHE[this.state.cacheIndex] = {
                ...CACHE[this.state.cacheIndex],
                mappedIssues: {
                    unresolvedIssues: issues
                }
            };

            this.setState({
                unresolvedIssuesNumber: response.available,
                unresolvedIssues: issues,
                unresolvedPagingInfo: pagingInfo,
                detained: detained,
                inactive: inactive
            });
        }
    }

    mapAlarmIssues(issues, response) {
        return {
            alarmIssues: issues,
            issuesNumber: issues.length,
            pagingInfo: {
                itemNumber: Math.min(response.pageSize, response.data.length),
                totalItemNumber: response.available,
                totalPageNumber: Math.ceil(response.available / response.pageSize),
                currentPage: Math.min(response.pageNumber, Math.ceil(response.available / response.pageSize)),
                recordsNumber: response.pageSize
            }
        };
    }

    async fetchAlarmIssues(sort, active, pageNumber = 1, pageSize = detailsGridRecordsNumber) {
        let totalPageNumber;
        if (active) {
            totalPageNumber = this.state.activeAlarmPagingInfo.totalPageNumber;
        } else {
            totalPageNumber = this.state.previousAlarmPagingInfo.totalPageNumber;
        }

        if (!pageNumber || pageNumber < 1 || pageNumber > totalPageNumber) {
            return;
        }

        if (CACHE[this.state.cacheIndex] !== undefined) {
            this.setState(CACHE[this.state.cacheIndex].mappedAlarmIssues);
        }

        const response = await this.context.get(issuesDetailsUrl, {
            types: ["ALARM", "LOW_FUEL_ALARM"],
            trailerId: this.props.match.params.trailerId,
            assignmentId: this.props.match.params.assignmentId,
            forMaintenance: true,
            sort: sort || [],
            active: !!active,
            pageNumber,
            pageSize
        });

        if (response.status === "error") {
            console.error(response.message);
            return {};
        }

        const data = response.data;
        if (!data) {
            return;
        }

        const issues = mapIssuesFromResponse(data);
        const mappedAlarmData = this.mapAlarmIssues(issues, response);

        CACHE[this.state.cacheIndex] = {
            ...CACHE[this.state.cacheIndex],
            mappedAlarmIssuesIssues: mappedAlarmData
        };

        if (active) {
            this.setState(prevState => ({
                activeAlarmIssues: mappedAlarmData.alarmIssues,
                unresolvedIssuesNumber: (prevState.unresolvedIssuesNumber || 0) + mappedAlarmData.issuesNumber,
                activeAlarmPagingInfo: mappedAlarmData.pagingInfo
            }));
        } else {
            this.setState(prevState => ({
                previousAlarmIssues: mappedAlarmData.alarmIssues,
                resolvedIssuesNumber: (prevState.resolvedIssuesNumber || 0) + mappedAlarmData.issuesNumber,
                previousAlarmPagingInfo: mappedAlarmData.pagingInfo
            }));
        }
    }

    async fetchCommands() {
        if (this.context.role.some(role => check(role, 'trailer-commands:read'))) {
            const response = await this.context.get(managerTrailerCommandsUrl, {
                trailerId: this.props.match.params.trailerId,
                pageSize: 10
            });

            this.setState({
                commands: ((response || {}).data || []).map(command => ({
                    ...command,
                    date: formatDate(new Date(command.date))
                }))
            });
        }
    }

    routeChange = (pathname, state) => {
        history.push({
            pathname: pathname,
            state: state,
        });
    }

    async onCloseManifestPopup(submitted) {
        if (submitted) {
            const submittedManifest = await this.fetchLatestAssignment();

            if (submittedManifest.id === this.props.match.params.assignmentId) {
                await this.fetchData();
            } else if (submittedManifest.active) {
                this.routeChange("/details/" + this.props.match.params.trailerId + "/" + submittedManifest.id);
            }
        }
    }

    onStartDateChange(date) {
        this.setState({
            selectedStartDate: date ? date : null,
            measurementsPagingInfo: {}
        }, async () => {
            await this.fetchMeasurements();
        });
    }

    onEndDateChange(date) {
        this.setState({
            selectedEndDate: date ? date : null,
            measurementsPagingInfo: {}
        }, async () => {
            await this.fetchMeasurements();
        });
    }

    async exportCsv() {
        this.setState({
            measurementsLoaded: false
        });

        await this.context.download(measurementsExportUrl, {
            trailerId: this.props.match.params.trailerId,
            assignmentId: this.props.match.params.assignmentId,
            startDate: this.state.selectedStartDate ? new Date(this.state.selectedStartDate) : null,
            endDate: this.state.selectedEndDate ? new Date(this.state.selectedEndDate) : null,
            sort: this.state.measurementsSort
        });

        this.setState({
            measurementsLoaded: true
        });
    }

    onCloseRemoveTrailerPopup() {
        this.setState(prevState => ({
            trailer: { ...prevState.trailer, active: false },
            assignment: prevState.assignment ? { ...prevState.assignment, active: false } : prevState.assignment,
            isActiveTrailer: false
        }));
    }

    onCloseAddOrEditTrailerPopup() {
        this.setState(prevState => ({
            trailer: { ...prevState.trailer, active: true }
        }));

        this.fetchTrailer();
    }

    render() {
        const { assignmentId, trailerId } = this.props.match.params;

        const currentTrailerInfo = {
            ...this.state.trailer,
            activeTrailerId: this.state.trailer ? this.state.trailer.businessId : null,
            mode: this.state.mode,
            actualMode: this.state.actualMode,
            minTemp: this.state.minTemp,
            maxTemp: this.state.maxTemp,
            setPoint: this.state.setPoint,
            returnAir: this.state.returnAir,
            expectedPower: this.state.expectedPower,
            power: this.state.power,
            issuesList: this.state.issues || [],
            insideSalesRepName: this.state.insideSalesRepName,
            hasAssignment: !!this.state.assignmentId,
            lastMeasurementTime: this.state.longDate,
            location: this.state.location,
            moving: this.state.moving,
            relevantLeg: this.state.relevantLeg,
            statusClassName: this.state.statusClassName,
            hasRelevantIssues: this.state.hasRelevantIssues
        };

        const issuesInfo = {
            resolvedIssuesNumber: this.state.resolvedIssuesNumber,
            unresolvedIssuesNumber: this.state.unresolvedIssuesNumber
        }

        let trailerSettingsCard = <></>;
        if (assignmentId && this.state.isActiveTrailer) {
            trailerSettingsCard = (
                <div className="col-12 col-lg-12">
                    <SimpleContainer title="TRAILER SETTINGS" className="trailer-settings">
                        <TrailerSettings currentInfo={ currentTrailerInfo } activeTrailer={ this.state.isActiveTrailer }></TrailerSettings>
                    </SimpleContainer>
                </div>
            );
        }

        let trailerIssuesCard = <></>;
        if ((this.state.resolvedIssues && this.state.resolvedIssues.length > 0) || (this.state.unresolvedIssues && this.state.unresolvedIssues.length > 0)) {
            trailerIssuesCard = (
                <div className="col-12 col-lg-12">
                    <SimpleContainer className="issues-container" title="TRAILER ISSUES">
                        <IssueTable
                            resolvedPagingInfo={ this.state.resolvedPagingInfo }
                            unresolvedPagingInfo={ this.state.unresolvedPagingInfo }
                            resolvedIssues={ this.state.resolvedIssues }
                            unresolvedIssues={ this.state.unresolvedIssues }
                            account={ this.props.account }
                            fetchIssues={ this.fetchIssues.bind(this) }
                        />
                    </SimpleContainer>
                </div>
            );
        }

        let warningMessage;
        if (!this.state.trailer.active) {
            warningMessage = 'This trailer has been removed from your fleet.';
        } else if (!this.state.hasMeasurements) {
            warningMessage = 'This trailer has not received any TK measurements yet. Check if the Trailer ID is valid.';
        }

        return (
            <div className="container-fluid page detailview">
                <div className="row">
                    <nav className="col-12">
                        <ol className="breadcrumb">
                            { (this.state.isManager || !this.state.activeAlarmIssues) && <li className="breadcrumb-item"><a href="/issues/all">All issues</a></li> }
                            { (!this.state.isManager && this.state.activeAlarmIssues) && <li className="breadcrumb-item"><a href="/issues/alarm">Alarm issues</a></li> }
                            { !assignmentId && <li className="breadcrumb-item"><a href={`/details/${trailerId}/${this.state.assignment ? this.state.assignment.id : ''}`}>Trailer #{currentTrailerInfo.businessId}</a></li> }
                            <li className="breadcrumb-item active">
                                <a href="#!">
                                    { assignmentId ? `Trailer #${currentTrailerInfo.businessId}` : 'Trailer History' }
                                </a>
                            </li>
                            <NavLink to="/dashboard" className="back-link">
                                <p>
                                    &larr; Back to dashboard
                                </p>
                            </NavLink>
                        </ol>
                    </nav>
                    <div className="col-12 col-lg-12">
                        <HeaderCard
                            loaded={ this.state.loaded }
                            assignment={ this.state.assignment }
                            trailer={ currentTrailerInfo }
                            issues={ issuesInfo }
                            onCloseManifestPopup={ this.onCloseManifestPopup }
                            onCloseRemoveTrailerPopup={ this.onCloseRemoveTrailerPopup }
                            onCloseAddOrEditTrailerPopup={ this.onCloseAddOrEditTrailerPopup }
                            onCloseRemoteReeferControls={ this.fetchCommands }
                            cardType="trailer"
                        />
                    </div>
                    { this.state.loaded && warningMessage && (
                        <div className="message">
                            <Message type="warning">
                                { warningMessage }
                            </Message>
                        </div>
                    ) }
                    <RequiresRole
                        roles={ this.context.role }
                        perform="details-page:dm"
                        yes={() => (
                            <>
                                { trailerSettingsCard }
                                { trailerIssuesCard }
                                <div className="col-12 col-lg-12">
                                    <SimpleContainer className="measurements-container" title="READINGS FOR THIS TRAILER">
                                        { this.state.measurements && (this.state.measurements.length > 0 || this.state.selectedStartDate || this.state.selectedEndDate) &&
                                            <div className="wrapper">
                                                <div className="filter-measurements">
                                                    <div className="measurements-loader">
                                                        { !this.state.measurementsLoaded &&
                                                            <Loader
                                                                type="TailSpin"
                                                                color="#289AC2"
                                                                height={ 24 }
                                                                width={ 24 }
                                                            />
                                                        }
                                                    </div>
                                                    <div className="start-date">
                                                        <label htmlFor="start-date">Start date</label>
                                                        <DatePicker
                                                            allowClear
                                                            showNow={ false}
                                                            showTime={{ format: 'HH:mm' }}
                                                            format="YYYY-MM-DD HH:mm"
                                                            onChange={ this.onStartDateChange.bind(this) }
                                                            onSelect={ this.onStartDateChange.bind(this) }
                                                            value={ this.state.selectedStartDate ? getCurrentDate(this.state.selectedStartDate) : null }
                                                        />
                                                    </div>
                                                    <div className="end-date">
                                                        <label htmlFor="end-date">End date</label>
                                                        <DatePicker
                                                            allowClear
                                                            showNow={ false }
                                                            showTime={{ format: 'HH:mm' }}
                                                            format="YYYY-MM-DD HH:mm"
                                                            onChange={ this.onEndDateChange.bind(this) }
                                                            onSelect={ this.onEndDateChange.bind(this) }
                                                            value={ this.state.selectedEndDate ? getCurrentDate(this.state.selectedEndDate) : null }
                                                        />
                                                    </div>
                                                    <div className="export-btn-container">
                                                        <Button
                                                            className="dark-blue-button"
                                                            onClick={ this.exportCsv.bind(this) }
                                                            disabled={ !this.state.measurementsLoaded }
                                                        >
                                                            <FontAwesomeIcon icon={ faFileAlt } />
                                                            EXPORT READINGS TO CSV
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {this.state.measurements && this.state.measurements.length > 0 ?
                                            <MeasurementsTable
                                                measurements={ this.state.measurements }
                                                fetchMeasurements={ this.fetchMeasurements.bind(this) }
                                                pagingInfo={ this.state.measurementsPagingInfo}
                                                shouldDisplayManifest={ !assignmentId }
                                            />
                                            : <p className="info-label trailer-settings no-readings-label">No readings</p>}
                                    </SimpleContainer>
                                </div>
                            </>)}
                        no={() => <></>}
                    />

                    <RequiresRole
                        roles={ this.context.role }
                        perform="details-page:maintenance"
                        yes={ () =>
                            <>
                                { this.state.activeAlarmIssues && this.state.activeAlarmIssues.length > 0 && <div className="col-12 col-lg-12">
                                    <SimpleContainer className="issues-container" title="ACTIVE ALARM ISSUES">
                                        <AlarmIssueTable
                                            tableName="activeAlarmIssues"
                                            alarmIssues={ this.state.activeAlarmIssues }
                                            account={ this.props.account} 
                                            pagingInfo={ this.state.activeAlarmPagingInfo }
                                            fetchIssues={ this.fetchAlarmIssues.bind(this) }
                                            active
                                        />
                                    </SimpleContainer>
                                </div> }
                                { this.state.previousAlarmIssues && this.state.previousAlarmIssues.length > 0 && <div className="col-12 col-lg-12">
                                    <SimpleContainer className="issues-container" title="PREVIOUS ALARM ISSUES">
                                        <AlarmIssueTable
                                            tableName="previousAlarmIssues"
                                            alarmIssues={ this.state.previousAlarmIssues }
                                            account={ this.props.account }
                                            pagingInfo={ this.state.previousAlarmPagingInfo }
                                            fetchIssues={ this.fetchAlarmIssues.bind(this) }
                                        />
                                    </SimpleContainer>
                                </div> }
                            </>}
                    />

                    <RequiresRole
                        roles={ this.context.role }
                        perform="trailer-commands:read"
                        yes={ () =>
                            <div className="col-12 col-lg-12">
                                <SimpleContainer title="Last 10 trailer commands">
                                    { (this.state.commands || []).length > 0 ?
                                        <CommandsTable data={ this.state.commands } /> :
                                        <p className="info-label trailer-settings no-commands-label">No commands submitted for this trailer yet.</p>
                                    }
                                </SimpleContainer>
                            </div>
                        }
                    />
                </div>
            </div>
        );
    }
}

export default withRouter(withAITracking(reactPlugin, DetailView, "TrailerDetailView"));
