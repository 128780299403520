import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import AuthContext from 'AuthContext';
import DataTableComp from 'components/datatable/DataTableComp';
import { defaultGridRecordsNumber, mapSort, MODE_MAPPING } from 'global/constants';
import { formatDate } from 'global/services/DateTimeService';
import StringUtils from 'global/utils/StringUtils';

import './DetailView.scss';

const noDataString = "NO DATA";

export default class MeasurementsTable extends Component {
    constructor(props) {
        super(props);

        let data = Array.from([]);

        const [columns, columnNames, columnDefs] = this.formTableData();

        this.columnNames = columnNames;

        this.state = {
            sort: [],
            columns: columns,
            columnDefs: columnDefs,
            sortingRule: [],
            data: data,
            page: 1,
            pagingInfo: {
              recordsNumber: defaultGridRecordsNumber,
            }
        };
    }

    formTableData() {
      let columns, columnNames, columnDefs;

      if (this.props.shouldDisplayManifest) {
        columns = [
          {title: 'Manifest ID', data: "manifestId"}, // 0
          {title: "Mode conf.", data: "expectedMode"}, // 1
          {title: "Mode reading", data: "mode"}, // 2
          {title: "Min temp.", data: "minTemp"}, // 3
          {title: "Set point", data: "setpoint"}, // 4
          {title: "Return air", data: "returnAir"}, // 5
          {title: "Op mode", data: "opMode"}, // 6
          {title: "Max temp.", data: "maxTemp"}, // 7
          {title: "Expected power", data: "expectedPower"}, // 8
          {title: "Power", data: "power"}, // 9
          {title: "Alarms", data: "alarms"}, // 10
          {title: "Created", data: "created"} // 11
        ];

        columnNames = {
          11 : "TIME"
        };

        columnDefs = [
          { className: "dt-align-left", targets: [1, 2, 6, 8, 9, 10] },
          { className: "dt-align-right", targets: [0, 3, 4, 5, 7, 11] },
          {
            orderable: false,
            targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
          },
          {
            targets: 0,
            createdCell: (td, cellData, rowData, row, col) => {
              const contentDecorator = rowData.manifestId !== 'No manifest' ? 'blueColorText assignment link' : '';

              ReactDOM.render(
                <div className={contentDecorator}>
                  {rowData.manifestId}
                </div>,
                td
              );
            }
          },
          {
              targets: 3,
              createdCell: (td, cellData, rowData) => {
                  ReactDOM.render(
                      <div>
                          { rowData.minTemp } { rowData.minTemp !== "NO DATA" && <span>&deg;F</span> }
                      </div>,
                      td
                  );
              }
          },
          {
            targets: 4,
            createdCell: (td, cellData, rowData, row, col) => {
              ReactDOM.render(
                <div>
                  {rowData.setpoint} {rowData.setpoint !== "NO DATA" && <span>&deg;F</span>}
                </div>,
                td
              );
            }
          },
          {
            targets: 5,
            createdCell: (td, cellData, rowData, row, col) => {
              ReactDOM.render(
                <div>
                  {rowData.returnAir} {rowData.returnAir !== "NO DATA" && <span>&deg;F</span>}
                </div>,
                td
              );
            }
          },
          {
              targets: 7,
              createdCell: (td, cellData, rowData) => {
                  ReactDOM.render(
                      <div>
                          { rowData.maxTemp } { rowData.maxTemp !== "NO DATA" && <span>&deg;F</span> }
                      </div>,
                      td
                  );
              }
          }
        ];
      } else {
        columns = [
          {title: "Mode conf.", data: "expectedMode"}, // 0
          {title: "Mode reading", data: "mode"}, // 1
          {title: "Min temp.", data: "minTemp"}, // 2
          {title: "Set point", data: "setpoint"}, // 3
          {title: "Return air", data: "returnAir"}, // 4
          {title: "Op mode", data: "opMode"}, // 5
          {title: "Max temp.", data: "maxTemp"}, // 6
          {title: "Expected power", data: "expectedPower"}, // 7
          {title: "Power", data: "power"}, // 8
          {title: "Alarms", data: "alarms"}, // 9
          {title: "Created", data: "created"} // 10
        ];

        columnNames = {
          10 : "TIME"
        };

        columnDefs = [
          { className: "dt-align-left", targets: [0, 1, 5, 7, 8, 9] },
          { className: "dt-align-right", targets: [2, 3, 4, 6, 10] },
          {
            orderable: false,
            targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
          },
          {
              targets: 2,
              createdCell: (td, cellData, rowData) => {
                  ReactDOM.render(
                      <div>
                          { rowData.minTemp } { rowData.minTemp !== "NO DATA" && <span>&deg;F</span> }
                      </div>,
                      td
                  );
              }
          },
          {
            targets: 3,
            createdCell: (td, cellData, rowData, row, col) => {
              ReactDOM.render(
                <div>
                  {rowData.setpoint} {rowData.setpoint !== "NO DATA" && <span>&deg;F</span>}
                </div>,
                td
              );
            }
          },
          {
            targets: 4,
            createdCell: (td, cellData, rowData, row, col) => {
              ReactDOM.render(
                <div>
                  {rowData.returnAir} {rowData.returnAir !== "NO DATA" && <span>&deg;F</span>}
                </div>,
                td
              );
            }
          },
          {
              targets: 6,
              createdCell: (td, cellData, rowData) => {
                  ReactDOM.render(
                      <div>
                          { rowData.maxTemp } { rowData.maxTemp !== "NO DATA" && <span>&deg;F</span> }
                      </div>,
                      td
                  );
              }
          }
        ];
      }

      return [columns, columnNames, columnDefs];
    }

    componentDidMount() {
        this.setState({
            pagingInfo: this.props.pagingInfo
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.pagingInfo !== prevProps.pagingInfo) {
            this.setState({
                pagingInfo: this.props.pagingInfo
            })
        }
    }

    addSortingForColumn(index, sorting, tableId) {
        const sortingArray = this.state.sort;
        const that = this;
        const existing = sortingArray.findIndex(x => x && x.columnName === that.columnNames[index]);
        existing === -1 ? sortingArray.push({
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
          }) : sorting === "" ? sortingArray.splice(existing, 1) : sortingArray[existing] = {
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }
        this.setState({
        sort: sortingArray
        }, async () => {
            await this.props.fetchMeasurements(mapSort(this.state.sort));
        })
    }

    handleFirstPage = async () => {
        this.setState({
            page: 1,
            dataUpdated: false
          },
          async () => {
            await this.props.fetchMeasurements(mapSort(this.state.sort), this.state.page, this.state.pagingInfo.recordsNumber);
            this.setState({
              pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: this.state.page,
              },
            });
          }
        );
      };

      handleLastPage = async () => {
        this.setState({
            page: parseInt(this.state.pagingInfo.totalPageNumber),
            dataUpdated: false
          },
          async () => {
            await this.props.fetchMeasurements(mapSort(this.state.sort), this.state.page, this.state.pagingInfo.recordsNumber);
            this.setState({
              pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: this.state.page,
              },
            });
          }
        );
      };

      handleNextPage = async () => {
        this.setState({
            page: parseInt(this.state.page) + 1,
            dataUpdated: false
          },
          async () => {
            await this.props.fetchMeasurements(mapSort(this.state.sort), this.state.page, this.state.pagingInfo.recordsNumber);
            this.setState({
              pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: this.state.page,
              },
            });
          }
        );
      };

      handlePreviousPage = async () => {
        this.setState({
            page: parseInt(this.state.page) - 1,
            dataUpdated: false
          },
          async () => {
            await this.props.fetchMeasurements(mapSort(this.state.sort), this.state.page, this.state.pagingInfo.recordsNumber);
            this.setState({
              pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: this.state.page,
              },
            });
          }
        );
      };

      handleRecordsNumber = async (event) => {
        let recordsNumber = parseInt(event.target.value);
        this.setState({
            pagingInfo: {
              ...this.state.pagingInfo,
              currentPage: 1,
              recordsNumber: recordsNumber,
            },
            page: 1,
            dataUpdated: false
          },
          async () => {
            await this.props.fetchMeasurements(mapSort(this.state.sort), this.state.page, this.state.pagingInfo.recordsNumber);
          }
        );
      };

      handlePageChange = async (event) => {
        if (isNaN(event.target.value) || event.target.value === "") {
          this.state.page = "";
        } else {
          this.state.page = parseInt(event.target.value);
          this.state.dataUpdated = false;
          const that = this;
          setTimeout(async function () {
            await that.props.fetchMeasurements(mapSort(that.state.sort), that.state.page, that.state.pagingInfo.recordsNumber);
          }, 1000);
        }
        this.setState({
          pagingInfo: {
            ...this.state.pagingInfo,
            currentPage: this.state.page,
          },
        });
      };

    render() {
        if(this.props.measurements){
            const data = this.props.measurements.map(measurement => ({
                manifestId: measurement.assignmentState && measurement.assignmentState.assignment ? measurement.assignmentState.assignment.businessId : 'No manifest',
                expectedMode: MODE_MAPPING[measurement.modeState.expectedUnitMode] || "NO DATA",
                mode: MODE_MAPPING[measurement.modeState.unitMode] || "NO DATA",
                minTemp: measurement.temperatureState.temperatureMin && measurement.temperatureState.temperatureMin !== "-Infinity" ? measurement.temperatureState.temperatureMin : "NO DATA",
                maxTemp: measurement.temperatureState.temperatureMax && measurement.temperatureState.temperatureMax !== "Infinity" ? measurement.temperatureState.temperatureMax : "NO DATA",
                setpoint: measurement.temperatureState.setTemperature === null || measurement.temperatureState.setTemperature === undefined ? "NO DATA": measurement.temperatureState.setTemperature,
                returnAir: measurement.temperatureState.actualTemperature === null || measurement.temperatureState.actualTemperature === undefined ? "NO DATA": measurement.temperatureState.actualTemperature,
                opMode: measurement.temperatureState.opMode ? StringUtils.snakeCaseToCapitalized(measurement.temperatureState.opMode) : "NO DATA",
                power: measurement.powerState.power === true ? "On" : measurement.powerState.power === false ? "Off" : noDataString,
                expectedPower: measurement.powerState.expectedPower === true ? "On" : measurement.powerState.expectedPower === false ? "Off" : noDataString,
                created: formatDate(new Date(measurement.time)),
                alarms: measurement.alarmState.alarms.length > 0 ? measurement.alarmState.alarms : "-",
                onClickState: {
                  assignmentId: measurement.assignmentState && measurement.assignmentState.assignment ? measurement.assignmentState.assignment.id : null
                }
            }));
            return (
                <div className="detail-tables">
                    <DataTableComp
                        tableId="detailMeasurements"
                        columns={this.state.columns}
                        columnDefs={this.state.columnDefs}
                        isDetailView={false}
                        orderRule={this.state.sortingRule}
                        tableHeight="300px"
                        data={data}
                        sortRule={this.state.sort.concat([])}
                        addSortingForColumn={this.addSortingForColumn.bind(this)}
                        handlePreviousPage={this.handlePreviousPage}
                        handleNextPage={this.handleNextPage}
                        handleFirstPage={this.handleFirstPage}
                        handleLastPage={this.handleLastPage}
                        handleRecordsNumber={(event) => this.handleRecordsNumber(event)}
                        handlePageChange={(event) => this.handlePageChange(event)}
                        pagingInfo={this.state.pagingInfo}
                    />
                </div>
            );
        } else {
            return(<div/>)
        }
    }
}

MeasurementsTable.contextType = AuthContext;
