import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown as downArrow } from '@fortawesome/pro-regular-svg-icons';

import StringUtils from 'global/utils/StringUtils';

import './MultiSelect.scss';

export default class MultiSelect extends Component {

    static defaultProps = {
        initiallySelected: [],
        options: [],
        onChange: () => null,
        multiLabel: 'Multiple selected'
    }

    constructor(props) {
        super(props);

        this.state = {
            selected: this.props.initiallySelected,
            isToggled: false,
            searchValue: '',
            showPlaceholder: true
        };

        this.wrapperRef = React.createRef();
        this.searchInputRef = React.createRef();

        this.toggleSelect = this.toggleSelect.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.searchableFormLabel = this.searchableFormLabel.bind(this);
        this.defaultFormLabel = this.defaultFormLabel.bind(this);
        this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.dropdownOptions = this.dropdownOptions.bind(this);
        this.optionItems = this.optionItems.bind(this);
        this.shouldShowPlaceholder = this.shouldShowPlaceholder.bind(this);
        this.handleOnSearchContainerClick = this.handleOnSearchContainerClick.bind(this);
    }

    componentDidMount() {
        window.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selected !== this.props.selected) {
            this.setState({ selected: this.props.selected });
        }
    }

    handleClickOutside(event) {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({
                isToggled: false,
            });
        }
    }

    shouldShowPlaceholder() {
        return StringUtils.isBlank(this.state.searchValue) &&
            (
                !this.state.selected || 
                (this.state.selected && this.state.selected.length === 0)
            );
    }

    onItemClick(option) {
        if (option.disabled) {
            return;
        }

        if (this.props.searchable) {
            this.clearSearch();
            if (this.props.onSelectedItem) {
                this.props.onSelectedItem(option.searchObject);
            }
        }

        if (this.state.selected.indexOf(option.value) !== -1) {
            this.setState(prevState => {
                let selected;
                if (prevState.selected.length === 1 && this.props.defaultSelected !== undefined) {
                    if (prevState.selected[0] === this.props.defaultSelected) {
                        selected = this.props.options
                            .filter(({ value }) => value !== this.props.defaultSelected)
                            .map(({ value }) => value);
                    } else {
                        selected = [this.props.defaultSelected];
                    }
                } else {
                    selected = prevState.selected.filter(value => value !== option.value);
                }

                return {
                    selected
                };
            }, () => this.props.onChange(this.state.selected));
        } else {
            this.setState(prevState => ({
                selected: [...prevState.selected, option.value]
            }), () => this.props.onChange(this.state.selected));
        }
        
        this.setState({ showPlaceholder: this.shouldShowPlaceholder() })
    }

    toggleSelect() {
        this.setState(prevState => ({
            isToggled: !prevState.isToggled,
            showPlaceholder: !prevState.showPlaceholder
        }));

       this.searchInputRef.current && this.searchInputRef.current.focus();
    }

    handleOnSearchContainerClick(e) {
        e.preventDefault();
        e.stopPropagation();

        this.setState(prevState => ({
            isToggled: !prevState.isToggled,
            showPlaceholder: !prevState.showPlaceholder
        }));
        this.searchInputRef.current && this.searchInputRef.current.focus();
    }

    handleSearchInputChange(e) {
        this.setState({ 
            isToggled: true, 
            searchValue: e.target.value,
        });

        if (!this.timer) {
            this.timer = setTimeout(() => {
                this.props.onSearch(this.state.searchValue);
                this.timer = null;
            }, 300);
        }
    }

    clearSearch() {
        this.setState({ searchValue: null })
        this.searchInputRef.current.value = null;
        this.props.resetSearchOptions();
    }

    formLabel() {
        return this.props.searchable ? this.searchableFormLabel() : this.defaultFormLabel();
    }

    optionItems() {
        let selectedItemsHtml;

        let selectedOptions;
        if (this.props.searchable) {
            selectedOptions = this.props.selectedSearchOptions
        } else {
            selectedOptions = this.props.options && this.props.options.length > 0 &&
                this.props.options.filter(option => !option.disabled && this.state.selected.indexOf(option.value) !== -1);
        }
        
        const selectedOptionsLength = selectedOptions.length;

        let slicedSelectedOptions = selectedOptions;

        if (slicedSelectedOptions.length > 0 && this.props.renderSelected) {
            selectedItemsHtml = slicedSelectedOptions.map(option => this.props.renderSelected(option));
        } 

        return { selectedItemsHtml, selectedOptionsLength };
    }

    searchableFormLabel() {
        let inputPlaceholder  = this.props.placeholder && this.props.placeholder !== "" ? this.props.placeholder : "None"
        let { selectedItemsHtml, selectedOptionsLength } = this.optionItems();

        if (selectedOptionsLength > 0) {
            inputPlaceholder = "";
        } 

        return (
            <>
                { selectedItemsHtml }
                <div className="search-container" onClick={ this.handleOnSearchContainerClick }>
                    <input
                        value={ this.state.searchValue || "" }
                        ref={ this.searchInputRef }
                        type="text"
                        className="select-search"
                        placeholder={ inputPlaceholder }
                        onChange={ this.handleSearchInputChange }
                    />
                </div>
            </>
        )
    }   

    defaultFormLabel() {
        const selectedOptions = this.props.options && this.props.options.length > 0 &&
            this.props.options.filter(option => !option.disabled && this.state.selected.indexOf(option.value) !== -1);

        if (selectedOptions.length > 0 && this.props.renderSelected) {
            return selectedOptions.map(option => this.props.renderSelected(option));
        }

        switch (selectedOptions.length) {
            case 0:
                return this.props.placeholder ? this.props.placeholder : 'None';
            case 1:
                return selectedOptions[0].label;
            default:
                return this.props.multiLabel;
        }
    }

    dropdownOptions() {
        const searchMenuCss = this.props.searchable ? " search-select-menu" : ""
    
        return this.props.options && this.props.options.length > 0 && (
            <div className={ "select-menu" + searchMenuCss }>
                { this.props.options.map(option => {
                    return (
                        <div
                            key={ option.value }
                            className={ `select-item ${ option.disabled ? "disabled" : "" }` }
                            onClick={ () => this.onItemClick(option) }
                        >
                            <input
                                checked={this.state.selected.indexOf(option.value) !== -1}
                                type="checkbox"
                                className="select-checkbox-input"
                                readOnly
                            />
                            <span className="select-checkbox">
                                { option.label }
                            </span>
                        </div>
                    )}) 
                }
            </div>
        )
    } 

    render() {
        const placeholderClass = this.state.selected.length === 0 && this.props.placeholder ? 'placeholder' : '';

        let icon;
        if (this.props.loading) {
            icon = (
                <Loader type="TailSpin" color="#289AC2" width={ 14 } height={ 14 } />
            );
        } else {
            icon = (
                <FontAwesomeIcon icon={ downArrow } className="select-down-arrow" />
            );
        }

        return (
            <div ref={ this.wrapperRef } className={ `multi-select ${this.props.borderless ? "borderless" : ""} ${this.props.searchable ? "searchable" : ""}` }>
                <div className={ `select-title ${placeholderClass}` } onClick={ this.toggleSelect }>
                    <div className="select-label">
                        { this.formLabel() }
                    </div>
                    { icon }
                </div>

                { this.state.isToggled && this.dropdownOptions() }
            </div>
        );
    }
}
