import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch as searchIcon } from '@fortawesome/free-solid-svg-icons';
import {
    faEllipsisH as menuIcon,
    faTimesCircle as deleteIcon,
    faUserPlus as addUserIcon,
    faPenToSquare as editUserIcon,
    faPaperPlane as reinviteUserIcon
} from '@fortawesome/pro-solid-svg-icons';
import { smallGridRecordsNumber, formSort, mapSort, usersUrl, USER_STATE } from 'global/constants';
import QueryParamService from 'global/services/QueryParamService';
import Chip from 'components/chip/Chip';
import Copy from 'components/copy/Copy';
import AddOrEditUserPopup from 'components/popup/AddOrEditUserPopup';
import ReinviteUserPopup from 'components/popup/ReinviteUserPopup';
import CustomDropdown from 'components/input/CustomDropdown';
import Pagination from 'components/paging/Pagination';
import {
    SortableTableHeaderCell,
    Table,
    TableCell,
    TableHeaderCell,
    TableHeaderRow,
    TableRow,
    NoDataRow
} from 'components/table/Table';
import DeletePopup from 'components/popup/DeletePopup';
import AuthContext from 'AuthContext';

import './UserManagement.scss';

export default class UserManagement extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        const queryParamsString = window.location.search.slice(1);
        const page = parseInt(QueryParamService.parseSimpleValueFromQueryString(queryParamsString, 'page', 1));
        const sort = QueryParamService.formSortArray(queryParamsString);
        const searchValue = QueryParamService.parseSimpleValueFromQueryString(queryParamsString, 'search', '');

        this.state = {
            loading: true,
            users: null,
            pageNumber: page,
            pageSize: smallGridRecordsNumber,
            available: 0,
            sort,
            searchValue,
            showAddOrEditUserModal: false,
            showDeleteUserModal: false,
            edit: false,
            showReinviteUserModal: false,
            user: {}
        };

        this.fetchUsers = this.fetchUsers.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.onSort = this.onSort.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onAddOrEditUserModalClose = this.onAddOrEditUserModalClose.bind(this);
        this.onDeleteUserModalClose = this.onDeleteUserModalClose.bind(this);
        this.onReinviteUserModalClose = this.onReinviteUserModalClose.bind(this);
        this.getUsersActionItems = this.getUsersActionItems.bind(this);
    }

    componentDidMount() {
        this.fetchUsers();
    }

    async fetchUsers() {
        this.setState({ loading: true });

        const response = await this.context.get(usersUrl, {
            includeGroups: true,
            includeDevelopers: false,
            contains: this.state.searchValue,
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize,
            sort: mapSort(this.state.sort)
        });

        if (!response || response.status === "error") {
            console.error(response.message);
            this.setState({
                users: [],
                loading: false
            });

            return;
        }

        QueryParamService.addPageToQueryString(window.location.search.slice(1), response.pageNumber);
        QueryParamService.addSortToQueryString(window.location.search.slice(1), this.state.sort);

        if (this.state.searchValue) {
            QueryParamService.addValueToQueryString(window.location.search.slice(1), 'search', this.state.searchValue);
        }

        const { data, ...pagingInfo } = response;

        this.setState({
            users: data,
            ...pagingInfo,
            loading: false
        });
    }

    onPageChange(pageNumber) {
        this.setState({ pageNumber }, this.fetchUsers);
    }

    onSort(columnName, direction) {
        this.setState(prevState => ({
            sort: formSort(prevState.sort, columnName, direction)
        }), this.fetchUsers);
    }

    onSearch() {
        this.setState({ pageNumber: 1 }, this.fetchUsers);
    }

    onAddOrEditUserModalClose(isAdded) {
        this.setState({
            user: {},
            showAddOrEditUserModal: false,
        }, () => isAdded && this.fetchUsers());
    }

    onDeleteUserModalClose(isDeleted) {
        this.setState({
            user: {},
            showDeleteUserModal: false
        }, () => isDeleted && this.fetchUsers());
    }

    onReinviteUserModalClose() {
        this.setState({
            showReinviteUserModal: false,
        });
    }

    getUsersActionItems(user) {
        const actionItems = [];

        actionItems.push(
            <div onClick={ () => this.setState({ user, showAddOrEditUserModal: true, edit: true }) }>
                <FontAwesomeIcon icon={ editUserIcon } className="action-icon" />
                Edit User
            </div>
        );

        if (user.state === USER_STATE.PENDING) {
            actionItems.push(
                <div onClick={ () => this.setState({ user, showReinviteUserModal: true }) }>
                    <FontAwesomeIcon icon={ reinviteUserIcon } className="action-icon" />
                    Reinvite User
                </div>
            );
        }

        actionItems.push(
            <div onClick={ () => this.setState({ user, showDeleteUserModal: true }) }>
                <FontAwesomeIcon icon={ deleteIcon } className="action-icon" />
                Delete User
            </div>
        );

        return actionItems;
    }

    renderUsers() {
        return this.state.users.map(user => (
            <TableRow key={ user.id }>
                <TableCell>
                    { user.name }
                    { USER_STATE.PENDING === user.state && (
                        <span className="greyColorText">
                            &nbsp;(Pending)
                        </span>
                    ) }
                </TableCell>
                <TableCell>
                    { user.email }
                    <Copy data={ user.email } />
                </TableCell>
                <TableCell>
                    <div className="group-chip-container">
                        { (user.groups || []).length > 0 ? this.renderGroups(user.groups) : '-' }
                    </div>
                </TableCell>
                <TableCell align="center">
                    <CustomDropdown
                        className="actions-dropdown"
                        title={ <FontAwesomeIcon icon={ menuIcon } className="menu-icon" /> }
                        items={ this.getUsersActionItems(user) }
                    />
                </TableCell>
            </TableRow>
        ));
    }

    renderGroups(groups) {
        return groups.map(group => <Chip key={ group.id } icon={ group.icon } text={ group.name } />);
    }

    render() {
        return (
            <div className="user-management">
                <div className="header-container">
                    <div className="header">
                        User Management
                        <Loader
                            className="loader"
                            type="TailSpin"
                            color="#289AC2"
                            height={ 30 }
                            width={ 30 }
                            visible={ this.state.loading }
                        />
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="search-input-container">
                            <input
                                className="search-input"
                                placeholder="Search by name, email"
                                disabled={ this.state.loading }
                                value={ this.state.searchValue }
                                onChange={ e => this.setState({ searchValue: e.target.value }) }
                                onKeyPress={ e => e.key === 'Enter' && this.onSearch() }
                            />
                            <FontAwesomeIcon icon={ searchIcon } className="search-icon" onClick={ this.onSearch } />
                        </div>
                        <Button variant="continue" onClick={ () => this.setState({ showAddOrEditUserModal: true, edit: false }) }>
                            <FontAwesomeIcon icon={ addUserIcon } className="add-icon" />
                            ADD NEW USER
                        </Button>
                    </div>
                </div>
                { this.state.users && (
                    <>
                        <Table>
                            <TableHeaderRow>
                                <SortableTableHeaderCell width={ 292 } columnName='DISPLAY_NAME' onSort={ this.onSort }>
                                    Full Name
                                </SortableTableHeaderCell>
                                <TableHeaderCell width={ 521 }>Email</TableHeaderCell>
                                <TableHeaderCell width={ 326 }>Groups</TableHeaderCell>
                                <TableHeaderCell fixedWidth={ 92 } align="center">Actions</TableHeaderCell>
                            </TableHeaderRow>
                            { this.state.users.length > 0 ? this.renderUsers() : <NoDataRow /> }
                        </Table>
                        <Pagination
                            page={ this.state.pageNumber }
                            size={ this.state.pageSize }
                            total={ this.state.available }
                            onChange={ this.onPageChange }
                        />
                    </>
                ) }
                { this.state.showAddOrEditUserModal && (
                    <AddOrEditUserPopup
                        edit={ this.state.edit }
                        user={ this.state.user }
                        show={ this.state.showAddOrEditUserModal }
                        onClose={ this.onAddOrEditUserModalClose }
                    />
                ) }
                <DeletePopup
                    onClose={ this.onDeleteUserModalClose }
                    show={ this.state.showDeleteUserModal }
                    url={ `${usersUrl}/${this.state.user.id}` }
                    title="DELETE USER"
                    withConfirmMessage
                    confirmMessageTitle="User removed"
                    confirmMessageText={ (
                        <>
                            User <span className="bold">{ this.state.user.name }</span> has been successfully removed.
                            Please note that it may take a couple of minutes for this change to fully apply across the application.
                        </>
                    ) }
                >
                    Are you sure you want to delete user <span className="bold">{ this.state.user.name }</span>?
                </DeletePopup>
                <ReinviteUserPopup
                    user={ this.state.user }
                    show={ this.state.showReinviteUserModal }
                    onClose={ this.onReinviteUserModalClose }
                />
            </div>
        );
    }
}
