import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faArrowUpRightFromSquare as linkIcon, faCircleInfo} from '@fortawesome/pro-solid-svg-icons';
import { hourPart, minutePart } from 'global/services/DateTimeService';
import SimpleTooltip from 'components/tooltip/SimpleTooltip';
import QueryParamService from 'global/services/QueryParamService';

export default class StatsHeader extends Component {

    static defaultProps = {
        orderCount: '-',
        issueCount: '-',
        resolvedIssueCount: '-',
        manuallyResolvedIssueCount: '-',
        avgResolutionTime: '-',
        snoozedIssueCount: '-'
    }

    renderAvgResolutionTime(time) {
        if (isNaN(time)) {
            return time;
        }

        return (
            <span>
                { hourPart(time || 0) }
                <span className="time-unit">h</span>
                { minutePart(time || 0) }
                <span className="time-unit">min</span>
            </span>
        );
    }

    render() {
        return (
            <div className="stats-header">
                <div className="issue-count-heading row grid-divider">
                    <div className="col">
                        Created Manifests
                    </div>
                    <div className="col">
                        Issues
                    </div>
                    <div className="col">
                        Snoozed Issues
                    </div>
                    <div className="col">
                        Closed Issues
                        <div className="tooltip-container">
                            <FontAwesomeIcon icon={ faCircleInfo } className="icon" />
                            <SimpleTooltip direction="bottom">
                                This is the number of all <br /> manually resolved issues
                            </SimpleTooltip>
                        </div>
                    </div>
                    <div className="col">
                        Resolved Issues
                        <div className="tooltip-container">
                            <FontAwesomeIcon icon={ faCircleInfo } className="icon" />
                            <SimpleTooltip direction="bottom">
                                This number is the sum <br /> of all manually and <br /> automatically resolved <br /> issues
                            </SimpleTooltip>
                        </div>
                    </div>
                    <div className="col">
                        Avg. Resolution Time
                    </div>
                </div>
                <div className="issue-count row grid-divider">
                    <div className="col">
                        { this.props.orderCount }
                    </div>
                    <div className="col">
                        { this.props.issueCount }
                    </div>
                    <div className="col">
                        { this.props.snoozedIssueCount }
                        <div className="tooltip-container">
                            <Link to={"/statistics/snoozed-issues?" + QueryParamService.parseParams(["startDate", "endDate", "issueTypes"])}>
                                <FontAwesomeIcon className="link-icon" icon={ linkIcon } />
                            </Link>
                            <SimpleTooltip direction="bottom">
                                Click to see the detailed <br /> table overview of the <br /> Snoozed Issues
                            </SimpleTooltip>
                        </div>
                    </div>
                    <div className="col">
                        { this.props.manuallyResolvedIssueCount }
                        <div className="tooltip-container">
                            <Link to={"/statistics/closed-issues?" + QueryParamService.parseParams(["startDate", "endDate", "issueTypes"])}>
                                <FontAwesomeIcon className="link-icon" icon={ linkIcon } />
                            </Link>
                                <SimpleTooltip direction="bottom">
                                Click to see the detailed <br /> table overview of the <br /> Closed Issues
                            </SimpleTooltip>
                        </div>
                    </div>
                    <div className="col">
                        { this.props.resolvedIssueCount }
                    </div>
                    <div className="col">
                        { this.renderAvgResolutionTime(this.props.avgResolutionTime) }
                    </div>
                </div>
            </div>
        );
    }
}
