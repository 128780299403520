import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEllipsis as ellipsisIcon,
    faArrowUpRightFromSquare as linkIcon,
    faCheckCircle as checkIcon,
    faSlidersH as slidersIcon,
    faTimesCircle as timesCircleIcon,
    faRedo as redoIcon,
    faGears as remoteReeferControlIcon,
    faClockRotateLeft as trailerHistoryIcon,
    faPenToSquare as editTrailerIcon,
    faCircleXmark as removeTrailerIcon,
} from '@fortawesome/pro-solid-svg-icons';
import Select, { components } from 'react-select';
import Loader from 'react-loader-spinner';
import { Button } from 'react-bootstrap';
import AuthContext from 'AuthContext';
import ManageManifestPopup from 'components/popup/ManageManifestPopup';
import ReeferControlPopup from 'components/popup/ReeferControlPopup';
import RemoveTrailerPopup from 'components/popup/RemoveTrailerPopup';
import AddOrEditTrailerPopup from 'components/popup/AddOrEditTrailerPopup';
import RequiresRole from 'components/requires-role/RequiresRole';
import SimpleContainer from 'components/container/SimpleContainer';
import { formatLongDate } from 'global/services/DateTimeService';
import { check } from 'global/services/RoleCheckService';
import history from "global/history";

import './HeaderCard.scss';

export default class HeaderCard extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            showManifestPopup: false,
            showRemoveTrailerPopup: false,
            showAddOrEditTrailerPopup: false,
            showReeferControlPopup: false,
            popupType: null
        }
        
        this.onOrderIdClick = this.onOrderIdClick.bind(this);
        this.onTrailerIdClick = this.onTrailerIdClick.bind(this);
        this.openPopup = this.openPopup.bind(this);
        this.showRestoreTrailerPopup = this.showRestoreTrailerPopup.bind(this);
        this.showManifestPopup = this.showManifestPopup.bind(this);
        this.closeRemoveTrailerPopup = this.closeRemoveTrailerPopup.bind(this);
        this.closeAddOrEditTrailerPopup = this.closeAddOrEditTrailerPopup.bind(this);
        this.closeReeferControlPopup = this.closeReeferControlPopup.bind(this);
        this.closeManifestPopup = this.closeManifestPopup.bind(this);
    }

    routeChange(pathname, state) {
        history.push({ pathname, state});
    }

    onOrderIdClick() {
        this.routeChange("/manifest-details/" + this.props.assignment.id, { assignmentId: this.props.assignment.id} );
    }

    onTrailerIdClick() {
        this.routeChange("/details/" + this.props.trailer.id + "/" + this.props.assignment.id, { assignmentId: this.props.assignment.id });
    }

    openPopup(type) {
        if (type.value === "remote-reefer-control") {
            this.setState({ showReeferControlPopup: true });
        } else if (type.value === "edit-trailer") {
            this.setState({
                showAddOrEditTrailerPopup: true,
                popupType: "edit"
            });
        } else if (type.value === "trailer-history") {
            this.routeChange("/details/" + this.props.trailer.id, {});
        } else if (type.value === "remove-trailer") {
            this.setState({ showRemoveTrailerPopup: true });
        }
    }

    showManifestPopup() {
        this.setState({
            showManifestPopup: true
        });
    }

    closeManifestPopup(submitted) {
        this.setState(
            { showManifestPopup: false },
            () => this.props.onCloseManifestPopup(submitted)
        );
    }

    closeRemoveTrailerPopup(isRemoved) {
        this.setState({
            showRemoveTrailerPopup: false
        });

        if (isRemoved) {
            this.props.onCloseRemoveTrailerPopup();
        }
    }

    showRestoreTrailerPopup() {
        this.setState({
            showAddOrEditTrailerPopup: true,
            popupType: "restore"
        });
    }

    closeAddOrEditTrailerPopup(isRestored) {
        this.setState({
            showAddOrEditTrailerPopup: false,
            popupType: ""
        });

        if (isRestored) {
            this.props.onCloseAddOrEditTrailerPopup();
        }
    }

    closeReeferControlPopup() {
        this.setState({ showReeferControlPopup: false }, this.props.onCloseRemoteReeferControls);
    }

    render() {
        const {
            issues,
            cardType,
            loaded,
            assignment,
            trailer
        } = this.props;

        const assignmentId = assignment && assignment.id;
        const assignmentBusinessId = assignment && assignment.businessId;
        const relevantLeg = assignment && assignment.relevantLeg;

        const trailerActions = [];

        if (this.context.role.some(role => check(role, 'trailer-commands:write'))) {
            trailerActions.push({
                value: 'remote-reefer-control',
                label: 'Remote Reefer Control',
                icon: <FontAwesomeIcon icon={ remoteReeferControlIcon } className="icon" />
            });
        }
        
        if (assignmentId) {
            trailerActions.push({ value: "trailer-history", label: "Trailer History", icon: <FontAwesomeIcon icon={ trailerHistoryIcon } className="icon" /> });
        }
        
        trailerActions.push(
            { value: "edit-trailer", label: "Edit Trailer", icon: <FontAwesomeIcon icon={ editTrailerIcon } className="icon" /> },
            { value: "remove-trailer", label: "Remove Trailer", icon: <FontAwesomeIcon icon={ removeTrailerIcon } className="icon" /> }
        );

        const { Option } = components;
        const IconOption = props => (
            <Option { ...props }>
                { props.data.icon }
                &nbsp;&nbsp;
                { props.data.label }
            </Option>
        );

        let manifestBadge = <></>;
        if (loaded && assignmentId) {
            const activeManifest = assignment.active;

            let badgeDecorator, icon, text;
            if (activeManifest) {
                badgeDecorator = ' active';
                text = 'Active Manifest';
                icon = checkIcon;
            } else {
                badgeDecorator = ' inactive';
                text = 'Inactive Manifest';
                icon = timesCircleIcon;
            }

            manifestBadge = (
                <div className={ `manifest-badge ${badgeDecorator}` } id="manifest-badge">
                    <FontAwesomeIcon className="icon" icon={ icon } />
                    { text }
                </div>
            );
        }

        let showTrailerIdOrSerialNumber;
        if (cardType === "trailer") {
            showTrailerIdOrSerialNumber = (
                <div className="info">
                    <p className="title">
                        Serial number
                    </p>
                    <span className="content" id="trailer-serial-number">
                        { trailer.serialNumber ? trailer.serialNumber : "N/A" }
                    </span>
                </div>
            );
        } else {
            showTrailerIdOrSerialNumber = (
                <div className="info">
                    <p className="title">
                        Trailer ID
                    </p>
                    <span className="content">
                        <p className="gathered-info link" id="trailer-business-id" onClick={ this.onTrailerIdClick }>
                            { trailer.businessId }
                            <FontAwesomeIcon icon={ linkIcon } className="icon" />
                        </p>
                    </span>
                </div>
            );
        }

        let manifestId = <></>;
        if (cardType === "trailer" && trailer.active && assignmentId) {
            manifestId = (
                <div className="info">
                    <p className="title">
                        Manifest ID
                    </p>
                    <span className="content">
                        <RequiresRole
                            roles={this.context.role}
                            perform="details-page:dm"
                            no={() =>
                                <p className="gathered-info" id="manifest-id">
                                    { assignmentBusinessId }
                                </p>
                            }
                            yes={() =>
                                <p className="gathered-info link" id="manifest-id" onClick={ this.onOrderIdClick }>
                                    { assignmentBusinessId }
                                    <FontAwesomeIcon icon={ linkIcon } className="icon" />
                                </p>
                            }
                        />
                    </span>
                </div>
            );
        }

        let trailerActionButton = <></>;
        if (cardType === "trailer" && trailer.active) {
            trailerActionButton = (
                <Select
                    className="basic-single-select"
                    classNamePrefix="select-value"
                    id="more-actions-dropdown"
                    options={ trailerActions }
                    placeholder= { <div className="select-placeholder">More Actions &nbsp;&nbsp; <FontAwesomeIcon icon={ ellipsisIcon } className="icon-manifest" /></div> }
                    components={{ Option: IconOption, DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    isSearchable={ false }
                    value={ null }
                    onChange={ this.openPopup }
                />
            );
        } else if (cardType === "trailer") {
            trailerActionButton = (
                <Button className="restore-button" id="restore-trailer-button" variant="light" onClick={ this.showRestoreTrailerPopup }>
                    <FontAwesomeIcon icon={ redoIcon } className="redo-icon" />
                    RESTORE TRAILER
                </Button>
            );
        }

        const hasResolvedAllIssues = issues.resolvedIssuesNumber !== null && issues.unresolvedIssuesNumber === 0;

        return (
            <SimpleContainer className="basic-info" id="header-card">
                <div className="heading col-12">
                    <span className="page-title">
                        <span className="title" id="card-title">
                            { cardType === "trailer" && loaded && !trailer.active && "Removed " }
                            { cardType === "trailer" ? `Trailer #${trailer.businessId}` : `Manifest #${assignmentBusinessId}` }
                        </span>
                        <Loader
                            type="TailSpin"
                            color="#289AC2"
                            id="loader"
                            height={ 40 }
                            width={ 40 }
                            visible={ !loaded }
                        />
                        { manifestBadge }
                    </span>
                    { loaded && (
                        <div className="trailer-actions">
                            { trailerActionButton }
                            <Button
                                variant="continue"
                                className="submit-button manifest-button manage"
                                id="manage-tracking-button"
                                onClick={ this.showManifestPopup }
                                disabled={ assignmentId && !trailer.active }
                            >
                                <FontAwesomeIcon icon={ slidersIcon } className="icon-manifest" />
                                MANAGE TRACKING
                            </Button>
                        </div>
                    ) }
                </div>
                <div className="header-details">
                    <div className="header-info">
                        { showTrailerIdOrSerialNumber }      
                        { manifestId }
                        { relevantLeg && assignment.active && (
                            <div className="info">
                                <p className="title">
                                    Active since
                                </p>
                                <span className="content" id="active-since">
                                    { formatLongDate(new Date(relevantLeg.startTime)) }
                                </span>
                            </div>
                        ) }
                        { relevantLeg && !assignment.active === 'Inactive' && (
                            <>
                                <div className="info">
                                    <p className="title">
                                        Activated on
                                    </p>
                                    <span className="content" id="activated-on">
                                        { formatLongDate(new Date(relevantLeg.startTime)) }
                                    </span>
                                </div>
                                <div className="info">
                                    <p className="title">
                                        Deactivated on
                                    </p>
                                    <span className="content" id="deactivated-on">
                                        { formatLongDate(new Date(relevantLeg.endTime)) }
                                    </span>
                                </div>  
                            </> 
                        ) } 
                    </div>
                    <div className="header-info right">
                        <div className="info">
                            <p className="title">
                                Last known location
                            </p>
                            <span className="content location" id="last-known-location">
                                { trailer.location }
                            </span>
                        </div>
                        <div className="info">
                            <p className="title">
                                Trailer status
                            </p>
                            <span className="content">
                                <p className={`${trailer.statusClassName}`} id="trailer-status">
                                    { !trailer.moving ? "Silent" : trailer.moving }
                                </p>
                            </span>
                        </div>
                        <div className="info">
                            <p className="title">
                                { cardType === "trailer" ? "Resolved " : "Relevant " } issues
                            </p>
                            <span className="content">
                                <p className={hasResolvedAllIssues ? "greenColorText" : "redColorText"} id="number-of-issues">
                                    { hasResolvedAllIssues && <><FontAwesomeIcon icon={ checkIcon } className="col-0 col-sm-0 issue-icon greenColorText" /> &nbsp;</> }
                                    { issues.resolvedIssuesNumber && cardType === "trailer" ? issues.resolvedIssuesNumber : cardType === "trailer" && 0 }
                                    { cardType === "trailer" ? `/${issues.resolvedIssuesNumber + issues.unresolvedIssuesNumber}` : issues.unresolvedIssuesNumber }
                                </p>
                            </span>
                        </div>
                    </div>
                </div>
                { loaded && (
                    <>
                        <RemoveTrailerPopup
                            onClose={ this.closeRemoveTrailerPopup }
                            show={ this.state.showRemoveTrailerPopup }
                            manifest={ assignment }
                            trailer={ trailer }
                            hasRelevantIssues={ trailer.hasRelevantIssues }
                        />
                        <AddOrEditTrailerPopup
                            onClose={ this.closeAddOrEditTrailerPopup }
                            show={ this.state.showAddOrEditTrailerPopup }
                            trailer={ trailer }
                            type= { this.state.popupType }
                        />
                        <RequiresRole
                            roles={ this.context.role }
                            perform="trailer-commands:write"
                            yes={ () =>
                                <ReeferControlPopup
                                    closeModal={ this.closeReeferControlPopup }
                                    showModal={ this.state.showReeferControlPopup }
                                    trailerId={ trailer.id }
                                    currentPower={ trailer.power }
                                    currentMode={ trailer.actualMode }
                                    currentSetPoint={ trailer.setPoint }
                                />
                            }
                        />
                        <ManageManifestPopup
                            close={ this.closeManifestPopup }
                            show={ this.state.showManifestPopup }
                            title="MANAGE TRACKING"
                            isManifestEditable={ !assignmentId }
                            manifest={ assignmentId || (assignment && assignment.active) ? assignment : null }
                            trailer={ trailer }
                        />
                    </>
                ) }
            </SimpleContainer>
        )
    }
}
