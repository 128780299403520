import { issueTypeMap } from "global/services/IssueTypeService";
import { addSilentUnitThresholdToDate, formatDate } from "global/services/DateTimeService";
import { RESOLVED_STATES } from "global/constants";

function mapSeenBy(issue, seenBy) {
  let returnJson = {
    seenBeforeResolution: [],
    seenAfterResolution: []
  }
  if (issue.type === 'INACTIVITY') {
    if (RESOLVED_STATES.indexOf(issue.state) !== -1) {
      seenBy.forEach(activity => {
        if (activity.time > issue.endTime) {
          returnJson.seenAfterResolution.push(activity);
        } else {
          returnJson.seenBeforeResolution.push(activity);
        }
      });
    } else {
      returnJson.seenBeforeResolution = seenBy;
    }
  } else {
    if (RESOLVED_STATES.indexOf(issue.state) !== -1 || (issue.assignment && issue.assignment.active === false) || (issue.leg && issue.leg.active === false)) {
      seenBy.forEach(activity => {
        if (activity.time > issue.endTime) {
          returnJson.seenAfterResolution.push(activity);
        } else {
          returnJson.seenBeforeResolution.push(activity);
        }
      });
    } else {
      returnJson.seenBeforeResolution = seenBy;
    }
  }
  return returnJson;
}

export function mapIssuesFromResponse(data, isMaintenance) {
  const issues = [];
  data.forEach(obj => {
    let measurement = obj.latestMeasurement;
    if(!measurement || Object.keys(measurement).length === 0) {
      measurement = {
        temperatureState: {},
        modeState: {},
        alarmState: {},
        powerState: {}
      }
    }
    let notification =  { alerts: null, notified: null, o_notified: null, snoozed: null};
    let latestWarningNotification = obj.latestWarningNotification || {};
    let latestEscalationNotification = obj.latestEscalationNotification || {};
    if(obj.latestWarningNotification) {
        latestWarningNotification = {
        ...obj.latestWarningNotification,
        alerts: "Warning",
        type: "Warning",
        notified: obj.latestWarningNotification.time ? new Date(obj.latestWarningNotification.time) : "",
        time: new Date(obj.latestWarningNotification.time),
        emails: obj.latestWarningNotification.contactInfos.map(x => x.email),
      }
    }
    if (obj.latestEscalationNotification){
        latestEscalationNotification = {
        ...obj.latestEscalationNotification,
        alerts: "Escalation",
        type: "Escalation",
        o_notified: new Date(obj.latestEscalationNotification.time) || null,
        time: new Date(obj.latestEscalationNotification.time),
        emails: obj.latestEscalationNotification.contactInfos.map(x => x.email)
      }
    }
    notification = {...notification, ...latestWarningNotification, ...latestEscalationNotification};
    if (obj.issue && obj.issue.state === "SNOOZED") {
      notification = {
        ...notification,
        snoozed: new Date(obj.issue.stateUpdated)
      }
    }
    const issue = obj.issue;
    if(issue.assignment) {
      if(issue.assignment.temperatureMin === null || issue.assignment.temperatureMin === undefined) {
        if(issue.metaData.temperatureMin !== null && issue.metaData.temperatureMin !== undefined) {
          issue.assignment.temperatureMin = parseInt(issue.metaData.temperatureMin);
        } else {
          issue.assignment.temperatureMin = null;
        }
      }
      if(issue.assignment.temperatureMax === null || issue.assignment.temperatureMax === undefined) {
        if(issue.metaData.temperatureMax !== null && issue.metaData.temperatureMax !== undefined) {
          issue.assignment.temperatureMax = parseInt(issue.metaData.temperatureMax);
        } else {
          issue.assignment.temperatureMax = null;
        }
      }
    }
    let sortedSeenBy = mapSeenBy(issue, obj.seenBy || []);
    let fuelLevel = null;
    if(issue.type === "LOW_FUEL_ALARM" && measurement.engineState) {
      fuelLevel = ((issue.metaData.fuelLevel || 0)/ (measurement.engineState.fuelTankSize || 1)) * 100;
    } else if (measurement.engineState) {
      fuelLevel = (measurement.engineState.fuelPercentage || measurement.engineState.fuelPercentage === 0) ? measurement.engineState.fuelPercentage : null;
    }
    issues.push({
      issue: issue,
      id: issue.id,
      orderId: issue.assignment ? issue.assignment.businessId : "No manifest", //TODO: figure out what to put in id,
      leg: issue.leg,
      legId: issue.leg ? issue.leg.businessId : "Unknown",
      trailerId: issue.trailer ? issue.trailer.businessId : "Unknown",
      status: measurement.positionState && measurement.positionState.moving !== undefined ? measurement.positionState.moving : null,
      address: measurement.positionState && measurement.positionState.position !== undefined ? measurement.positionState.position : "Unknown",
      companyName: issue  && issue.metaData ? issue.metaData.companyName : null,
      companyAddress: issue && issue.metaData ? issue.metaData.companyAddress : null,
      company: measurement.detentionState && measurement.detentionState.company !== undefined ? measurement.detentionState.company.name : null,
      fuelLevel: (fuelLevel || fuelLevel === 0) ? Math.round(fuelLevel) : null,
      detentionState: measurement && measurement.detentionState ? {...measurement.detentionState, ...{location: measurement.positionState.position}} : {},
      metaData: issue && issue.metaData ? issue.metaData : {},
      detentionReason: (issue && issue.metaData && issue.metaData.type)? issue.metaData.type : "Unknown",
      alarmCode: (issue && issue.metaData && issue.metaData.code) ? issue.metaData.code : "Unknown",
      alarmDescription: (issue && issue.metaData && issue.metaData.description) ? issue.metaData.description : "Unknown",
      alarmAction: (issue && issue.metaData && issue.metaData.action) ? issue.metaData.action : "Unknown",
      companyId: (issue && issue.metaData && issue.metaData.companyId) ? issue.metaData.companyId : null,
      minTemp: issue.assignment ? issue.assignment.temperatureMin : "Unknown",
      maxTemp: issue.assignment ? issue.assignment.temperatureMax : "Unknown",
      setpoint: measurement.temperatureState.setTemperature !== undefined ? measurement.temperatureState.setTemperature : null,
      returnAir: measurement.temperatureState.actualTemperature !== undefined ? measurement.temperatureState.actualTemperature : null,
      modeC: issue.assignment ? issue.assignment.unitMode === "CONTINUOUS" ? "C" : "CS" : "NO DATA",
      modeR: measurement.modeState.unitMode !== undefined ? measurement.modeState.unitMode === "CONTINUOUS" ? "C" : "CS" : null,
      powerC: issue.assignment ? issue.assignment.power === true ? "On" : "Off" : "NO DATA",
      powerR: measurement.powerState.power !== undefined ? measurement.powerState.power === true ? "On" : "Off" : null,
      alerts: (notification.alerts || null),
      notified: notification.notified || null,
      latestWarning: latestWarningNotification,
      o_notified: notification.o_notified || null,
      latestEscalation: latestEscalationNotification,
      notifications: [],
      latestNotification: notification,//latestNotification,
      snoozed: notification.snoozed,
      resolved: RESOLVED_STATES.indexOf(issue.state) !== -1 ? issue.endTime : false,
      action: "",
      seenByBefore: sortedSeenBy.seenBeforeResolution,
      seenByAfter: sortedSeenBy.seenAfterResolution,
      closed: obj.closed,
      comments: obj.comments,
      dbIssue: issue,
      snoozedIssue: obj.snoozedIssue,
      silentUnit: (issue.type === "SILENT" && RESOLVED_STATES.indexOf(issue.state) === -1) ? true : (issue.type === "SILENT") ? false : null,
      inactive: (issue.type === "INACTIVITY" && RESOLVED_STATES.indexOf(issue.state) === -1) ? true : (issue.type === "INACTIVITY") ? false : null,
      created: formatDate(new Date(issue.startTime)),
      eventDate: formatDate(new Date(issue.endTime)),
      lastDepartureDate: (issue.metaData || {}).lastDepartureDate ? formatDate(new Date(issue.metaData.lastDepartureDate)) : "Unknown",
      beginning: issue.type === "SILENT" ? formatDate(addSilentUnitThresholdToDate(new Date(issue.startTime))) : formatDate(new Date(issue.startTime)),
      type: issueTypeMap(issue.type),
      state: issue.state,
      onClickState: issue.assignment ? {trailerId: issue.trailer.id, assignmentId: issue.assignment.id, isMaintenance} : {trailerId: issue.trailer.id, isMaintenance},
      truckPosition: (issue && issue.metaData && issue.metaData.truckPosition) || "Unknown",
      trailerPosition: (issue && issue.metaData && issue.metaData.trailerPosition) || "Unknown",
      distance: issue && issue.metaData && issue.metaData.distance,
    });
  });
  return issues;
}
