import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faGear as settingsIcon, faInfoCircle as infoIcon } from '@fortawesome/pro-solid-svg-icons';

import { scheduleNotificationUrl, NOTIFICATION_SCHEDULE_FREQUENCY_MAPPING } from 'global/constants';
import { issueType } from 'global/services/IssueTypeService';
import { notificationType } from 'global/services/NotificationTypeService';
import { 
    formatLongDateWithoutTime, 
    getCurrentDate, 
    getDayName, 
    getPmTime
} from 'global/services/DateTimeService';

import ExpandableCard from 'components/card/ExpandableCard';
import ManageEmailUpdatesPopup from 'components/popup/ManageEmailUpdatesPopup';
import Pill from 'components/input/Pill';
import NotificationToggles from './NotificationToggles';

import AuthContext from 'AuthContext';

import "./NotificationsManagement.scss";

class NotificationsManagement extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            expand: false,
            showManageEmailUpdatesModal: false,
            statisticsSummary: null
        }

        this.handleEnableButtonClick = this.handleEnableButtonClick.bind(this);
        this.handleEnableButtonChange = this.handleEnableButtonChange.bind(this);
        this.onManageEmailUpdateModalClose = this.onManageEmailUpdateModalClose.bind(this);
        this.fetchScheduleNotificationSettings = this.fetchScheduleNotificationSettings.bind(this);
        this.getNotificationScheduleSettingsDescription = this.getNotificationScheduleSettingsDescription.bind(this);
        this.getEmptyState = this.getEmptyState.bind(this);
    }

    componentDidMount() {
        this.fetchScheduleNotificationSettings(notificationType.STATISTICS_SUMMARY);
    }

    async fetchScheduleNotificationSettings(notificationType) {
        try {
            const response = await this.context.get(`${scheduleNotificationUrl}/type/${notificationType}`, {});
            if (response && response.status !== 'error') {
                this.setState({
                    statisticsSummary: {
                        ...response, 
                    }
                });
            }
        } catch(error) {
            console.error(error);
            this.setState({
                statisticsSummary: null
            })
        }
    }

    async handleEnableButtonChange(active) {
        const response = await this.context.put(scheduleNotificationUrl, { 
            ...this.state.statisticsSummary,
            active, 
        });
        if (response.status !== 'error') {
            this.setState({
                statisticsSummary: {
                    ...response, 
                }
            }, () => this.fetchScheduleNotificationSettings(notificationType.STATISTICS_SUMMARY));
        }
    }

    expandClick() {
        this.setState({
            expand: !this.state.expand
        })
    }

    getRule(reason, issueType) {
        return this.props.notificationRules
            .filter(rule => rule.notificationReason === reason && rule.issueType === issueType)
            .map(rule => {
                return {
                    id: rule.id,
                    contactGroups: rule.contactGroups.map(group => group.id)
                }
            })[0];
    }

    getIssueTypeRule(issueType) {
        return this.props.notificationIssueTypeRules.filter(rule => rule.issueType === issueType)[0];
    }

    onManageEmailUpdateModalClose(fetchData) {
        this.setState({
            showManageEmailUpdatesModal: false,
        }, () => fetchData && this.fetchScheduleNotificationSettings(notificationType.STATISTICS_SUMMARY) );
    }

    handleEnableButtonClick(e) {
        e.preventDefault();
        e.stopPropagation();
    }

    getEmptyState() {
        return (
            <div className="default-schedule-notification-settings-box">
                <FontAwesomeIcon icon={ infoIcon } className="info-icon" />
                <div className="title">Email Updates Not Defined</div>
                <div className="subtitle">
                    Before enabling Stats Email Updates you need to <br />
                    define the contents by clicking on Manage Email Updates
                </div>
            </div>
        );
    }
    
    getNotificationScheduleSettingsDescription() {
        let day;
        let startDate;
        if(this.state.statisticsSummary && this.state.statisticsSummary.frequency === "EVERY_WEEK") {
            day = (
                <div>
                    <b>Day: </b>
                    <span> { getDayName(this.state.statisticsSummary.day) } </span>
                </div>
            );
        } 
        if(this.state.statisticsSummary && this.state.statisticsSummary.frequency === "EVERY_MONTH") {
            startDate = (
                <div>
                    <b>Start date: </b>
                    <span> 
                        { formatLongDateWithoutTime(new Date(this.state.statisticsSummary.startDate)) }
                    </span>
                </div>
            );
        }
        
        return this.state.statisticsSummary && (
            <div className="schedule-notification-settings-box">
                <p className="schedule-notification-settings-title">Stats Email Updates Setup</p>
                <div><b>Recurring: </b><span>{ NOTIFICATION_SCHEDULE_FREQUENCY_MAPPING[this.state.statisticsSummary.frequency] }</span></div>
                { day }
                { startDate }
                <div>
                    <b>Time: </b>
                    <span> { getPmTime(this.state.statisticsSummary.time) } </span>
                </div>
                <br />
                <div>
                    <b>Active Since: </b> 
                    <span> 
                        { this.state.statisticsSummary.active ? getCurrentDate(this.state.statisticsSummary.activeDate).format("MM/DD/YYYY") : "N/A" } 
                    </span>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="notifications-management">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="header">
                        Notifications Management
                    </div>
                    <div className="expand" onClick={this.expandClick.bind(this)}>
                        Expand All
                        {this.state.expand ?
                            <FontAwesomeIcon icon={faChevronUp} className="expand-arrow" /> :
                            <FontAwesomeIcon icon={faChevronDown} className="expand-arrow" />
                        }
                    </div>
                </div>
                <ExpandableCard 
                    expand={ this.state.expand }
                    title={
                        (
                            <div className="expandable-card-title-settings">
                                Stats Email Updates
                                { this.state.statisticsSummary && 
                                    <div onClick={ this.handleEnableButtonClick }>
                                        <Pill
                                            className="enable-button"
                                            uncheckedGreyColor
                                            checked={ this.state.statisticsSummary.active }
                                            onChange={ this.handleEnableButtonChange }
                                        />
                                    </div>
                                }
                            </div>
                        )
                    }
                    buttonContent={
                        (
                            <>
                                <FontAwesomeIcon icon={ settingsIcon } className="action-icon" />
                                Manage email updates
                            </>
                        )
                    }
                    handleButton={ () => this.setState({ showManageEmailUpdatesModal: true }) }
                >
                    <NotificationToggles 
                        addRule={ this.props.updateRule }
                        updateRule={ this.props.updateRule }
                        notificationTypes={ [notificationType.STATISTICS_SUMMARY, null, null] }
                        warningRule={ this.getRule(notificationType.STATISTICS_SUMMARY) }
                        defaultTogglesHeader="Emails"
                        noEscalation
                        noResolved
                        disableDefaultToggle = { this.state.statisticsSummary && !this.state.statisticsSummary.active }
                        contactGroups={ this.props.contactGroups }
                        description={ this.getNotificationScheduleSettingsDescription() }
                        emptyState={ this.getEmptyState() }
                        isEmptyState={ !this.state.statisticsSummary }
                    />
                </ExpandableCard>
                <ExpandableCard title="Power Issue" expand={this.state.expand}>
                    <NotificationToggles
                        addRule={this.props.updateRule}
                        updateRule={this.props.updateRule}
                        issueType={issueType.POWER_STATE}
                        notificationTypes={[notificationType.VALUE_MISMATCH_WARNING, notificationType.VALUE_MISMATCH_ESCALATION, notificationType.VALUE_MISMATCH_RESOLVED]}
                        warningRule={this.getRule(notificationType.VALUE_MISMATCH_WARNING, issueType.POWER_STATE)}
                        escalationRule={this.getRule(notificationType.VALUE_MISMATCH_ESCALATION, issueType.POWER_STATE)}
                        resolvedRule={this.getRule(notificationType.VALUE_MISMATCH_RESOLVED, issueType.POWER_STATE)}
                        contactGroups={this.props.contactGroups}
                        issueTypeRule={this.getIssueTypeRule(issueType.POWER_STATE)}
                    />
                </ExpandableCard>
                <ExpandableCard title="Mode Issue" expand={this.state.expand}>
                    <NotificationToggles
                        addRule={this.props.addRule}
                        updateRule={this.props.updateRule}
                        issueType={issueType.UNIT_MODE}
                        notificationTypes={[notificationType.VALUE_MISMATCH_WARNING, notificationType.VALUE_MISMATCH_ESCALATION, notificationType.VALUE_MISMATCH_RESOLVED]}
                        warningRule={this.getRule(notificationType.VALUE_MISMATCH_WARNING, issueType.UNIT_MODE)}
                        escalationRule={this.getRule(notificationType.VALUE_MISMATCH_ESCALATION, issueType.UNIT_MODE)}
                        resolvedRule={this.getRule(notificationType.VALUE_MISMATCH_RESOLVED, issueType.UNIT_MODE)}
                        contactGroups={this.props.contactGroups}
                        issueTypeRule={this.getIssueTypeRule(issueType.UNIT_MODE)}
                    />
                </ExpandableCard>
                <ExpandableCard title="Temperature Issue" expand={this.state.expand}>
                    <NotificationToggles
                        addRule={this.props.addRule}
                        updateRule={this.props.updateRule}
                        issueType={issueType.TEMPERATURE}
                        notificationTypes={[notificationType.VALUE_MISMATCH_WARNING, notificationType.VALUE_MISMATCH_ESCALATION, notificationType.VALUE_MISMATCH_RESOLVED]}
                        warningRule={this.getRule(notificationType.VALUE_MISMATCH_WARNING, issueType.TEMPERATURE)}
                        escalationRule={this.getRule(notificationType.VALUE_MISMATCH_ESCALATION, issueType.TEMPERATURE)}
                        resolvedRule={this.getRule(notificationType.VALUE_MISMATCH_RESOLVED, issueType.TEMPERATURE)}
                        contactGroups={this.props.contactGroups}
                        issueTypeRule={this.getIssueTypeRule(issueType.TEMPERATURE)}
                    />
                </ExpandableCard>
                <ExpandableCard title="Silent Units" expand={this.state.expand}>
                    <NotificationToggles
                        addRule={this.props.addRule}
                        updateRule={this.props.updateRule}
                        issueType={issueType.SILENT}
                        notificationTypes={[notificationType.SILENT_UNIT_WARNING, notificationType.SILENT_UNIT_ESCALATION, notificationType.SILENT_UNIT_RESOLVED]}
                        warningRule={this.getRule(notificationType.SILENT_UNIT_WARNING, issueType.SILENT)}
                        escalationRule={this.getRule(notificationType.SILENT_UNIT_ESCALATION, issueType.SILENT)}
                        resolvedRule={this.getRule(notificationType.SILENT_UNIT_RESOLVED, issueType.SILENT)}
                        contactGroups={this.props.contactGroups}
                        issueTypeRule={this.getIssueTypeRule(issueType.SILENT)}
                    />
                </ExpandableCard>
                <ExpandableCard title="Inactive Trailers" expand={this.state.expand}>
                    <NotificationToggles
                        addRule={this.props.addRule}
                        updateRule={this.props.updateRule}
                        issueType={issueType.INACTIVITY}
                        notificationTypes={[notificationType.INACTIVITY_WARNING, null, notificationType.INACTIVITY_RESOLVED]}
                        warningRule={this.getRule(notificationType.INACTIVITY_WARNING, issueType.INACTIVITY)}
                        noEscalation
                        resolvedRule={this.getRule(notificationType.INACTIVITY_RESOLVED, issueType.INACTIVITY)}
                        contactGroups={this.props.contactGroups}
                        issueTypeRule={this.getIssueTypeRule(issueType.INACTIVITY)}
                    />
                </ExpandableCard>
                <ExpandableCard title="Fuel Level" expand={this.state.expand}>
                    <NotificationToggles
                        addRule={this.props.addRule}
                        updateRule={this.props.updateRule}
                        issueType={issueType.LOW_FUEL_ALARM}
                        notificationTypes={[notificationType.LOW_FUEL_WARNING, notificationType.LOW_FUEL_ESCALATION, notificationType.LOW_FUEL_RESOLVED]}
                        warningRule={this.getRule(notificationType.LOW_FUEL_WARNING, issueType.LOW_FUEL_ALARM)}
                        escalationRule={this.getRule(notificationType.LOW_FUEL_ESCALATION, issueType.LOW_FUEL_ALARM)}
                        resolvedRule={this.getRule(notificationType.LOW_FUEL_RESOLVED, issueType.LOW_FUEL_ALARM)}
                        contactGroups={this.props.contactGroups}
                        issueTypeRule={this.getIssueTypeRule(issueType.LOW_FUEL_ALARM)}
                    />
                </ExpandableCard>
                <ExpandableCard title="Alarms" expand={this.state.expand}>
                    <NotificationToggles
                        addRule={this.props.addRule}
                        updateRule={this.props.updateRule}
                        issueType={issueType.ALARM}
                        notificationTypes={[notificationType.MAINTENANCE, null, null]}
                        warningRule={this.getRule(notificationType.MAINTENANCE, issueType.ALARM)}
                        noEscalation
                        noResolved
                        contactGroups={this.props.contactGroups}
                    />
                </ExpandableCard>

                <ManageEmailUpdatesPopup 
                    show={ this.state.showManageEmailUpdatesModal } 
                    onClose={ this.onManageEmailUpdateModalClose }  
                    notificationType={ notificationType.STATISTICS_SUMMARY }
                    data={ this.state.statisticsSummary }
                />
            </div>
        )
    }
}

export default NotificationsManagement;
