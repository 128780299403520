import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { getShortDayName, getPmTime } from 'global/services/DateTimeService';
import ExpandableCard from 'components/card/ExpandableCard';
import GroupMembers from './GroupMembers';
import AddGroupPopup from 'components/popup/AddGroupPopup';
import DeletePopup from 'components/popup/DeletePopup';
import { contactGroupsUrl } from 'global/constants';

import "./EmailRecipientGroups.scss";

class EmailRecipientGroups extends Component {

    constructor(props) {
        super(props);
        this.state = {
            expand: false,
            showAddPopup: false,
            showDeletePopup: false,
            edit: false,
            group: null,
        }
    }

    expandClick() {
        this.setState({
            expand: !this.state.expand
        })
    }

    showAddPopup = (edit, group) => {
        this.setState({
            showAddPopup: true,
            edit,
            group,
        });
    }

    closeAddPopup(group, edit) {
        this.setState({
            showAddPopup: false
        });
        if (group) {
            if (edit) {
                this.props.updateContactGroup(group);
            } else {
                this.props.addContactGroup(group);
            }
        }
    }

    showDeletePopup = (group) => {
        this.setState({
            showDeletePopup: true,
            group,
        });
    }

    closeDeletePopup(isDeleted, params) {
        this.setState({
            showDeletePopup: false
        });
        if (isDeleted) {
            this.props.deleteContactGroup(params.id);
        }
    }

    getHoursOfOperation(officeHours) {
        if (officeHours) {
            return `${getShortDayName(officeHours.startDay)} - ${getShortDayName(officeHours.endDay)} ${getPmTime(officeHours.startTime)} - ${getPmTime(officeHours.endTime)}`;
        } else {
            return "24/7";
        }
    }

    render() {
        return (
            <div className="email-recipient-groups">
                <div className="d-flex justify-content-between align-items-center">
                    <div className="header">
                        Email Recipient Groups
                    </div>
                    <div className="d-flex align-items-center">
                        <Button variant="continue" onClick={() => this.showAddPopup(false, null)}>
                            <FontAwesomeIcon icon={faPlusCircle} className="add-icon" />
                            ADD NEW GROUP
                        </Button>
                        <div className="expand" onClick={this.expandClick.bind(this)}>
                            Expand All
                            {this.state.expand ?
                                <FontAwesomeIcon icon={faChevronUp} className="expand-arrow" /> :
                                <FontAwesomeIcon icon={faChevronDown} className="expand-arrow" />
                            }
                        </div>
                    </div>
                </div>
                {this.props.contactGroups.map(contactGroup => (
                    <ExpandableCard
                        key={contactGroup.name}
                        title={contactGroup.name}
                        columns={ [
                            {
                                label: 'Hours of Operation',
                                value: this.getHoursOfOperation(contactGroup.officeHours)
                            },
                            {
                                label: 'Fleet',
                                value: contactGroup.fleet ? contactGroup.fleet.name : 'All Fleets'
                            }
                        ] }
                        expand={this.state.expand}
                        handleEdit={() => this.showAddPopup(true, contactGroup)}
                        handleDelete={() => this.showDeletePopup(contactGroup)}
                    >
                        <GroupMembers contactGroup={contactGroup} members={contactGroup.contactInfos} addMember={this.props.addMember} updateContactGroup={this.props.updateContactGroup} />
                    </ExpandableCard>
                ))}
                <AddGroupPopup closeModal={this.closeAddPopup.bind(this)} showModal={this.state.showAddPopup} edit={this.state.edit} group={this.state.group} />
                <DeletePopup
                    onClose={this.closeDeletePopup.bind(this)}
                    show={this.state.showDeletePopup}
                    url={contactGroupsUrl}
                    params={this.state.group && { id: this.state.group.id }}
                    title="GROUP"
                    subtitle="Delete Group?"
                >
                    Are you sure you want to delete group <span className="bold">“{this.state.group && this.state.group.name}”</span>?
                </DeletePopup>
            </div>
        )
    }
}

export default EmailRecipientGroups;
