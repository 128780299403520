import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import Message from 'components/Message';
import SimpleContainer from 'components/container/SimpleContainer';
import ConfirmMessageContainer from 'components/container/ConfirmMessageContainer';
import ErrorMessageContainer from 'components/container/ErrorMessageContainer';
import { removeTrailerUrl } from 'global/constants';
import AuthContext from 'AuthContext';

import './ModifyTrailerPopup.scss';

export default class RemoveTrailerPopup extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            requestSucceeded: null
        };

        this.onClose = this.onClose.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    static defaultProps = {
        manifest: null,
        trailer: null
    }

    handleSubmit() {
        this.context.delete(`${removeTrailerUrl}/${this.props.trailer.businessId}`)
            .then(response => this.setState({ requestSucceeded: (response || {}).status !== 'error' }))
            .catch(error => console.error(error));
    }

    onClose() {
        const isRemoved = this.state.requestSucceeded;

        this.setState({
            requestSucceeded: null
        }, () => this.props.onClose(isRemoved));
    }

    render() {
        let modalContent;

        if (this.state.requestSucceeded && this.props.trailer) {
            const description = (
                <>
                    Trailer <b>{ this.props.trailer.businessId }</b> has been successfully removed from your fleet.
                </>
            );

            modalContent = (
                <ConfirmMessageContainer title="Trailer removed" description={ description } onClose={ this.onClose } />
            );
        } else if (this.state.requestSucceeded === false) {
            modalContent = (
                <ErrorMessageContainer onClose={ this.onClose } />
            );
        } else if (this.props.trailer) {
            let warningMessage;

            if (this.props.hasRelevantIssues && (this.props.manifest || {}).active) {
                warningMessage = 'This trailer has an active manifest and relevant issues. If you remove this trailer, the manifest will automatically be set to Inactive and the issues will become irrelevant.';
            } else if ((this.props.manifest || {}).active) {
                warningMessage = 'This trailer has an active manifest. If you remove this trailer, the manifest will automatically be set to Inactive.';
            } else if (this.props.hasRelevantIssues) {
                warningMessage = 'This trailer has relevant issues. Issues will become irrelevant as soon as you remove the trailer.';
            }

            let trailerDetailsLink = `/details/${this.props.trailer.id}`;
            if (this.props.manifest) {
                trailerDetailsLink += '/' + this.props.manifest.id;
            }

            modalContent = (
                <SimpleContainer className="modal-container" modal title="Remove Trailer">
                    { warningMessage && (
                        <Message type="warning" size="small">
                            { warningMessage }
                        </Message>
                    )}
                    <p className="modal-text">
                        Are you sure you want to remove Trailer&nbsp;
                        <Link to={ trailerDetailsLink }>
                            { this.props.trailer.businessId }
                        </Link>
                        ?
                    </p>
                    <div className="buttons">
                        <Button variant="light" className="cancel-button" onClick={ this.onClose }>
                            Cancel
                        </Button>
                        <Button variant="danger" className="submit-button" onClick={ this.handleSubmit }>
                            Remove
                        </Button>
                    </div>
                </SimpleContainer>
            );
        }

        return (
            <Modal
                show={ this.props.show }
                onHide={ this.onClose }
                backdrop="static"
                centered
                dialogClassName="modify-trailer-modal"
            >
                { modalContent }
            </Modal>
        );
    }
}
