import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { usersUrl } from 'global/constants';
import ConfirmMessageContainer from 'components/container/ConfirmMessageContainer';
import ErrorMessageContainer from 'components/container/ErrorMessageContainer';
import SimpleContainer from 'components/container/SimpleContainer';
import AuthContext from 'AuthContext';

import './ReinviteUserPopup.scss';

export default class ReinviteUserPopup extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.initialState = {
            requestSucceeded: null,
            loading: false
        };

        this.state = { ...this.initialState };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            this.setState(this.initialState);
        }
    }

    handleSubmit() {
        this.setState({ loading: true });

        this.context.post(`${usersUrl}/${this.props.user.id}/reinvite`)
            .then(response => {
                const requestSucceeded = response.status !== 'error';
                this.setState({
                    requestSucceeded,
                    errorTitle: requestSucceeded ? '' : response.message.response.data.error,
                    errorDescription: requestSucceeded ? '' : response.message.response.data.message
                });
            })
            .catch(error => {
                console.error(error);
                this.setState({ requestSucceeded: false });
            })
            .finally(() => this.setState({ loading: false }));
    }

    render() {
        let modalContent;

        if (this.state.requestSucceeded) {
            modalContent = (
                <ConfirmMessageContainer
                    title="Invite sent"
                    description={
                        (
                            <>
                                User <span className="bold">{this.props.user.name}</span> has been successfully
                                reinvited.
                            </>
                        )
                    }
                    onClose={ () => this.props.onClose() }
                />
            );
        } else if (this.state.requestSucceeded === false) {
            let props = {};
            if (this.state.errorTitle) {
                props = { title: this.state.errorTitle, description: this.state.errorDescription };
            }

            modalContent = (
                <ErrorMessageContainer { ...props } onClose={ () => this.props.onClose(false) } />
            );
        } else {
            modalContent = (
                <SimpleContainer className="modal-container" modal title="REINVITE USER">
                    <p>
                        Are you sure you want to resend the invitation for user <span className="bold">{ this.props.user.name }</span>?
                    </p>
                    <div className="buttons">
                        <Button
                            disabled={ this.state.loading }
                            variant="light"
                            className="cancel-button"
                            onClick={ () => this.props.onClose(false) }
                        >
                            CANCEL
                        </Button>
                        <Button
                            disabled={ this.state.loading }
                            variant="continue"
                            className="submit-button"
                            onClick={ this.handleSubmit }
                        >
                            REINVITE
                        </Button>
                    </div>
                </SimpleContainer>
            );
        }

        return (
            <Modal
                show={ this.props.show }
                onHide={ () => this.props.onClose(false) }
                backdrop="static"
                keyboard={ false }
                centered
                dialogClassName="reinvite-user-modal"
            >
                { modalContent }
            </Modal>
        );
    }
}
