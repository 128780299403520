import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import history from 'global/history'
import Select from 'react-select'

import './SearchBar.scss';

const options = [
    { value: 'legs', label: 'Manifest' },
    { value: 'trailers', label: 'Trailer' },
  ]
export default class SearchBar extends Component {

    state = {
        value: "",
        selectedSearchItem: options[0],
        searchBarPlaceholder: this.props.maintenance ? "Search for trailer or alarm code" : "Search for manifest or trailer",
        maintenance: this.props.maintenance
    }

    onChange = event => {
        event.preventDefault();
        this.setState({ value: event.target.value });
    }

    onClearButtonClick = event => {
        event.preventDefault();
        this.setState({
            value: ""
        });
    }

    onSubmit = event => {
        event.preventDefault();
        if(this.state.value && !this.state.maintenance) {
            this.routeChange("/orders/search", { contains: this.state.value, searchBy: this.state.selectedSearchItem.value });
        } else if (this.state.value) {
            this.routeChange("/issues/alarm", { contains: this.state.value });
        }
    }

    routeChange = (pathname, state) => {
        history.push({
          pathname: pathname,
          state: state,
        });
    };

    toggleDropdown() {
        this.setState(state => ({ showDropdown: !state.showDropdown }));
    }

    render() {
        const customStyles = {
            container: (provided, state) => ({
                ...provided,
                minHeight: 20,
                maxHeight: 32,
                width: 91
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                minHeight: 20,
                maxHeight: 32,
                width: 91,
                position: "absolut"
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                minHeight: 20,
                maxHeight: 32,
                width: 30
            }),
            control: (provided, state) => ({
                ...provided,
                border: '1px solid #D0D2DD',
                boxShadow: 'none',
                '&:hover': {
                    border: '1px solid #D0D2DD',
                },
                minHeight: 20,
                maxHeight: 32,
                width: 91
            }),
            menu: (provided, state) => ({
                ...provided,

            }),
            option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
                ...provided,
                backgroundColor: isFocused || isSelected ? '#F4F5FA' :  "white",
                color: "black",
                maxHeight: 32,
                paddingTop: 8,
                paddingLeft: 10,
                marginBottom: 10,
                paddingBottom: 32,
                height: 32,
                minHeight: 32,
                textAlign: "left"
            })
          }
        return (
            <form onSubmit={this.onSubmit} onKeyPress={this.onKey} className="search-bar">
                <input
                    id="main-search-bar"
                    type="text"
                    data-items="4"
                    value={this.state.value}
                    placeholder={this.state.searchBarPlaceholder || "Search"}
                    onChange={this.onChange} />
                <div className="search-buttons">
                    {this.state.value !== "" && <><button className="btn clear-button" type="button" onClick={this.onClearButtonClick.bind(this)}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </button>
                    <span className="vertical-line"></span></>
                    }
                    <button className={`btn btnTransparent search-button ${this.state.value !== "" ? "active" : ""}`} type="submit" onClick={this.onSubmit.bind(this)}>
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                    {!this.props.maintenance && <><div className="by-div">
                        In:
                    </div>
                    <div className="select-div">
                        <Select options={options} styles={customStyles} value={this.state.selectedSearchItem} onChange={e => {
                            this.setState({
                                selectedSearchItem: e,
                                searchBarPlaceholder: e.value === "trailers" ? "Search for trailer" : "Search for manifest or trailer"
                            });
                        }}/>
                    </div>
                    </>}
                </div>
            </form>
        );
    }
}
