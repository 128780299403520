import React, { Component } from 'react';
import { DatePicker, Select } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown as downArrow } from '@fortawesome/free-solid-svg-icons';
import { MONITORED_ISSUE_TYPES } from 'global/services/IssueTypeService';
import {
    formatDate,
    getCurrentDate,
    getRange,
    getMonthsBefore,
    formatDateTimeToTimestamp
} from 'global/services/DateTimeService';
import QueryParamService from 'global/services/QueryParamService';

import 'antd/dist/antd.css';
import './StatsFilter.scss';

const { Option } = Select;

export default class StatsFilter extends Component {

    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();

        QueryParamService.addValueToQueryString(window.location.search.slice(1), "issueTypes", this.props.filter.issueTypes);
        QueryParamService.addValueToQueryString(window.location.search.slice(1), "startDate", formatDateTimeToTimestamp(this.props.filter.startTime));
        QueryParamService.addValueToQueryString(window.location.search.slice(1), "endDate", formatDateTimeToTimestamp(this.props.filter.endTime));

        this.state = {
            selectedTypes: this.setTypeSelected(props.filter.issueTypes.length > 0 ? props.filter.issueTypes : ['ALL']),
            selectedStartDate: props.filter.startTime || getCurrentDate(getMonthsBefore(getCurrentDate(), 1)),
            selectedEndDate: props.filter.endTime || getCurrentDate(),
            loading: false
        };

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onStartDateChange = this.onStartDateChange.bind(this);
        this.onEndDateChange = this.onEndDateChange.bind(this);
        this.onTypeSelectChange = this.onTypeSelectChange.bind(this);
        this.toggleTypeDropdown = this.toggleTypeDropdown.bind(this);
        this.handleUserChange = this.handleUserChange.bind(this);
        this.onScroll = this.onScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleClickOutside);
    }

    componentDidUpdate(prevProps) {
        if (this.props.filter.issueTypes !== prevProps.filter.issueTypes) {
            this.setState({
                selectedTypes: this.setTypeSelected(this.props.filter.issueTypes && this.props.filter.issueTypes.length > 0 ? this.props.filter.issueTypes : ['ALL'])
            });
        }

        if (this.props.users !== prevProps.users) {
            this.setState({ loading: false });
        }
    }

    setTypeSelected(types) {
        const selectedTypes = [];
        types.forEach(type => selectedTypes[type] = true);
        return selectedTypes;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
            this.setState({ showTypeDropdown: false });
        }
    }

    onStartDateChange(date) {
        let didChange = true;
        this.setState(prevState => {
            if (prevState.selectedStartDate.isSame(date, 'minute')) {
                didChange = false;
                return {};
            }

            return { selectedStartDate: date ? date : getMonthsBefore(getCurrentDate(), 1), hasStartDateChanged: true };
        }, () => didChange && this.handleStateChange());
    }

    onEndDateChange(date) {
        let didChange = true;
        this.setState(prevState => {
            if (prevState.selectedEndDate.isSame(date, 'minute')) {
                didChange = false;
                return {};
            }

            return { selectedEndDate: date ? date : getCurrentDate(), hasEndDateChanged: true };
        }, () => didChange && this.handleStateChange());
    }

    onTypeSelectChange(event) {
        const target = event.target;
        let selectedTypes = this.state.selectedTypes;

        selectedTypes[target.name] = target.checked;
        if (selectedTypes['ALL'] && target.name !== 'ALL') {
            selectedTypes['ALL'] = false;
        } else if (target.name === 'ALL' && target.checked) {
            selectedTypes = [];
            selectedTypes['ALL'] = true;
        }

        this.setState({ selectedTypes }, this.handleStateChange);
    }

    toggleTypeDropdown() {
        this.setState(prevState => ({ showTypeDropdown: !prevState.showTypeDropdown, showUserDropdown: false }));
    }

    isTypeMultiSelection() {
        return this.getTypeSelection().length > 1;
    }

    getTypeSelection() {
        return Object.entries(this.state.selectedTypes)
            .filter(([_, value]) => value)
            .map(([key, _]) => key);
    }

    handleUserChange(value) {
        if (value !== undefined) {
            this.props.onUserSelect(JSON.parse(value));
        } else {
            this.props.onUserSelect('')
        }
    }

    async onSearchChange(search) {
        if (!this.timer) {
            this.timer = setTimeout(() => {
                this.props.searchUser(search);
                this.timer = null;
            }, 300);
        }
    }

    onScroll(event) {
        const target = event.target;
        if (this.props.hasMoreUsers && !this.state.loading && target.scrollTop + target.offsetHeight === target.scrollHeight) {
            this.setState({ loading: true }, () => {
                target.scrollTo(0, target.scrollHeight);
                this.props.fetchMoreUsers();
            });
        }
    }

    handleStateChange() {
        this.props.onFilterChange(this.getTypeSelection(), this.state.selectedStartDate, this.state.selectedEndDate);

        QueryParamService.addValueToQueryString(window.location.search.slice(1), "issueTypes", this.getTypeSelection());
        QueryParamService.addValueToQueryString(window.location.search.slice(1), "startDate", formatDateTimeToTimestamp(this.state.selectedStartDate));
        QueryParamService.addValueToQueryString(window.location.search.slice(1), "endDate", formatDateTimeToTimestamp(this.state.selectedEndDate));
    }

    render() {
        const allTypes = {
            ALL: 'Select all',
            ...MONITORED_ISSUE_TYPES
        };

        const issueTypeItems = [];
        for (const [key, value] of Object.entries(allTypes)) {
            if (key !== "DEFAULT") {
                issueTypeItems.push(
                    <li className="issue-type-picker-item" key={ 'type-picker-' + key }>
                        <input
                            type="checkbox"
                            id={ 'type-picker-' + key }
                            name={ key }
                            checked={ !!this.state.selectedTypes[key] }
                            onChange={ this.onTypeSelectChange }
                        />
                        <label className={`${this.state.selectedTypes[key] && 'selected-item'}`} htmlFor={ 'type-picker-' + key }>
                            <span>&nbsp;&nbsp;</span>{ value }
                        </label>
                    </li>
                );
            }
        }

        let typeLabel;
        if (this.isTypeMultiSelection()) {
            typeLabel = (
                <span className="multiple-types">
                    <span>Multiple</span>
                    <FontAwesomeIcon className="arrow-icon" icon={ downArrow } />
                </span>
            );
        } else {
            const selected = this.getTypeSelection()[0];
            const type = allTypes[selected];
            typeLabel = (
                <span className="multiple-types">
                    { type && type !== 'Select all' ? <span>{ type }</span> : <span className="not-selected">All issues</span> }
                    <FontAwesomeIcon className="arrow-icon" icon={ downArrow } />
                </span>
            );
        }

        const startDateChangedClassName = this.state.hasStartDateChanged ? 'bold-placeholder' : '';
        const endDateChangedClassName = this.state.hasEndDateChanged ? 'bold-placeholder' : '';

        const startDateClassName = this.props.filter.startTime.isSame(getMonthsBefore(getCurrentDate(), 1)) ? 'picker' : 'not-selected picker ' + startDateChangedClassName;
        const endDateClassName = this.props.filter.endTime.isSame(getCurrentDate(getCurrentDate())) ? 'picker' : 'not-selected picker ' + endDateChangedClassName;

        return (
            <form className={ "filter-form" + (this.props.className ? ` ${this.props.className}` : "") }>
                <div>
                    <label htmlFor="start-date">Start Date</label>
                    <DatePicker
                        className={ startDateClassName }
                        allowClear
                        showNow={ false }
                        onChange={ this.onStartDateChange }
                        onSelect={ this.onStartDateChange }
                        showTime={ { format: 'HH:mm' } }
                        format="YYYY-MM-DD HH:mm"
                        value={ this.props.filter.startTime }
                        disabledDate={ d => !d || d.isSameOrAfter(this.state.selectedEndDate, 'minute') }
                        disabledTime={ d => {
                            const endDate = this.state.selectedEndDate;
                            if (!d || d.isSame(endDate, 'day')) {
                                return {
                                    disabledHours: () => getRange(endDate.hour() + 1, 24),
                                    disabledMinutes: hour => hour === endDate.hour() ? getRange(endDate.minutes(), 60) : []
                                };
                            }
                        } }
                        placeholder={ formatDate(getCurrentDate()) }
                    />
                </div>
                <div>
                    <label htmlFor="end-date">End date</label>
                    <DatePicker
                        className={ endDateClassName }
                        allowClear
                        showNow={ false }
                        onChange={ this.onEndDateChange }
                        onSelect={ this.onEndDateChange }
                        showTime={ { format: 'HH:mm' } }
                        format="YYYY-MM-DD HH:mm"
                        value={ this.props.filter.endTime }
                        disabledDate={ d => !d || d.isAfter(getCurrentDate()) || d.isBefore(this.state.selectedStartDate) }
                        disabledTime={ d => {
                            const startDate = this.state.selectedStartDate;
                            if (!d || d.isSame(startDate, 'day')) {
                                return {
                                    disabledHours: () => getRange(0, Math.max(startDate.hour() - 1, 0)),
                                    disabledMinutes: hour => hour === startDate.hour() ? getRange(0, startDate.minutes()) : []
                                };
                            }
                        } }
                        placeholder={ formatDate(getCurrentDate()) }
                    />
                </div>
                <div className="d-flex" ref={ this.wrapperRef }>
                    <div className="issue-type-select-div">
                        <label htmlFor="issue-type-select">Issue type</label>
                        <div className="picker issue-type-select-picker" id="type-select">
                            <div className="picker-label" onClick={ this.toggleTypeDropdown }>
                                { typeLabel }
                            </div>
                            <ul className={ 'issue-type-drop-menu ' + (this.state.showTypeDropdown ? 'shown' : 'hidden') }>
                                { issueTypeItems }
                            </ul>
                        </div>
                    </div>
                    { this.props.filterUser && (
                        <div className="issue-type-select-div">
                            <label htmlFor="user-select">User</label>
                            <Select
                                suffixIcon={ <FontAwesomeIcon className="arrow-icon" icon={ downArrow } /> }
                                showSearch
                                onSearch={ search => this.onSearchChange(search) }
                                placeholder={ this.props.filterUser || 'Select user' }
                                value={ this.state.value }
                                className="picker picker-label"
                                onChange={ this.handleUserChange }
                                onPopupScroll={ this.onScroll }
                                filterOption={ false }
                                allowClear={ this.props.allowClear }
                            >
                                { this.props.users.map(user => (
                                    <Option key={ user.id } value={ JSON.stringify(user) }>
                                        { user.name }
                                    </Option>
                                )) }
                                { this.state.loading && (
                                    <Option key="loading" disabled value={ null }>Loading...</Option>
                                ) }
                            </Select>
                        </div>
                    ) }
                </div>
            </form>
        );
    }
}
