import React, { Component } from 'react';

import "./ActiveSelection.scss"

export default class ActiveSelection extends Component {

    static defaultProps = {
        initiallyActive: true,
        activeCallback: () => {/* */},
        disabled: false,
        relevantLabel: null,
        irrelevantLabel: null
    }

    constructor(props) {
        super(props);

        this.state = {
            activeSelected: this.props.initiallyActive,
        }

        this.activeToggle.bind(this);
        this.inactiveToggle.bind(this);
    }

    activeToggle() {
        this.setState({
            activeSelected: true
        }, () => {
            this.props.activeCallback && this.props.activeCallback(true);
        });
    }

    inactiveToggle() {
        this.setState({
            activeSelected: false
        }, () => {
            this.props.activeCallback && this.props.activeCallback(false);
        });
    }

    render() {
        return (
            <div className="active-selection">
                <div className={`info-label ${this.props.disabled ? "disabled" : ""}`}>Displaying: </div>
                <div className="buttons">
                    <button disabled={this.props.disabled} id="relevant" className={`btn left ${this.state.activeSelected ? "active" : "inactive"}`} onClick={this.activeToggle.bind(this)}>
                        { this.props.relevantLabel || "Relevant issues" }
                    </button>
                    <button disabled={this.props.disabled} id="irrelevant" className={`btn right ${!this.state.activeSelected ? "active" : "inactive"}`} onClick={this.inactiveToggle.bind(this)}>
                        { this.props.irrelevantLabel || "Irrelevant issues" }
                    </button>
                </div>
            </div>
        );
    }
}
