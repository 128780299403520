import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import SimpleContainer from 'components/container/SimpleContainer';
import Message from 'components/Message';
import ConfirmMessageContainer from 'components/container/ConfirmMessageContainer';
import ErrorMessageContainer from 'components/container/ErrorMessageContainer';
import FleetSelect from 'components/input/FleetSelect';
import CustomFileInput from 'components/input/CustomFileInput';
import CustomSelect from 'components/input/CustomSelect';
import SimpleTooltip from 'components/tooltip/SimpleTooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle as info } from '@fortawesome/pro-solid-svg-icons';

import { isTrackedTrailerUrl, isTrailerSerialNumberTrackedUrl, addTrailerUrl, editTrailerUrl, inputTimeoutDelay, addTrailerCSVUrl, downloadCsvTemplateUrl } from 'global/constants';

import AuthContext from 'AuthContext';

import './AddOrEditTrailerPopup.scss';

const DELIMITER_VALUES = [
    {
        "title": ',',
        "label": 'Comma ","'
    },
    {
        "title": ';',
        "label": 'Semicolon ";"'
    },
    {
        "title": ':',
        "label": 'Colon ":"'
    },
    {
        "title": '\t',
        "label": 'Tab "\\t"'
    },
    {
        "title": ' ',
        "label": 'Space " "'
    },
    {
        "title": '/',
        "label": 'Slash "/"'
    },
    {
        "title": '|',
        "label": 'Pipe "|"'
    },
    {
        "title": '#',
        "label": 'Octothorp "#"'
    }
];

export default class AddOrEditTrailerPopup extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = { 
            initialTrailerId: '',
            trailerId: '',
            trailerSerialNumber: '',
            fleetName: '',
            isTrailerTracked: null,
            isTrailerSerialNumberTracked: null,
            idChanged: null,
            uploadType: "",
            file: null,
            wrongFileType: null,
            delimiter: "",
            requestSucceeded: null
         };

        this.timerId = null;
        this.timerSerial = null;

        this.handleInputChange = this.handleInputChange.bind(this);
        this.onFleetChange = this.onFleetChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.isButtonDisabled = this.isButtonDisabled.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
        this.onSelectedFileChange = this.onSelectedFileChange.bind(this);
        this.onDelimiterChange = this.onDelimiterChange.bind(this);
        this.downloadTemplate = this.downloadTemplate.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            const trailerId = this.props.trailer ? this.props.trailer.businessId : '';
            const serialNumber = (this.props.trailer || {}).serialNumber;
            this.setState({
                initialTrailerId: trailerId,
                trailerId: trailerId,
                trailerSerialNumber: serialNumber ? this.props.trailer.serialNumber : '',
                fleetName: (this.props.trailer || {}).fleet ? (this.props.trailer || {}).fleet.name : '',
                isTrailerTracked: trailerId ? false : null,
                isTrailerSerialNumberTracked: serialNumber ? false : null,
                uploadType: "manual",
                delimiter: ",",
            });
        }

        if (this.state.initialTrailerId !== this.state.trailerId && !this.state.idChanged) {
            this.setState({
                idChanged: true
            });
        }
    }

    onClose() {
        const didAdd = this.state.requestSucceeded;

        this.setState({
            initialTrailerId: '',
            trailerId: '',
            trailerSerialNumber: '',
            fleetName: '',
            isTrailerTracked: null,
            isTrailerSerialNumberTracked: null,
            idChanged: null,
            file: null,
            wrongFileType: null,
            delimiter: "",
            requestSucceeded: null
        }, () => this.props.onClose(didAdd));
    }

    async handleSubmit() {
        try {
            const payload = { trailerBusinessId: this.state.trailerId, 
                trailerSerialNumber: this.state.trailerSerialNumber, 
                fleetName: this.state.fleetName };

            let response;

            if (this.state.uploadType === 'file') {
                const formData = new FormData();
                formData.append(
                    "file",
                    this.state.file
                )
                response = await this.context.post(addTrailerCSVUrl, formData, { delimiter: this.state.delimiter});
            } else if (this.props.type === 'add' || this.props.type === 'restore') {
                response = await this.context.post(addTrailerUrl, payload);
            } else {
                response = await this.context.put(`${editTrailerUrl}/${this.state.initialTrailerId}`, payload);
            }

            if (response.status === 'error') {
                this.setState({
                    requestSucceeded: false
                });
            } else {
                this.setState({
                    requestSucceeded: true,
                    numberOfAddedTrailers: response
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    validInput(input) {
        return /^[A-Za-z0-9_-]+$/.test(input);
    }

    handleInputChange(event) {
        const input = event.target.value;
        const name = event.target.name;
        const isTrackedName = name === 'trailerId' ? 'isTrailerTracked' : 'isTrailerSerialNumberTracked';
        const isTracked = this.state[isTrackedName];

        if (input === '' || this.validInput(input)) {
            this.setState({
                [name]: input
            });
        } else {
            return;
        }

        if (isTracked !== null) {
            this.setState({
                [isTrackedName]: null
            });
        }

        if (name === "trailerId") {
            clearTimeout(this.timerId);
            this.timerId = setTimeout(() => {
                this.isIdTracked(input, isTrackedName, isTrackedTrailerUrl)
            }, inputTimeoutDelay);
        } else {
            clearTimeout(this.timerSerial);
            this.timerSerial = setTimeout(() => {
                this.isIdTracked(input, isTrackedName, isTrailerSerialNumberTrackedUrl)
            }, inputTimeoutDelay);
        }
    }

    async isIdTracked(id, name, url) {
        if (!id) {
            return;
        }

        if (id === this.props.trailer?.[name === 'isTrailerTracked' ? 'businessId' : 'serialNumber']) {
            this.setState({
                [name]: false
            });
            return;
        }

        try {
            const response = await this.context.get(url + '/' + id);

            if (typeof response !== 'boolean') {
                this.setState({
                    requestSucceeded: false
                });
            }

            this.setState({
                [name]: response
            });
        } catch (error) {
            console.error(error);
        }
    }

    onFleetChange(option) {
        this.setState({
            fleetName: (option.value || {}).name
        });
    }

    onDelimiterChange(delimiter) {
        this.setState({ delimiter });
    }

    isButtonDisabled() {
        if (this.state.uploadType === 'file') {
            return this.state.wrongFileType !== false || !this.state.file;
        } else if (this.props.type === 'add') {
            return this.state.isTrailerTracked !== false || this.state.isTrailerSerialNumberTracked !== false;
        } else if (this.props.type === 'edit') {
            /* If we open popup in Edit mode it needs to check if any of the existing info has changed so it can enable the submit button */
            return this.state.isTrailerTracked !== false || this.state.isTrailerSerialNumberTracked !== false 
            || (this.state.trailerId === this.state.initialTrailerId && this.state.trailerSerialNumber === this.props.trailer.serialNumber 
            && ((this.props.trailer || {}).fleet ? this.props.trailer.fleet.name === this.state.fleetName : this.state.fleetName === ""));
        } else if (this.props.type === 'restore') {
            return this.state.isTrailerSerialNumberTracked !== false;
        }
    }

    onValueChange(event) {
        this.setState({
            uploadType: event.target.value
        })
    }

    onSelectedFileChange(file) {
        const FILE_SIZE_LIMIT = 2097152;
        if (file && (file.type !== "text/csv" || file.size > FILE_SIZE_LIMIT)) {
            this.setState({
                file: file,
                wrongFileType: true
            })
        } else {
            this.setState({
                file: file,
                wrongFileType: false
            })
        }
    }

    async downloadTemplate() {
        await this.context.download(downloadCsvTemplateUrl, { delimiter: this.state.delimiter});
    }

    getAddOrEditPopupProps() {
        let title;
        let trailerIdMesage;
        let serialNumberMessage;
        let modalDescription;
        let defaultFleetOption;
        let trailerIdSectionClassName = "trailer-box";
        let trailerSerialNumberSectionClassName = "trailer-box";

        if (this.props.trailer) {
            if (this.props.trailer.fleet) {
                defaultFleetOption = {
                    label: this.props.trailer.fleet.name,
                    value: this.props.trailer.fleet
                };
            } else {
                defaultFleetOption = {
                    value: null,
                    label: 'None'
                };
            }

            if (!this.state.trailerSerialNumber) {
                serialNumberMessage = (
                    <div className="message-content">
                        <p className="error-message">Serial Number field cannot be empty.</p>
                    </div>
                );
            }

            if (!this.state.trailerId){
                trailerIdMesage = (
                    <div className="message-content">
                        <p className="error-message">Trailer Id field cannot be empty.</p>
                    </div>
                );
            }
        }

        if (this.state.isTrailerTracked) {
            trailerIdSectionClassName += " error";

            trailerIdMesage = (
                <div className="message-content">
                    <p className="error-message">Trailer is already in your fleet.</p>
                </div>
            );
        }

        if (this.state.isTrailerSerialNumberTracked) {
            trailerSerialNumberSectionClassName += " error";

            serialNumberMessage = (
                <div className="message-content">
                    <p className="error-message">Serial number is already being used by some other trailer.</p>
                </div>
            );
        }

        switch (this.props.type) {
            case 'add':
                title = "Add Trailer";

                modalDescription = (
                    <>
                        <p className="description">
                            In order to add trailers to your fleet, you need to enter the Trailer Details <b>manually</b> or <b>upload</b> a valid .csv file.
                        </p>
                        <div className="description upload-radio" onChange={ this.onValueChange }>
                            <div className="radio-group">
                                <input type="radio" id="manual" defaultChecked={ true } name="type" value="manual" />
                                <label htmlFor="manual">Add Manually</label>
                            </div>
                            <div className="radio-group">
                                <input type="radio" id="file" name="type" value="file" />
                                <label htmlFor="file">Upload File</label>
                            </div>
                        </div>
                    </>
                );
                break;
            case 'edit':
                title = "Edit Trailer";

                if (this.state.idChanged) {
                    modalDescription = (
                        <Message type="info" size="small">
                            <p className="description">
                                Changing this trailer's ID means it will be moved to the Removed Trailers section with the old ID and the new trailer will be added to your fleet.
                            </p>
                        </Message>
                    );
                }
                break;
            case 'restore':
                title = "Restore Trailer"
                trailerIdSectionClassName += " disabled";

                modalDescription = (
                    <p className="description">
                        Please confirm you wish to restore the Trailer with the following details:
                    </p>
                );
                
                if ((this.props.trailer || {}).serialNumber) {
                    trailerSerialNumberSectionClassName += " disabled";
                }
                break;
            default:
                title = "";
                trailerIdMesage = "";
                serialNumberMessage = "";
        }

        return { title, trailerIdMesage, serialNumberMessage, modalDescription, defaultFleetOption, trailerIdSectionClassName, trailerSerialNumberSectionClassName };
    }

    render() {
        let { title, trailerIdMesage, serialNumberMessage, modalDescription, defaultFleetOption, trailerIdSectionClassName, trailerSerialNumberSectionClassName  } = this.getAddOrEditPopupProps();
        const { trailerId, trailerSerialNumber } = this.state;

        const buttons = (
            <div className="buttons">
                <Button variant="light" onClick={ this.onClose } className="cancel-button">
                    Cancel
                </Button>
                <Button
                    disabled={ this.isButtonDisabled() }
                    variant="continue"
                    className="submit-button"
                    onClick={ this.handleSubmit }
                >
                    Submit
                </Button>
            </div>
        );

        let modalContent;

        if (this.state.requestSucceeded) {
            let title;
            let message;
            if (this.props.type === "restore") {
                title = "Trailer Restored";
                message = "restored to your fleet";
            } else if (this.props.type === "edit") {
                title = "Trailer Details Updated";
                message = "updated";
            } else {
                title = "Trailer Added";
                message = "added to your fleet";
            }
            
            const description = (
                <p className="centered">
                    {this.state.uploadType === 'file' ?
                        `${this.state.numberOfAddedTrailers} ${this.state.numberOfAddedTrailers === 1 ? "trailer" : "trailers"} have been ${this.state.numberOfAddedTrailers > 0 ? "successfully" : ""} added to your fleet`
                    :
                        `Trailer ${this.state.initialTrailerId ? this.state.initialTrailerId : this.state.trailerId} has been successfully ${message}`
                    }.
                </p>
            );

            modalContent = (
                <ConfirmMessageContainer title={ title } description={ description } onClose={ this.onClose } />
            );
        } else if (this.state.requestSucceeded === false) {
            let description;

            if (this.state.uploadType === "file") {
                description = "Invalid CSV file, please check provided data."
            } else {
                description = "An unexpected error has occurred.";
            }

            modalContent = (
                <ErrorMessageContainer description={ description } onClose={ this.onClose } />
            );
        } else {
            switch (this.state.uploadType) {
                case 'manual':
                    modalContent = (
                        <SimpleContainer className="modal-container" modal title={ title }>
                            { modalDescription }
                            <div className="trailer-section">
                                <div className="trailer-input-section">
                                    <div className="trailer-input">
                                        <h6 className="heading">
                                            Trailer ID
                                        </h6>
                                        <div className={ trailerIdSectionClassName }>
                                            <input
                                                type="text"
                                                placeholder={ "Enter Trailer ID" }
                                                value={ trailerId }
                                                name={ "trailerId" }
                                                className="editable"
                                                disabled={ this.props.type === "restore" }
                                                onChange={ this.handleInputChange }
                                            />
                                        </div>
                                        { trailerIdMesage }
                                    </div>
                                    <div>
                                        <h6 className="heading">
                                            Trailer Serial Number
                                        </h6>
                                        <div className={ trailerSerialNumberSectionClassName }>
                                            <input
                                                type="text"
                                                placeholder={ "Enter Serial Number" }
                                                value={ trailerSerialNumber }
                                                name={ "trailerSerialNumber" }
                                                className="editable"
                                                disabled={ this.props.type === "restore" && this.props.trailer.serialNumber }
                                                onChange={ this.handleInputChange }
                                            />
                                        </div>
                                        { serialNumberMessage }
                                    </div>
                                </div>
                                <h6 className="heading">
                                    Fleet
                                </h6>
                                <FleetSelect
                                    className="fleet-select"
                                    onChange={ this.onFleetChange }
                                    defaultOption={ defaultFleetOption }
                                />
                            </div>
                            { buttons }
                        </SimpleContainer>
                    );
                    break;
                case 'file':
                    modalContent = (
                        <SimpleContainer className="modal-container" modal title={ title }>
                            { modalDescription }

                            <div className="delimiter-select">
                                <small>
                                    <label>Column Delimiter</label>
                                    <div className="tooltip-container">
                                        <FontAwesomeIcon icon={ info } className="tooltip-info-icon" />
                                        <SimpleTooltip direction="right">
                                            The column delimiter represents the character <br/>
                                            used to separate data into columns. <br/> <br/>
                                            You can find it by checking your export or <br/>
                                            regional settings, or opening the csv in a <br/>
                                            standard text editor.
                                        </SimpleTooltip>
                                    </div>
                                </small>
                                <CustomSelect
                                    values={ DELIMITER_VALUES }
                                    selectedValue={ this.state.delimiter }
                                    onSelect={ this.onDelimiterChange }
                                />
                            </div>

                            <CustomFileInput
                                file={ this.state.file }
                                delimiter={ this.state.delimiter }
                                onSelectedFileChange={ this.onSelectedFileChange }
                                wrongFileType={ this.state.wrongFileType }
                                downloadCsv= { this.downloadTemplate }
                            />
                            { buttons }
                        </SimpleContainer>
                    );
                    break;
                default:
                    return <></>;
            }
        }

        return (
            <Modal
                show={ this.props.show }
                onHide={ this.onClose }
                backdrop="static"
                keyboard={ false }
                centered
                dialogClassName="add-trailers-modal"
            >
                { modalContent }
            </Modal>
        );
    }
}
