import React, { Component } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Cell
} from 'recharts';
import { issueTypeMap } from 'global/services/IssueTypeService';
import { minsToHours } from 'global/services/DateTimeService';
import { COLORS } from 'global/constants';

import './StackedGroupedBar.scss';

export default class StackedGroupedBar extends Component {

    static defaultProps = {
        allowDecimals: true,
        barSize: 41
    }

    constructor(props) {
        super(props);

        this.renderLegend = this.renderLegend.bind(this);
        this.customTooltip = this.customTooltip.bind(this);
    }

    renderSimpleIssueXAxis({ x, y, payload }) {
        return (
            <text x={ x } y={ y } className="x-type-label-tick">
                { issueTypeMap(payload.value).split(' ').map(issueType => (
                    <tspan textAnchor="middle" key={ issueType } x={ x } dy="1.2em">
                        { issueType }
                    </tspan>
                )) }
            </text>
        );
    }

    renderLegend({ payload }) {
        return (
            <div className="legend-wrapper">
                { payload.length > 1 && payload.map((entry, index) => (
                    <div key={ index } className="legend">
                        <div className="color-rectangle" style={ { backgroundColor: entry.color } } />
                        <div key={ `item-${index}` }>
                            { this.legendValue(entry.value) }
                        </div>
                    </div>
                )) }
                { this.props.timeFrame && (
                    <div className="right">
                        Showing results for { this.props.timeFrame.replace(/^a/, '') }
                    </div>
                ) }
            </div>
        );
    }

    customTooltip({ active, payload }) {
        if (!active || !this.tooltip || !payload) {
            return null;
        }

        for (const bar of payload) {
            if (bar.dataKey === this.tooltip) {
                return (
                    <div>
                        <div className="tooltip-text">
                            { this.props.minuteBar ? minsToHours(bar.value) : bar.value }&nbsp;{ this.legendValue(bar.name) }<br />
                        </div>
                    </div>
                );
            }
        }

        return null;
    }

    legendValue(value) {
        const dataKey = this.props.dataKeys.find(key => key.name === value);
        if (dataKey) {
            return dataKey.label;
        } else {
            return 'Unknown';
        }
    }

    cellFill(entry, dataKey, index) {
        if (entry.disabled) {
            return COLORS.DISABLED;
        } else {
            if (Array.isArray(dataKey.color)) {
                return dataKey.color[index % dataKey.color.length];
            } else {
                return dataKey.color;
            }
        }
    }

    render() {
        return (
            <ResponsiveContainer width="100%" height="100%" className="custom-chart-container">
                <BarChart
                    data={ this.props.data }
                    margin={ { left: 50, right: 50 } }
                    barSize={ this.props.barSize }
                    height={ 410 }
                >
                    <CartesianGrid vertical={ false } strokeDasharray="15 20" stroke={ COLORS.LIGHT_BORDER } />
                    <XAxis
                        dataKey="label"
                        minTickGap={ 1 }
                        tickMargin={ 1 }
                        height={ 50 }
                        interval={ 0 }
                        tick={ this.renderSimpleIssueXAxis }
                        axisLine={ { stroke: COLORS.DARK_GREY } }
                    />
                    <YAxis
                        tick={ { dx: -10 } }
                        axisLine={ { stroke: COLORS.WHITE } }
                        stroke={ COLORS.DARK_GREY }
                        style={ { fontSize: '0.8rem' } }
                        interval={ 0 }
                        tickFormatter={ this.props.yTickFormatter }
                        allowDecimals={ this.props.allowDecimals }
                    />
                    <Legend verticalAlign="top" content={ this.renderLegend } />
                    <Tooltip content={ this.customTooltip } cursor={ false } />
                    { this.props.dataKeys.map((dataKey, index) =>
                        <Bar
                            key={ index }
                            dataKey={ dataKey.name }
                            fill={ dataKey.disabled ? '' : dataKey.color }
                            stackId={ dataKey.stackId }
                            onMouseOver={ () => this.tooltip = dataKey.name }
                        >
                            { this.props.data.map((entry, i) => (
                                <Cell key={ `cell-${i}` } fill={ this.cellFill(entry, dataKey, i) } />
                            )) }
                        </Bar>
                    ) }
                </BarChart>
            </ResponsiveContainer>
        );
    }
}
