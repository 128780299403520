import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';

import SimpleContainer from 'components/container/SimpleContainer';

import './ErrorMessageContainer.scss'

export default class ErrorMessageContainer extends Component {

    static defaultProps = {
        title: 'Something went wrong',
        description: 'An unexpected error has occurred.'
    }

    render() {
        return (
            <SimpleContainer className="modal-container" modal>
                <div className="error-message-section">
                   <div className="centered">
                        <h1 className="titleContent">
                            { this.props.title }
                        </h1>                   
                    </div>
                    <p className="messageContent centered">
                        { this.props.description }
                    </p>
                    <Button
                        variant="danger"
                        className="errorMessageButton"
                        onClick={ this.props.onClose }
                    >
                         OK
                    </Button>
                </div>  
            </SimpleContainer>
        );
    }
}
