import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faEye, faInfoCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faAlarmSnooze } from '@fortawesome/pro-solid-svg-icons';

import { activityUrl, mapSort, RESOLVED_STATES } from "global/constants";
import DataTableComp from 'components/datatable/DataTableComp';
import './DetailView.scss';
import { issueTypes } from "global/services/IssueTypeService";
import { issueSnoozeable } from 'global/services/IssueTypeService';
import deleteComment from 'global/utils/deleteComment';
import { getCurrentDate as currentDate, getDifferenceBetweenDates } from 'global/services/DateTimeService';

import AuthContext from "AuthContext";

export default class IssueTable extends Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.columnNames = {
            4 : "START_TIME"
        }

        const columns = [
          { title: "Fuel level", data: "fuelLevel" }, // 0
          { title: "Alarm", data: "alarmCode" }, // 1
          { title: "Description", data: "alarmDescription" }, // 2
          { title: "Actions to take", data: "alarmAction" }, // 3
          { title: "Created", data: "beginning"}, // 4
          { title: "Alerts", data: "alerts" }, // 5
          { title: "Action", data: "action" }, // 6
        ];
    
        const sortingRule = [];  
        
        const columnDefs = [
            { width: "5%", "targets": [0, 1] },
            { width: "10%", "targets": [6]},
            { width: "15%", "targets": [4, 5]},
            { width: "20%", "targets": [2, 3] },
            { className: "dt-align-center", targets: [6] },
            { className: "dt-align-left", targets: [2, 3, 5] },
            { className: "dt-align-right", targets: [0, 1, 4] },
            {
                orderable: false,
                targets: [0, 1, 2, 3, 5, 6]
            },
            {
              targets: 0,
              createdCell: (td, cellData, rowData, row, col) => {
                  let className = `${rowData.fuelLevel !== 0 && !rowData.fuelLevel ? "orangeColorText" : rowData.type === issueTypes.LOW_FUEL_ALARM && rowData.resolved ? "greenColorText" : rowData.type === issueTypes.LOW_FUEL_ALARM && "redColorText"}`;
                  ReactDOM.render(
                      <div>
                          <span className={className}>
                              {rowData.fuelLevel || rowData.fuelLevel === 0 ? rowData.fuelLevel : "Unknown"} {(rowData.fuelLevel || rowData.fuelLevel === 0) && <>%</>}
                          </span>
                      </div>,
                      td
                  );
              }
            },
            {
                targets: 1,
                createdCell: (td, cellData, rowData, row, col) => {
                  let className = `${rowData.type === issueTypes.ALARM && rowData.resolved ? "" : rowData.type === issueTypes.ALARM && "redColorText"}`;
                    ReactDOM.render(
                        <div className={className} style={{minWidth: '40px'}}>
                            {rowData.alarmCode}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 2,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div>
                            {rowData.alarmDescription}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="no-flex">
                            {rowData.alarmAction}
                        </div>,
                        td
                    );
                }
            },
            {
              targets: 4,
              createdCell: (td, cellData, rowData, row, col) => {
                  ReactDOM.render(
                      <div className={`flex`}>
                          {rowData.beginning}
                      </div>,
                      td
                  );
              }
          },
          {
            targets: 5,
            createdCell: (td, cellData, rowData, row, col) => {
                let className = "greenColorText bold";
                let coloredText = "";
                let otherText = "";
                if (!rowData.alerts) {
                    coloredText = "No";
                    className = "redColorText bold";
                    otherText = `  (for ${getDifferenceBetweenDates(rowData.beginning, currentDate())})`
                } else if (rowData.alerts === "Escalation") {
                    coloredText = "Escalation"
                    className = "bold"
                } else if (rowData.alerts === "Warning") {
                    coloredText = "Warning";
                    className = "bold"
                }
                if (rowData.snoozed) {
                    otherText = `(snoozed ${getDifferenceBetweenDates(rowData.snoozedIssue.snoozeStart, rowData.snoozedIssue.snoozeEnd)})`;
                }
                const showIconStyle = coloredText === "No" ? {display: "none"} : {};
                ReactDOM.render(
                    <div className="notifications" style={{minWidth: '80px'}}>
                        <div className={`notification-level ` + className}>
                            {coloredText}
                        </div>
                        <div className="notification-info" style={showIconStyle}>
                            &nbsp;&nbsp;<FontAwesomeIcon icon={faInfoCircle} className="info-icon"/>
                        </div>
                        {
                        otherText !== "" &&
                        <div className="greyColorText italic snoozed-info">
                            <br/> {otherText}
                        </div>
                        }
                    </div>,
                    td
                );
                }
            },
            {
              targets: 6,
              className: "justify",
              createdCell: (td, cellData, rowData) => {
                    let snoozeDivClass = "";
                    let snoozeIconClass = "";
                    if (issueSnoozeable(rowData.issue) === 1) {
                        snoozeDivClass = "snooze";
                        snoozeIconClass = "snooze-icon medium";
                    } else if (issueSnoozeable(rowData.issue) === 2) {
                          snoozeDivClass = "disabled-snooze";
                          snoozeIconClass = "disabled-icon-snooze light";
                    } else {
                          snoozeDivClass = "disabled-snooze";
                          snoozeIconClass = "disabled-icon";
                    }

                    let seenIconClass = "seen-icon";
                    if (rowData.seenByBefore.length === 0 && rowData.seenByAfter.length === 0) {
                        seenIconClass += " light";
                    } else {
                        seenIconClass += " medium";
                    }

                    let commentIconClass = "comment-icon"
                    if (rowData.comments.length === 0) {
                        commentIconClass += " light";
                    } else {
                        commentIconClass += " medium";
                    }

                  const isResolved = RESOLVED_STATES.indexOf(rowData.issue.state) !== -1;

                  ReactDOM.render(
                      <div className="flex-always">
                          <div
                              className="seen"
                              id={rowData.id}
                          >
                              <FontAwesomeIcon icon={faEye} className={seenIconClass}></FontAwesomeIcon>
                          </div>
                          &nbsp; &nbsp;
                          <div className={snoozeDivClass}>
                              {snoozeIconClass === "disabled-icon" ? <svg width="16" height="12" style={{marginTop: "2px"}} viewBox="0 0 16 12" xmlns="http://www.w3.org/2000/svg" className="disabled-icon">
                                      <path d="M8.40156 5.3999H8.20156H6.60156L7.70156 6.2999L8.30156 5.4999L8.40156 5.3999Z"/>
                                      <path d="M14.0008 2.2C14.0008 1 13.0008 0 11.8008 0C11.2008 0 10.7008 0.2 10.3008 0.6L13.7008 3.4C13.9008 3.1 14.0008 2.7 14.0008 2.2Z"/>
                                      <path d="M9.5 9.1998H6.5C6.3 9.1998 6.1 9.0998 6 8.8998C5.9 8.6998 5.9 8.4998 6.1 8.2998L7 7.0998L3.4 4.2998C3 4.9998 2.8 5.8998 2.8 6.7998C2.8 7.8998 3.2 8.9998 3.8 9.8998L2.9 10.7998C2.7 10.8998 2.7 11.1998 2.9 11.2998L3.4 11.7998C3.5 11.9998 3.8 11.9998 3.9 11.7998L4.8 10.8998C6.7 12.2998 9.2 12.2998 11.1 10.8998L12 11.7998C12.1 11.8998 12.4 11.8998 12.5 11.7998L12.7 11.5998L9.5 9.1998C9.6 9.1998 9.6 9.1998 9.5 9.1998Z"/>
                                      <path d="M8.20117 8.0998L7.90117 7.7998L7.70117 8.0998H8.20117Z"/>
                                      <path d="M3.90081 2.1998L5.80081 0.699805C5.40081 0.299805 4.80081 0.0998047 4.30081 0.0998047C3.50081 0.0998047 2.80081 0.499805 2.40081 1.0998L1.30081 0.199805C1.10081 -0.00019531 0.800814 -0.00019531 0.700814 0.199805C0.500814 0.399805 0.600813 0.699805 0.800813 0.899805L2.10081 1.8998L3.20081 2.7998L3.90081 2.1998Z"/>
                                      <path d="M15.3 11.1001L12.7 9.1001C13.1 8.4001 13.3 7.6001 13.3 6.8001C13.3 3.9001 10.9 1.6001 8.1 1.6001C6.8 1.6001 5.6 2.1001 4.7 2.8001C4.4 3.0001 4.2 3.3001 4 3.5001L6.2 5.3001V5.2001V4.7001C6.2 4.5001 6.4 4.3001 6.6 4.3001H6.7H9.6C9.8 4.3001 10 4.4001 10.1 4.6001C10.2 4.8001 10.2 5.0001 10 5.2001L9.2 6.2001L8.5 7.0001L12.4 10.1001L14.7 11.9001C14.8 12.0001 14.9 12.0001 15 12.0001C15.2 12.0001 15.3 11.9001 15.4 11.8001C15.6 11.6001 15.5 11.3001 15.3 11.1001Z"/>
                                  </svg>
                                  : <FontAwesomeIcon icon={faAlarmSnooze} className={snoozeIconClass}/>}
                          </div>
                          &nbsp; &nbsp;
                          <div className="comment">
                              <FontAwesomeIcon icon={faComment} className={commentIconClass}></FontAwesomeIcon>
                          </div>
                          &nbsp; &nbsp;
                          <div className={!isResolved ? "resolve" : "disabled-resolve"}>
                               <FontAwesomeIcon icon={faTimesCircle} className={!isResolved ? "resolve-icon" : "disabled-icon"} />
                          </div>
                      </div>,
                      td
                  );
              }
            }
        ];

        this.state = {
            allIssues: (this.props.alarmIssues || []),
            alarmIssuesSort: [],
            columns: columns,
            columnDefs: columnDefs,
            sortingRule: sortingRule,
            account: this.props.account,
            page: 1,
            pagingInfo: this.props.pagingInfo
        };
        this.onReceiveNewComment = this.onReceiveNewComment.bind(this);
        this.onDeleteComment = this.onDeleteComment.bind(this);
    }

    onReceiveNewComment(comment) {
		let newData = this.state.allIssues;
		newData.find((data) => data.issue.id === comment.issue.id).comments.unshift(comment);
		this.setState({
            allIssues: newData
		});
    }
    
    onDeleteComment(deletedComment) {
        let newData = this.state.allIssues;

        this.setState({
            allIssues: deleteComment(deletedComment, newData)
        });
    }

    async handlePage(page) {
        this.setState({
            page,
        }, async () => {
            await this.props.fetchIssues(mapSort(this.state.alarmIssuesSort), this.props.active, this.state.page, this.state.pagingInfo.recordsNumber);
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            });
        });
    }

    async handlePreviousPage() {
        await this.handlePage(parseInt(this.state.page) - 1);
    }

    async handleNextPage() {
        await this.handlePage(parseInt(this.state.page) + 1);
    }

    async handleFirstPage() {
        await this.handlePage(1);
    }

    async handleLastPage() {
        await this.handlePage(parseInt(this.state.pagingInfo.totalPageNumber));
    }

    async handleRecordsNumber(event) {
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: 1,
                recordsNumber: parseInt(event.target.value)
            },
            page: 1,
        }, async () => await this.props.fetchIssues(mapSort(this.state.alarmIssuesSort), this.props.active, this.state.page, this.state.pagingInfo.recordsNumber));
    }

    async handlePageChange(event) {
        const value = event.target.value;
        if (isNaN(value) || value === "") {
            this.setState({
                page: ""
            });
        } else {
            this.setState({
                page: parseInt(value)
            });
            const that = this;
            setTimeout(async function () {
                await that.props.fetchIssues(mapSort(that.state.alarmIssuesSort), that.props.active, that.state.page, that.state.pagingInfo.recordsNumber);
            }, 1000);
        }
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: value
            }
        });
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.alarmIssuesSort;
        const that = this;
        const existing = sortingArray.findIndex(x => x && x.columnName === that.columnNames[index]);
        existing === -1 ? sortingArray.push({
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
          }) : sorting === "" ? sortingArray.splice(existing, 1) : sortingArray[existing] = {
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }
          this.setState({
            alarmIssuesSort: sortingArray
          }, async () => {
              await this.props.fetchIssues(mapSort(this.state.alarmIssuesSort), this.props.active, this.state.page, this.state.pagingInfo.recordsNumber);
          })
    }

    componentDidMount() {
        this.state.allIssues.forEach(issue => {
            this.context.post(activityUrl, {
                activity: 'SEEN',
                issue: issue.dbIssue
            });
        });
    }

    componentDidUpdate(prevProps) {
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
            this.setState({
                allIssues: (this.props.alarmIssues || []),
                account: this.props.account,
                pagingInfo: this.props.pagingInfo
            }, () => {
                    this.state.allIssues.forEach(issue => {
                        this.context.post(activityUrl, {
                            activity: 'SEEN',
                            issue: issue.dbIssue
                        });
                    });
                }
            )
        }
    }
    
    render() {
        if(this.props.alarmIssues) {
            return (
                <div className="detail-tables">
                    {this.props.alarmIssues.length !== 0 ? 
                    <>
                        <br/>
                        <div className="unresolved-table">
                            { this.props.alarmIssues && <DataTableComp
                                tableId={ this.props.tableName }
                                columns={ this.state.columns }
                                orderRule={ this.state.sortingRule }
                                data={ this.props.alarmIssues }
                                onReceiveNewComment={ this.onReceiveNewComment }
                                onDeleteComment={ this.onDeleteComment }
                                handlePreviousPage={ this.handlePreviousPage.bind(this) }
                                handleNextPage={ this.handleNextPage.bind(this) }
                                handleFirstPage={ this.handleFirstPage.bind(this) }
                                handleLastPage={ this.handleLastPage.bind(this) }
                                handleRecordsNumber={ this.handleRecordsNumber.bind(this) }
                                handlePageChange={ this.handlePageChange.bind(this) }
                                pagingInfo={ this.state.pagingInfo }
                                isDetailView={ true }
                                columnDefs={ this.state.columnDefs }
                                tableHeight="500px"
                                account={ this.state.account }
                                sortRule={ this.state.alarmIssuesSort.concat([]) }
                                addSortingForColumn={ this.addSortingForColumn.bind(this) }
                            />}
                        </div>
                    </> : <></>
                    }
                </div>
            );
        } else {
          return <></>
        }
    }
}
