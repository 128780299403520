import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import Loader from 'react-loader-spinner';

import AuthContext from "AuthContext";
import { reactPlugin } from 'AppInsights';
import DataTableComp from 'components/datatable/DataTableComp';
import { defaultGridRecordsNumber, mapSort, detainedTrailersUrl } from 'global/constants';
import { formatDate } from "global/services/DateTimeService";
import QueryParamService from "global/services/QueryParamService";
import formSortObject from 'global/utils/formSortObject';
import mapGridResponseData from 'global/utils/mapGridResponseData';

import './DetainedTrailers.scss';

const CACHE = {};

class DetainedTrailers extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.columnNames = {
            0 : "TRAILER"
        }

        this.columns = [
            { title: "Trailer #", data: "trailerBusinessId" }, // 0
            { title: "Manifest #", data: "manifestBusinessId"}, // 1
            { title: "Fuel level", data: "fuelLevel"}, // 2
            { title: "Detained since", data: "detentionDuration" } // 3
        ];

        this.sortingRule = [];
        this.data = Array.from([]);

        this.columnDefs = [
            { width: "25%", "targets": [0, 1, 2, 3] },
            { className: "dt-align-left", targets: [2, 3] },
            { className: "dt-align-right", targets: [0, 1] },
            {
                searchable: false,
                targets: [0, 1, 2, 3]
            },
            {
                orderable: false,
                targets: [1, 2, 3]
            },
            {
                targets: 0,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="blueColorText trailer link">
                            { rowData.trailerBusinessId }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 1,
                createdCell: (td, cellData, rowData, row, col) => {
                    const contentDecorator = rowData.manifestBusinessId !== 'No manifest' ? 'blueColorText assignment link' : '';

                    ReactDOM.render(
                        <div className={ contentDecorator }>
                            { rowData.manifestBusinessId }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 2,
                createdCell: (td, cellData, rowData, row, col) => {
                    let displayedFuelLevel, classNameDecorator = '';
                    if (rowData.fuelLevel || rowData.fuelLevel === 0) {
                        displayedFuelLevel = rowData.fuelLevel + "%";
                    } else {
                        classNameDecorator = "bold orangeColorText"
                        displayedFuelLevel = "Unknown";
                    }

                    ReactDOM.render(
                        <div className={ `fuelLevel ${ classNameDecorator }` }>
                            { displayedFuelLevel }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="no-flex" style={{ minWidth: '40px' }}>
                            { formatDate(new Date(rowData.detentionDuration)) }
                        </div>,
                        td
                    );
                }
            }
        ];

        let currentPage = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page') ? parseInt(QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page')) : 1;

        QueryParamService.addFleets(JSON.parse(localStorage.getItem('fleets') || '[]'));

        this.state = {
            data: this.data,
            columns: this.columns,
            columnDefs: this.columnDefs,
            sort: [],
            sortingRule: this.sortingRule,
            dataLoaded: false,
            dataUpdated: false,
            page: currentPage,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber
            }
        }
    }

    async componentDidMount() {
        await this.fetchDetainedTrailers();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fleets !== this.props.fleets) {
            this.state.page !== 1 ? this.handleFirstPage() : this.fetchDetainedTrailers(true);
        }
    }

    async handlePage(page) {
        this.setState({
            page: page,
            dataUpdated: false
        }, async () => {
            await this.fetchDetainedTrailers();
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            });
        });
    }

    handleFirstPage = async () => {
        await this.handlePage(1);
    }

    handleLastPage = async () => {
        await this.handlePage(parseInt(this.state.pagingInfo.totalPageNumber));
    }

    handlePreviousPage = async () => {
        await this.handlePage(parseInt(this.state.page) - 1);
    }

    handleNextPage = async () => {
        await this.handlePage(parseInt(this.state.page) + 1);
    }

    handleRecordsNumber = async (event) => {
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: 1,
                recordsNumber: parseInt(event.target.value),
            },
            page: 1,
            dataUpdated: false
        }, async () => {
            await this.fetchDetainedTrailers();
        });
    }

    handlePageChange = async (event) => {
        const value = event.target.value;

        if (isNaN(value) || value === "") {
            this.setState({
                page: ""
            });
        } else {
            this.setState({
                page: parseInt(value),
                dataUpdated: false
            });
            const that = this;
            setTimeout(async function () {
                await that.fetchDetainedTrailers();
            }, 1000);
        }

        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: this.state.page
            }
        });
    }

    async fetchDetainedTrailers(force) {
        const cachingIndex = "detained";

        if (CACHE[cachingIndex] !== undefined && this.state.page === 1) {
            this.setState({
                data: CACHE[cachingIndex].issues,
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                dataLoaded: true,
                dataUpdated: false,
                pagingInfo: CACHE[cachingIndex].pagingInfo
            });
        } else {
            this.setState({
                dataUpdated: false
            });
        }

        let sort = this.state.sort.length > 0 ? mapSort(this.state.sort) : QueryParamService.parseSortingQueryString(window.location.search.slice(1));
        let tableSortObject = formSortObject(sort, this.columnNames);

        try {
            if (!force && (this.state.page === "" || this.state.page < 1 || this.state.page > this.state.pagingInfo.totalPageNumber)) {
                this.setState({
                    dataUpdated: true
                });
                return;
            }

            const response = await this.context.get(detainedTrailersUrl, {
                sort: sort,
                pageNumber: this.state.page,
                pageSize: this.state.pagingInfo.recordsNumber
            });

            if (response.status === "error") {
                console.error(response.message);
                return {};
            }

            const data = response.data;
            if (!data) {
                this.setState({
                    dataUpdated: true
                });
                return;
            }

            let currentPage = Math.min(response.pageNumber, Math.ceil(response.available / response.pageSize));
            let queryString = window.location.search.slice(1);
            QueryParamService.addPageToQueryString(queryString, currentPage || 1);

            const detainedTrailers = [];
            data.map((entry, index) => {
                return detainedTrailers.push({
                    trailer: entry.trailer,
                    leg: entry.leg,
                    engineState: entry.engineState,
                    positionState: entry.positionState,
                    trailerBusinessId: entry.trailer.businessId,
                    manifestBusinessId: entry.leg ? entry.leg.assignment.businessId : "No manifest",
                    fuelLevel: (entry.engineState.fuelPercentage || entry.engineState.fuelPercentage === 0) ? entry.engineState.fuelPercentage : null,
                    detentionDuration: entry.positionState.since,
                    onClickState: {
                        trailerId: entry.trailer.id,
                        assignmentId: entry.leg ? entry.leg.assignment.id : null
                    }
                });
            });
            const newData = mapGridResponseData(detainedTrailers, response);

            if (this.state.page === 1) {
                CACHE[cachingIndex] = {
                    data: newData.issues,
                    pagingInfo: newData.pagingInfo
                };
            }

            this.setState({
                data: newData.issues,
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                dataLoaded: true,
                dataUpdated: true,
                pagingInfo: newData.pagingInfo,
                sort: tableSortObject
            });
        } catch (error) {
            console.error(error);
            this.setState({
                data: Array.from([]),
                columns: this.columns,
                columnDefs: this.columnDefs,
                sortingRule: this.sortingRule,
                dataLoaded: true,
                dataUpdated: true,
                sort: tableSortObject
            });
        }
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.sort;
        const that = this;
        const existing = sortingArray.findIndex(x => x && x.columnName === that.columnNames[index]);

        existing === -1 ? sortingArray.push({
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }) : sorting === "" ? sortingArray.splice(existing, 1) : sortingArray[existing] = {
            columnIndex: index,
            columnName: this.columnNames[index],
            direction: sorting
        }

        let queryString = window.location.search.slice(1);
        QueryParamService.addSortToQueryString(queryString, sortingArray);

        this.setState({
            sort: sortingArray,
            dataUpdated: false
        }, async () => {
            await this.fetchDetainedTrailers();
        });
    }

    render() {
        return (
            <div className="page detained-trailers">
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                        <li className="breadcrumb-item active"><a href="#!">Detained trailers</a></li>
                    </ol>
                </nav>
                <div className="heading-div">
                    <p className="heading">
                        Detained Trailers
                    </p>
                    &nbsp; &nbsp; <Loader type="TailSpin" color="#289AC2" height={ 47 } width={ 47 } visible={ !this.state.dataLoaded || !this.state.dataUpdated } />
                </div>
                <div className="detained-trailers-div">
                    { this.state.dataLoaded && <DataTableComp
                        tableId="detainedTrailersIssues"
                        account={ this.props.account }
                        columns={ this.state.columns }
                        orderRule={ this.state.sortingRule }
                        data={ this.state.data }
                        isDetailView={ false }
                        columnDefs={ this.state.columnDefs }
                        customTableClass="cell-border"
                        tableHeight="1200px"
                        handlePreviousPage={ this.handlePreviousPage }
                        handleNextPage={ this.handleNextPage }
                        handleFirstPage={ this.handleFirstPage }
                        handleLastPage={ this.handleLastPage }
                        handleRecordsNumber={ (event) => this.handleRecordsNumber(event) }
                        handlePageChange={ (event) => this.handlePageChange(event) }
                        pagingInfo={ this.state.pagingInfo }
                        sortRule={ this.state.sort.concat([]) }
                        addSortingForColumn={ this.addSortingForColumn.bind(this) }
                    /> }
                </div>
            </div>
        );
    }
}

export default withAITracking(reactPlugin, DetainedTrailers, "DetainedTrailers");
