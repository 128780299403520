import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { issueTypeMap } from "global/services/IssueTypeService";
import { getDifferenceBetweenDates } from "global/services/DateTimeService";
import SimpleTooltip from 'components/tooltip/SimpleTooltip';
import './TrailerSettings.scss';

const noDataString = "NO DATA";

export default class TrailerSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: props.currentInfo.mode,
      actualMode: props.currentInfo.actualMode,
      minTemp: props.currentInfo.minTemp === undefined ? null : props.currentInfo.minTemp === "-Infinity" ? "NO DATA" : props.currentInfo.minTemp,
      maxTemp: props.currentInfo.maxTemp === undefined ? null : props.currentInfo.maxTemp === "Infinity" ? "NO DATA" : props.currentInfo.maxTemp,
      setPoint: props.currentInfo.setPoint === undefined ? null : props.currentInfo.setPoint,
      returnAir: props.currentInfo.returnAir === undefined ? null : props.currentInfo.returnAir,
      expectedPower: props.currentInfo.expectedPower,
      power: props.currentInfo.power,
      issuesList: props.currentInfo.issuesList || [],
      insideSalesRepName: props.currentInfo.insideSalesRepName,
      hasAssignment: props.currentInfo.hasAssignment,
      isActiveTrailer: props.activeTrailer === false ? false : true,
      activeTrailerId: props.currentInfo.activeTrailerId,
      relevantLeg: props.currentInfo.relevantLeg,
      lastMeasurementTime: props.currentInfo.lastMeasurementTime,
      snoozedPower: props.activeTrailer !== false && props.currentInfo.issuesList.find(issue =>  issue.leg && issue.leg.active === true && issue.type === issueTypeMap("POWER_STATE") && issue.state === "SNOOZED" && issue.trailerId === props.currentInfo.activeTrailerId),
      snoozedUnitMode: props.activeTrailer !== false && props.currentInfo.issuesList.find(issue =>  issue.leg && issue.leg.active === true && issue.type === issueTypeMap("UNIT_MODE") && issue.state === "SNOOZED" && issue.trailerId === props.currentInfo.activeTrailerId),
      snoozedTemperature: props.activeTrailer !== false && props.currentInfo.issuesList.find(issue => issue.leg && issue.leg.active === true && issue.type === issueTypeMap("TEMPERATURE") && issue.state === "SNOOZED" && issue.trailerId === props.currentInfo.activeTrailerId),
      closedPower: props.activeTrailer !== false && props.currentInfo.issuesList.find(issue =>  issue.leg && issue.leg.active === true && issue.type === issueTypeMap("POWER_STATE") && issue.state === "CLOSED" && issue.trailerId === props.currentInfo.activeTrailerId),
      closedUnitMode: props.activeTrailer !== false && props.currentInfo.issuesList.find(issue =>  issue.leg && issue.leg.active === true && issue.type === issueTypeMap("UNIT_MODE") && issue.state === "CLOSED" && issue.trailerId === props.currentInfo.activeTrailerId),
      closedTemperature: props.activeTrailer !== false && props.currentInfo.issuesList.find(issue =>  issue.leg && issue.leg.active === true && issue.type === issueTypeMap("TEMPERATURE") && issue.state === "CLOSED" && issue.trailerId === props.currentInfo.activeTrailerId),
    }
  }

  componentDidUpdate(prevProps) {
    if(JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
        this.setState({
            mode: this.props.currentInfo.mode,
            actualMode: this.props.currentInfo.actualMode,
            minTemp: this.props.currentInfo.minTemp === undefined ? null : this.props.currentInfo.minTemp === "-Infinity" ? "NO DATA" : this.props.currentInfo.minTemp,
            maxTemp: this.props.currentInfo.maxTemp === undefined ? null : this.props.currentInfo.maxTemp === "Infinity" ? "NO DATA" : this.props.currentInfo.maxTemp,
            setPoint: this.props.currentInfo.setPoint === undefined ? null : this.props.currentInfo.setPoint,
            returnAir: this.props.currentInfo.returnAir === undefined ? null : this.props.currentInfo.returnAir,
            expectedPower: this.props.currentInfo.expectedPower,
            power: this.props.currentInfo.power,
            issuesList: this.props.currentInfo.issuesList || [],
            insideSalesRepName: this.props.currentInfo.insideSalesRepName,
            hasAssignment: this.props.currentInfo.hasAssignment,
            isActiveTrailer: this.props.activeTrailer === false ? false : true,
            activeTrailerId: this.props.currentInfo.activeTrailerId,
            relevantLeg: this.props.currentInfo.relevantLeg,
            lastMeasurementTime: this.props.currentInfo.lastMeasurementTime,
            snoozedPower: this.props.activeTrailer !== false && this.props.currentInfo.issuesList.find(issue => issue.leg && issue.leg.active === true && issue.type === issueTypeMap("POWER_STATE") && issue.state === "SNOOZED" && issue.trailerId === this.props.currentInfo.activeTrailerId),
            snoozedUnitMode: this.props.activeTrailer !== false && this.props.currentInfo.issuesList.find(issue => issue.leg && issue.leg.active === true && issue.type === issueTypeMap("UNIT_MODE") && issue.state === "SNOOZED" && issue.trailerId === this.props.currentInfo.activeTrailerId),
            snoozedTemperature: this.props.activeTrailer !== false && this.props.currentInfo.issuesList.find(issue => issue.leg && issue.leg.active === true && issue.type === issueTypeMap("TEMPERATURE") && issue.state === "SNOOZED" && issue.trailerId === this.props.currentInfo.activeTrailerId),
            closedPower: this.props.activeTrailer !== false && this.props.currentInfo.issuesList.find(issue =>  issue.leg && issue.leg.active === true && issue.type === issueTypeMap("POWER_STATE") && issue.state === "CLOSED" && issue.trailerId === this.props.currentInfo.activeTrailerId),
            closedUnitMode: this.props.activeTrailer !== false && this.props.currentInfo.issuesList.find(issue =>  issue.leg && issue.leg.active === true && issue.type === issueTypeMap("UNIT_MODE") && issue.state === "CLOSED" && issue.trailerId === this.props.currentInfo.activeTrailerId),
            closedTemperature: this.props.activeTrailer !== false && this.props.currentInfo.issuesList.find(issue =>  issue.leg && issue.leg.active === true && issue.type === issueTypeMap("TEMPERATURE") && issue.state === "CLOSED" && issue.trailerId === this.props.currentInfo.activeTrailerId),
        })
    }
  }
  render() {
    const temperatureIssueExists = this.state.issuesList.find(issue => [issueTypeMap("TEMPERATURE"), "TEMPERATURE"].indexOf(issue.type) !== -1 && issue.leg && (issue.leg.active === true || (this.state.relevantLeg && this.state.relevantLeg.leg && issue.leg.id === this.state.relevantLeg.leg.id) || (this.state.relevantLeg && issue.leg.id === this.state.relevantLeg.id)) && issue.state.indexOf("RESOLVED") === -1 && issue.trailerId === this.state.activeTrailerId);
    const modeIssueExists = this.state.issuesList.find(issue => [issueTypeMap("UNIT_MODE"), "UNIT_MODE"].indexOf(issue.type) !== -1 && issue.leg && (issue.leg.active === true || (this.state.relevantLeg && this.state.relevantLeg.leg && issue.leg.id === this.state.relevantLeg.leg.id) || (this.state.relevantLeg && issue.leg.id === this.state.relevantLeg.id))  && issue.state.indexOf("RESOLVED") === -1 && issue.trailerId === this.state.activeTrailerId );
    const powerIssueExists = this.state.issuesList.find(issue => [issueTypeMap("POWER_STATE"), "POWER_STATE"].indexOf(issue.type) !== -1 && issue.leg && (issue.leg.active === true || (this.state.relevantLeg && this.state.relevantLeg.leg && issue.leg.id === this.state.relevantLeg.leg.id) || (this.state.relevantLeg && issue.leg.id === this.state.relevantLeg.id)) && issue.state.indexOf("RESOLVED") === -1 && issue.trailerId === this.state.activeTrailerId);

    return (
              <div className="row trailer-settings-container">
                  <div className="col-12 col-sm-12 measurements">
                        <div className="row">
                            { this.state.hasAssignment === false && <div className="flex status-info-label order-inactive">
                            <p className="info-icon">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="14" height="14" rx="2" fill="#F27C46"/>
                                    <path d="M8.78432 9.92857C8.78432 9.73326 8.62249 9.57143 8.42718 9.57143H8.07003V6.35714C8.07003 6.16183 7.9082 6 7.71289 6H5.57003C5.37472 6 5.21289 6.16183 5.21289 6.35714V7.07143C5.21289 7.26674 5.37472 7.42857 5.57003 7.42857H5.92718V9.57143H5.57003C5.37472 9.57143 5.21289 9.73326 5.21289 9.92857V10.6429C5.21289 10.8382 5.37472 11 5.57003 11H8.42718C8.62249 11 8.78432 10.8382 8.78432 10.6429V9.92857ZM8.07003 3.5C8.07003 3.30469 7.9082 3.14286 7.71289 3.14286H6.28432C6.08901 3.14286 5.92718 3.30469 5.92718 3.5V4.57143C5.92718 4.76674 6.08901 4.92857 6.28432 4.92857H7.71289C7.9082 4.92857 8.07003 4.76674 8.07003 4.57143V3.5Z" fill="white"/>
                                </svg>
                            </p>
                            <p className="text">
                                Settings for this trailer are not available because this trailer is not assigned to any active manifest.
                            </p>
                          </div>}
                          { this.state.hasAssignment !== false && this.state.isActiveTrailer === false && <div className="flex status-info-label trailer-inactive">
                              <p className="info-icon">
                                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="14" height="14" rx="2" fill="#F27C46"/>
                                <path d="M8.78432 9.92857C8.78432 9.73326 8.62249 9.57143 8.42718 9.57143H8.07003V6.35714C8.07003 6.16183 7.9082 6 7.71289 6H5.57003C5.37472 6 5.21289 6.16183 5.21289 6.35714V7.07143C5.21289 7.26674 5.37472 7.42857 5.57003 7.42857H5.92718V9.57143H5.57003C5.37472 9.57143 5.21289 9.73326 5.21289 9.92857V10.6429C5.21289 10.8382 5.37472 11 5.57003 11H8.42718C8.62249 11 8.78432 10.8382 8.78432 10.6429V9.92857ZM8.07003 3.5C8.07003 3.30469 7.9082 3.14286 7.71289 3.14286H6.28432C6.08901 3.14286 5.92718 3.30469 5.92718 3.5V4.57143C5.92718 4.76674 6.08901 4.92857 6.28432 4.92857H7.71289C7.9082 4.92857 8.07003 4.76674 8.07003 4.57143V3.5Z" fill="white"/>
                                </svg>
                              </p>
                              <p className="text">
                                This trailer is currently not assigned. { this.state.lastMeasurementTime && <>Settings showed here are from {this.state.lastMeasurementTime}.</>}
                              </p>
                          </div>}
                        </div>

                        {this.state.hasAssignment !== false && <>
                            <div className="row">
                          <div className="right-line bottom-padding col-12 col-sm-3">
                              <p className="measurement-type-1">
                                  Mode:
                              </p>
                              <p className={`measurement-info-1 ${this.state.isActiveTrailer === false && "greyColorText"}`} id="expected-mode">
                                  {this.state.mode}
                              </p>
                          </div>
                          <div className="col-12 col-sm-3 bottom-padding left-padding">
                              <p className="measurement-type">
                                  Min. temp. allowed:
                              </p>
                              <p className={`measurement-info ${this.state.isActiveTrailer === false || this.state.minTemp === "NO DATA" ? "greyColorText" : "greenColorText"}`} id="min-temp-allowed">
                                  {this.state.minTemp !== null ? this.state.minTemp : noDataString}
                                  {this.state.minTemp !== null && this.state.minTemp !== noDataString && this.state.minTemp !== "NO DATA" ? <span>&deg;F</span> : ""}
                              </p>
                          </div>
                          <div className="col-12 col-sm-3 right-line bottom-padding ">
                              <p className="measurement-type">
                                  Max. temp. allowed:
                              </p>
                              <p className={`measurement-info ${this.state.isActiveTrailer === false || this.state.minTemp === "NO DATA" ? "greyColorText" : "greenColorText"}`} id="max-temp-allowed">
                                  {this.state.maxTemp !== null ? this.state.maxTemp : noDataString}
                                  {this.state.maxTemp !== null && this.state.maxTemp !== noDataString && this.state.maxTemp !== "NO DATA" ? <span>&deg;F</span> : ""}
                              </p>
                          </div>
                          <div className="col-12 col-sm-3 bottom-padding ">
                              <p className="measurement-type">
                                  Expected power:
                              </p>
                              <p className={`measurement-info ${this.state.isActiveTrailer === false ? "greyColorText" : "greenColorText"}`} id="expected-power">
                                  {this.state.expectedPower}
                              </p>
                          </div>
                      </div>
                      <hr className="small-screen-only"></hr>
                      <div className="row">
                          <div className="right-line col-12 col-sm-3 latest-measurement">
                              Latest mode reading: &nbsp;
                              {!this.state.actualMode ? <></> : !modeIssueExists ?
                                  <>
                                      <FontAwesomeIcon icon={faCheckCircle} className={`col-0 col-sm-0 issue-icon greenColorText ${this.state.isActiveTrailer === false && "greyColorText"}`}></FontAwesomeIcon>
                                  </> :
                                  <>
                                      <FontAwesomeIcon icon={faExclamationCircle} className={`col-0 col-sm-0 issue-icon redColorText ${(this.state.snoozedUnitMode || this.state.closedUnitMode) && "greyColorText"} ${this.state.isActiveTrailer === false && "greyColorText"}`}></FontAwesomeIcon>
                                  </>
                              }
                              <p className=" measurement-type-1">
                                  Mode:
                              </p>
                              <div className={`measurement-info-1 ${!this.state.actualMode ? "greyColorText" : !modeIssueExists ? "greenColorText" : "redColorText"} ${(this.state.snoozedUnitMode || this.state.closedUnitMode) && "greyColorText"} ${this.state.isActiveTrailer === false && "greyColorText"}`} id="actual-mode">
                                  {this.state.actualMode ? this.state.actualMode : "NO DATA"} &nbsp;
                                  {!this.state.actualMode ? <></> : this.state.mode === this.state.actualMode ?
                                      <>
                                          <FontAwesomeIcon icon={faCheckCircle} className="col-0 col-sm-0 issue-icon"></FontAwesomeIcon>
                                      </> :
                                      <>
                                          <FontAwesomeIcon icon={faExclamationCircle} className="col-0 col-sm-0 issue-icon"></FontAwesomeIcon>
                                      </>
                                  }
                                  <div className="snoozed">
                                    <p>
                                        {this.state.closedUnitMode ? `(closed ${getDifferenceBetweenDates(this.state.closedUnitMode.closed.closedAt, new Date())} ago)` :
                                            this.state.snoozedUnitMode ? `(snoozed for ${getDifferenceBetweenDates(this.state.snoozedUnitMode.snoozedIssue.snoozeStart, this.state.snoozedUnitMode.snoozedIssue.snoozeEnd)})` : ""}
                                    </p>
                                  </div>
                              </div>
                          </div>

                          <div className="right-line col-12 col-sm-6 latest-measurement left-padding">
                              Latest temperature reading: &nbsp;
                              {this.state.setPoint == null ? <></> : !temperatureIssueExists ?
                                  <>
                                      <FontAwesomeIcon icon={faCheckCircle} className={`col-0 col-sm-0 issue-icon greenColorText ${this.state.isActiveTrailer === false && "greyColorText"}`}></FontAwesomeIcon>
                                  </> :
                                  temperatureIssueExists &&
                                  <>
                                      <FontAwesomeIcon icon={faExclamationCircle} className={`col-0 col-sm-0 redColorText issue-icon ${(this.state.isActiveTrailer === false || this.state.closedTemperature || this.state.snoozedTemperature) && "greyColorText"}`}></FontAwesomeIcon>
                                  </>
                              }
                              <div className="row">
                                  <div className="col-6">
                                      <div className="measurement-type temperature">
                                          Set point:&nbsp;
                                          <div className="tooltip-container" style={{ position: 'relative' }}>
                                              <FontAwesomeIcon icon={ faInfoCircle } className="temp-info-icon" />
                                              <SimpleTooltip direction="top">
                                                  The temperature setpoint required for the shipment. This is set forth by the user in the system.
                                              </SimpleTooltip>
                                          </div>
                                      </div>
                                      <div style={{ position: 'relative' }} className={`measurement-info tooltip-container ${ this.state.isActiveTrailer === false || this.state.setPoint === null || this.state.setPoint === 'NO DATA' || this.state.snoozedTemperature || this.state.closedTemperature ? 'greyColorText' : (temperatureIssueExists && ((temperatureIssueExists || {}).metaData || {}).setPointIssue === 'true' ? 'redColorText' : 'greenColorText') }`} id="set-point">
                                          {this.state.setPoint !== null && this.state.setPoint !== "NO DATA" ? Number.parseFloat(this.state.setPoint).toFixed(0) : "NO DATA"} {this.state.setPoint !== null && this.state.setPoint !== "NO DATA" && <span>&deg;F</span>}
                                          {this.state.setPoint !== null && this.state.setPoint !== "NO DATA" && (<SimpleTooltip direction="bottom">{ Number.parseFloat(this.state.setPoint).toFixed(1) }<span>&deg;F</span></SimpleTooltip>)}
                                      </div>
                                  </div>
                                  <div className="col-6">
                                      <div className="measurement-type temperature">
                                          Return air:&nbsp;
                                          <div className="tooltip-container" style={{ position: 'relative' }}>
                                              <FontAwesomeIcon icon={ faInfoCircle } className="temp-info-icon" />
                                              <SimpleTooltip direction="top">
                                                  The actual measured air temperature inside the reefer.
                                              </SimpleTooltip>
                                          </div>
                                      </div>
                                      <div style={{ position: 'relative' }} className={`measurement-info tooltip-container ${ this.state.isActiveTrailer === false || this.state.returnAir === null || this.state.returnAir === 'NO DATA' || this.state.snoozedTemperature || this.state.closedTemperature ? 'greyColorText' : (temperatureIssueExists && ((temperatureIssueExists || {}).metaData || {}).returnAirIssue === 'true' ? 'redColorText' : 'greenColorText') }`} id="return-air">
                                          {this.state.returnAir !== null && this.state.returnAir !== "NO DATA" ? Number.parseFloat(this.state.returnAir).toFixed(0) : "NO DATA"} {this.state.returnAir !== null && this.state.returnAir !== "NO DATA" && <span>&deg;F</span>}
                                          {this.state.returnAir !== null && this.state.returnAir !== "NO DATA" && (<SimpleTooltip direction="bottom">{ Number.parseFloat(this.state.returnAir).toFixed(2) }<span>&deg;F</span></SimpleTooltip>)}
                                      </div>
                                  </div>
                              </div>
                              <div className="col-12">
                                  <div className="snoozed">
                                      <p>
                                          {this.state.closedTemperature ? `(closed ${getDifferenceBetweenDates(this.state.closedTemperature.closed.closedAt, new Date())} ago)` :
                                              this.state.snoozedTemperature ? `(snoozed for ${getDifferenceBetweenDates(this.state.snoozedTemperature.snoozedIssue.snoozeStart, this.state.snoozedTemperature.snoozedIssue.snoozeEnd)})` : ""}
                                      </p>
                                  </div>
                              </div>
                          </div>

                          <div className="col-12 col-sm-3 latest-measurement">
                              Latest power reading: &nbsp;
                              {this.state.power === null || this.state.power === undefined ? <></> : !powerIssueExists ?
                                  <>
                                      <FontAwesomeIcon icon={faCheckCircle} className={`col-0 col-sm-0 issue-icon greenColorText ${this.state.isActiveTrailer === false && "greyColorText"}`}></FontAwesomeIcon>
                                  </> :
                                  <>
                                      <FontAwesomeIcon icon={faExclamationCircle} className={`col-0 col-sm-0 issue-icon redColorText ${(this.state.snoozedPower || this.state.closedPower) && "greyColorText"} ${this.state.isActiveTrailer === false && "greyColorText"}`}></FontAwesomeIcon>
                                  </>
                              }
                              <p className="measurement-type">
                                  Trailer is:
                              </p>
                              <div className={`measurement-info ${this.state.power === null || this.state.power === undefined ? "greyColorText" : (!powerIssueExists ? "greenColorText" : "redColorText")}  ${(this.state.snoozedPower || this.state.closedPower) && "greyColorText"} ${this.state.isActiveTrailer === false && "greyColorText"}`} id="actual-power">
                                  {this.state.power !== null && this.state.power !== undefined ? this.state.power : "NO DATA"} &nbsp;
                                  {this.state.power === null || this.state.power === undefined ? <></> : !powerIssueExists ?
                                      <>
                                          <FontAwesomeIcon icon={faCheckCircle} className={`col-0 col-sm-0 issue-icon greenColorText ${this.state.isActiveTrailer === false && "greyColorText"}`}></FontAwesomeIcon>
                                      </> :
                                      <>
                                          <FontAwesomeIcon icon={faExclamationCircle} className={`col-0 col-sm-0 issue-icon redColorText ${(this.state.snoozedPower || this.state.closedPower) && "greyColorText"} ${this.state.isActiveTrailer === false && "greyColorText"}`}></FontAwesomeIcon>
                                      </>
                                  }
                                  <div className="snoozed">
                                    <p>
                                        {this.state.closedPower ? `(closed ${getDifferenceBetweenDates(this.state.closedPower.closed.closedAt, new Date())} ago)` :
                                            this.state.snoozedPower ? `(snoozed for ${getDifferenceBetweenDates(this.state.snoozedPower.snoozedIssue.snoozeStart, this.state.snoozedPower.snoozedIssue.snoozeEnd)})` : ""}
                                    </p>
                                  </div>
                              </div>
                          </div>

                      </div>
                      <hr className="small-screen-only"></hr>
                      <div className="row">

                      </div>
                    </>}
                  </div>
              </div>
    )
  };
}

