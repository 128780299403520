import { check } from "global/services/RoleCheckService"

const RequiresRole = props => {
  return props.roles.some(role => check(role, props.perform, props.data)) ? props.yes() : props.no();
}

RequiresRole.defaultProps = {
  yes: () => null,
  no: () => null
};

export default RequiresRole;
