import React, { Component } from "react";

import SimpleContainer from 'components/container/SimpleContainer';
import TrailerSettings from "components/trailer-settings/TrailerSettings";

import "./DetailView.scss";

export default class LegCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            trailerSettingsVisible: !!(props.legInfo.active || props.isRelevant)
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.trailerSettingsVisible !== this.props.trailerSettingsVisible) {
            this.setState({
                trailerSettingsVisible: !!(this.props.legInfo.active || this.props.isRelevant)
            });
        }
    }
        
    render() {
        return (
            <div className="row">
                <div className="col-12 col-lg-12">
                    { this.state.trailerSettingsVisible && this.props.currentInfo &&
                        <div className="trailerSettingsDetails" >
                            <div className="trailer-settings">
                                <SimpleContainer title="TRAILER SETTINGS" className="trailer-settings">
                                    <TrailerSettings currentInfo={this.props.currentInfo}></TrailerSettings>
                                </SimpleContainer>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}
