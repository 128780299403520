import React, { Component } from 'react';
import AsyncSelect from 'react-select/async';

import { fleetUrl } from 'global/constants';
import AuthContext from 'AuthContext';

import './FleetSelect.scss';

export default class FleetSelect extends Component {
    static contextType = AuthContext;

    static defaultProps = {
        defaultLabel: 'None',
        defaultOption: { value: null, label: 'None' },
        onChange: () => null
    }

    constructor(props) {
        super(props);

        this.fetchFleets = this.fetchFleets.bind(this);
    }

    async fetchFleets() {
        try {
            const response = await this.context.get(fleetUrl, { active: true });

            const defaultOption = { value: null, label: this.props.defaultLabel };
            const fleetOptions = (response.data || []).map(fleet => ({
                value: fleet,
                label: fleet.name
            }));

            return [defaultOption, ...fleetOptions];
        } catch (error) {
            console.error(error);
            return [];
        }
    }

    render() {
        return (
            <AsyncSelect
                className="fleet-select"
                classNamePrefix="fleet-select"
                defaultValue={ this.props.defaultOption }
                loadOptions={ this.fetchFleets }
                onChange={ this.props.onChange }
                isSearchable={ false }
                defaultOptions
                cacheOptions
            />
        );
    }
}
