export default class StringUtils {

    static snakeCaseToCapitalized(string) {
        if (!string) {
            return string;
        }

        return string
            .toLowerCase()
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .reduce((prev, current) => prev + ' ' + current);
    }

    static pluralize(amount, word, suffix = 's') {
        return amount === 1 ? word : word + suffix;
    }

    static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static isBlank(field) {
        return !field || field.trim() === '';
    }
}
