/**
 * @description
 * Accepts an array of issues and a response object and maps them into paging info and an array of issues
 * that are to be used on all grid views.
 *
 * @param issues Issues of a certain type that were mapped from the response object.
 * @param response The response returned by the API for getting detailed information on issues of specific types.
 *
 * @returns An object containing an array of issues and a pagination object.
 */

 function mapGridResponseData(issues, response, numAlarms = 0) {
    return {
        issues: Array.from(issues),
        pagingInfo: {
            itemNumber: Math.min(response.pageSize, response.data.length - numAlarms),
            totalItemNumber: response.available - numAlarms,
            totalPageNumber: Math.ceil(response.available / response.pageSize),
            currentPage: Math.min(response.pageNumber, Math.ceil(response.available / response.pageSize)),
            recordsNumber: response.pageSize
        }
    }
  }
  
  export default mapGridResponseData;
