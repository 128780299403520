import React, { Component } from 'react';
import ReactDOM from "react-dom";
import AuthContext from "AuthContext";
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faPlusCircle, faUser } from '@fortawesome/pro-solid-svg-icons';
import DataTableComp from 'components/datatable/DataTableComp';
import AddMemberPopup from 'components/popup/AddMemberPopup';
import DeletePopup from 'components/popup/DeletePopup';
import { contactToGroupUrl } from 'global/constants';

import "./GroupMembers.scss";

class GroupMembers extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.columns = [
            { title: "Members", data: "name" }, //0
            { title: "Email", data: "email" }, //1
            { title: "Action", data: "action", defaultContent: "" }, //2
        ];

        this.sortingRule = [
            //to be changed accordingly
        ];

        this.columnDefs = [
            { width: "30%", "targets": [0] },
            { width: "65%", "targets": [1] },
            { width: "5%", "targets": [2] },
            { className: "dt-align-left", targets: [0, 1] },
            { className: "dt-align-center", targets: [2] },
            {
                searchable: false,
                targets: [0, 1, 2], //change this when implement search option
            },
            {
                orderable: false,
                targets: [0, 1, 2]
            },
            {
                targets: 0,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="name">
                            <FontAwesomeIcon icon={faUser} style={{ marginRight: 3, marginBottom: 1 }}/>
                            {rowData.name}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 1,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="email">
                            {rowData.email}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 2,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="action">
                            <FontAwesomeIcon className="delete-icon" icon={faTimesCircle} onClick={() => this.showRemovePopup(rowData)}/>
                        </div>,
                        td
                    );
                }
            },
        ];

        this.state = {
            columns: this.columns,
            columnDefs: this.columnDefs,
            sortingRule: this.sortingRule,
            showAddPopup: false,
            showRemovePopup: false,
            member: null,
        };
    }

    showAddPopup() {
        this.setState({
            showAddPopup: true
        });
    }

    closeAddPopup(member) {
        this.setState({
            showAddPopup: false
        });
        if (member) {
            this.props.addMember(member, this.props.contactGroup);
        }
    }

    showRemovePopup = (member) => {
        this.setState({
            showRemovePopup: true,
            member,
        });
    }

    closeRemovePopup(updatedGroup) {
        this.setState({
            showRemovePopup: false
        });
        if (updatedGroup) {
            this.props.updateContactGroup(updatedGroup);
        }
    }

    render() {
        return (
            <div className="group-members">
                {this.props.members.length !== 0 ?
                    <>
                        <DataTableComp
                            tableId={this.props.contactGroup.id}
                            columns={this.state.columns}
                            orderRule={this.state.sortingRule}
                            columnDefs={this.state.columnDefs}
                            data={this.props.members}
                        />
                        <div className="add-container" onClick={this.showAddPopup.bind(this)}>
                            <FontAwesomeIcon style={{ marginRight: 6 }} icon={faPlusCircle} className="add-icon" />
                            Add Member
                        </div>
                    </> : 
                    <div className="no-members-container">
                        <FontAwesomeIcon icon={faUser} className="user-icon" />
                        <div className="no-member-title">
                            No members yet.
                        </div>
                        <Button variant="continue" onClick={this.showAddPopup.bind(this)}>
                            <FontAwesomeIcon icon={faPlusCircle} className="add-icon" />
                            ADD FIRST MEMBER
                        </Button>
                    </div>
                }
                <AddMemberPopup
                    closeModal={this.closeAddPopup.bind(this)}
                    showModal={this.state.showAddPopup}
                    contactGroup={this.props.contactGroup}
                />
                <DeletePopup
                    onClose={this.closeRemovePopup.bind(this)}
                    show={this.state.showRemovePopup}
                    url={contactToGroupUrl}
                    params={{
                        contactGroupId: this.props.contactGroup.id,
                        contactInfoId: this.state.member && this.state.member.id
                    }}
                    title="MEMBER"
                    subtitle="Remove Member?"
                >
                    Are you sure you want to remove <span className="bold">“{this.state.member && this.state.member.name}”</span> from the group <span className="bold">“{this.props.contactGroup.name}”</span>?
                </DeletePopup>
            </div>
        )
    }
}

export default GroupMembers;
