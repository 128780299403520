import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Loader from 'react-loader-spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faTimesCircle,
    faSlidersH,
    faTruck,
    faInfoCircle,
    faEdit,
    faRedo
} from '@fortawesome/pro-solid-svg-icons';
import { Button } from 'react-bootstrap';
import $ from 'jquery';

import AuthContext from 'AuthContext';
import DataTableComp from 'components/datatable/DataTableComp';
import ManageManifestPopup from 'components/popup/ManageManifestPopup';
import AddOrEditTrailerPopup from 'components/popup/AddOrEditTrailerPopup';
import SimpleTooltip from 'components/tooltip/SimpleTooltip';
import {
    defaultGridRecordsNumber,
    mapSort,
    trailerWithDetailsUrl,
    MODE_MAPPING,
    deletedTrailerUrl
} from 'global/constants';
import ActiveSelection from 'components/input/ActiveSelection';
import RemoveTrailerPopup from 'components/popup/RemoveTrailerPopup';
import { formatLongDateWithoutSeconds } from 'global/services/DateTimeService';
import QueryParamService from 'global/services/QueryParamService';
import formSortObject from 'global/utils/formSortObject';

import StringUtils from 'global/utils/StringUtils';

import './FleetOverview.scss';

export default class FleetOverview extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.activeColumnNames = {
            0: "TRAILER",
            2: "FLEET_NAME"
        };

        this.inactiveColumnNames = {
            0: "TRAILER",
            1: "DELETED_AT",
            2: "DELETED_BY"
        };

        this.activeColumns = [
            { title: "Trailer #", data: "businessId" },
            { title: "Manifest #", data: "manifestId" },
            { title: "Fleet", data: "fleetName" },
            { title: "Last known address", data: "address" },
            { title: "Fuel level", data: "fuelLevel" },
            { title: "Min temp.", data: "minTemp" },
            { title: "Set point", data: "setPoint" },
            { title: "Return air", data: "returnAir" },
            { title: "Max temp.", data: "maxTemp" },
            { title: "Mode", data: "modeC" },
            { title: "Mode R", data: "modeR" },
            { title: "Expected power", data: "expectedPower" },
            { title: "Actual power", data: "actualPower" },
            { title: "Manifest status", data: "active" },
            { title: "Actions", data: "actions", defaultContent: "" }
        ];

        this.inactiveColumns = [
            { title: "Trailer #", data: "trailer.businessId" },
            { title: "Removed at", data: "deletedAt", defaultContent: "" },
            { title: "Removed by", data: "deletedBy", defaultContent: "" },
            { title: "Actions", data: "actions", defaultContent: "" }
        ];

        this.activeColumnDefs = [
            { width: "6%", targets: [0, 1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14] },
            { width: "16%", "targets": [3] },
            { className: "dt-align-right", targets: [0, 1, 5, 6, 7, 8] },
            { className: "dt-align-center", targets: [14] },
            {
                orderable: false,
                targets: [1, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
            },
            {
                targets: 0,
                createdCell: (td, cellData, rowData, row, col) => {
                    const hasMeasurements = rowData.hasMeasurements;

                    let textColor = hasMeasurements ? "blueColorText" : "orangeColorText";

                    ReactDOM.render(
                        <div className={ "no-flex trailer link " + textColor}>
                            { rowData.businessId }
                            { !hasMeasurements &&
                                <div className="tooltip-container">
                                    <FontAwesomeIcon icon={ faInfoCircle } className="tooltip-info-icon"/>
                                    <SimpleTooltip direction="right">
                                        This trailer has not received any TK measurements yet. Check if the Trailer ID is valid.
                                    </SimpleTooltip>
                                </div>
                            }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 1,
                createdCell: (td, cellData, rowData) => {
                    const contentDecorator = rowData.manifestId !== 'No manifest' ? 'blueColorText assignment link' : '';

                    ReactDOM.render(
                        <div className={ contentDecorator }>
                            { rowData.manifestId }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 2,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="fleet-cell">
                            { rowData.fleetName }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="no-flex" style={{ minWidth: '40px' }}>
                            { rowData.address }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 4,
                createdCell: (td, _cellData, rowData) => {
                    ReactDOM.render(
                        <div>
                            { rowData.fuelLevel }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 5,
                createdCell: (td, cellData, rowData) => {
                    const hasData = (rowData.minTemp || rowData.minTemp === 0) && rowData.minTemp !== "NO DATA";

                    ReactDOM.render(
                        <div>
                            { hasData ? <>{ Number.parseFloat(rowData.minTemp).toFixed(0) }<span>&deg;F</span></> : 'NO DATA' }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 6,
                createdCell: (td, cellData, rowData) => {
                    const hasData = (rowData.setPoint || rowData.setPoint === 0) && rowData.setPoint !== "NO DATA";

                    ReactDOM.render(
                        <div>
                            { hasData ? <>{ Number.parseFloat(rowData.setPoint).toFixed(0) }<span>&deg;F</span></> : 'NO DATA' }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 7,
                createdCell: (td, cellData, rowData) => {
                    const hasData = (rowData.returnAir || rowData.returnAir === 0) && rowData.returnAir !== "NO DATA";

                    ReactDOM.render(
                        <div>
                            { hasData ? <>{ Number.parseFloat(rowData.returnAir).toFixed(0) }<span>&deg;F</span></> : 'NO DATA' }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 8,
                createdCell: (td, cellData, rowData) => {
                    const hasData = (rowData.maxTemp || rowData.maxTemp === 0) && rowData.maxTemp !== "NO DATA";

                    ReactDOM.render(
                        <div>
                            { hasData ? <>{ Number.parseFloat(rowData.maxTemp).toFixed(0) }<span>&deg;F</span></> : 'NO DATA' }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 9,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div>
                            { rowData.modeC }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 10,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div>
                            { rowData.modeR }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 11,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div>
                            { rowData.expectedPower }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 12,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div>
                            { rowData.actualPower }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 13,
                createdCell: (td, cellData, rowData) => {
                    let label, icon, iconDecorator;

                    if (rowData.active) {
                        label = 'Active';
                        icon = faCheckCircle;
                        iconDecorator = 'greenColorText';
                    } else {
                        label = 'Inactive';
                        icon = faTimesCircle;
                        iconDecorator = 'greyColorText';
                    }

                    ReactDOM.render(
                        <div>
                            <FontAwesomeIcon className={ iconDecorator } icon = { icon }/> { label }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 14,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="flex-always">
                            <FontAwesomeIcon
                                className="action-icon clickable"
                                icon={ faEdit }
                                onClick={ () => this.showEditTrailersPopup(rowData) }
                            />
                            <FontAwesomeIcon
                                className="action-icon clickable"
                                icon={ faSlidersH }
                                onClick={ () => this.showManifestPopup(rowData) }
                            />
                            <FontAwesomeIcon
                                className="action-icon clickable"
                                icon={ faTimesCircle }
                                onClick={ () => this.showRemoveTrailerPopup(rowData) }
                            />
                        </div>,
                        td
                    );
                }
            }
        ];

        this.inactiveColumnDefs = [
            { width: "10%", targets: [0, 3] },
            { width: "40%", "targets": [1, 2] },
            { className: "dt-align-right", targets: [0] },
            { className: "dt-align-center", targets: [3] },
            {
                orderable: false,
                targets: [3]
            },
            {
                targets: 0,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div className="trailer link blueColorText">
                            { rowData.trailer.businessId }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 1,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div>
                            { rowData.deletedAt ? formatLongDateWithoutSeconds(new Date(rowData.deletedAt)) : "Unknown" }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 2,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <div>
                            { rowData.deletedBy ? rowData.deletedBy : "Unknown" }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 3,
                createdCell: (td, cellData, rowData) => {
                    ReactDOM.render(
                        <Button variant="continue" onClick={ () => this.showRestoreTrailersPopup(rowData) }>
                            <FontAwesomeIcon className="redo-icon" icon={ faRedo } />
                            RESTORE
                        </Button>,
                        td
                    );
                }
            }
        ];

        const page = parseInt(QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'page', 1));
        const activeTrailers = QueryParamService.parseSimpleValueFromQueryString(window.location.search.slice(1), 'relevant', "true") === "true";

        QueryParamService.addFleets(JSON.parse(localStorage.getItem('fleets') || '[]'));

        this.state = {
            data: [],
            dataLoaded: false,
            dataUpdated: false,
            columns: activeTrailers ? this.activeColumns : this.inactiveColumns,
            columnNames: activeTrailers ? this.activeColumnNames : this.inactiveColumnNames,
            columnDefs: activeTrailers ? this.activeColumnDefs : this.inactiveColumnDefs,
            sortingRule: [],
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber
            },
            showManifestPopup: false,
            showAddTrailersPopup: false,
            showRemoveTrailerPopup: false,
            activeTrailers,
            page,
            sort: [],
            trailer: null
        };

        this.closeAddOrEditTrailerPopup = this.closeAddOrEditTrailerPopup.bind(this);
        this.closeRemoveTrailerPopup = this.closeRemoveTrailerPopup.bind(this);
    }

    componentDidMount() {
        this.fetchFleet();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fleets !== this.props.fleets) {
            this.state.page !== 1 ? this.handleFirstPage() : this.fetchFleet();
        }
    }

    async fetchFleet() {
        try {
            this.setState({
               dataUpdated: false
            });

            const sort = this.state.sort.length > 0 ? mapSort(this.state.sort) : QueryParamService.parseSortingQueryString(window.location.search.slice(1));
            const tableSortObject = formSortObject(sort, this.state.columnNames);
            const activeTrailers = this.state.activeTrailers;
            const url = activeTrailers ? trailerWithDetailsUrl : deletedTrailerUrl;

            const response = await this.context.get(url, {
                sort: sort,
                pageNumber: this.state.page || 1,
                pageSize: this.state.pagingInfo.recordsNumber
            });

            if (response.status === "error") {
                return {};
            }

            const data = response.data;
            if (!data) {
                this.setState({
                    dataLoaded: true,
                    dataUpdated: true
                });
                return;
            }

            let currentPage = Math.min(response.pageNumber, Math.ceil(response.available / response.pageSize));
            let queryString = window.location.search.slice(1);
            QueryParamService.addPageToQueryString(queryString, currentPage || 1);

            let mappedData;
            if (activeTrailers) {
                mappedData = data.map(
                    trailerWithManifest => ({
                        trailer: trailerWithManifest.trailer,
                        manifest: trailerWithManifest.leg ? trailerWithManifest.leg.assignment : null,
                        fleet: trailerWithManifest.trailer.fleet,
                        businessId: trailerWithManifest.trailer.businessId,
                        fleetName: trailerWithManifest.trailer.fleet ? trailerWithManifest.trailer.fleet.name : '-',
                        manifestId: trailerWithManifest.leg ? trailerWithManifest.leg.assignment.businessId : 'No manifest',
                        address: trailerWithManifest.positionState && trailerWithManifest.positionState.position ? trailerWithManifest.positionState.position : 'Unknown',
                        fuelLevel: (trailerWithManifest.engineState || {}).fuelPercentage !== undefined ? `${trailerWithManifest.engineState.fuelPercentage}%` : 'Unknown',
                        minTemp: trailerWithManifest.leg && trailerWithManifest.leg.assignment.temperatureMin !== "-Infinity" ? trailerWithManifest.leg.assignment.temperatureMin : 'NO DATA',
                        setPoint: trailerWithManifest.temperatureState && (trailerWithManifest.temperatureState.setTemperature || trailerWithManifest.temperatureState.setTemperature === 0) ? trailerWithManifest.temperatureState.setTemperature : 'NO DATA',
                        returnAir: trailerWithManifest.temperatureState && (trailerWithManifest.temperatureState.actualTemperature || trailerWithManifest.temperatureState.actualTemperature === 0) ? trailerWithManifest.temperatureState.actualTemperature : 'NO DATA',
                        maxTemp: trailerWithManifest.leg && trailerWithManifest.leg.assignment.temperatureMax !== "Infinity" ? trailerWithManifest.leg.assignment.temperatureMax : 'NO DATA',
                        modeC: trailerWithManifest.leg ? MODE_MAPPING[trailerWithManifest.leg.assignment.unitMode] : 'NO DATA',
                        modeR: trailerWithManifest.modeState && trailerWithManifest.modeState.unitMode ? MODE_MAPPING[trailerWithManifest.modeState.unitMode] : 'NO DATA',
                        expectedPower: trailerWithManifest.leg ? (trailerWithManifest.leg.assignment.power ? 'On' : 'Off') : 'NO DATA',
                        actualPower: trailerWithManifest.powerState && trailerWithManifest.powerState.power !== undefined ? (trailerWithManifest.powerState.power ? 'On' : 'Off') : 'NO DATA',
                        active: trailerWithManifest.leg ? trailerWithManifest.leg.assignment.active : false,
                        onClickState: trailerWithManifest.leg ? { trailerId: trailerWithManifest.trailer.id, assignmentId: trailerWithManifest.leg.assignment.id } : { trailerId: trailerWithManifest.trailer.id },
                        hasMeasurements: trailerWithManifest.hasMeasurements,
                        hasRelevantIssues: trailerWithManifest.hasRelevantIssues
                    })
                );
            } else {
                mappedData = data.map(deletedTrailer => ({
                    ...deletedTrailer,
                    onClickState: {
                        trailerId: deletedTrailer.trailer.id
                    }
                }));
            }

            this.setState({
                data: mappedData,
                columns: activeTrailers ? this.activeColumns : this.inactiveColumns,
                columnNames: activeTrailers ? this.activeColumnNames : this.inactiveColumnNames,
                columnDefs: activeTrailers ? this.activeColumnDefs : this.inactiveColumnDefs,
                dataLoaded: true,
                dataUpdated: true,
                pagingInfo: {
                    itemNumber: Math.min(response.pageSize, response.data.length),
                    totalItemNumber: response.available,
                    totalPageNumber: Math.ceil(response.available / response.pageSize),
                    currentPage: Math.min(response.pageNumber, Math.ceil(response.available / response.pageSize)),
                    recordsNumber: response.pageSize
                },
                sort: tableSortObject
            });
        } catch(error) {
            console.error(error);
            this.setState({
                data: Array.from([]),
                dataLoaded: true,
                dataUpdated: true
            });
        }
    }

    showManifestPopup(trailerWithManifest) {
        const { trailer, manifest } = trailerWithManifest || {};

        this.setState({
            manifest,
            trailer,
            showManifestPopup: true
        });
    }

    showAddTrailersPopup() {
        this.setState({
            showAddTrailersPopup: true,
            popupType: "add"
        });
    }

    showEditTrailersPopup(rowData) {
        this.setState({
            showAddTrailersPopup: true,
            trailer: rowData.trailer,
            popupType: "edit"
        });
    }

    showRestoreTrailersPopup(rowData) {
        this.setState({
            showAddTrailersPopup: true,
            trailer: rowData.trailer,
            popupType: "restore"
        });
    }

    showRemoveTrailerPopup(rowData) {
        this.setState({
            showRemoveTrailerPopup: true,
            manifest: rowData.manifest,
            trailer: rowData.trailer,
            hasRelevantIssues: rowData.hasRelevantIssues
        });
    }

    closeManifestPopup(didAdd) {
        this.setState({
            showManifestPopup: false,
            trailer: null
        }, () => {
            if (didAdd) {
                this.fetchFleet();
            }
        });
    }

    closeAddOrEditTrailerPopup(didAdd) {
        if (didAdd) {
            this.setState(({
                showAddTrailersPopup: false,
                trailer: null,
                popupType: "",
                activeTrailers: true
            }), () => $(`button[id=relevant]`).trigger('click'));
        } else {
            this.setState({
                showAddTrailersPopup: false,
                trailer: null,
                popupType: ""
            });
        }
    }

    closeRemoveTrailerPopup(isRemoved) {
        this.setState(({
            showRemoveTrailerPopup: false,
            trailer: null
        }), () => {
            if (isRemoved) {
                this.fetchFleet();
            }
        });
    }

    async handlePage(page) {
        this.setState({
            page: page
        }, async () => {
            await this.fetchFleet();
            this.setState({
                pagingInfo: {
                    ...this.state.pagingInfo,
                    currentPage: this.state.page
                }
            });
        });
    }

    handleFirstPage = async () => {
        await this.handlePage(1);
    }

    handleLastPage = async () => {
        await this.handlePage(parseInt(this.state.pagingInfo.totalPageNumber));
    }

    handlePreviousPage = async () => {
        await this.handlePage(parseInt(this.state.page) - 1);
    }

    handleNextPage = async () => {
        await this.handlePage(parseInt(this.state.page) + 1);
    }

    handleRecordsNumber = async (event) => {
        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: 1,
                recordsNumber: parseInt(event.target.value),
            },
            page: 1
        }, async () => {
            await this.fetchFleet();
        });
    }

    handlePageChange = async (event) => {
        const value = event.target.value;

        if (isNaN(value) || value === "") {
            this.setState({
                page: ""
            });
        } else {
            this.setState({
                page: parseInt(value)
            });
            const that = this;
            setTimeout(async function () {
                await that.fetchFleet();
            }, 1000);
        }

        this.setState({
            pagingInfo: {
                ...this.state.pagingInfo,
                currentPage: this.state.page
            }
        });
    }

    addSortingForColumn(index, sorting) {
        const sortingArray = this.state.sort;
        const existing = sortingArray.findIndex(x => x && x.columnName === this.state.columnNames[index]);

        existing === -1 ? sortingArray.push({
            columnIndex: index,
            columnName: this.state.columnNames[index],
            direction: sorting
        }) : sorting === "" ? sortingArray.splice(existing, 1) : sortingArray[existing] = {
            columnIndex: index,
            columnName: this.state.columnNames[index],
            direction: sorting
        }

        let queryString = window.location.search.slice(1);
        QueryParamService.addSortToQueryString(queryString, sortingArray);

        this.setState({
            sort: sortingArray
            }, async () => {
                await this.fetchFleet();
            }
        );
    }

    changeActiveness(activeTrailers) {
        QueryParamService.addValueToQueryString(window.location.search.slice(1), 'relevant', activeTrailers);

        this.setState({
            activeTrailers,
            page: 1,
            pagingInfo: {
                recordsNumber: defaultGridRecordsNumber,
            },
            dataLoaded: false
        }, this.fetchFleet);
    }

    render() {
        return (
            <div className="page fleet-overview">
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <a href="#!">Fleet Overview</a>
                        </li>
                    </ol>
                </nav>
                <div className="heading">
                    Fleet Overview <span className="total-trailers">({this.state.pagingInfo.totalItemNumber} {StringUtils.pluralize(this.state.pagingInfo.totalItemNumber, "Trailer", "s")})</span> <Loader type="TailSpin" color="#289AC2" height={ 47 } width={ 47 } visible={ !this.state.dataLoaded || !this.state.dataUpdated } />
                </div>
                <div className="fleet-actions">
                    <div className="fleet-buttons">
                        <ActiveSelection
                            relevantLabel="Fleet Trailers"
                            irrelevantLabel="Removed Trailers"
                            activeCallback={ this.changeActiveness.bind(this) }
                            initiallyActive={ this.state.activeTrailers }
                        />
                        <Button
                            variant="continue"
                            className="trailers-button create" onClick={ () => this.showAddTrailersPopup() }
                        >
                            <FontAwesomeIcon icon={ faTruck } className="icon-trailers"/>
                            <span>
                                ADD TRAILER
                            </span>
                        </Button>
                    </div>
                </div>
                <div className="fleetTableDiv">
                    { this.state.dataLoaded && (
                        <DataTableComp
                            tableId="fleetOverview"
                            columns={ this.state.columns }
                            orderRule={ this.state.sortingRule }
                            data={ this.state.data }
                            columnDefs={ this.state.columnDefs }
                            isDetailView={ false }
                            customTableClass="cell-border"
                            tableHeight="1200px"
                            handlePreviousPage={ this.handlePreviousPage }
                            handleNextPage={ this.handleNextPage }
                            handleFirstPage={ this.handleFirstPage }
                            handleLastPage={ this.handleLastPage }
                            handleRecordsNumber={ (event) => this.handleRecordsNumber(event) }
                            handlePageChange={ (event) => this.handlePageChange(event) }
                            pagingInfo={ this.state.pagingInfo }
                            account={ this.props.account }
                            sortRule={ this.state.sort.concat([]) }
                            addSortingForColumn={ this.addSortingForColumn.bind(this) }
                        />
                    ) }
                    <ManageManifestPopup
                        close={ this.closeManifestPopup.bind(this) }
                        show={ this.state.showManifestPopup }
                        title="Manage Tracking"
                        isManifestEditable={ true }
                        manifest={ this.state.manifest }
                        trailer={ this.state.trailer }
                    />
                    <AddOrEditTrailerPopup
                        onClose={ this.closeAddOrEditTrailerPopup }
                        show={ this.state.showAddTrailersPopup }
                        trailer={ this.state.trailer }
                        type={ this.state.popupType }
                    />
                    <RemoveTrailerPopup
                        onClose={ this.closeRemoveTrailerPopup }
                        show={ this.state.showRemoveTrailerPopup }
                        manifest={ this.state.manifest }
                        trailer={ this.state.trailer }
                        hasRelevantIssues={ this.state.hasRelevantIssues }
                    />
                </div>
            </div>
        );
    }
}
