export const MONITORED_ISSUE_TYPES = Object.freeze({
    POWER_STATE: "Power issue",
    TEMPERATURE: "Temp. issue",
    UNIT_MODE: "Unit mode issue",
    SILENT: "Silent unit",
    LOW_FUEL_ALARM: "Low fuel level",
    INACTIVITY: "Inactive trailer",
    ALARM: "Alarm code"
});

export const issueTypes = Object.freeze({
    "TEMPERATURE": "Temp. issue",
    "UNIT_MODE": "Unit mode issue",
    "POWER_STATE": "Power issue",
    "SILENT": "Silent unit",
    "ALARM": "Alarm code",
    "INACTIVITY": "Inactive trailer",
    "LOW_FUEL_ALARM": "Low fuel level",
    "DEFAULT": "Unknown issue"
})

export function issueTypeMap(type) {
    switch (type) {
        case "TEMPERATURE":
            return issueTypes.TEMPERATURE;
        case "UNIT_MODE":
            return issueTypes.UNIT_MODE;
        case "POWER_STATE":
            return issueTypes.POWER_STATE;
        case "SILENT":
            return issueTypes.SILENT;
        case "ALARM":
            return issueTypes.ALARM;
        case "INACTIVITY":
            return issueTypes.INACTIVITY;
        case "LOW_FUEL_ALARM":
            return issueTypes.LOW_FUEL_ALARM;
        default:
            return issueTypes.DEFAULT;
    }
}

export const issueType = Object.freeze({
    TEMPERATURE: "TEMPERATURE",
    UNIT_MODE: "UNIT_MODE",
    POWER_STATE: "POWER_STATE",
    ALARM: "ALARM",
    LOW_FUEL_ALARM: "LOW_FUEL_ALARM",
    SILENT: "SILENT",
    INACTIVITY: "INACTIVITY"
})

export function issueSnoozeable(issue) {
    const relevant = issue.relevant;
    const issueState = issue.state;

    if (relevant) {
        if (issueState === "WARNED" || issueState === "ESCALATED") {
            return 1;
        } else if (issueState === "SNOOZED") {
            return 2;
        }
    } else {
        return 0;
    }
}
