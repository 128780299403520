import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import SimpleTooltip from 'components/tooltip/SimpleTooltip';

import './NavBar.scss';

export default class NavItem extends Component {

    render() {
        const title = this.props.title ? <p>{ this.props.title }</p> : <></>;

        let icon = <></>;
        if (this.props.icon) {
            if (this.props.tooltip) {
                icon = (
                    <div className="tooltip-container" style={{ position: 'relative' }}>
                        <FontAwesomeIcon icon={ this.props.icon } className="icon" />
                        <SimpleTooltip direction="right">
                            { this.props.tooltip }
                        </SimpleTooltip>
                    </div>
                );
            } else {
                icon = <FontAwesomeIcon icon={ this.props.icon } />;
            }
        }

        return (
            <div className="nav-item">
                { icon }
                { title }
            </div>
        );
    }
}
