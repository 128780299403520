import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';

import './CustomDropdown.scss';

export default class CustomDropdown extends Component {

    static defaultProps = {
        className: '',
        title: '',
        items: [],
        disabled: false
    }

    render() {
        const { className, title, items, disabled } = this.props;

        return (
            <Dropdown bsPrefix="custom-dropdown" className={ className }>
                <Dropdown.Toggle id="custom-dropdown-toggle" disabled={ disabled || !items.length } variant="custom">
                    { title }
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    { items.map((item, i) => (
                        <Dropdown.Item key={ i } as="div" onClick={ item.props.onClick }>
                            { item }
                        </Dropdown.Item>
                    )) }
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
