import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { managerCloseReasonsUrl, managerSnoozeReasonsUrl } from 'global/constants';
import AuthContext from "AuthContext";
import SimpleContainer from 'components/container/SimpleContainer';

import './AddReasonPopup.scss';

export default class AddReasonPopup extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            initialReason: this.props.reason ? this.props.reason : {},
            reason: null,
            extended: this.props.reason && this.props.reason.extended,
            submittable: false,
            characterCount: (this.props.reason && this.props.reason.reason.length) || 0
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                initialReason: this.props.reason ? this.props.reason : {},
                reason: null,
                extended: this.props.reason && this.props.reason.extended,
                submittable: false,
                characterCount: (this.props.reason && this.props.reason.reason.length) || 0,
            });
        }
    }

    handleSubmit = async () => {
        const url = this.props.snooze ? managerSnoozeReasonsUrl : managerCloseReasonsUrl;
        try {
            let response;
            if (this.props.reason) {
                response = await this.context.put(url, {
                    ...this.props.reason,
                    reason: (this.state.reason || this.state.initialReason.reason).trim(),
                    extended: this.state.extended
                });
            } else {
                response = await this.context.post(url, {
                    reason: (this.state.reason || this.state.initialReason.reason).trim(),
                    extended: this.state.extended
                });
            }
            if (!response || response.status === "error") {
                this.props.closeModal();
                return;
            }
            this.props.closeModal(response, !!this.props.reason);
        } catch (error) {
            console.error(error);
            this.props.closeModal();
        }
    }

    onChange = (event) => {
        const value = event.target.value;

        this.setState(state => ({
            reason: value,
            characterCount: value.length,
            submittable: value && /\S/.test(value) && (!this.props.edit || value !== state.initialReason.reason || state.extended !== state.initialReason.extended)
        }));
    }

    onCheckboxChange = (event) => {
        const checked = event.target.checked;
        const reason = this.state.reason === null && this.state.initialReason ? this.state.initialReason.reason : this.state.reason;
        this.setState(state => ({
            extended: checked,
            submittable: reason && /\S/.test(reason) && (!this.props.edit || reason !== state.initialReason.reason || checked !== state.initialReason.extended)
        }));
    }

    onClose = () => this.props.closeModal()

    render() {
        return (
            <Modal
                show={this.props.showModal}
                onHide={this.onClose}
                backdrop="static"
                keyboard={false}
                centered
                dialogClassName="add-reason-modal"
            >
                <SimpleContainer className="modal-container" modal title={this.props.edit ? "EDIT REASON" : "NEW REASON"}>
                    {this.props.edit &&
                        <div className="warning-text">
                            *Editing this {this.props.snooze ? "Snooze" : "Close"} Issue Reason will affect all existing records where this reason has been selected
                        </div>
                    }
                    <p className="action-label">{this.props.snooze ? "Snooze" : "Close"} Issue Reason: </p>
                    <textarea
                        key={this.state.initialReason.reason}
                        defaultValue={this.state.initialReason.reason}
                        onChange={this.onChange}
                        className="reason-box"
                        maxLength="240"
                        required
                    />
                    <div className="character-count">
                        {this.state.characterCount}/240
                    </div>
                    <div className="form-check">
                        <label className="checkbox-label">
                            <input
                                key={this.state.initialReason.extended}
                                type="checkbox"
                                defaultChecked={this.state.extended}
                                onChange={this.onCheckboxChange.bind(this)}
                                className="form-check-input"
                            />
                            Note Required
                        </label>
                    </div>
                    <div className="buttons">
                        <Button variant="light" onClick={this.onClose} className="cancel-button">Cancel</Button>
                        <Button variant="continue" className="add-button" disabled={!this.state.submittable} onClick={this.handleSubmit}>{this.props.edit ? "Edit" : "Add"} reason</Button>
                    </div>
                </SimpleContainer>
            </Modal>
        );
    }
}

AddReasonPopup.contextType = AuthContext;
