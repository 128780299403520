import React, { Component } from "react";
import AuthContext from 'AuthContext';
import { capitalFirstMap } from "global/constants";

import "./DetentionInfoTooltip.scss";

export default class DetentionInfoTooltip extends Component {
    static contextType = AuthContext; 
    constructor(props) {
        super();
        this.state = {
            title: props.title,
            detentionIssue: props.detentionIssue || { metaData: {}, trailer: {} },
            detentionState: props.detentionState || { company: {} },
            company: "",
            location: "",
            loaded: false
        }
    }

    componentDidUpdate(props) {
        if (props !== this.props) {
            this.setState({
                title: this.props.title,
                detentionIssue: this.props.detentionIssue || { metaData: {}, trailer: {} },
                detentionState: this.props.detentionState || { company: {} }
            });
        }
    }

    async componentDidMount() {
        this.setState({
            loaded: true,
        })
    }

    render() {
        return (
            this.state.loaded ? <div className="detention-tooltip">
                <div className="arrow-box">
                    <p className="detention-title">
                        Detained trailer
                    </p>
                    <div className="inline-text">
                      <p className="info-label inline-info-label">
                          Trailer ID:
                      </p>
                      <p className="gathered-info inline-gathered-info">
                          {this.state.detentionIssue.trailer.businessId}
                      </p>
                    </div>
                    <p className="info-label" style={{marginBottom: "20px"}}>
                      <svg width="200" height="1" viewBox="0 0 200 1" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="200" height="1" fill="#E2E5E9"/>
                      </svg>
                    </p>
                    <p className="info-label">
                        Detention location:
                    </p>
                    <p className="gathered-info">
                       {this.state.detentionIssue.metaData.companyAddress || this.state.detentionState.location || "Unknown"}
                    </p>
                    <p className="info-label">
                        Nearby company:
                    </p>
                    <p className="gathered-info">
                        {this.state.detentionIssue.metaData.companyName || (this.state.detentionState.company && this.state.detentionState.company.name) || "Unknown"}
                    </p>
                    <p className="info-label">
                        Detention reason:
                    </p>
                    <p className="gathered-info last">
                       {capitalFirstMap(this.state.detentionIssue.metaData.type) || "Unknown"}
                    </p>
                </div>
            </div> : <></>
        );
    }

}