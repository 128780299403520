import React, { Component } from 'react';
import ReactDOM from "react-dom";
import { faPlusCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'react-bootstrap';
import SimpleContainer from 'components/container/SimpleContainer';
import DataTableComp from 'components/datatable/DataTableComp';
import AddReasonPopup from 'components/popup/AddReasonPopup';
import DeleteReasonPopup from 'components/popup/DeleteReasonPopup';

import "./SnoozeIssueReasons.scss";

class SnoozeIssueReasons extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            { title: "Reason", data: "reason" }, //0
            { title: "Action", data: "action", defaultContent: "" }, //1
        ];

        this.sortingRule = [
            //to be changed accordingly
        ];

        this.columnDefs = [
            { width: "90%", "targets": [0] },
            { width: "10%", "targets": [1] },
            { className: "dt-align-left", targets: [0] },
            { className: "dt-align-center", targets: [1] },
            {
                searchable: false,
                targets: [0, 1], //change this when implement search option
            },
            {
                orderable: false,
                targets: [0, 1]
            },
            {
                targets: 0,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="reason">
                            {rowData.reason}
                            {rowData.extended && 
                                <span className="note">
                                    (Note Required)
                                </span>
                            }
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 1,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="actions">
                            <FontAwesomeIcon icon={faEdit} onClick={() => this.editReasonClick(rowData)}/>
                            <FontAwesomeIcon icon={faTrash} onClick={() => this.deleteReasonClick(rowData)}/>
                        </div>,
                        td
                    );
                }
            },
        ];

        this.otherColumns = [
            { title: "Note", data: "reason" }, //0
            { title: "Count", data: "count" }, //1
        ];

        this.otherColumnDefs = [
            { width: "90%", "targets": [0] },
            { width: "10%", "targets": [1] },
            { className: "dt-align-left", targets: [0] },
            { className: "dt-align-right", targets: [1] },
            {
                searchable: false,
                targets: [0, 1], //change this when implement search option
            },
            {
                orderable: false,
                targets: [0, 1]
            },
            {
                targets: 0,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div className="reason">
                            {rowData.reason}
                        </div>,
                        td
                    );
                }
            },
            {
                targets: 1,
                createdCell: (td, cellData, rowData, row, col) => {
                    ReactDOM.render(
                        <div>
                            {rowData.count}
                        </div>,
                        td
                    );
                }
            },
        ];

        this.state = {
            columns: this.columns,
            columnDefs: this.columnDefs,
            otherColumns: this.otherColumns,
            otherColumnDefs: this.otherColumnDefs,
            sortingRule: this.sortingRule,
            showPopup: false,
            showDeletePopup: false,
            edit: false,
            reason: null,
        };
    }

    deleteReasonClick = (reason) => {
        this.setState({
            showDeletePopup: true,
            reason
        });
    }

    editReasonClick = (reason) => {
        this.setState({
            showPopup: true,
            edit: true,
            reason
        });
    }

    addReasonClick = () => {
        this.setState({
            showPopup: true,
            edit: false,
            reason: null,
        });
    }

    closePopup(newReason, edit) {
        this.setState({
            showPopup: false
        });
        if (newReason) {
            if (edit) {
                this.props.handleUpdate(newReason, true);
            } else {
                this.props.handleAdd(newReason, true);
            }
        }
    }

    closeDeletePopup(deletedReason) {
        this.setState({
            showDeletePopup: false
        });
        if (deletedReason) {
            this.props.handleDelete(deletedReason, true);
        }
    }

    render() {
        return (
            <div className="snooze-issue-reasons">
                <div className="row">
                    <div className="col">
                        <Button variant="continue" onClick={this.addReasonClick}>
                            <FontAwesomeIcon icon={faPlusCircle} className="add-icon" />
                            ADD NEW REASON
                        </Button>
                    </div>
                </div>
                <SimpleContainer title="CURRENT REASONS">
                    <div className="reasons-div">
                        <DataTableComp
                            tableId="snoozeIssueReasons"
                            columns={this.state.columns}
                            orderRule={this.state.sortingRule}
                            columnDefs={this.state.columnDefs}
                            data={this.props.snoozeReasons}
                        />
                    </div>
                </SimpleContainer>
                <SimpleContainer title="NOTES">
                    <div className="reasons-div">
                        <DataTableComp
                            tableId="snoozeNotes"
                            columns={this.state.otherColumns}
                            orderRule={this.state.sortingRule}
                            columnDefs={this.state.otherColumnDefs}
                            data={this.props.snoozeNotes}
                        />
                    </div>
                </SimpleContainer>
                <AddReasonPopup snooze closeModal={this.closePopup.bind(this)} showModal={this.state.showPopup} edit={this.state.edit} reason={this.state.reason} />
                <DeleteReasonPopup snooze closeModal={this.closeDeletePopup.bind(this)} showModal={this.state.showDeletePopup} reason={this.state.reason} />
            </div>
        )
    }
}

export default SnoozeIssueReasons;
