import AuthContext from 'AuthContext';
import React, { Component } from 'react';

class Integrate extends Component {
    static contextType = AuthContext;

    state = {
        loading: true
    };

    componentDidMount() {
        this._integratePendo();
        this.setState({ loading: false });
    }

    _integratePendo() {
        // Don't try to initialize Pendo if the api key is not provided or user is not logged in
        if (!window.config.PENDO_API_KEY || !this.context.account) {
            return;
        }

        const { accountIdentifier, name, userName } = this.context.account;

        // This function creates visitors and accounts in Pendo
        window.pendo.initialize({
            // End users that are using the appication
            visitor: {
                id: accountIdentifier, // Must be globally unique
                email: userName,
                full_name: name
            },
            // Groups of visitors
            account: {
                id: 'c5faa138-3c71-444a-9f48-67061cadaf3b', // TODO: Tenant id
                name: window.config.URL_FRONT // TODO: Tenant name
            }
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <div>Configuring integrations...</div>
            );
        }

        return (
            <>{ this.props.children }</>
        );
    }
}

export default Integrate;
