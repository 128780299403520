import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import Loader from 'react-loader-spinner';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'AppInsights';
import AuthContext from 'AuthContext';
import SimpleContainer from 'components/container/SimpleContainer';
import StackedGroupedBar from 'components/chart/StackedGroupedBar';
import UserStatsHeader from 'pages/statistics/user/UserStatsHeader';
import StatsFilter from 'pages/statistics/StatsFilter';
import {
    formatTimestampToDate,
    getCurrentDate,
    getFullDifferenceBetweenDates,
    getMonthsBefore
} from 'global/services/DateTimeService';
import { MONITORED_ISSUE_TYPES } from 'global/services/IssueTypeService';
import {
    COLORS,
    USER_STATE,
    userDropdownRecordsNumber,
    managerUsersUrl,
    countSeenByUserUrl,
    countSnoozeByUserUrl,
    countCloseByUserUrl,
    avgSeenDurationUrl,
    userActivityUrl
} from 'global/constants';
import history from 'global/history';
import QueryParamService from 'global/services/QueryParamService';

import 'pages/statistics/Statistics.scss';

class UserStats extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.seenCountKeys = [{
            name: 'seenCount',
            label: 'Seen count',
            stackId: 'seenCount',
            color: COLORS.BLUE_CHART
        }];

        this.avgIssueResolutionKeys = [{
            name: 'seenDuration',
            label: 'Average issue resolution time',
            stackId: 'seenDuration',
            color: COLORS.BLUE_CHART
        }];

        this.snoozeCountKeys = [{
            name: 'snoozeCount',
            label: 'Snooze count',
            stackId: 'snoozeCount',
            color: COLORS.BLUE_CHART
        }];

        this.closeCountKeys = [{
            name: 'closeCount',
            label: 'Close count',
            stackId: 'closeCount',
            color: COLORS.BLUE_CHART
        }];

        this.issueTypeLabels = Object.keys(MONITORED_ISSUE_TYPES).map(key => ({ label: key }));
        const queryParamsString = window.location.search.slice(1);
        const issueTypes = QueryParamService.parseSimpleValueFromQueryString(queryParamsString, 'issueTypes', '');
        const startTime = QueryParamService.parseSimpleValueFromQueryString(queryParamsString, 'startDate');
        const endTime = QueryParamService.parseSimpleValueFromQueryString(queryParamsString, 'endDate');

        let processedIssueTypes;
        if (Array.isArray(issueTypes)) {
            processedIssueTypes = issueTypes;
        } else if (issueTypes.length > 0 && issueTypes !== 'ALL') {
            processedIssueTypes = [issueTypes];
        } else {
            processedIssueTypes = [];
        }

        this.state = {
            userPage: 1,
            users: [],
            filter: {
                startTime: startTime ? formatTimestampToDate(startTime) : getMonthsBefore(getCurrentDate(), 1),
                endTime: endTime ? formatTimestampToDate(endTime) : getCurrentDate(),
                issueTypes: processedIssueTypes
            },
            seenDurationData: this.issueTypeLabels,
            closeData: this.issueTypeLabels,
            snoozeData: this.issueTypeLabels,
            seenData: this.issueTypeLabels,
            statisticsLoading: true,
            usersLoading: true
        };

        this.fetchMoreUsers = this.fetchMoreUsers.bind(this);
        this.searchUser = this.searchUser.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
    }

    async componentDidMount() {
        if (this.props.match && this.props.match.params.id) {
            window.scrollTo(0, 0);

            this.setState({
                userName: this.props.location.state ? this.props.location.state.user.name : '',
                userId: this.props.match.params.id,
                data: this.issueTypeLabels
            }, () => this.fetchStatistics(true));
        }
    }

    async componentDidUpdate(prevProps) {
        if (((this.props.match || {}).params || {}).id !== prevProps.match.params.id) {
            this.setState(prevState => ({
                userId: this.props.match.params.id,
                userName: this.props.location.state ? this.props.location.state.user.name : "",
                data: this.issueTypeLabels.map(type => ({
                    ...type,
                    disabled: prevState.filter.issueTypes.indexOf(type.label) === -1
                })),
                searchName: null,
                statisticsLoading: true
            }), () => this.fetchStatistics(false));
        }
    }

    onUserSelect(user) {
        history.push({
            pathname: `/statistics/user/${user.id}`,
            state: { user }
        });
    }

    async fetchStatistics(fetchUsers) {
        if (!this.props.location.state) {
            await this.fetchUser();
        }

        await this.fetchUserActivity();
        await this.fetchSeenCount();
        await this.fetchAvgSeenDuration();
        await this.fetchSnoozeCount();
        await this.fetchCloseCount();

        if (fetchUsers) {
            await this.fetchUsers();
        }

        this.setState({ statisticsLoading: false });
    }

    async fetchUser() {
        const response = await this.context.get(`${managerUsersUrl}/${this.props.match.params.id}`);

        if (response.status === 'error') {
            console.error(response.message);
            this.setState({ userName: 'Unknown user' });
            return;
        }

        this.setState({ userName: response.name });
    }

    async fetchUserActivity() {
        const response = await this.context.get(userActivityUrl, {
            userId: this.state.userId,
            startTimeFrom: this.state.filter.startTime.toDate(),
            endTimeTo: this.state.filter.endTime.toDate(),
            types: this.state.filter.issueTypes
        });

        if (response.status === 'error') {
            console.error(response.message);
            return;
        }

        this.setState({
            seenCount: response.seenCount,
            snoozeCount: response.snoozeCount,
            closeCount: response.closeCount
        });
    }

    async fetchSeenCount() {
        const response = await this.context.get(countSeenByUserUrl, {
            userId: this.state.userId,
            startTimeFrom: this.state.filter.startTime.toDate(),
            endTimeTo: this.state.filter.endTime.toDate(),
            types: this.state.filter.issueTypes
        });

        if (response.status === 'error') {
            console.error(response.message);
            return;
        }

        const seenData = this.issueTypeLabels.map(type => ({
            label: type.label,
            seenCount: (response.find(d => d.issueType === type.label) || {}).count || 0
        }));

        this.setState({ seenData });
    }

    async fetchAvgSeenDuration() {
        const response = await this.context.get(avgSeenDurationUrl, {
            userId: this.state.userId,
            startTimeFrom: this.state.filter.startTime.toDate(),
            endTimeTo: this.state.filter.endTime.toDate(),
            types: this.state.filter.issueTypes
        });

        if (response.status === 'error') {
            console.error(response.message);
            return;
        }

        const seenDurationData = this.issueTypeLabels.map(type => ({
            label: type.label,
            seenDuration: (response.find(d => d.issueType === type.label) || {}).avgSeenToResolutionTime || 0
        }));

        this.setState({ seenDurationData });
    }

    async fetchSnoozeCount() {
        const response = await this.context.get(countSnoozeByUserUrl, {
            userId: this.state.userId,
            startTimeFrom: this.state.filter.startTime.toDate(),
            endTimeTo: this.state.filter.endTime.toDate(),
            types: this.state.filter.issueTypes
        });

        if (response.status === 'error') {
            console.error(response.message);
            return;
        }

        const snoozeData = this.issueTypeLabels.map(type => ({
            label: type.label,
            snoozeCount: (response.find(d => d.issueType === type.label) || {}).count || 0
        }));

        this.setState({ snoozeData });
    }

    async fetchCloseCount() {
        const response = await this.context.get(countCloseByUserUrl, {
            userId: this.state.userId,
            startTimeFrom: this.state.filter.startTime.toDate(),
            endTimeTo: this.state.filter.endTime.toDate(),
            types: this.state.filter.issueTypes
        });

        if (response.status === 'error') {
            console.error(response.message);
            return;
        }

        const closeData = this.issueTypeLabels.map(type => ({
            label: type.label,
            closeCount: (response.find(d => d.issueType === type.label) || {}).count || 0
        }));

        this.setState({ closeData });
    }

    async fetchUsers(addToExisting) {
        this.setState({ usersLoading: true });
        const pageNumber = this.state.userPage;
        const pageSize = userDropdownRecordsNumber;

        const params = { state: USER_STATE.ACCEPTED, pageNumber, pageSize };

        if (this.state.searchUser) {
            params.contains = this.state.searchUser;
        }

        const response = await this.context.get(managerUsersUrl, params);

        if (response.status === 'error') {
            console.error(response.message);
            this.setState({ usersLoading: false });
            return;
        }

        this.setState(prevState => ({
            searchUser: null,
            users: addToExisting ? [...prevState.users, ...response.data] : response.data,
            hasMoreUsers: pageNumber * pageSize < response.available,
            usersLoading: false
        }));
    }

    fetchMoreUsers() {
        this.setState(prevState => ({
            userPage: prevState.userPage + 1, usersLoading: true
        }), () => this.fetchUsers(true));
    }

    searchUser(searchUser) {
        this.setState({ searchUser, userPage: 1 }, this.fetchUsers);
    }

    onFilterChange(selectedTypes, selectedStartDate, selectedEndDate) {
        const types = selectedTypes.indexOf('ALL') === -1 ? selectedTypes : [];

        this.setState(prevState => ({
            seenData: prevState.seenData.map(type => ({ ...type, disabled: types.indexOf(type.label) === -1 })),
            seenDurationData: prevState.seenDurationData.map(type => ({ ...type, disabled: types.indexOf(type.label) === -1 })),
            snoozeData: prevState.snoozeData.map(type => ({ ...type, disabled: types.indexOf(type.label) === -1 })),
            closeData: prevState.closeData.map(type => ({ ...type, disabled: types.indexOf(type.label) === -1 })),
            filter: {
                startTime: selectedStartDate && prevState.filter.startTime !== selectedStartDate ? selectedStartDate : prevState.filter.startTime,
                endTime: selectedEndDate && prevState.filter.endTime !== selectedEndDate ? selectedEndDate : prevState.filter.endTime,
                issueTypes: types
            },
            statisticsLoading: true
        }), () => this.fetchStatistics(false));
    }

    render() {
        return (
            <div className="container-fluid page statistics">
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to={"/statistics?" + QueryParamService.parseParams(["startDate", "endDate", "issueTypes"])}>
                                Stats
                            </Link>
                        </li>
                        <li className="breadcrumb-item active">
                            <Link to={ window.location.pathname }>
                                { this.state.userName }
                            </Link>
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="heading">
                            { this.state.userName }
                            <Loader
                                type="TailSpin"
                                className="loader"
                                color={ COLORS.BLUE }
                                height={ 36 }
                                width={ 36 }
                                visible={ this.state.statisticsLoading || this.state.usersLoading }
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-8 d-flex">
                        <StatsFilter
                            onUserSelect={ this.onUserSelect }
                            searchUser={ this.searchUser }
                            fetchMoreUsers={ this.fetchMoreUsers }
                            onFilterChange={ this.onFilterChange }
                            filterUser={ this.state.userName }
                            users={ this.state.users }
                            hasMoreUsers={ this.state.hasMoreUsers }
                            filter={ this.state.filter }
                        />
                    </div>
                </div>
                <UserStatsHeader
                    seenCount={ this.state.seenCount }
                    snoozeCount={ this.state.snoozeCount }
                    closeCount={ this.state.closeCount }
                />
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <SimpleContainer className="chart-card" title="SEEN ISSUES">
                            <StackedGroupedBar
                                allowDecimals={ false }
                                data={ this.state.seenData }
                                dataKeys={ this.seenCountKeys }
                                timeFrame={ getFullDifferenceBetweenDates(this.state.filter.startTime, this.state.filter.endTime) }
                            />
                        </SimpleContainer>
                    </div>
                    <div className="col-12 col-lg-6">
                        <SimpleContainer className="chart-card" title="FROM SEEN TO RESOLVE">
                            <StackedGroupedBar
                                minuteBar
                                yTickFormatter={ value => `${value} min` }
                                data={ this.state.seenDurationData }
                                dataKeys={ this.avgIssueResolutionKeys }
                                timeFrame={ getFullDifferenceBetweenDates(this.state.filter.startTime, this.state.filter.endTime) }
                            />
                        </SimpleContainer>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <SimpleContainer className="chart-card" title="SNOOZED ISSUES">
                            <StackedGroupedBar
                                allowDecimals={ false }
                                data={ this.state.snoozeData }
                                dataKeys={ this.snoozeCountKeys }
                                timeFrame={ getFullDifferenceBetweenDates(this.state.filter.startTime, this.state.filter.endTime) }
                            />
                        </SimpleContainer>
                    </div>
                    <div className="col-12 col-lg-6">
                        <SimpleContainer className="chart-card" title="CLOSED ISSUES">
                            <StackedGroupedBar
                                allowDecimals={ false }
                                data={ this.state.closeData }
                                dataKeys={ this.closeCountKeys }
                                timeFrame={ getFullDifferenceBetweenDates(this.state.filter.startTime, this.state.filter.endTime) }
                            />
                        </SimpleContainer>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(withAITracking(reactPlugin, UserStats, 'UserStats'));
