import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CustomRoute from 'CustomRoute';
import { AccessCase, CanAccessSwitch } from 'components/can-access/CanAccessSwitch';
import RequiresRole from 'components/requires-role/RequiresRole';
import Dashboard from 'pages/dashboard/Dashboard';
import FleetOrders from 'pages/fleet/FleetOrders';
import Fleet from 'pages/fleet/Fleet';
import ModeIssues from 'pages/issues/ModeIssues';
import NotificationHistory from 'pages/notification-history/NotificationHistory';
import SilentUnitIssues from 'pages/issues/SilentUnitIssues';
import TemperatureIssues from 'pages/issues/TemperatureIssues';

import DetailView from 'pages/trailer/DetailView';
import AssignmentDetailView from 'pages/assignment/DetailView'
import PowerIssues from 'pages/issues/PowerIssues';
import AuthContext from "AuthContext";
import DetainedTrailers from 'pages/detention/DetainedTrailers';
import InactiveTrailers from 'pages/issues/InactiveTrailers';
import LowFuelLevel from 'pages/issues/LowFuelLevel';
import AlarmIssues from 'pages/issues/AlarmIssues';
import Settings from 'pages/settings/Settings';
import NotFoundPage from 'pages/not-found/NotFoundPage';
import NotAuthorized from 'pages/not-authorized/NotAuthorized';
import EditReleaseNotes from 'pages/release-notes/EditReleaseNotes';
import ReleaseNotes from './pages/release-notes/ReleaseNotes';
import UnreadContextProvider from 'UnreadContextProvider';
import ManifestOverview from 'pages/assignment/ManifestOverview';
import FleetOverview from 'pages/trailer/FleetOverview';
import Statistics from 'pages/statistics/Statistics';
import UserStats from 'pages/statistics/user/UserStats';
import SnoozedIssues from 'pages/statistics/issues/SnoozedIssues';
import ClosedIssues from 'pages/statistics/issues/ClosedIssues';
import StatisticsSummaryPreview from 'pages/statistics/StatisticsSummaryPreview';

export default class Router extends Component {
    static contextType = AuthContext;

    render() {
        return (
            <UnreadContextProvider>
                <Switch>
                    <CustomRoute exact path="/dashboard">
                        <RequiresRole
                            roles={this.context.role}
                            perform="dashboard-page:visit"
                            yes={ () => (
                                <Dashboard account={ this.context.account } fleets={ this.context.fleets } />
                            ) }
                            no={() => <RequiresRole
                                roles={this.context.role}
                                perform="alarm-issues:list"
                                yes={ () => (
                                    <AlarmIssues account={ this.context.account } fleets={ this.context.fleets } />
                                ) }
                                no={() => <NotAuthorized />} />
                            }
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/orders/search">
                        <RequiresRole
                            roles={this.context.role}
                            perform="search-page:visit"
                            yes={() => (<FleetOrders account={this.context.account} />)}
                            no={() => <NotAuthorized />}
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/issues/all">
                        <RequiresRole
                            roles={this.context.role}
                            perform="all-issues:list"
                            yes={ () => (<Fleet account={ this.context.account } fleets={ this.context.fleets } />) }
                            no={() => <NotAuthorized />}
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/manifests">
                        <RequiresRole
                            roles={this.context.role}
                            perform="all-manifests:list"
                            yes={ () => (
                                <ManifestOverview account={ this.context.account } fleets={ this.context.fleets } />
                            ) }
                            no={() => <NotAuthorized />}
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/fleet">
                        <RequiresRole
                            roles={this.context.role}
                            perform="all-trailers:list"
                            yes={() => (<FleetOverview fleets={ this.context.fleets } />)}
                            no={() => <NotAuthorized />}
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/notifications">
                        <RequiresRole
                            roles={this.context.role}
                            perform="notifications:list"
                            yes={ () => (
                                <NotificationHistory account={ this.context.account } fleets={ this.context.fleets } />
                            ) }
                            no={() => <NotAuthorized />}
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/issues/silent">
                        <RequiresRole
                            roles={this.context.role}
                            perform="silent-issues:list"
                            yes={ () => (
                                <SilentUnitIssues account={ this.context.account } fleets={ this.context.fleets } />
                            ) }
                            no={() => <NotAuthorized />}
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/">
                        <Redirect to="/dashboard" />
                    </CustomRoute>
                    <CustomRoute exact path="/issues/mode">
                        <RequiresRole
                            roles={this.context.role}
                            perform="mode-issues:list"
                            yes={ () => (
                                <ModeIssues account={ this.context.account } fleets={ this.context.fleets } />
                            ) }
                            no={() => <NotAuthorized />}
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/issues/power">
                        <RequiresRole
                            roles={this.context.role}
                            perform="power-issues:list"
                            yes={() => (
                                <PowerIssues account={ this.context.account } fleets={ this.context.fleets } />
                            )}
                            no={() => <NotAuthorized />}
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/issues/temperature">
                        <RequiresRole
                            roles={this.context.role}
                            perform="temperature-issues:list"
                            yes={ () => (
                                <TemperatureIssues account={ this.context.account } fleets={ this.context.fleets } />
                            ) }
                            no={() => <NotAuthorized />}
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/detention">
                        <RequiresRole
                            roles={this.context.role}
                            perform="detained-trailers:list"
                            yes={ () => (
                                <DetainedTrailers account={ this.context.account } fleets={ this.context.fleets } />
                            ) }
                            no={() => <NotAuthorized />}
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/issues/inactivity">
                        <RequiresRole
                            roles={this.context.role}
                            perform="inactivity-issues:list"
                            yes={ () => (
                                <InactiveTrailers account={ this.context.account } fleets={ this.context.fleets } />
                            ) }
                            no={() => <NotAuthorized />}
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/issues/low-fuel">
                        <RequiresRole
                            roles={this.context.role}
                            perform="low-fuel-issues:list"
                            yes={ () => (
                                <LowFuelLevel account={ this.context.account } fleets={ this.context.fleets } />
                            ) }
                            no={() => <NotAuthorized />}
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/issues/alarm">
                        <RequiresRole
                            roles={this.context.role}
                            perform="alarm-issues:list"
                            yes={ () => (
                                <AlarmIssues account={ this.context.account } fleets={ this.context.fleets } />
                            ) }
                            no={() => <NotAuthorized />}
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/details/:trailerId/:assignmentId?">
                        <DetailView account={this.context.account} ></DetailView>
                    </CustomRoute>
                    <CustomRoute exact path="/manifest-details/:assignmentId">
                        <RequiresRole
                            roles={this.context.role}
                            perform="manifest-details:visit"
                            yes={() => (<AssignmentDetailView account={this.context.account} />)}
                            no={() => <NotAuthorized />}
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/statistics">
                        <RequiresRole
                            roles={ this.context.role }
                            perform="statistics:visit"
                            yes={ () => <Statistics account={ this.context.account } /> }
                            no={ () => <NotAuthorized /> }
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/statistics/user/:id">
                        <RequiresRole
                            roles={ this.context.role }
                            perform="statistics:visit"
                            yes={ () => <UserStats account={ this.context.account } /> }
                            no={ () => <NotAuthorized /> }
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/statistics/snoozed-issues">
                        <RequiresRole
                            roles={ this.context.role }
                            perform="statistics:visit"
                            yes={ () => <SnoozedIssues /> }
                            no={ () => <NotAuthorized /> }
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/statistics/closed-issues">
                        <RequiresRole
                            roles={ this.context.role }
                            perform="statistics:visit"
                            yes={ () => <ClosedIssues /> }
                            no={ () => <NotAuthorized /> }
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/release-notes">
                        <ReleaseNotes />
                    </CustomRoute>
                    <CustomRoute exact path="/release-notes/edit/:id?">
                        <RequiresRole
                            roles={this.context.role}
                            perform="release-notes:publish"
                            yes={() => (<EditReleaseNotes />)}
                            no={() => (<Redirect to="/release-notes" />)}
                        />
                    </CustomRoute>

                    <Route exact path="/settings">
                        <CanAccessSwitch>
                            <AccessCase action="settings:user-management:visit">
                                <Redirect to="/settings/user-management" />
                            </AccessCase>

                            <AccessCase action="settings:email-recipients:visit">
                                <Redirect to="/settings/email-recipients" />
                            </AccessCase>

                            <AccessCase action="settings:notifications:visit">
                                <Redirect to="/settings/notifications" />
                            </AccessCase>

                            <AccessCase action="settings:close-reasons:visit">
                                <Redirect to="/settings/close-reasons" />
                            </AccessCase>

                            <AccessCase action="settings:snooze-reasons:visit">
                                <Redirect to="/settings/snooze-reasons" />
                            </AccessCase>

                            <AccessCase>
                                <NotFoundPage />
                            </AccessCase>
                        </CanAccessSwitch>
                    </Route>

                    <CustomRoute exact path="/settings/user-management">
                        <RequiresRole
                            roles={ this.context.role }
                            perform="settings:user-management:visit"
                            yes={ () => <Settings tab="user-management" /> }
                            no={ () => <NotAuthorized /> }
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/settings/email-recipients">
                        <RequiresRole
                            roles={ this.context.role }
                            perform="settings:email-recipients:visit"
                            yes={ () => <Settings tab="email-recipients" /> }
                            no={ () => <NotAuthorized /> }
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/settings/notifications">
                        <RequiresRole
                            roles={this.context.role}
                            perform="settings:notifications:visit"
                            yes={() => (<Settings tab="notifications" />)}
                            no={() => <NotAuthorized />}
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/settings/close-reasons">
                        <RequiresRole
                            roles={this.context.role}
                            perform="settings:close-reasons:visit"
                            yes={() => (<Settings tab="close-reasons" />)}
                            no={() => <NotAuthorized />}
                        />
                    </CustomRoute>
                    <CustomRoute exact path="/settings/snooze-reasons">
                        <RequiresRole
                            roles={this.context.role}
                            perform="settings:snooze-reasons:visit"
                            yes={() => (<Settings tab="snooze-reasons" />)}
                            no={() => <NotAuthorized />}
                        />
                    </CustomRoute>
                    <Route exact path="/statistics/summary">
                        <RequiresRole
                            roles={ this.context.role }
                            perform="statistics:visit"
                            yes={ () => <StatisticsSummaryPreview /> }
                            no={ () => <NotAuthorized /> }
                        />
                    </Route>
                    <Route>
                        <NotFoundPage />
                    </Route>
                </Switch>
            </UnreadContextProvider>
        );
    }
}
