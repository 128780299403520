import { Children, Component } from 'react';

import { check } from 'global/services/RoleCheckService';
import AuthContext from 'AuthContext';

/**
 * A Switch component for Access Control.
 * Renders the first (in declaration order) access case child the user can access.
 */
class CanAccessSwitch extends Component {
    static contextType = AuthContext;

    state = {
        child: null
    };

    determineCase(children) {
        const childrenArray = Children.toArray(children);

        // Attempt to find the first matching access case. Returns immediately when/if found.
        for (const child of childrenArray) {
            if (child.type !== AccessCase) {
                throw Error(
                    child.type + ' is not a valid access case. ' +
                    'Only AccessCase components can be direct children of a CanAccessSwitch.'
                );
            }

            if (!child.props.action) {
                // In case we encounter a default case, skip it for now.
                continue;
            }

            if (this.context.role.some(role => check(role, child.props.action, child.props.data))) {
                return child;
            }
        }

        // No matching access case could be found. Attempt to find the first default case.
        for (const child of childrenArray) {
            if (!child.props.action) {
                return child;
            }
        }

        // No case was found. Return null to indicate nothing needs to be rendered.
        return null;
    }

    updateState() {
        this.setState({ child: this.determineCase(this.props.children) });
    }

    componentDidMount() {
        this.updateState();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.children !== this.props.children) {
            this.updateState();
        }
    }

    render() {
        return this.state.child;
    }
}

/**
 * A single access case. Contains no logic of its own, simply a container for case data.
 * Renders its children without any wrapping.
 */
class AccessCase extends Component {
    render() {
        return this.props.children;
    }
}

export { CanAccessSwitch, AccessCase };
