import React, { Component } from "react";
import { formatShortDate } from "global/services/DateTimeService";
import { issueTypeMap } from "global/services/IssueTypeService";

import "./LocationInfoTooltip.scss";

export default class LocationInfoTooltip extends Component {
    render() {
        const metaData = this.props.locationIssue.metaData;

        return (
            <div className="location-tooltip">
                <div className="arrow-box">
                    <p className="location-title">
                        { issueTypeMap(this.props.locationIssue.type) }
                    </p>
                    <div className="d-flex">
                        <p className="info-label">
                            Trailer ID:
                        </p>
                        <p className="info-text">
                            { this.props.locationIssue.trailer.businessId }
                        </p>
                    </div>
                    <div className="line" />
                    <p className="info-label">
                        Last known truck address:
                    </p>
                    <p className="info-text">
                        { metaData.truckPosition }
                        <br />
                        <span className="greyColorText">
                            ({ formatShortDate(new Date(metaData.truckUpdated)) })
                        </span>
                    </p>
                    <p className="info-label">
                        Last known trailer address:
                    </p>
                    <p className="info-text">
                        { metaData.trailerPosition }
                        <br />
                        <span className="greyColorText">
                            ({ formatShortDate(new Date(metaData.trailerUpdated)) })
                        </span>
                    </p>
                    <p className="info-label">
                        Distance:
                    </p>
                    <p style={ { marginBottom: 0 } } className="info-text">
                        { metaData.distance + " miles" }
                    </p>
                </div>
            </div>
        );
    }
}
