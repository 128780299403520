import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faShareFromSquare as shareIcon,
    faSliders as settingsIcon
 } from '@fortawesome/pro-solid-svg-icons';
import AuthContext from 'AuthContext';
import { reactPlugin } from 'AppInsights';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import StatsFilter from 'pages/statistics/StatsFilter';
import StatsHeader from 'pages/statistics/StatsHeader';
import UserCard from 'pages/statistics/user/UserCard';
import Pagination from 'components/paging/Pagination';
import RequiresRole from 'components/requires-role/RequiresRole';
import SimpleContainer from 'components/container/SimpleContainer';
import StackedGroupedBar from 'components/chart/StackedGroupedBar';
import ShareStatsPopup from 'components/popup/ShareStatsPopup';
import {
    getCurrentDate,
    getMonthsBefore,
    getFullDifferenceBetweenDates,
    formatTimestampToDate
} from 'global/services/DateTimeService';
import QueryParamService from 'global/services/QueryParamService';
import { MONITORED_ISSUE_TYPES } from 'global/services/IssueTypeService';
import {
    issuesByTypeUrl,
    typeIssueInfoUrl,
    snoozedIssuesUrl,
    issueInfoUrl,
    userActivityUrl,
    COLORS,
    USER_STATE,
    userRecordsNumber,
    managerUsersUrl
} from 'global/constants';
import history from "global/history";

import './Statistics.scss';

class Statistics extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.issueDataKeys = [
            {
                name: 'automaticallyResolved',
                label: 'Automatically resolved issues',
                stackId: 'issues',
                color: COLORS.CHART[0]
            },
            {
                name: 'manuallyResolved',
                label: 'Manually resolved issues',
                stackId: 'issues',
                color: COLORS.CHART[1]
            },
            {
                name: 'unresolved',
                label: 'Unresolved issues',
                stackId: 'issues',
                color: COLORS.CHART[2]
            },
            {
                name: 'escalations',
                label: 'Escalations',
                stackId: 'escalations',
                color: COLORS.CHART[3]
            }
        ];

        this.avgResolutionKeys = [{
            name: 'avgResolutionTime',
            label: 'Average issue resolution time',
            stackId: 'avgResolution',
            color: [COLORS.CHART[0], COLORS.CHART[3]]
        }];

        this.snoozedDataKeys = [{
            name: 'numSnoozed',
            label: 'Snoozed issues',
            stackId: 'snoozed',
            color: [COLORS.CHART[0], COLORS.CHART[3]]
        }];

        const issueTypeLabels = Object.keys(MONITORED_ISSUE_TYPES).map(key => ({ label: key }));

        const queryParamsString = window.location.search.slice(1);
        const issueTypes = QueryParamService.parseSimpleValueFromQueryString(queryParamsString, 'issueTypes', '');
        const startTime = QueryParamService.parseSimpleValueFromQueryString(queryParamsString, 'startDate');
        const endTime = QueryParamService.parseSimpleValueFromQueryString(queryParamsString, 'endDate');

        let processedIssueTypes;
        if (Array.isArray(issueTypes)) {
            processedIssueTypes = issueTypes;
        } else if (issueTypes.length > 0 && issueTypes !== 'ALL') {
            processedIssueTypes = [issueTypes];
        } else {
            processedIssueTypes = [];
        }

        this.state = {
            filter: {
                startTime: startTime ? formatTimestampToDate(startTime) : getMonthsBefore(getCurrentDate(), 1),
                endTime: endTime ? formatTimestampToDate(endTime) : getCurrentDate(),
                issueTypes: processedIssueTypes
            },
            issueData: issueTypeLabels,
            avgResolutionData: issueTypeLabels,
            snoozedData: issueTypeLabels,
            statisticsLoading: true,
            usersLoading: true,
            users: null,
            userStats: [],
            pageNumber: 1,
            pageSize: userRecordsNumber,
            available: 0,
            showShareStatsModal: false
        };

        this.onPageChange = this.onPageChange.bind(this);
        this.onFilterChange = this.onFilterChange.bind(this);
        this.onShareStatsModalClose = this.onShareStatsModalClose.bind(this);
        this.handleSettingsClick = this.handleSettingsClick.bind(this);
    }

    componentDidMount() {
        this.fetchStatistics(true);
    }

    async fetchStatistics(fetchUsers) {
        await this.fetchTotalStats();
        await this.fetchIssueData();
        await this.fetchAvgResolutionData();
        await this.fetchSnoozedData();

        this.setState({ statisticsLoading: false });

        if (fetchUsers) {
            await this.fetchUsers();
        } else {
            await this.fetchUsersStatistics();
        }
    }

    async fetchTotalStats() {
        const response = await this.context.get(issueInfoUrl, this.getActiveFilter());

        if (response.status === 'error') {
            console.error(response.message);
            return;
        }

        this.setState({
            orderCount: response.orderCount,
            issueCount: response.issueCount,
            resolvedIssueCount: response.resolvedIssueCount,
            manuallyResolvedIssueCount: response.manuallyResolvedIssueCount,
            avgIssueResolutionTime: response.avgIssueResolutionTime ? response.avgIssueResolutionTime.toFixed(1) : 0,
            snoozedIssueCount: response.snoozedIssueCount
        });
    }

    async fetchIssueData() {
        const response = await this.context.get(issuesByTypeUrl, this.getActiveFilter());

        if (response.status === 'error') {
            console.error(response.message);
            return;
        }

        this.setState({
            issueData: response.map(entity => ({ ...entity, label: entity.issueType }))
        });
    }

    async fetchAvgResolutionData() {
        const response = await this.context.get(typeIssueInfoUrl, this.getActiveFilter());

        if (response.status === 'error') {
            console.error(response.message);
            return;
        }

        this.setState({
            avgResolutionData: response.map(entity => ({
                ...entity,
                avgResolutionTime: entity.avgResolutionTime ? entity.avgResolutionTime.toFixed(1) : 0,
                label: entity.issueType
            }))
        });
    }

    async fetchSnoozedData() {
        const response = await this.context.get(snoozedIssuesUrl, this.getActiveFilter());

        if (response.status === 'error') {
            console.error(response.message);
            return;
        }

        this.setState({
            snoozedData: response.map(entity => ({
                ...entity,
                label: entity.issueType,
                numSnoozed: entity.count
            }))
        });
    }

    async fetchUsers() {
        this.setState({ usersLoading: true });

        const response = await this.context.get(managerUsersUrl, {
            state: USER_STATE.ACCEPTED,
            pageNumber: this.state.pageNumber,
            pageSize: this.state.pageSize
        });

        if (response.status === 'error') {
            console.error(response.message);
            this.setState({
                users: [],
                loading: false
            });

            return;
        }

        const { data, ...pagingInfo } = response;

        this.setState({
            users: data,
            usersLoading: false,
            userStats: Array(response.data.length).fill(null),
            ...pagingInfo
        }, this.fetchUsersStatistics);
    }

    async fetchUsersStatistics() {
        for (let i = 0; i < this.state.users.length; i++) {
            const response = await this.context.get(userActivityUrl, {
                userId: this.state.users[i].id,
                ...this.getActiveFilter()
            });

            if (response.status === 'error') {
                console.error(response.message);
                continue;
            }

            this.setState(prevState => ({
                userStats: [
                    ...prevState.userStats.slice(0, i),
                    { seenCount: response.seenCount, snoozeCount: response.snoozeCount, closeCount: response.closeCount },
                    ...prevState.userStats.slice(i + 1)
                ]
            }));
        }
    }

    getActiveFilter() {
        return {
            startTimeFrom: this.state.filter.startTime.toDate(),
            endTimeTo: this.state.filter.endTime.toDate(),
            types: this.state.filter.issueTypes
        };
    }

    onFilterChange(selectedTypes, selectedStartDate, selectedEndDate) {
        const types = selectedTypes.indexOf('ALL') === -1 ? selectedTypes : [];

        this.setState(prevState => ({
            issueData: prevState.issueData.map(type => ({ ...type, disabled: types.indexOf(type.label) === -1 })),
            avgResolutionData: prevState.avgResolutionData.map(type => ({ ...type, disabled: types.indexOf(type.label) === -1 })),
            snoozedData: prevState.snoozedData.map(type => ({ ...type, disabled: types.indexOf(type.label) === -1 })),
            filter: {
                startTime: selectedStartDate && prevState.filter.startTime !== selectedStartDate ? selectedStartDate : prevState.filter.startTime,
                endTime: selectedEndDate && prevState.filter.endTime !== selectedEndDate ? selectedEndDate : prevState.filter.endTime,
                issueTypes: types
            }
        }), () => this.fetchStatistics(false));
    }

    onPageChange(pageNumber) {
        this.setState({ pageNumber }, this.fetchUsers);
    }

    onShareStatsModalClose() {
        this.setState({
            showShareStatsModal: false,
        });
    }

    handleSettingsClick() {
        history.push({ pathname: '/settings/notifications' })
    }

    render() {
        return (
            <div className="container-fluid page statistics">
                <nav>
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active">
                            <Link to={ window.location.pathname }>
                                Stats
                            </Link>
                        </li>
                    </ol>
                </nav>
                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <div className="heading">
                            Stats
                            <Loader
                                type="TailSpin"
                                className="loader"
                                color={ COLORS.BLUE }
                                height={ 36 }
                                width={ 36 }
                                visible={ this.state.statisticsLoading }
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-8 col-xs-12 d-flex">
                        <StatsFilter className="main-page" filter={ this.state.filter } onFilterChange={ this.onFilterChange } />
                    </div>
                    <div className="col stats-preview-button-group-container">
                        <div className="stats-preview-button-group">
                            <RequiresRole
                                roles={ this.context.role }
                                perform="settings:notifications:visit"
                                yes={ () => (
                                    <Button
                                        variant="continue"
                                        className="stats-btn settings-button"
                                        onClick={ this.handleSettingsClick }
                                    >
                                        <FontAwesomeIcon className="settings-icon" icon={ settingsIcon } />
                                        Email settings
                                    </Button>
                                ) }
                            />
                            <Button
                                variant="continue"
                                onClick={ () => this.setState({ showShareStatsModal: true }) }
                                className="stats-btn share-button"
                            >
                                <FontAwesomeIcon className="share-icon" icon={ shareIcon } />
                                Share stats
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <StatsHeader
                            orderCount={ this.state.orderCount }
                            issueCount={ this.state.issueCount }
                            resolvedIssueCount={ this.state.resolvedIssueCount }
                            manuallyResolvedIssueCount={ this.state.manuallyResolvedIssueCount }
                            avgResolutionTime={ this.state.avgIssueResolutionTime }
                            snoozedIssueCount={ this.state.snoozedIssueCount }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <SimpleContainer className="chart-card" title="ISSUES AND ESCALATIONS">
                            <StackedGroupedBar
                                allowDecimals={ false }
                                data={ this.state.issueData }
                                dataKeys={ this.issueDataKeys }
                                timeFrame={ getFullDifferenceBetweenDates(this.state.filter.startTime, this.state.filter.endTime) }
                            />
                        </SimpleContainer>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <SimpleContainer className="chart-card" title="AVERAGE ISSUE RESOLUTION TIME">
                            <StackedGroupedBar
                                minuteBar
                                yTickFormatter={ value => `${value} min` }
                                data={ this.state.avgResolutionData }
                                dataKeys={ this.avgResolutionKeys }
                                timeFrame={ getFullDifferenceBetweenDates(this.state.filter.startTime, this.state.filter.endTime) }
                            />
                        </SimpleContainer>
                    </div>
                    <div className="col-12 col-lg-6">
                        <SimpleContainer className="chart-card" title="SNOOZED ISSUES">
                            <StackedGroupedBar
                                allowDecimals={ false }
                                data={ this.state.snoozedData }
                                dataKeys={ this.snoozedDataKeys }
                                timeFrame={ getFullDifferenceBetweenDates(this.state.filter.startTime, this.state.filter.endTime) }
                            />
                        </SimpleContainer>
                    </div>
                </div>
                <div className="row">
                    <div className="col heading">
                        Members
                        <Loader
                            type="TailSpin"
                            className="loader"
                            color={ COLORS.BLUE }
                            height={ 24 }
                            width={ 24 }
                            visible={ this.state.usersLoading }
                        />
                    </div>
                </div>
                <div className="row members">
                    { this.state.users && (
                        <>
                            { this.state.users.map((user, i) => (
                                <div key={ user.id } className="col-lg-4 col-sm-6 col-xs-12">
                                    <UserCard
                                        user={ user }
                                        seenCount={ (this.state.userStats[i] || {}).seenCount }
                                        closeCount={ (this.state.userStats[i] || {}).closeCount }
                                        snoozeCount={ (this.state.userStats[i] || {}).snoozeCount }
                                        loading={ !this.state.userStats[i] }
                                    />
                                </div>
                            )) }
                            <div className="col-12">
                                <Pagination
                                    className="mt-0 mb-0"
                                    page={ this.state.pageNumber }
                                    size={ this.state.pageSize }
                                    total={ this.state.available }
                                    onChange={ this.onPageChange }
                                />
                            </div>
                        </>
                    ) }
                </div>
                <ShareStatsPopup show={ this.state.showShareStatsModal } onClose={ this.onShareStatsModalClose } data={ this.getActiveFilter() } />
            </div>
        );
    }
}

export default withAITracking(reactPlugin, Statistics, 'Statistics');
