import React, { Component } from 'react';
import Pill from 'components/input/Pill';
import AuthContext from "AuthContext";
import { groupToRuleUrl, notificationRulesUrl } from 'global/constants';
import { issueType } from "global/services/IssueTypeService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import TextTooltip from 'components/tooltip/TextTooltip';

import "./NotificationToggles.scss";

class NotificationToggles extends Component {
    static contextType = AuthContext;

    constructor() {
        super();

        this.state = {
            showEmailRecipientsTooltip: false,
            showFleetTooltip: false
        };
    }

    handleNotificationToggle = async (checked, rule, issueType, notificationReason, contactGroup) => {
        let response;
        try {
            if (checked) {
                response = await this.context.delete(groupToRuleUrl, {
                    notificationRuleId: rule.id,
                    contactGroupId: contactGroup.id
                });
            } else {
                if (!rule) {
                    response = await this.context.post(notificationRulesUrl, {
                        issueType,
                        notificationReason,
                        contactGroups: [contactGroup]
                    });
                } else {
                    response = await this.context.post(groupToRuleUrl, {
                        notificationRuleId: rule.id,
                        contactGroupId: contactGroup.id
                    });
                }
            }
            if (!response || response.status === "error") {
                return;
            } else {
                if (checked || rule) {
                    this.props.updateRule(response);
                } else {
                    this.props.addRule(response);
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    handleInfoIcon(tooltip, show) {
        this.setState({
            [tooltip]: show
        });
    }

    getEmailRecipientsTooltipText() {
        switch (this.props.issueType) {
            case issueType.INACTIVITY:
                return "These rules apply to all trailers.";
            case issueType.ALARM:
                return "These rules apply to trailers with active manifest and to trailers with no manifest but with a reefer turned on.";
            default:
                return "These rules apply only to trailers with an active manifest.";
        }
    }

    render() {
        if (this.props.isEmptyState) {
            return this.props.emptyState;
        } else {
            return (
                <>
                    <div className="notification-toggles">
                        <div className="row header">
                            <div className="col-md-4 col-3">
                                <div className="header-tooltip-container">
                                    Email Recipients
                                    <FontAwesomeIcon
                                        onMouseOver={ () => this.handleInfoIcon('showEmailRecipientsTooltip', true) }
                                        onMouseLeave={ () => this.handleInfoIcon('showEmailRecipientsTooltip', false) }
                                        icon={ faInfoCircle }
                                        className="info-icon"
                                    />
                                    { this.state.showEmailRecipientsTooltip && (
                                        <TextTooltip text={ this.getEmailRecipientsTooltipText() } />
                                    ) }
                                </div>
                            </div>
                            <div className="col-md-4 col-3">
                                <div className="header-tooltip-container">
                                    Fleet
                                    <FontAwesomeIcon
                                        onMouseOver={ () => this.handleInfoIcon('showFleetTooltip', true) }
                                        onMouseLeave={ () => this.handleInfoIcon('showFleetTooltip', false) }
                                        icon={ faInfoCircle }
                                        className="info-icon"
                                    />
                                    { this.state.showFleetTooltip && (
                                        <TextTooltip text="Emails will be sent to the corresponding group if the trailer on an issue belongs to this fleet." />
                                    ) }
                                </div>
                            </div>
                            <div className="col">
                                { this.props.defaultTogglesHeader ? this.props.defaultTogglesHeader : "Warning" }
                            </div>
                            {!this.props.noEscalation &&
                                <div className="col">
                                    Escalation
                                </div>
                            }
                            {!this.props.noResolved &&
                                <div style={!(this.props.issueTypeRule && this.props.issueTypeRule.resolved) ? { color: '#D0D2DD' } : null} className="col">
                                    Resolved
                                </div>
                            }
                            { this.props.noEscalation && <div className="col" /> }
                            { this.props.noResolved && <div className="col" /> }
                        </div>
                        {this.props.contactGroups.map((contactGroup, index) => {
                            const warningChecked = this.props.warningRule && this.props.warningRule.contactGroups.some(id => id === contactGroup.id);
                            const escalationChecked = !this.props.noEscalation && this.props.escalationRule && this.props.escalationRule.contactGroups.some(id => id === contactGroup.id);;
                            const resolvedChecked = !this.props.noResolved && this.props.resolvedRule && this.props.resolvedRule.contactGroups.some(id => id === contactGroup.id);

                            return (
                                <div key={ "key-" + contactGroup.name + "-" + index } className="row recipient-row">
                                    <div className="col-md-4 col-3 recipient">
                                        {contactGroup.name}
                                    </div>
                                    <div className="col-md-4 col-3 recipient">
                                        { contactGroup.fleet ? contactGroup.fleet.name : 'All Fleets' }
                                    </div>
                                    <div className="col">
                                        <Pill
                                            disabled={ this.props.disableDefaultToggle }
                                            uncheckedGreyColor
                                            manualChange
                                            checked={ warningChecked }
                                            onChange={ checked => this.handleNotificationToggle(
                                                checked,
                                                this.props.warningRule,
                                                this.props.issueType,
                                                this.props.notificationTypes[0],
                                                contactGroup
                                            ) }
                                        />
                                    </div>
                                    {!this.props.noEscalation &&
                                        <div className="col">
                                            <Pill
                                                uncheckedGreyColor
                                                manualChange
                                                checked={escalationChecked}
                                                onChange={checked => this.handleNotificationToggle(
                                                    checked,
                                                    this.props.escalationRule,
                                                    this.props.issueType,
                                                    this.props.notificationTypes[1],
                                                    contactGroup
                                                )}
                                            />
                                        </div>
                                    }
                                    {!this.props.noResolved &&
                                        <div className="col">
                                            <Pill
                                                uncheckedGreyColor
                                                manualChange
                                                checked={this.props.issueTypeRule && this.props.issueTypeRule.resolved ? resolvedChecked : false}
                                                disabled={!(this.props.issueTypeRule && this.props.issueTypeRule.resolved)}
                                                onChange={checked => this.handleNotificationToggle(
                                                    checked,
                                                    this.props.resolvedRule,
                                                    this.props.issueType,
                                                    this.props.notificationTypes[2],
                                                    contactGroup
                                                )}
                                            />
                                        </div>
                                    }
                                    { this.props.noEscalation && <div className="col" /> }
                                    { this.props.noResolved && <div className="col" /> }
                                </div>
                            )
                        })}
                    </div>
                    { this.props.description }
                </>
            );
        }
    }
}

export default NotificationToggles;
