import React, { Component } from 'react';
import PagingComp from './PagingComp';

export default class Pagination extends Component {

    static defaultProps = {
        className: '',
        page: 1,
        size: 10,
        total: 1,
        onChange: () => {}
    }

    render() {
        const info = {
            currentPage: this.props.page,
            itemNumber: Math.max(Math.min(this.props.total - (this.props.page - 1) * this.props.size, this.props.size), 0),
            totalItemNumber: this.props.total,
            totalPageNumber: Math.ceil(this.props.total / this.props.size),
            recordsNumber: this.props.size
        };

        return (
            <PagingComp
                className={ this.props.className }
                pagingInfo={ info }
                handlePageChange={ event => this.props.onChange(event.target.value) }
                handleFirstPage={ () => this.props.onChange(1) }
                handleLastPage={ () => this.props.onChange(info.totalPageNumber) }
                handlePreviousPage={ () => this.props.onChange(this.props.page - 1) }
                handleNextPage={ () => this.props.onChange(this.props.page + 1) }
            />
        );
    }
}
