import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library as faLibrary } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';

import './Chip.scss';

faLibrary.add(fas);

export default class Chip extends Component {

    static defaultProps = {
        className: '',
        isRemovable: false,
        onClick: () => null,
        onRemove: () => null
    }

    render() {
        return (
            <div className={ `chip ${this.props.className}` } onClick={ this.props.onClick }>
                <FontAwesomeIcon icon={ this.props.icon } className="chip-icon" />
                { this.props.text }
                { this.props.isRemovable && (
                    <FontAwesomeIcon icon="fa-xmark" className="remove-icon" onClick={ this.props.onRemove } />
                ) }
            </div>
        );
    }
}
