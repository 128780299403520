import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { DatePicker } from 'antd';
import Button from 'react-bootstrap/Button';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faArrowUpRightFromSquare as redirectIcon,
    faQuestionCircle as helpIcon,
    faChevronDown as openIcon
} from '@fortawesome/pro-solid-svg-icons';

import { NOTIFICATION_SCHEDULE_FREQUENCY_OPTIONS, scheduleNotificationUrl } from 'global/constants';
import { dayOptions, formatDate, getCurrentDate } from 'global/services/DateTimeService';
import { formatDateTimeToTimestamp } from 'global/services/DateTimeService';

import DayPicker from 'components/input/DayPicker';
import TimePicker from 'components/input/TimePicker';
import Pill from 'components/input/Pill';
import ErrorMessageContainer from 'components/container/ErrorMessageContainer';
import SimpleContainer from 'components/container/SimpleContainer';
import SimpleTooltip from 'components/tooltip/SimpleTooltip';

import AuthContext from 'AuthContext';

import './ManageEmailUpdatesPopup.scss'

class ManageEmailUpdatesPopup extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.defaultFrequency = NOTIFICATION_SCHEDULE_FREQUENCY_OPTIONS.find(frequency => frequency.default);
        this.defaultDay = dayOptions().at(0);

        this.state = {
            requestSucceeded: null,
            dataChanged: false
        }

        this.onClose = this.onClose.bind(this);
        this.fillData = this.fillData.bind(this);
        this.isDataChanged = this.isDataChanged.bind(this);
        this.handleConfirm = this.handleConfirm.bind(this);
        this.getDefaultFrequency = this.getDefaultFrequency.bind(this);
        this.isConfirmButtonDisabled = this.isConfirmButtonDisabled.bind(this);
        this.getDateTimeContainerCss = this.getDateTimeContainerCss.bind(this);
    }

    componentDidMount() {
        this.fillData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.fillData();
        }
    }

    getDefaultFrequency() {
        return NOTIFICATION_SCHEDULE_FREQUENCY_OPTIONS.find(frequency => frequency.value === this.state.frequency);
    }
    
    fillData() {
        this.setState({
            id: this.props.data && this.props.data.id ? this.props.data.id : null,
            frequency: this.props.data && this.props.data.frequency ? this.props.data.frequency : this.defaultFrequency.value,
            time: this.props.data && this.props.data.time ? this.props.data.time : null,
            day: this.props.data && this.props.data.day ? this.props.data.day : this.defaultDay.value,
            startDate: this.props.data && this.props.data.startDate ? getCurrentDate(this.props.data.startDate) : getCurrentDate(),
            notificationEventType: this.props.data && this.props.data.notificationEventType ? this.props.data.notificationEventType : this.props.notificationType,
            active: this.props.data && this.props.data.active !== undefined ? this.props.data.active : true,
        })
    }

    handleChange(field, value) {
        this.setState({ 
            [field]: value,
        })
    }

    isDataChanged() {
        if (this.props.data) {
            if (this.state.frequency !== this.props.data.frequency ||
                this.state.time !== this.props.data.time || 
                this.state.active !== this.props.data.active ) {
                return true;
            }

            if ("EVERY_MONTH" === this.props.data.frequency) {
                return formatDateTimeToTimestamp(this.state.startDate) !== formatDateTimeToTimestamp(this.props.data.startDate)
            } else if("EVERY_WEEK" === this.props.data.frequency) {
                return this.state.day !== this.props.data.day
            }
        }

        return false;
    }

    async handleConfirm() {
        this.setState({ loading: true });
        const body = {
            frequency: this.state.frequency,
            time: this.state.time,
            day: this.state.day && "EVERY_WEEK" === this.state.frequency ? this.state.day : null,
            startDate: this.state.startDate && "EVERY_MONTH" === this.state.frequency ? this.state.startDate : null,
            notificationEventType: this.props.notificationType,
            active: this.state.active
        };

        let response;
        if (this.state.id) {
            response = await this.context.put(scheduleNotificationUrl, { 
                ...body,
                id: this.state.id 
            });
        } else {
            response = await this.context.post(scheduleNotificationUrl, { ...body });
        }

        this.setState({ 
            requestSucceeded: response.status !== 'error',
            loading: false
        }, () => this.onClose(true) );
    }

    getDateTimeContainerCss() {
        if ("EVERY_MONTH" === this.state.frequency) {
            return "month-interval";
        }
        else if ("EVERY_WEEK" === this.state.frequency) {
            return "week-interval";
        }
        return "day-interval";
    }

    isConfirmButtonDisabled() {
        let isDisabled = !this.state.time;
        if ("EVERY_MONTH" === this.state.frequency) {
            isDisabled = isDisabled || !this.state.startDate;
        }
        return isDisabled || this.state.loading || (this.props.data && !this.isDataChanged());
    }

    onClose(fetchData) {
        this.fillData();
        this.setState({
            dataChanged: false
        }, () => this.props.onClose(fetchData));
    }

    render() {
        let modalContent;

        if (this.state.requestSucceeded === false) {
            modalContent = (
                <ErrorMessageContainer onClose={ this.onClose } />
            );
        } else {
            modalContent = (
                <SimpleContainer className="modal-container" modal title="STATS EMAIL UPDATES">
                    <div className="subtitle">Stats Overview Details</div>
                    <p>
                        The emails containing the stats overview will be sent in <br/> 
                        regular intervals to the selected recipient groups, starting <br/>
                        with the time and date you choose below.
                    </p>
                    <p>
                        <Link 
                            target="_blank"
                            className="redirect-link" 
                            to="/statistics/summary"
                        >
                            <FontAwesomeIcon className="redirect-icon" icon={ redirectIcon } />
                            Preview Email Template
                        </Link>
                    </p>

                    <div className="interval-container">
                        <div className="label">Email Update Interval</div>
                        <Select
                            className="manage-email-dropdown"
                            classNamePrefix="select-option"
                            options={ NOTIFICATION_SCHEDULE_FREQUENCY_OPTIONS }
                            defaultValue={ this.getDefaultFrequency() }
                            onChange={ (frequency) => this.handleChange("frequency", frequency.value) }
                        />
                    </div>

                    <div className="date-time-container">
                        <div className={ "day-container " + this.getDateTimeContainerCss() }>
                            <div className="label">Weekday</div>
                            <DayPicker 
                                defaultDay={ this.defaultDay.value } 
                                onChange={ (day) => this.handleChange("day", day.value) }
                            />
                        </div>
                        <div className={ "date-container " + this.getDateTimeContainerCss() }>
                            <div className="label-tooltip">
                                <div className="label">Start date</div>
                                <div className="tooltip-container">
                                    <FontAwesomeIcon icon={ helpIcon } className="tooltip-info-icon" />
                                    <SimpleTooltip direction="bottom">
                                        This email update will <br />
                                        be sent every following <br /> 
                                        month on the same day.
                                    </SimpleTooltip>
                                </div>
                            </div>
                            <DatePicker
                                className="manage-email-updates-date-picker"
                                allowClear
                                value={ this.state.startDate }
                                suffixIcon={ <FontAwesomeIcon icon={ openIcon }/> }
                                showNow={ false }
                                onChange={ (date) => this.handleChange("startDate", date) }
                                format="MM/DD/YYYY"
                                placeholder={ formatDate(getCurrentDate()) }
                            />
                        </div>
                        <div className={ "time-container " + this.getDateTimeContainerCss() }>
                            <div className="label">Time</div>
                            <TimePicker 
                                defaultTime = { this.state.time }
                                interval={ 30 } 
                                onChange={ (time) => this.handleChange("time", time.value) } 
                            />
                        </div>
                    </div>
                    <div className="enable-email-updates-action-container">
                        <div className="subtitle">Enable Email Updates</div>
                        <Pill 
                            uncheckedGreyColor 
                            checked={ this.state.active }
                            onChange={ (active) => this.handleChange("active", active) } 
                        />
                    </div>
                    <p>
                        You can temporarily disable the stats email updates but <br /> 
                        your preferred settings will still be stored.
                    </p>

                    <div className="buttons">
                        <Button
                            disabled={ this.state.loading }
                            variant="light"
                            className="cancel-button"
                            onClick={ this.onClose }
                        >
                            CANCEL
                        </Button>
                        <Button
                            disabled={ this.isConfirmButtonDisabled() }
                            variant="continue"
                            className="confirm-button"
                            onClick={ this.handleConfirm }
                        >
                            CONFIRM
                        </Button>
                    </div>
                </SimpleContainer>
            );
        }

        return (
            <Modal
                show={ this.props.show }
                onHide={ this.onClose }
                backdrop="static"
                keyboard={ false }
                centered
                dialogClassName="manage-email-updates-modal"
            >
                { modalContent }
            </Modal>
        )
    }
}

export default ManageEmailUpdatesPopup;
