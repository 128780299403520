import { snoozeReasonsUrl } from "global/constants";

export const snoozeReasons = async function () {
    const response = await this.context.get(snoozeReasonsUrl, {});

    if (response.status === "error") {
        console.error(response.message);
        return [];
    }

    return response.data.map(snoozeReason => (
        {
            value: snoozeReason,
            label: snoozeReason.reason
        }
    ));
}

export const snoozeDurations = [
    { value: 'PT30M', label: '30 minutes' },
    { value: 'PT1H', label: '1 hour' },
    { value: 'PT2H', label: '2 hours' },
    { value: 'PT3H', label: '3 hours' },
    { value: 'PT4H', label: '4 hours' },
    { value: 'PT5H', label: '5 hours' },
    { value: 'PT6H', label: '6 hours' },
    { value: 'PT7H', label: '7 hours' },
    { value: 'PT8H', label: '8 hours' },
    { value: 'PT9H', label: '9 hours' },
    { value: 'PT10H', label: '10 hours' },
]