import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import AuthContext from 'AuthContext';
import SimpleContainer from 'components/container/SimpleContainer';
import TimePicker from 'components/input/TimePicker';
import DayPicker from 'components/input/DayPicker';
import FleetSelect from 'components/input/FleetSelect';
import { contactGroupsUrl } from 'global/constants';

import './AddGroupPopup.scss';

export default class AddGroupPopup extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            initialGroup: this.props.group ? this.props.group : {},
            name: this.props.group && this.props.group.name,
            startDay: this.props.group && this.props.group.officeHours && this.props.group.officeHours.startDay,
            endDay: this.props.group && this.props.group.officeHours && this.props.group.officeHours.endDay,
            startTime: this.props.group && this.props.group.officeHours && this.props.group.officeHours.startTime,
            endTime: this.props.group && this.props.group.officeHours && this.props.group.officeHours.endTime,
            officeHours: this.props.group && !!this.props.group.officeHours,
            fleet: (this.props.group || {}).fleet,
            submittable: false,
        };

        this.onFleetChange = this.onFleetChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setState({
                initialGroup: this.props.group ? this.props.group : {},
                name: this.props.group && this.props.group.name,
                startDay: this.props.group && this.props.group.officeHours && this.props.group.officeHours.startDay,
                endDay: this.props.group && this.props.group.officeHours && this.props.group.officeHours.endDay,
                startTime: this.props.group && this.props.group.officeHours && this.props.group.officeHours.startTime,
                endTime: this.props.group && this.props.group.officeHours && this.props.group.officeHours.endTime,
                officeHours: this.props.group && !!this.props.group.officeHours,
                fleet: (this.props.group || {}).fleet,
                submittable: false,
            });
        }
    }

    async handleSubmit() {
        try {
            const officeHoursObject = {
                startDay: this.state.startDay || this.state.initialGroup.startDay,
                endDay: this.state.endDay || this.state.initialGroup.endDay,
                startTime: this.state.startTime || this.state.initialGroup.startTime,
                endTime: this.state.endTime || this.state.initialGroup.endTime
            }
            let response;
            if (this.props.edit) {
                response = await this.context.put(contactGroupsUrl, {
                    ...this.props.group,
                    name: (this.state.name || this.state.initialGroup.name).trim(),
                    officeHours: this.state.officeHours ? officeHoursObject : null,
                    fleet: this.state.fleet
                });
            } else {
                response = await this.context.post(contactGroupsUrl, {
                    name: (this.state.name || this.state.initialGroup.name).trim(),
                    contactInfos: [],
                    notificationRules: [],
                    officeHours: this.state.officeHours ? officeHoursObject : null,
                    fleet: this.state.fleet
                });
            }
            if (!response || response.status === "error") {
                this.props.closeModal();
                return;
            }
            this.props.closeModal(response, this.props.edit);
        } catch (error) {
            console.error(error);
            this.props.closeModal();
        }
    }

    validateForm() {
        this.setState({
            submittable: this.validOfficeHours() && this.validName() && (!this.props.edit || this.valuesChanged())
        });
    }

    validOfficeHours = () => !this.state.officeHours || (this.state.startDay && this.state.endDay && this.state.startTime && this.state.endTime)

    validName = () => this.state.name && /\S/.test(this.state.name)

    valuesChanged = () => {
        return this.state.name !== this.state.initialGroup.name ||
               this.state.officeHours !== !!this.state.initialGroup.officeHours ||
               this.state.startDay !== (this.state.initialGroup.officeHours && this.state.initialGroup.officeHours.startDay) ||
               this.state.endDay !== (this.state.initialGroup.officeHours && this.state.initialGroup.officeHours.endDay) ||
               this.state.startTime !== (this.state.initialGroup.officeHours && this.state.initialGroup.officeHours.startTime) ||
               this.state.endTime !== (this.state.initialGroup.officeHours && this.state.initialGroup.officeHours.endTime) ||
               (this.state.fleet || {}).id !== (this.state.initialGroup.fleet || {}).id
    }

    onChange(event) {
        const value = event.target.value;
        this.setState({
            name: value
        }, this.validateForm)
    }

    onOfficeHoursChange(event) {
        const checked = event.target.checked;
        this.setState({
            officeHours: checked,
            startDay: checked ? this.state.startDay : undefined,
            endDay: checked ? this.state.endDay : undefined,
            startTime: checked ? this.state.startTime : undefined,
            endTime: checked ? this.state.endTime : undefined,
        }, this.validateForm);
    }

    onClose = () => this.props.closeModal()

    onSelect = (state, selected) => {
        this.setState({
            [state]: selected.value
        }, this.validateForm);
    }

    onFleetChange(option) {
        this.setState({
            fleet: option.value
        }, this.validateForm);
    }

    render() {
        let defaultFleetOption;
        if ((this.props.group || {}).fleet) {
            defaultFleetOption = {
                label: this.props.group.fleet.name,
                value: this.props.group.fleet
            };
        } else {
            defaultFleetOption = {
                value: null,
                label: 'All Fleets'
            };
        }

        return (
            <Modal
                show={this.props.showModal}
                onHide={this.onClose}
                backdrop="static"
                keyboard={false}
                centered
                dialogClassName="add-group-modal"
            >
                <SimpleContainer className="modal-container" modal title={this.props.edit ? "EDIT GROUP" : "NEW GROUP"}>
                    <p className="action-label">Fleet:</p>
                    <FleetSelect
                        defaultLabel="All Fleets"
                        defaultOption={ defaultFleetOption }
                        onChange={ this.onFleetChange }
                    />
                    <p className="action-label">Group Name:</p>
                    <input
                        key={this.state.initialGroup.name}
                        type="text"
                        className="group-name-input"
                        maxLength="255"
                        required
                        placeholder="Driver Managers"
                        defaultValue={this.state.initialGroup.name}
                        onChange={this.onChange.bind(this)}
                    />
                    <div className="form-check">
                        <label style={this.state.officeHours ? { color: '#030D1D', fontWeight: 700 } : null} className="checkbox-label">
                            <input
                                key={this.state.initialGroup.officeHours}
                                type="checkbox"
                                className="form-check-input"
                                defaultChecked={this.state.officeHours}
                                onChange={this.onOfficeHoursChange.bind(this)}
                            />
                            Hours of Operation
                        </label>
                    </div>

                    {this.state.officeHours &&
                        <>
                            <div style={{ marginTop: 8 }} className="row">
                                <p className="action-label col-6 half-form">Start Day</p>
                                <p className="action-label col-6 half-form">End Day</p>
                            </div>

                            <div className="row">
                                <DayPicker
                                    placeholder="Monday"
                                    onChange={selected => this.onSelect("startDay", selected)}
                                    defaultDay={this.state.startDay}
                                    className="col-6 half-form"
                                />
                                <DayPicker
                                    placeholder="Friday"
                                    onChange={selected =>this.onSelect("endDay", selected)}
                                    defaultDay={this.state.endDay}
                                    className="col-6 half-form"
                                />
                            </div>

                            <div style={{ marginTop: 12 }} className="row">
                                <p className="action-label col-6 half-form">Start Time</p>
                                <p className="action-label col-6 half-form">End Time</p>
                            </div>

                            <div className="row">
                                <TimePicker
                                    placeholder="09:00AM"
                                    onChange={selected => this.onSelect("startTime", selected)}
                                    defaultTime={this.state.startTime}
                                    interval={30}
                                    className="col-6 half-form"
                                />
                                <TimePicker
                                    placeholder="05:00PM"
                                    onChange={selected => this.onSelect("endTime", selected)}
                                    defaultTime={this.state.endTime}
                                    interval={30}
                                    className="col-6 half-form"
                                />
                            </div>
                        </>
                    }

                    <div className="buttons">
                        <Button variant="light" onClick={this.onClose} className="cancel-button">Cancel</Button>
                        <Button
                            variant="continue"
                            className="add-button"
                            disabled={!this.state.submittable}
                            onClick={this.handleSubmit.bind(this)}
                        >
                            {this.props.edit ? "Edit" : "Add"} group
                        </Button>
                    </div>
                </SimpleContainer>
            </Modal>
        );
    }
}

AddGroupPopup.contextType = AuthContext;
