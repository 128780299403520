import React, { Component } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell as notificationsIcon, faTimesCircle as closeIcon } from '@fortawesome/free-solid-svg-icons';
import { faAlarmSnooze as snoozeIcon, faUser as userIcon, faUsers as usersIcon } from '@fortawesome/pro-solid-svg-icons';
import {
    managerCloseReasonNotesUrl,
    managerSnoozeReasonNotesUrl,
    closeReasonsUrl,
    contactGroupsUrl,
    notificationIssueTypeRulesUrl,
    notificationRulesUrl,
    snoozeReasonsUrl
} from 'global/constants';
import AuthContext from "AuthContext";
import history from "global/history";
import { check } from 'global/services/RoleCheckService';
import UserManagement from './UserManagement';
import NotificationsManagement from './NotificationsManagement';
import EmailRecipientGroups from './EmailRecipientGroups';
import CloseIssueReasons from './CloseIssueReasons';
import SnoozeIssueReasons from './SnoozeIssueReasons';

import "./Settings.scss";

class Settings extends Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            closeReasons: [],
            closeNotes: [],
            snoozeReasons: [],
            snoozeNotes: [],
            tab: this.props.tab,
            contactGroups: [],
            notificationRules: [],
            notificationIssueTypeRules: [],
        };
    }

    async componentDidMount() {
        try {
            let closeReasons = [];
            let closeNotes = [];
            let snoozeReasons = [];
            let snoozeNotes = [];
            let contactGroups = [];
            let notificationRules = [];
            let notificationIssueTypeRules = [];

            const closeResponse = await this.context.get(closeReasonsUrl, {});

            if (closeResponse.status !== "error") {
                closeReasons = closeResponse.data
            }

            const closeNotesResponse = await this.context.get(managerCloseReasonNotesUrl, {});

            if (closeNotesResponse.status !== "error") {
                closeNotes = closeNotesResponse.data
            }

            const snoozeResponse = await this.context.get(snoozeReasonsUrl, {});

            if (snoozeResponse.status !== "error") {
                snoozeReasons = snoozeResponse.data
            }

            const snoozeNotesResponse = await this.context.get(managerSnoozeReasonNotesUrl, {});

            if (snoozeNotesResponse.status !== "error") {
                snoozeNotes = snoozeNotesResponse.data
            }

            const contactGroupsResponse = await this.context.get(contactGroupsUrl, {});

            if (contactGroupsResponse.status !== "error") {
                contactGroups = contactGroupsResponse.data
            }

            const notificationRulesResponse = await this.context.get(notificationRulesUrl, {});

            if (notificationRulesResponse.status !== "error") {
                notificationRules = notificationRulesResponse.data
            }

            const issueTypeRulesResponse = await this.context.get(notificationIssueTypeRulesUrl, {});

            if (issueTypeRulesResponse.status !== "error") {
                notificationIssueTypeRules = issueTypeRulesResponse.data
            }

            this.setState({
                closeReasons,
                closeNotes,
                snoozeReasons,
                snoozeNotes,
                contactGroups,
                notificationRules,
                notificationIssueTypeRules,
            });
        } catch (error) {
            console.error(error);
            this.setState({
                closeReasons: [],
                closeNotes: [],
                snoozeReasons: [],
                snoozeNotes: [],
                contactGroups: [],
                notificationRules: [],
                notificationIssueTypeRules: [],
            });
        }
    }

    handleAdd(newReason, snooze) {
        if (snooze) {
            this.setState({
                snoozeReasons: [...this.state.snoozeReasons, newReason]
            });
        } else {
            this.setState({
                closeReasons: [...this.state.closeReasons, newReason]
            });
        }

    }

    handleUpdate(updatedReason, snooze) {
        if (snooze) {
            this.setState({
                snoozeReasons: this.state.snoozeReasons.map(reason => reason.id === updatedReason.id ? updatedReason : reason)
            });
        } else {
            this.setState({
                closeReasons: this.state.closeReasons.map(reason => reason.id === updatedReason.id ? updatedReason : reason)
            });
        }
    }

    handleDelete(deletedReason, snooze) {
        if (snooze) {
            this.setState({
                snoozeReasons: this.state.snoozeReasons.filter(reason => reason.id !== deletedReason.id)
            });
        } else {
            this.setState({
                closeReasons: this.state.closeReasons.filter(reason => reason.id !== deletedReason.id)
            });
        }
    }

    changeTab = (tab) => history.push("/settings/" + tab);

    addRule(rule) {
        this.setState({
            notificationRules: [...this.state.notificationRules, rule]
        });
    }

    updateRule(updatedRule) {
        this.setState({
            notificationRules: this.state.notificationRules.map(rule => rule.id === updatedRule.id ? updatedRule : rule)
        });
    }

    addContactGroup(group) {
        this.setState({
            contactGroups: [...this.state.contactGroups, group]
        });
    }

    updateContactGroup(updatedGroup) {
        this.setState({
            contactGroups: this.state.contactGroups.map(group => group.id === updatedGroup.id ? updatedGroup : group)
        });
    }

    deleteContactGroup(groupId) {
        this.setState({
            contactGroups: this.state.contactGroups.filter(group => group.id !== groupId)
        });
    }

    addMember(member, contactGroup) {
        this.setState({
            contactGroups: this.state.contactGroups.map(group => {
                if (group.id !== contactGroup.id || group.contactInfos.some(contact => contact.id === member.id)) {
                    return group;
                }
                return {...group, contactInfos: [ ...group.contactInfos, member ]};
            })
        });
    }

    render() {
        const tabs = [];

        if (this.context.role.some(role => check(role, 'settings:user-management:visit'))) {
            const userManagementTabTitle = <><FontAwesomeIcon icon={ userIcon } className="tab-icon" />User Management</>;

            const userManagementTab = (
                <Tab key="user-management" eventKey="user-management" title={ userManagementTabTitle } tabClassName="tab">
                    <UserManagement />
                </Tab>
            );

            tabs.push(userManagementTab);
        }

        if (this.context.role.some(role => check(role, 'settings:email-recipients:visit'))) {
            const emailRecipientsTabTitle = <><FontAwesomeIcon icon={ usersIcon } className="tab-icon" />Email Recipients</>;

            const emailRecipientsTab = (
                <Tab key="email-recipients" eventKey="email-recipients" title={ emailRecipientsTabTitle } tabClassName="tab">
                    <EmailRecipientGroups
                        contactGroups={ this.state.contactGroups }
                        addContactGroup={ this.addContactGroup.bind(this) }
                        updateContactGroup={ this.updateContactGroup.bind(this) }
                        deleteContactGroup={ this.deleteContactGroup.bind(this) }
                        addMember={ this.addMember.bind(this) }
                    />
                </Tab>
            );

            tabs.push(emailRecipientsTab);
        }

        if (this.context.role.some(role => check(role, 'settings:notifications:visit'))) {
            const notificationsTabTitle = <><FontAwesomeIcon icon={ notificationsIcon } className="tab-icon" />Notifications</>;

            const notificationsTab = (
                <Tab key="notifications" eventKey="notifications" title={ notificationsTabTitle } tabClassName="tab">
                    <NotificationsManagement
                        addRule={ this.addRule.bind(this) }
                        updateRule={ this.updateRule.bind(this) }
                        contactGroups={ this.state.contactGroups }
                        notificationRules={ this.state.notificationRules }
                        notificationIssueTypeRules={ this.state.notificationIssueTypeRules }
                    />
                </Tab>
            );

            tabs.push(notificationsTab);
        }

        if (this.context.role.some(role => check(role, 'settings:close-reasons:visit'))) {
            const closeIssueReasonsTabTitle = <><FontAwesomeIcon icon={ closeIcon } className="tab-icon" />Close Issue Reasons</>;

            const closeIssueReasonsTab = (
                <Tab key="close-reasons" eventKey="close-reasons" title={ closeIssueReasonsTabTitle } tabClassName="tab">
                    <CloseIssueReasons
                        closeReasons={ this.state.closeReasons }
                        closeNotes={ this.state.closeNotes }
                        handleAdd={ this.handleAdd.bind(this) }
                        handleUpdate={ this.handleUpdate.bind(this) }
                        handleDelete={ this.handleDelete.bind(this) }
                    />
                </Tab>
            );

            tabs.push(closeIssueReasonsTab);
        }

        if (this.context.role.some(role => check(role, 'settings:snooze-reasons:visit'))) {
            const snoozeIssueReasonsTabTitle = <><FontAwesomeIcon icon={ snoozeIcon } className="tab-icon" />Snooze Issue Reasons</>;

            const snoozeIssueReasonsTab = (
                <Tab key="snooze-reasons" eventKey="snooze-reasons" title={ snoozeIssueReasonsTabTitle } tabClassName="tab">
                    <SnoozeIssueReasons
                        snoozeReasons={ this.state.snoozeReasons }
                        snoozeNotes={ this.state.snoozeNotes }
                        handleAdd={ this.handleAdd.bind(this) }
                        handleUpdate={ this.handleUpdate.bind(this) }
                        handleDelete={ this.handleDelete.bind(this) }
                    />
                </Tab>
            );

            tabs.push(snoozeIssueReasonsTab);
        }

        return (
            <div className="container-fluid page settings">
                <div className="row">
                    <div className="col">
                        <h1 className="page-title">
                            Settings
                        </h1>
                    </div>
                </div>
                <Tabs
                    id="settings-tabs"
                    defaultActiveKey={ this.state.tab }
                    mountOnEnter
                    unmountOnExit
                    onSelect={ this.changeTab }
                >
                    { tabs }
                </Tabs>
            </div>
        )
    }
}

export default Settings;
