import React, { Component } from 'react';
import Tag from 'components/Tag';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder as folderClosed, faFolderOpen as folderOpen, faDownload as downloadIcon } from '@fortawesome/pro-solid-svg-icons';

import './CustomFileInput.scss';

export default class CustomFileInput extends Component {

    constructor(props) {
        super(props);

        this.onFileSelected = this.onFileSelected.bind(this);
        this.download = this.download.bind(this);
        this.onTagClose = this.onTagClose.bind(this);
    }

    fileInputRef = React.createRef();

    componentDidMount() {
        if (!this.props.file) {
            this.deselectFile();
        }
    }

    componentDidUpdate() {
        if (!this.props.file) {
            this.deselectFile();
        }
    }

    deselectFile() {
        this.fileInputRef.current.value = null;
    }

    determineUploadStyle() {
        return {
            icon: this.props.file ? folderOpen : folderClosed
        }
    }

    onTagClose() {
        this.props.onSelectedFileChange(null);
        this.deselectFile();
    }

    onFileSelected(event) {
        this.props.onSelectedFileChange(event.target.files[0]);
    }

    download() {
        this.props.downloadCsv();
    }

    render() {
        const { icon } = this.determineUploadStyle();

        let tag = <></>;
        if (this.props.file) {
            tag = <Tag 
                    text={ this.props.file.name }
                    closeable={ true }
                    onClose={ this.onTagClose }
                    wrongFileType={ this.props.wrongFileType }
                />;
        }

        return (
            <div className="upload-container">
                <div className="form-label">
                    <label>Upload File <span>(.csv)</span></label>
                    <button className="download-label" onClick={ this.download }>
                        <FontAwesomeIcon className="download-icon" icon={ downloadIcon }/>
                        CSV Template
                    </button>
                </div>
                <label className="upload-region">
                    <div className="upload-icon">
                        <FontAwesomeIcon icon={ icon }/>
                    </div>
                    <small className="upload-text">Select or drag and drop a file to this area to upload.</small>
                    <small className="upload-subtext">Max file size 2MB</small>
                    <input
                        type="file"
                        accept={ ".csv" }
                        ref={ this.fileInputRef }
                        className="file-input"
                        onChange={ this.onFileSelected }
                    />
                </label>
                { tag }
            </div>
        );
    }
}
