import React, { Component } from 'react';
import AuthProvider from 'AuthProvider';
import './AccountWidget.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

export default AuthProvider(class AccountWidget extends Component {

    constructor(props) {
        super(props)
        this.state = {
            logoutMenuOpen: false
        }
    }

    handleClick(e) {
        this.setState({logoutMenuOpen: !this.state.logoutMenuOpen});
    }

    render() {
        const account = this.props.account;
        if (!!account) {
            account.initials = account.name.split(" ").map((n)=>n[0]);
            return (
                <div className="account">
                  <span className="circle" onClick={this.handleClick.bind(this)}>
                      <div className="initials">
                        {account.initials}
                      </div>
                      <div className={this.state.logoutMenuOpen ? "visible" : "hidden"}>
                          <div className="submenu">
                              <div>
                            <span className="circle inside">
                                <div className="initials">
                                    {account.initials}
                                </div>
                            </span>
                            </div>
                            <div className="name inside">
                                {account.name}
                            </div>
                            <div className="username inside">
                                {account.userName}
                            </div>
                            <div className="logout inside">
                                <button className="logout-button" onClick={this.props.onSignOut}>                                
                                    <FontAwesomeIcon icon={faSignOutAlt} className="icon"></FontAwesomeIcon> 
                                    <span>&nbsp;&nbsp;</span>
                                    LOGOUT
                                </button>
                            </div>
                          </div>
                      </div>
                  </span>

                </div>
            );
        } else {
            return (
                <div className="account">
                    <button onClick={this.props.onSignIn}>Login</button>
                </div>
            );
        }
    }
})
