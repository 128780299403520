import React, { Component } from "react";

import "./LowFuelLevelTooltip.scss";

export default class LowFuelLevelTooltip extends Component {
    constructor(props) {
        super();

        this.state = {
            trailerId: props.trailerId,
            location: props.location,
            fuelLevel: props.fuelLevel,
            resolved: props.resolved
        }
    }

    componentDidUpdate(props) {
        if (props !== this.props) {
            this.setState({
                trailerId: this.props.trailerId,
                location: this.props.location,
                fuelLevel: this.props.fuelLevel,
                resolved: this.props.resolved
            });
        }
    }

    render() {
        return (
            <div className="low-fuel-level-tooltip">
                <div className="arrow-box">
                    <p className="low-fuel-title">
                        Fuel Level
                    </p>
                    <div className="inline-text">
                        <p className="info-label inline-info-label">
                            Trailer ID:
                        </p>
                        <p className="gathered-info inline-gathered-info">
                            {this.state.trailerId}
                        </p>
                    </div>
                    <hr className="divider"></hr>
                    <p className="info-label">
                        Last known address:
                    </p>
                    <p className="gathered-info">
                       {this.state.location}
                    </p>
                    <p className="info-label">
                        Fuel level:
                    </p>
                    <p className="gathered-info last">
                        <span className={`bold ${this.state.resolved ? "" : ((!this.state.fuelLevel && this.state.fuelLevel !== 0) || this.state.fuelLevel === "Unknown") ? "orangeColorText" : "redColorText"}`}>
                            {((!this.state.fuelLevel && this.state.fuelLevel !== 0) || this.state.fuelLevel === "Unknown") ? "Unknown" : this.state.fuelLevel} 
                            {((!this.state.fuelLevel && this.state.fuelLevel === 0) || this.state.fuelLevel) && this.state.fuelLevel !== "Unknown" && <>%</>}
                        </span>
                    </p>
                </div>
            </div>
        );
    }
}
