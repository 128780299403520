import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { COLORS } from 'global/constants';
import history from 'global/history';
import QueryParamService from 'global/services/QueryParamService';

import './UserCard.scss';

export default class UserCard extends Component {

    static defaultProps = {
        loading: false,
        seenCount: '-',
        closeCount: '-',
        snoozeCount: '-',
        user: {}
    }

    constructor(props) {
        super(props);

        this.onCardClick = this.onCardClick.bind(this);
    }

    onCardClick() {
        const user = this.props.user;

        history.push({
            pathname: `/statistics/user/${user.id}`,
            search: QueryParamService.parseParams(["startDate", "endDate", "issueTypes"]),
            state: { user }
        });
    }

    render() {
        return (
            <div className="user-card" onClick={ this.onCardClick }>
                <div className="name">
                    <span>
                        { this.props.user.name }
                    </span>
                    <Loader
                        type="TailSpin"
                        className="loader"
                        color={ COLORS.BLUE }
                        height={ 15 }
                        width={ 15 }
                        visible={ this.props.loading }
                    />
                </div>
                <div className="issue-count-heading row">
                    <div className="col-4">
                        Seen Issues
                    </div>
                    <div className="col-4">
                        Closed Issues
                    </div>
                    <div className="col-4">
                        Snoozed Issues
                    </div>
                </div>
                <div className="issue-count row">
                    <div className="col-4">
                        { this.props.seenCount }
                    </div>
                    <div className="col-4">
                        { this.props.closeCount }
                    </div>
                    <div className="col-4">
                        { this.props.snoozeCount }
                    </div>
                </div>
            </div>
        );
    }
}
