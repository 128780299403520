import React, { Component } from "react";

import { formatLongDateWithoutTime, formatTime, getDifferenceBetweenDates } from "global/services/DateTimeService";

import "./NotifiedPersonsTooltip.scss";

export default class NotifiedPersonsTooltip extends Component {
    constructor(props) {
        super();

        this.state = {
            title: props.title,
            time: props.firstTime && formatTime(props.firstTime),
            latestTime: props.latestTime ? formatTime(props.latestTime) : null,
            date: props.firstTime && formatLongDateWithoutTime(props.firstTime),
            latestDate: props.latestTime ? formatLongDateWithoutTime(props.latestTime) : null,
            emails: props.emails,
            snoozedIssue: props.snoozedIssue
        }

        this.seeNotificationHistory = this.seeNotificationHistory.bind(this);
    }

    componentDidUpdate(props) {
        if (props !== this.props) {
            this.setState({
                title: this.props.title,
                time: this.props.firstTime && formatTime(this.props.firstTime),
                latestTime: this.props.latestTime ? formatTime(this.props.latestTime) : null,
                date: this.props.firstTime && formatLongDateWithoutTime(this.props.firstTime),
                latestDate: this.props.latestTime ? formatLongDateWithoutTime(this.props.latestTime) : null,
                emails: this.props.emails,
                snoozedIssue: this.props.snoozedIssue
            });
        }
    }

    seeNotificationHistory() {
        this.props.seeNotificationHistory();
    }

    render() {
        return (
            <div className="notified-tooltip">
                <div className="arrow-box">
                    { this.state.emails && (
                        <>
                            <p className="mail-title">
                                { this.state.title }
                                <p className="blueColorText link see-history" onClick={this.seeNotificationHistory}>
                                    See History
                                </p>
                            </p>
                            <p className="info-label">
                                Sent to:
                            </p>
                            <div className="gathered-info">
                                { this.state.emails.map((el, index) => <div className="email-field" key={index}>{el} &nbsp; </div>) }
                            </div>
                            <p className="info-label">
                                Mail sent at:
                            </p>
                            <p className="gathered-info">
                                { this.state.date } at { this.state.time }
                            </p>
                        </>
                    ) }
                    
                    { this.state.snoozedIssue && (
                        <>
                            { this.state.emails && <hr className="divider" /> }
                            <p className="info-label">
                                Snoozed at: 
                            </p>
                            <p className="gathered-info">
                                { formatLongDateWithoutTime(new Date(this.state.snoozedIssue.snoozeStart))} at {formatTime(new Date(this.state.snoozedIssue.snoozeStart)) }
                            </p>
                            <p className="info-label">
                                Snoozed by: 
                            </p>
                            <p className="gathered-info">
                                { this.state.snoozedIssue.snoozedBy }
                            </p>
                            <p className="info-label">
                                Snoozed for: 
                            </p>
                            <p className="gathered-info">
                                { getDifferenceBetweenDates(this.state.snoozedIssue.snoozeStart, this.state.snoozedIssue.snoozeEnd) }
                            </p>
                            <p className="info-label">
                                Snooze reason: 
                            </p>
                            <p className="gathered-info last">
                                { this.state.snoozedIssue.snoozeReason.reason }
                            </p>
                            { this.state.snoozedIssue.snoozeReason.extended && this.state.snoozedIssue.note &&
                                <>
                                    <p className="info-label-heading">
                                        Additional note:
                                    </p>
                                    <p className="gathered-info">
                                        {this.state.snoozedIssue.note}
                                    </p>
                                </>
                            }
                        </>
                    ) }
                </div>
            </div>
        );
    }
}
