import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle as error, faExclamationTriangle as warning, faInfoCircle as info } from '@fortawesome/pro-solid-svg-icons';
import './Message.scss';

export default class Message extends Component {

    static defaultProps = {
        type: 'info', // Possible types: info, warning, error
        size: 'regular' // Possible sizes: regular, small
    };

    static iconMapping = {
        info,
        warning,
        error
    };

    render() {
        const icon = Message.iconMapping[this.props.type];

        return (
            <div className={ `message-card ${ this.props.type } ${ this.props.size }` }>
                <FontAwesomeIcon icon={ icon } className="icon" />
                <div>
                    { this.props.children }
                </div>
            </div>
        );
    }
}
