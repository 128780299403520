import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

import 'antd/dist/antd.css';
import './CustomSelect.scss';

const { Option } = Select;

export default class CustomSelect extends Component {

    static propTypes = {
        selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        values: PropTypes.arrayOf(PropTypes.object),
        fieldName: PropTypes.string,
        size: PropTypes.oneOf(['small', 'regular']),
        onSelect: PropTypes.func,
        customValueMapper: PropTypes.func,
    }

    static defaultProps = {
        selectedValue: [],
        values: [],
        fieldName: 'title',
        size: 'regular',
        onSearch: () => { /* */ },
        onSelect: () => { /* */ },
        customValueMapper: null
    }

    constructor(props) {
        super(props);

        this._onSelect = this._onSelect.bind(this);
        this._customValueMapper = this.props.customValueMapper || this._customValueMapper.bind(this);
    }

    _customValueMapper(value) {
        return value.label || value[this.props.fieldName];
    }

    _mapValuesToOptions(values) {
        return values.map(value => 
            <Option key={ JSON.stringify(value) } value={ value[this.props.fieldName] }>
                { this._customValueMapper(value) }
            </Option>
        );
    }

    _onSelect(key, options) {
        this.props.onSelect(key, options); 
    }

    render() {
        return (
            <Select
                id={ this.props.id }
                getPopupContainer={ trigger => trigger.parentNode }
                placeholder="Select..." 
                className={ `select ${ this.props.size }` }
                value={ this.props.selectedValue }
                suffixIcon={ <FontAwesomeIcon className="chevron-icon" icon={ faChevronDown } /> }
                onChange={ this._onSelect }
                filterOption={ false }
            >
                { this._mapValuesToOptions(this.props.values) }
            </Select>
        );
    }
}
