import rules from 'rbac-rules';

export function check(role, action, data) {
    const permissions = rules[role];
    if (!permissions) {
        // role is not present in the rules
        return false;
    }

    const actions = Array.isArray(action) ? action : [action];

    for (const actionItem of actions) {
        const staticPermissions = permissions.static;

        if (staticPermissions && staticPermissions.indexOf(actionItem) !== -1) {
            // static rule provided for action
            return true;
        }

        const dynamicPermissions = permissions.dynamic;

        if (dynamicPermissions) {
            const permissionCondition = dynamicPermissions[actionItem];
            if (permissionCondition) {
                // dynamic rule provided for action
                if (permissionCondition(data)) {
                    return true;
                }
            }
        }
    }

    return false;
}
